import { useQuery } from 'react-query';

import changeControlApi from '../../api/changeControl';
import { useMessagesContext } from '../../components/Messages';
import { MessageType } from '../../components/Messages/types';
import { isStaged } from '../../lib/isStaged';
import { DashboardItem } from '../../types/changeControl';

type UseDashboardItems = {
  isLoading: boolean;
  stagedItems: Array<DashboardItem>;
  unstagedItems: Array<DashboardItem>;
};

export const useDashboardItems = (company: number, product: string): UseDashboardItems => {
  const { addMessage } = useMessagesContext();
  const { data, isLoading } = useQuery(
    ['DASHBOARD', company, product],
    () => changeControlApi.retrieveDashboard(company, product),
    {
      onError: () => {
        addMessage({
          type: MessageType.DANGER,
          title: 'Failed to load dashboard',
        });
      },
    },
  );

  return {
    isLoading,
    stagedItems: (data ?? []).filter((item) => isStaged(item.changeControlStatus)),
    unstagedItems: (data ?? []).filter((item) => !isStaged(item.changeControlStatus)),
  };
};
