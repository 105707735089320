import React from 'react';

import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

import itemsApi from '../../api/items';
import { useCompanyId } from '../../context/CurrentUserContext';
import { useProduct } from '../../context/product';
import { FormRequirement } from '../../types/requirement';
import ProductRequirement from './component/ProductRequirement';

const CreateProductRequirement: React.FC = () => {
  const company = useCompanyId();
  const navigate = useNavigate();
  const product = useProduct();

  const { mutate, isLoading } = useMutation(
    ({ payload }: { payload: FormRequirement }) => itemsApi.create(company, product.id, { data: payload }),
    {
      onSuccess: () => {
        navigate(`/product/${product.id}/requirement`);
      },
    },
  );

  const onSubmit = async (requirement: FormRequirement) => {
    await mutate({ payload: requirement });
  };

  return <ProductRequirement product={product} onSubmit={onSubmit} isSubmitting={isLoading} />;
};

export default CreateProductRequirement;
