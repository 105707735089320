import { AxiosError } from 'axios';
import { ErrorOption } from 'react-hook-form';
import * as z from 'zod';
import { RefinementCtx } from 'zod/lib/types';

import { ServerSideValidationError, ServerSideValidationErrors } from '../types/form';
import { DataValidationIssue, RequestValidationError } from '../types/requestValidationError';

export const scrollWindowToTop = (): void => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });
};

export const scrollWindowToElementTop = (elementId: string): void => {
  const element = document.getElementById(elementId);
  window.scrollTo({
    top: Math.max(element ? element.clientTop - 50 : 0, 0),
    behavior: 'smooth',
  });
};

export const handleValidationErrors = (
  setError: (name: string, option: ErrorOption) => void,
  validationErrors: Array<RequestValidationError>,
  rootPath?: string,
  pathMap: { [key: string]: string } = {},
): void => {
  validationErrors.forEach((errors: RequestValidationError) => {
    const pathPrefix = rootPath ? `${rootPath}.${errors.path}.` : '';
    errors.errors.forEach((error: DataValidationIssue) => {
      const fieldPath = pathPrefix + (pathMap[error.path] ?? error.path);
      setError(fieldPath, { message: error.message, type: 'custom' });
    });
  });
};

export const handleFormErrors =
  (setError: (name: string, option: ErrorOption) => void) =>
  (error: AxiosError<ServerSideValidationErrors>): void => {
    const requestValidation = parseServerErrors(error);
    if (requestValidation) {
      handleValidationErrors(setError, [requestValidation]);
    }
  };

export const parseServerErrors = (
  error: AxiosError<ServerSideValidationErrors>,
): RequestValidationError | undefined => {
  if (error.response?.status === 400 && error.response.data?.errors) {
    return new RequestValidationError(
      '',
      error.response.data.errors.map(
        (item: ServerSideValidationError) =>
          new DataValidationIssue(item.field, item.message ? item.message[0] ?? '' : ''),
      ),
    );
  }
};

export const toRequestValidationError = (error: Error | RequestValidationError): RequestValidationError => {
  if (error instanceof RequestValidationError) {
    return error;
  } else {
    return new RequestValidationError('', [new DataValidationIssue('', 'An error has occurred. ' + error.message)]);
  }
};

export const filterValidationErrors = (error: Array<Error | RequestValidationError>): Array<RequestValidationError> => {
  if (Array.isArray(error)) {
    return error.map(toRequestValidationError);
  }
  return [toRequestValidationError(error)];
};

const isString = (content: string | undefined): content is string => content !== undefined;

export const verifyLabelsUnique = (items: Array<{ label?: string }>, ctx: RefinementCtx, fieldName: string): void => {
  const labelCounts = items
    .map((item) => item.label)
    .filter(isString)
    .reduce((labels: { [key: string]: number }, currentLabel) => {
      if (!labels[currentLabel]) {
        labels[currentLabel] = 0;
      }
      ++labels[currentLabel];
      return labels;
    }, {});
  items
    .map((item) => item.label)
    .filter(isString)
    .forEach((label, index) => {
      if (labelCounts[label] > 1) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: `${fieldName} must be unique`,
          path: [index, 'label'],
        });
      }
    });
};

export const triggerFieldValuesValidation = async (
  fieldCount: number,
  fieldName: string,
  trigger: (name: string | string[] | undefined) => Promise<boolean>,
): Promise<boolean> => {
  const fieldNames = Array(fieldCount)
    .fill('')
    .map((_v, i) => `${fieldName}.${i}.label`);
  return trigger(fieldNames);
};
