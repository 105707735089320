import React, { useMemo } from 'react';

import { QTabList, QTabs, QTab } from '@qualio/ui-components';
import { useLocation, useNavigate } from 'react-router-dom';

import { useQualityConfigs } from '../../context/qualityConfigs';
import { showConfigurationGroup } from '../../lib/designControlNativeEnabled';
import { DesignElementType } from '../../types/baseQualityItem';

const SettingsLeftNavigation: React.FC = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { configs } = useQualityConfigs();

  const showRequirements = showConfigurationGroup(configs, [
    DesignElementType.REQ1,
    DesignElementType.REQ2,
    DesignElementType.REQ3,
  ]);

  const showRequirementsNav = showRequirements || !configs.length;

  const showTestCases = showConfigurationGroup(configs, [
    DesignElementType.TEST_CASE_1,
    DesignElementType.TEST_CASE_2,
    DesignElementType.TEST_CASE_3,
  ]);
  const showTestCasesNav = showTestCases || !configs.length;

  const defaultIndex = useMemo(() => {
    const tabs = [];
    if (showRequirementsNav) {
      tabs.push('requirement');
    }
    if (showTestCasesNav) {
      tabs.push('testCases');
    }
    tabs.push('risk');

    const route = pathname.split('/').pop();
    const index = tabs.findIndex((item) => item === route);
    return index > -1 ? index : 0;
  }, [showRequirementsNav, showTestCasesNav, pathname]);

  return (
    <QTabs orientation="vertical" defaultIndex={defaultIndex} data-cy="settings-left-navigation">
      <QTabList>
        {showRequirementsNav && (
          <QTab data-cy="requirement-settings-left-navigation" onClick={() => navigate('requirement')}>
            Requirements
          </QTab>
        )}
        {showTestCasesNav && (
          <QTab data-cy="testcase-settings-left-navigation" onClick={() => navigate('testCases')}>
            Test cases & results
          </QTab>
        )}
        <QTab data-cy="risk-settings-left-navigation" onClick={() => navigate('risk')}>
          Risks
        </QTab>
      </QTabList>
    </QTabs>
  );
};

export default SettingsLeftNavigation;
