import React, { useMemo, useState } from 'react';

import { RequirementTrace } from '../../../types/trace';
import { requirementFilterFns, RequirementTraceFilterTypes } from '../components/filters';

export type UseFilteredRequirementsReturnType = {
  filteredRequirements: Array<RequirementTrace>;
  selectedFilter: RequirementTraceFilterTypes;
  setSelectedFilter: React.Dispatch<React.SetStateAction<RequirementTraceFilterTypes>>;
};

export const useFilteredRequirements = (requirements: Array<RequirementTrace>): UseFilteredRequirementsReturnType => {
  const [selectedFilter, setSelectedFilter] = useState<RequirementTraceFilterTypes>(RequirementTraceFilterTypes.ALL);
  const filteredRequirements = useMemo(
    () => requirementFilterFns[selectedFilter](requirements),
    [requirements, selectedFilter],
  );
  return { filteredRequirements, selectedFilter, setSelectedFilter };
};
