import React from 'react';

import { QTag } from '@qualio/ui-components';

import { riskLevelAndColor } from '../../views_new/Review/List/statusLabelAndColorTag';

export const RiskTag: React.FC<{ label?: string; riskLevels: Array<string> }> = ({ label, riskLevels }) => {
  const risk = riskLevelAndColor(label ?? '', riskLevels);
  return risk ? <QTag variantColor={risk?.variantColor}>{risk?.label}</QTag> : null;
};
