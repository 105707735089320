import { RetrieveDocumentsPayload } from '../../hooks/documents/useDocuments';
import { Company } from '../../types/currentUser';
import { RetrieveDocumentsResponse, Tag } from '../../types/document';
import api from './index';

export const MedtechAPI = {
  async getDocumentTags(companyId: Company['id']): Promise<Array<Tag>> {
    const res = await api.get('tags', { params: { company: companyId } });
    return Tag.array().parse(res.data);
  },
  async retrieveDocuments(
    companyId: Company['id'],
    { searchTerm, tagIds, offset, statuses, limit, sort }: RetrieveDocumentsPayload,
  ): Promise<RetrieveDocumentsResponse> {
    const searchParams = new URLSearchParams();

    offset && searchParams.append('offset', String(offset));
    limit && searchParams.append('limit', String(limit));
    sort?.column &&
      searchParams.append('order_by', `${sort.descending ? '-' : ''}${columnIdToSortKey[sort.column] ?? sort.column}`);
    statuses?.forEach((status) => searchParams.append('status_id', status));
    searchTerm && searchParams.append('query', searchTerm);
    tagIds?.forEach((tagId) => searchParams.append('tag_id', String(tagId)));

    const queryString = searchParams.toString() ? `?${searchParams.toString()}` : '';

    const response = await api.get(`/v2/${companyId}/documents${queryString}`);
    return RetrieveDocumentsResponse.parse(response.data);
  },
} as const;

const columnIdToSortKey: Record<string, string> = {
  owner: 'author_name',
};
