import React, { useCallback, useMemo } from 'react';

import { QButton, QFlex, QText } from '@qualio/ui-components';
import { IconNameType } from '@qualio/ui-components/lib/types/Icons';

import { useDrawerAwareness } from '../../components/CustomDrawer/context/useDrawerAwareness';
import { SearchAndSelectElementType } from '../SearchAndSelectElements/SearchAndSelectElement';
import { DesignElementGroups, getSelectedElementGroups } from './lib/getSelectedElementGroups';

export interface DesignElementGroupProps {
  elements: Array<SearchAndSelectElementType>;
  selectedElementComponent: React.FC<{
    element: SearchAndSelectElementType;
    handleSelectedElementOnClick?: (designElementId: string, designElementVersion: string) => void;
  }>;
  elementCallBackFunction: (designElementId: string, designElementVersion?: string) => void;
  handleSelectedElementOnClick?: (designElementId: string, designElementVersion: string) => void;
  buttonIconName?: IconNameType;
}

const DesignElementGroup: React.FC<DesignElementGroupProps> = ({
  elements,
  selectedElementComponent: SelectedElementComponent,
  elementCallBackFunction,
  handleSelectedElementOnClick,
  buttonIconName,
  children,
}) => {
  const groups = useMemo(() => getSelectedElementGroups(elements), [elements]);
  const { shouldDesignElementRedirectButtonBeEnabled } = useDrawerAwareness();

  const handleClickEvent = useCallback(
    (e: React.MouseEvent) => {
      const designElementId = e.currentTarget.getAttribute('target-id') as string;
      const designElementVersion = e.currentTarget.getAttribute('target-version') as string;
      elementCallBackFunction(designElementId, designElementVersion);
    },
    [elementCallBackFunction],
  );

  return (
    <>
      {[DesignElementGroups.REQUIREMENTS, DesignElementGroups.TESTCASES, DesignElementGroups.RISKS].map((group) => {
        const designElements = groups.get(group) ?? [];

        return (
          designElements.length > 0 && (
            <QFlex
              key={group}
              data-cy={`design-element-group-${group}`}
              marginTop={5}
              rowGap={3}
              flexDirection={'column'}
            >
              <QText fontSize={14} color="gray.700" weight="semibold" casing={'capitalize'}>
                {group}
              </QText>
              <QFlex direction={'column'} rowGap={1}>
                {designElements.map((element) => (
                  <SelectedElementComponent
                    key={element.id}
                    element={element}
                    handleSelectedElementOnClick={handleSelectedElementOnClick}
                  >
                    {shouldDesignElementRedirectButtonBeEnabled && (
                      <QButton
                        target-version={element.item.version}
                        target-id={element.id}
                        rightIcon={buttonIconName ?? 'ArrowRight'}
                        onClick={handleClickEvent}
                        variant="link"
                        data-cy={`selected-item-remove-${element.id}`}
                      >
                        &nbsp;
                      </QButton>
                    )}
                  </SelectedElementComponent>
                ))}
              </QFlex>
              {children}
            </QFlex>
          )
        );
      })}
    </>
  );
};

export default DesignElementGroup;
