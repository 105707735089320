import { RiskConfigurationType } from '../types/riskConfiguration';

export type RiskMatrixSize = '3x3' | '5x5';

export const INITIAL_RISK_PROBABILITY: Record<RiskMatrixSize, Array<{ label: string }>> = {
  '3x3': [{ label: 'Low' }, { label: 'Medium' }, { label: 'High' }],
  '5x5': [
    { label: 'Improbable' },
    { label: 'Remote' },
    { label: 'Occasional' },
    { label: 'Probable' },
    { label: 'Frequent' },
  ],
};

export const RISK_MATRIX_DIMENSION: Array<RiskMatrixSize> = ['3x3', '5x5'];

export const INITIAL_RISK_SEVERITY: Record<RiskMatrixSize, Array<{ label: string }>> = {
  '3x3': [{ label: 'Negligible' }, { label: 'Moderate' }, { label: 'Significant' }],
  '5x5': [{ label: 'Negligible' }, { label: 'Minor' }, { label: 'Serious' }, { label: 'Major' }, { label: 'Critical' }],
};

export const INITIAL_RISK_LEVEL_MAPPING: Record<RiskMatrixSize, Array<Array<string>>> = {
  '3x3': [
    ['Low', 'Low', 'Medium'],
    ['Low', 'Medium', 'Medium'],
    ['Medium', 'Medium', 'High'],
  ],
  '5x5': [
    ['Low', 'Low', 'Low', 'Low', 'Low'],
    ['Low', 'Low', 'Low', 'Medium', 'Medium'],
    ['Low', 'Low', 'Medium', 'Medium', 'High'],
    ['Low', 'Medium', 'Medium', 'High', 'High'],
    ['Medium', 'Medium', 'High', 'High', 'High'],
  ],
};

export const INITIAL_RISK_CONFIGURATION_VALUES: RiskConfigurationType = {
  prefix: 'RSK',
  label: 'Risk',
  statuses: [{ label: 'Draft' }, { label: 'Preliminary' }, { label: 'Complete' }],
  riskLevels: [
    {
      label: 'Low',
      requiresMitigation: 'No',
    },
    {
      label: 'Medium',
      requiresMitigation: 'No',
    },
    {
      label: 'High',
      requiresMitigation: 'Yes',
    },
  ],
  matrixDimension: '5x5',
  probability: INITIAL_RISK_PROBABILITY['5x5'],
  severity: INITIAL_RISK_SEVERITY['5x5'],
  levelMapping: INITIAL_RISK_LEVEL_MAPPING['5x5'],
  enableFmea: false,
};
