import React from 'react';

import { QTag } from '@qualio/ui-components';

import { BaseChangeControlStatus, QualityItemStatus } from '../../types/baseQualityItem';
import { ChangeControlStatus } from '../../types/changeControl';
import {
  changeControlLabelAndVariantColor,
  designElementStatusLabelAndColor,
} from '../../views_new/Review/List/statusLabelAndColorTag';

export type DesignControlTagProps = {
  changeControlStatus: BaseChangeControlStatus;
  itemStatus: QualityItemStatus;
};

export const convertBaseChangeControlToChangeControlStatus = (item: BaseChangeControlStatus): ChangeControlStatus => {
  switch (item) {
    case 'approved':
      return ChangeControlStatus.APPROVED;
    case 'not_approved':
      return ChangeControlStatus.NOT_APPROVED;
    default:
      return ChangeControlStatus.IN_PROGRESS;
  }
};

export const DesignControlTag: React.FC<DesignControlTagProps> = ({ changeControlStatus, itemStatus }) => {
  const status =
    changeControlStatus !== 'unstaged'
      ? changeControlLabelAndVariantColor(convertBaseChangeControlToChangeControlStatus(changeControlStatus))
      : designElementStatusLabelAndColor(itemStatus);
  return <QTag variantColor={status.variantColor}>{status.label}</QTag>;
};
