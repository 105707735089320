import React, { useMemo } from 'react';

import { QBox, QTab, QTabList, QTabs } from '@qualio/ui-components';
import { useMatch, useNavigate } from 'react-router-dom';

export const NAVIGATION_TABS = [
  { id: 'open-issues', title: 'Open Issues' },
  { id: 'dashboard', title: 'Dashboard' },
  { id: 'requirement', title: 'Requirements' },
  { id: 'risk', title: 'Risks' },
  { id: 'document', title: 'Documents' },
  { id: 'change-control', title: 'Reviews' },
];

type Props = {
  selectedKeys: string[];
};

export const Navigation: React.FC<Props> = ({ selectedKeys }) => {
  const navigate = useNavigate();
  const match = useMatch('/product/:product/*');

  const activeRouteId = useMemo((): number => {
    const activeTabId = NAVIGATION_TABS.findIndex((t) => selectedKeys.includes(t.id));
    return activeTabId !== -1 ? activeTabId : 2;
  }, [selectedKeys]);

  function navigateTo({ id }: any) {
    navigate(`${match?.pathnameBase}/${id}`);
  }

  return (
    <QBox mb="30px" data-cy="navigation">
      <QTabs index={activeRouteId}>
        <QTabList>
          {NAVIGATION_TABS.map((item) => (
            <QTab key={item.id} data-cy={`menu-${item.id}`} onClick={() => navigateTo(item)}>
              {item.title}
            </QTab>
          ))}
        </QTabList>
      </QTabs>
    </QBox>
  );
};
