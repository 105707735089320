import { useParams } from 'react-router-dom';

import { useRetrieveDesignElementById } from './useRetrieveDesignElementById';
import { useRetrieveDesignElementHistory } from './useRetrieveDesignElementHistory';

type DrawerParams = {
  selectedItemId: string;
};

export const useDeleteElementDrawer = () => {
  const { selectedItemId } = useParams<DrawerParams>() as DrawerParams;

  const designElement = useRetrieveDesignElementById(selectedItemId);
  const designElementHistory = useRetrieveDesignElementHistory(selectedItemId);

  return {
    isLoading: designElement.isLoading || designElementHistory.isLoading,
    designElement: designElement.data,
    designElementHistory: designElementHistory.data,
  };
};
