import React, { useMemo, useState } from 'react';

import { isTestCasePolicy, TestCaseList } from '@design-controls/types';
import {
  createQColumnHelper,
  DataProvider,
  Filtering,
  QDataTable,
  TableMenuItem,
  QDeleteConfirmationModal,
  QText,
  Pagination,
  QTag,
} from '@qualio/ui-components';
import { Outlet } from 'react-router';

import { useDeleteTestCase } from '../../../hooks/designElement/useDeleteTestCase';
import { useTestCaseList } from '../../../hooks/useTestCaseList';
import { TestResultElement } from '../../../types/testCase';
import { ResultValueToLabel } from '../../../views/TestCaseResult/testResultValueToLabel';
import { CategoryFilter } from '../../filter/CategoryFilter';
import { ShowOnlyOpen } from '../../filter/ShowOnlyOpen';
import { filterDefinitions, TestCaseType } from './filter';

const columnHelper = createQColumnHelper<TestCaseList>();

const filterOption = ((
  item: Readonly<TestCaseList>,
  searchTerm: string | undefined,
  filters: Filtering.ResolvedFilters<typeof filterDefinitions> | undefined,
) => {
  if (searchTerm && !item.title.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())) {
    return false;
  }

  if (filters && Object.values(filters).some((field) => field.value)) {
    const { show_only_open, type, category } = filters;

    return (
      (!show_only_open?.value || Object.values(item.policyIssues).some((issue) => issue)) &&
      (!type?.value || item.type === type.value) &&
      (!category?.value || category.value === item.category?.qri)
    );
  }

  return true;
}) as DataProvider.FilterOptionFn<TestCaseList>;

const testResultVariants: Record<TestResultElement['result'], 'green' | 'red' | 'gray'> = {
  passed: 'green',
  failed: 'red',
  blocked: 'gray',
};

export const TestList: React.FC = () => {
  const { isLoading, data } = useTestCaseList();
  const { isLoading: isDeleting, mutate: deleteElement } = useDeleteTestCase();
  const [itemToDelete, setItemToDelete] = useState<TestCaseList | null>(null);

  const columns = useMemo(() => {
    return [
      columnHelper.code('code', { header: 'ID', maxWidth: '100px' }),
      columnHelper.textLink('title', (record) => record.id, {
        header: 'Test case',
        weight: 1,
      }),
      columnHelper.text((record) => record.category?.label, { header: 'Category' }),
      columnHelper.date((record) => new Date(record.updated), { header: 'Last modified', maxWidth: '150px' }),
      {
        header: 'Last results',
        meta: {
          maxWidth: '150px',
        },
        cell: ({ row: { original: row } }: { row: { original: TestCaseList } }) => {
          if (!row.testResult) {
            return '';
          }

          return (
            <QTag variantColor={testResultVariants[row.testResult.result as never]}>
              {ResultValueToLabel[row.testResult.result as never]}
            </QTag>
          );
        },
      },
      columnHelper.menu({
        items: (
          <>
            <TableMenuItem onClick={console.log} data-cy="add-test-result">
              Add test result
            </TableMenuItem>
            <TableMenuItem onClick={console.log} data-cy="edit">
              Edit
            </TableMenuItem>
            <TableMenuItem onClick={(item: TestCaseList) => setItemToDelete(item)} color="red.500" data-cy="delete">
              Delete
            </TableMenuItem>
          </>
        ),
        hide: (record) => record.source !== 'product-development',
      }),
    ];
  }, []);

  return (
    <>
      <Pagination.Auto pageSizeKey="size" pageIndexKey="page" clientSide>
        <Filtering.FilterProvider definitions={filterDefinitions} searchTermKey="search">
          <DataProvider.Fixed data={data ?? []} isLoading={isLoading} filterOption={filterOption}>
            <QDataTable columns={columns} data-cy="test-list" getRowId={(row) => row.id}>
              <Filtering.FormContent>
                <TestCaseType />
                <CategoryFilter policyFilterFn={isTestCasePolicy} />
                <ShowOnlyOpen />
              </Filtering.FormContent>
            </QDataTable>
          </DataProvider.Fixed>
        </Filtering.FilterProvider>
      </Pagination.Auto>
      <Outlet />
      {itemToDelete && (
        <QDeleteConfirmationModal
          title="Delete test"
          onConfirm={() => {
            itemToDelete &&
              deleteElement(itemToDelete as any, {
                onSettled: () => setItemToDelete(null),
              });
          }}
          inProgress={isDeleting}
          onClose={() => setItemToDelete(null)}
          message={
            <QText fontSize="sm">
              Are you sure you want to delete {itemToDelete.codeTitle}? You cannot undo this action.
            </QText>
          }
        />
      )}
    </>
  );
};
