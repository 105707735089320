import React from 'react';

import {
  QButton,
  QEmptyState,
  QEmptyStateButtonGroup,
  QEmptyStateSecondaryButtonGroup,
  QualioQThemeProvider,
} from '@qualio/ui-components';
import { useNavigate } from 'react-router-dom';

import {
  INSUFFICIENT_USER_PERMISSION_EMPTY_STATE_BUTTON_LABEL,
  INSUFFICIENT_USER_PERMISSION_EMPTY_STATE_DIV_DATA_CY,
  INSUFFICIENT_USER_PERMISSION_EMPTY_STATE_SUBTITLE,
  INSUFFICIENT_USER_PERMISSION_EMPTY_STATE_TITLE,
} from './InsufficientUserPermissionState.constants';

export type InsufficientUserPermissionStateProps = {
  title?: string;
  subtitle?: string;
};

const InsufficientUserPermissionState: React.FC<InsufficientUserPermissionStateProps> = ({ ...props }) => {
  const navigate = useNavigate();
  const stateTitle = props?.title || INSUFFICIENT_USER_PERMISSION_EMPTY_STATE_TITLE;
  const stateSubtitle = props?.subtitle || INSUFFICIENT_USER_PERMISSION_EMPTY_STATE_SUBTITLE;

  return (
    <QualioQThemeProvider>
      <div data-cy={INSUFFICIENT_USER_PERMISSION_EMPTY_STATE_DIV_DATA_CY}>
        <QEmptyState title={stateTitle} subtitle={stateSubtitle}>
          <QEmptyStateButtonGroup>
            <QEmptyStateSecondaryButtonGroup>
              <QButton variant="outline" onClick={() => navigate('/')}>
                {INSUFFICIENT_USER_PERMISSION_EMPTY_STATE_BUTTON_LABEL}
              </QButton>
            </QEmptyStateSecondaryButtonGroup>
          </QEmptyStateButtonGroup>
        </QEmptyState>
      </div>
    </QualioQThemeProvider>
  );
};

export default InsufficientUserPermissionState;
