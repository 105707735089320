import React from 'react';

import {
  getChildrenOfType,
  QBadge,
  QCloseButton,
  QFlex,
  QFlexProps,
  QIconButton,
  QStack,
  QTag,
  QText,
} from '@qualio/ui-components';
import { QTagProps } from '@qualio/ui-components/lib/QAtoms/Tag/Tag';

import { DisplayTimestamp } from '../DisplayTimestamp/DisplayTimestamp';
import { DotSeparator } from '../DotSeparator/DotSeparator';
import { DetailsDrawerHeaderAction, DetailsDrawerHeaderBody } from './components';

type DetailsDrawerHeaderProps = QFlexProps & {
  code: string;
  title?: string;
  statusLabel?: string;
  status: Pick<QTagProps, 'variantColor'> & { label: string };
  updated?: { date: string; user?: string };
  created?: { date: string; user?: string };
  onBack: () => void;
  onClose: () => void;
};

export const DetailsDrawerHeader: React.FC<DetailsDrawerHeaderProps> = ({
  code,
  title,
  status,
  updated,
  created,
  statusLabel,
  onBack,
  onClose,
  children,
  ...props
}) => {
  const [action, body] = getChildrenOfType(children, DetailsDrawerHeaderAction.name, DetailsDrawerHeaderBody.name);

  return (
    <QFlex flexDirection="column" rowGap={3} width="100%" data-cy={`details-drawer-header`} {...props}>
      <QFlex position={'relative'}>
        <QBadge data-cy={`details-drawer-change-control-code`}>{code}</QBadge>
      </QFlex>
      <QFlex justifyContent={'space-between'} columnGap={2}>
        <QFlex columnGap={2}>
          <QIconButton data-cy={`details-drawer-back-button`} iconName="ArrowLeft" aria-label="Back" onClick={onBack} />
          <QText datay-cy="details-drawer-title" fontSize={20} weight="semibold" style={{ wordBreak: 'break-word' }}>
            {title}
          </QText>
        </QFlex>
        <QFlex columnGap={2}>
          {action}
          <QCloseButton onClick={onClose} data-cy={'details-drawer-close-button'} />
        </QFlex>
      </QFlex>
      <QStack direction="row" spacing="5px" alignItems="center">
        <QTag variantColor={status.variantColor}>{status.label}</QTag>
        {updated && (
          <>
            <DotSeparator />
            <DisplayTimestamp type={'modified'} date={updated.date} user={updated.user} />
          </>
        )}
        {created && (
          <>
            <DotSeparator />
            <DisplayTimestamp type={'created'} date={created.date} user={created.user} />
          </>
        )}
        {statusLabel && (
          <>
            <DotSeparator />
            <QText fontSize="xs" color="gray.500">
              {statusLabel}
            </QText>
          </>
        )}
      </QStack>
      {body && <QFlex>{body}</QFlex>}
    </QFlex>
  );
};

DetailsDrawerHeader.displayName = DetailsDrawerHeader.name;
