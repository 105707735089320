import { theme as qualioTheme } from '@qualio/ui-components';

export const theme = {
  ...qualioTheme,
  colors: {
    ...qualioTheme.colors,
    gray: {
      ...qualioTheme.colors.gray,
      10: '#F1F1F1',
    },
  },
};
