import React, { useMemo } from 'react';

import { QDivider, QFlex, QText, QStack } from '@qualio/ui-components';

import { FeatureFlags } from '../../../components/FeatureToggle/FeatureToggle.enum';
import useFeatureFlag from '../../../components/FeatureToggle/hooks/useFeatureFlag';
import { ItemRelationType, Relation } from '../../../components/QualityItemDetails/types';
import { DesignElementType } from '../../../types/baseQualityItem';
import { ChangeHistory } from '../../../types/changeHistory';
import { isFMEARisk, isISORisk } from '../../../types/risk';
import { RiskTrace } from '../../../types/trace';
import DesignElementDisplay from '../../DesignElementDisplay/DesignElementDisplay';
import DesignElementGroup from '../../DesignElementGroup/DesignElementGroup';
import { ReviewDisplayElement } from '../../ReviewDisplayElement/ReviewDisplayElement';
import { riskRelatedItemsByType } from '../lib/mapRelationToDesignElementDisplayItem';
import { FmeaRiskInitialAssessment } from './FmeaRiskInitialAssessment';
import { FmeaRiskMitigation } from './FmeaRiskMitigation';
import { ISORiskInitialAssessment } from './ISORiskInitialAssessment';
import { ISORiskMitigation } from './ISORiskMitigation';

export const RiskDetailsDrawerBody: React.FC<{
  designElement: RiskTrace;
  relatedDesignElements: Array<Relation>;
  reviewHistory: ChangeHistory;
  configsMap: Record<DesignElementType, string>;
  reviewClick?: (id: string) => void;
  designElementClick: (designElementId: string, designElementVersion?: string) => void;
  riskLevels: string[];
  fmeaThreshold: number | undefined;
}> = ({
  designElement,
  relatedDesignElements,
  reviewHistory,
  configsMap,
  reviewClick,
  designElementClick,
  riskLevels,
  fmeaThreshold,
}) => {
  const features = useFeatureFlag([FeatureFlags.DESIGN_CONTROLS_CATEGORIES]);
  const relatedSourceItems = useMemo(
    () => riskRelatedItemsByType(relatedDesignElements, configsMap, ItemRelationType.SOURCED_BY),
    [relatedDesignElements, configsMap],
  );

  const relatedMitigationItems = useMemo(
    () => riskRelatedItemsByType(relatedDesignElements, configsMap, ItemRelationType.MITIGATED_BY),
    [relatedDesignElements, configsMap],
  );

  return (
    <QFlex data-cy={'risk-design-element-drawer'} marginTop={5} marginBottom={5} rowGap={3} flexDirection={'column'}>
      {designElement.category && features[FeatureFlags.DESIGN_CONTROLS_CATEGORIES] && (
        <QStack data-cy="drawer-category" marginTop={5} marginBottom={5} flexDirection="row" columnGap={2}>
          <QText fontSize="sm" weight="semibold">
            Category
          </QText>
          <QText fontSize="sm">{designElement.category.label}</QText>
        </QStack>
      )}
      {isISORisk(designElement) && <ISORiskInitialAssessment risk={designElement} riskLevels={riskLevels} />}
      {isFMEARisk(designElement) && <FmeaRiskInitialAssessment risk={designElement} fmeaThreshold={fmeaThreshold} />}
      {relatedSourceItems.length > 0 && (
        <>
          <QFlex data-cy={'risk-initial-assessment-sources'} marginBottom={5} flexDirection={'column'}>
            <QText fontSize={'md'} weight="semibold">
              Impacted Design Elements
            </QText>
            <DesignElementGroup
              elements={relatedSourceItems}
              selectedElementComponent={DesignElementDisplay}
              elementCallBackFunction={designElementClick}
            />
          </QFlex>
        </>
      )}
      <QDivider />
      {isISORisk(designElement) && <ISORiskMitigation risk={designElement} riskLevels={riskLevels} />}
      {isFMEARisk(designElement) && <FmeaRiskMitigation risk={designElement} fmeaThreshold={fmeaThreshold} />}
      {relatedMitigationItems.length > 0 && (
        <>
          <QFlex data-cy={'risk-mitigation-items'} marginTop={5} marginBottom={5} flexDirection={'column'}>
            <QText fontSize={'md'} weight="semibold">
              Design Elements Associated With The Mitigation
            </QText>
            <DesignElementGroup
              elements={relatedMitigationItems}
              selectedElementComponent={DesignElementDisplay}
              elementCallBackFunction={designElementClick}
            />
          </QFlex>
          <QDivider />
        </>
      )}
      <QFlex data-cy={'design-element-drawer-review-history'} marginTop={5} marginBottom={5} flexDirection={'column'}>
        <QText color="gray.700" fontSize={14} weight="semibold">
          Review History
        </QText>
        {reviewHistory.length > 0 && <ReviewDisplayElement reviews={reviewHistory} reviewClick={reviewClick} />}
      </QFlex>
    </QFlex>
  );
};
