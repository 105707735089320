import { useCurrentUser } from '@qualio/ui-components';
import { useQuery } from 'react-query';

import { GlobalSearchAPI } from '../../api/global-search/search';

export const useResourceLibraryQuickSearch = (resourceSubType: string) => {
  const { companyId } = useCurrentUser();

  return useQuery({
    queryKey: ['SEARCH', companyId, resourceSubType],
    queryFn: () => GlobalSearchAPI.quickSearchResourceItem(companyId.toString(10), resourceSubType),
  });
};
