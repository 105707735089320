import styled from 'styled-components';

export const Menu = styled.nav`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  border: ${(props: any) => `${props.theme.space[1]} solid #b3ceff`};
  border-radius: ${(props: any) => props.theme.space[3]};
  margin-bottom: ${(props: any) => props.theme.space[6]};
`;

export const MenuItem = styled.div<{ isActive?: boolean; isDisabled?: boolean }>`
  width: 100%;
  text-align: center;
  margin: ${(props: any) => props.theme.space[2]};
  border-radius: ${(props: any) => props.theme.space[3]};

  p {
    padding: ${(props: any) => props.theme.space[3]} 0px;
    margin-bottom: 0px !important;
  }
  ${({ isDisabled }) =>
    !isDisabled
      ? `cursor: pointer;
    :hover {
    background-color: #b3ceff;
    p {
      color: #0069ff;
    }
  }
  `
      : `
    p {
      color: #B6B7C2;
    }
  `}

  ${({ isActive, isDisabled }) =>
    isActive &&
    `
    ${
      isDisabled
        ? `
            background-color: #B2CEFF;
            p {
                color: white;
            }
          `
        : `
            background-color: #0069FF;
            p {
                color: white;
            }
            :hover {
              background-color: #0069FF;
            p {
              color: white;
            }
          `
    }
    
    `}
`;
