import * as z from 'zod';

import { Prefix } from '../context/qualityConfigTypes';
import { verifyLabelsUnique } from '../lib/formValidationUtilities';
import { prefixDoesNotExist } from '../lib/prefixDoesNotExist';
import { BaseConfiguration, BaseConfigurationType } from './baseConfiguration';
import { BaseQualityTypeConfig, RiskQualityTypeConfig } from './qualityTypeConfig';

const isSizeValid = (values: any[]) => values.length === 3 || values.length === 5;

const isRiskMatrixSizeValid = (riskMatrixDimension: number, levelMappings: string[][], matrixSizeProperty: string) => {
  if (levelMappings.length !== riskMatrixDimension) {
    return false;
  }

  for (const row of levelMappings) {
    if (row.length !== riskMatrixDimension) {
      return false;
    }
  }

  return `${levelMappings.length}x${riskMatrixDimension}` === matrixSizeProperty;
};

export const RiskConfiguration = z
  .object({
    riskLevels: z
      .array(
        z.object({
          label: z.string().min(1).max(20),
          requiresMitigation: z.union([z.literal('Yes'), z.literal('No')]),
        }),
      )
      .superRefine((items, ctx) => verifyLabelsUnique(items, ctx, 'Risk Levels')),
    matrixDimension: z.union([z.literal('3x3'), z.literal('5x5')]),
    probability: z.array(z.object({ label: z.string().min(1) })),
    severity: z.array(z.object({ label: z.string().min(1) })),
    levelMapping: z.array(z.array(z.string())),
    enableFmea: z.boolean(),
    scaleMaxValue: z
      .object({ label: z.number({ invalid_type_error: 'Must be a number' }).nonnegative().optional() })
      .optional(),
    mitigationThreshold: z.number({ invalid_type_error: 'Must be a number' }).nonnegative().optional(),
  })
  .merge(BaseConfiguration)
  .refine((data) => isSizeValid(data.probability), 'Probability list size is invalid')
  .refine((data) => isSizeValid(data.severity), 'Severity list size is invalid')
  .refine(
    (data) => data.probability.length === data.severity.length,
    'Probability and severity lists are not same size',
  )
  .refine(
    (data) => isRiskMatrixSizeValid(data.probability.length, data.levelMapping, data.matrixDimension),
    'RiskMatrix size is invalid',
  )
  .superRefine((data, ctx) => {
    const levels = new Set(data.riskLevels.map((item) => item.label));
    data.levelMapping.forEach((rows, rowIndex) =>
      rows.forEach((value, columnIndex) => {
        if (levels.has(value)) {
          return;
        }
        ctx.addIssue({
          code: z.ZodIssueCode.too_small,
          minimum: 1,
          type: 'string',
          inclusive: true,
          message: 'Invalid risk level',
          path: ['levelMapping', rowIndex, columnIndex],
        });
      }),
    );

    if (!data.enableFmea) {
      return;
    }

    if (!data.scaleMaxValue?.label) {
      ctx.addIssue({
        code: z.ZodIssueCode.too_small,
        minimum: 1,
        type: 'number',
        inclusive: true,
        message: 'Missing scaling value',
        path: ['scaleMaxValue', 'label'],
      });
    }

    if (!data.mitigationThreshold) {
      ctx.addIssue({
        code: z.ZodIssueCode.too_small,
        minimum: 1,
        type: 'number',
        inclusive: true,
        message: 'Mitigation threshold value required',
        path: ['mitigationThreshold'],
      });
    }
  });

export type RiskConfigurationType = z.infer<typeof RiskConfiguration>;

export const riskConfigurationSchemaProvider = (usedPrefixes: Prefix[], archivePrefixes: Array<string>): any => {
  return RiskConfiguration.refine((data) => prefixDoesNotExist(data.prefix, 'risk', usedPrefixes, archivePrefixes), {
    message: 'Prefix must be unique',
    path: ['prefix'],
  });
};

export const mapRiskQualityTypeToRiskConfiguration = (config: RiskQualityTypeConfig): RiskConfigurationType => ({
  riskLevels: config.assessment.iso.levels.map((level) => ({
    label: level,
    requiresMitigation: config.assessment.iso.acceptableLevels.some((item) => item === level) ? 'No' : 'Yes',
  })),
  matrixDimension: config.assessment.iso.levelMapping.length === 5 ? '5x5' : '3x3',
  probability: config.assessment.iso.probability.map((probability) => ({ label: probability })),
  severity: config.assessment.iso.severity.map((severity) => ({ label: severity })),
  levelMapping: config.assessment.iso.levelMapping,
  enableFmea: config.assessment.fmea !== undefined,
  scaleMaxValue: { label: (config.assessment.fmea || {}).scaleMaxValue! },
  mitigationThreshold: config.assessment.fmea?.mitigationThreshold,
  prefix: config.codePrefix || '',
  label: config.label,
  statuses: config.workflow.states.map((item) => ({ label: item.name })),
});

export const mapTestCaseToDesignElementConfigType = (testCaseConfig: BaseConfigurationType): BaseQualityTypeConfig =>
  mapConfigurationToDesignElementConfiguration(testCaseConfig);

export const mapRequirementToBaseQualityType = (baseConfig: BaseConfigurationType): BaseQualityTypeConfig =>
  mapConfigurationToDesignElementConfiguration(baseConfig);

const mapConfigurationToDesignElementConfiguration = (config: BaseConfigurationType): BaseQualityTypeConfig => ({
  label: config.label,
  codePrefix: config.prefix,
  codeStrategy: 'GENERATED',
  workflow: {
    states: config.statuses.map((status) => ({ name: status.label, isReleasable: false })),
  },
});

export const mapRiskConfigurationTORiskQualityType = (formConfig: RiskConfigurationType): RiskQualityTypeConfig => ({
  assessment: {
    iso: {
      acceptableLevels: formConfig.riskLevels
        .filter((item) => item.requiresMitigation === 'No')
        .map((item) => item.label),
      levelMapping: formConfig.levelMapping,
      levels: formConfig.riskLevels.map((item) => item.label),
      probability: formConfig.probability.map((item) => item.label),
      severity: formConfig.severity.map((item) => item.label),
    },
    fmea: formConfig.enableFmea
      ? {
          scaleMaxValue: (formConfig.scaleMaxValue || {}).label!,
          mitigationThreshold: formConfig.mitigationThreshold!,
        }
      : undefined,
  },
  label: formConfig.label,
  codePrefix: formConfig.prefix,
  codeStrategy: 'GENERATED',
  workflow: {
    states: formConfig.statuses.map((item) => ({ name: item.label, isReleasable: false })),
  },
});
