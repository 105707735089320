import styled from 'styled-components';

export const ButtonContainer = styled.div<{ isLast: boolean }>`
  margin-top: ${({ theme }) => theme.space[6]};
  margin-left: ${({ isLast }) => isLast && `50px`};
`;

export const RequirementLevelCards = styled.div<{ isLast: boolean }>`
  display: flex;
  flex-direction: row;
  margin-top: ${({ theme }) => theme.space[6]};

  margin-left: ${({ isLast }) => isLast && `50px`};

  .arrow-svg {
    width: 35px;
    height: 35px;
    margin-right: 20px;
  }

  .basic-configuration-card {
    margin-top: 0;
  }
`;
