import React from 'react';

import { Tooltip } from 'antd';

type Props = {
  code?: string;
  shorten?: boolean;
} & React.HTMLAttributes<HTMLElement>;

const MAX_DISPLAY_LENGTH = 10;

const ItemCode: React.FC<Props> = ({ code, shorten = true, className, ...other }) => {
  const shouldBeShortened = shorten && (code || '').length > MAX_DISPLAY_LENGTH;

  if (shouldBeShortened) {
    return (
      <Tooltip title={code}>
        <strong className={className} {...other}>
          {(code || '').substring(0, MAX_DISPLAY_LENGTH)}…
        </strong>
      </Tooltip>
    );
  } else {
    return (
      <strong className={className} {...other}>
        {code}
      </strong>
    );
  }
};

export default ItemCode;
