import React, { useCallback, useMemo } from 'react';

import { Product } from '@design-controls/types';
import { QBox, QButton, QFlex, QHeading, QIconButton, QMenuButton, QMenuItemType, QText } from '@qualio/ui-components';
import { useNavigate } from 'react-router-dom';

import { UserPermissions } from '../../components';
import { FeatureFlags } from '../../components/FeatureToggle/FeatureToggle.enum';
import hasPermission from '../../components/UserGroupToggle/lib/hasPermission';
import { useAnalytics } from '../../context/analytics';
import { useUserGroups } from '../../context/CurrentUserContext';
import { useQualityConfigs } from '../../context/qualityConfigs';
import { ContextMenuItems } from '../../types/contextMenuItems';
import { DisabledFeatureFlag, FeatureToggle } from '../FeatureFlag/FeatureFlag';

export type HeaderProps = {
  product: Product | undefined;
  goBack?: () => void;
};

const Intercom =
  (window as any).Intercom ??
  function () {
    return undefined;
  };

export const Header: React.FC<HeaderProps> = ({ product, goBack }) => {
  const navigate = useNavigate();
  const [userGroups] = useUserGroups();
  const { configs, loading } = useQualityConfigs();
  const analytics = useAnalytics();

  const optionClicked = useCallback(
    (option: QMenuItemType) => {
      switch (option.id) {
        case ContextMenuItems.editProduct.id:
          navigate(`/product/edit/${product?.id}`);
          break;
        case ContextMenuItems.configurePolicies.id:
          navigate(`/product/${product?.id}/config`);
          break;
      }
    },
    [navigate, product],
  );

  const moreOptions: Array<QMenuItemType> = [];
  if (hasPermission(userGroups, UserPermissions.MANAGE_PRODUCT)) {
    moreOptions.push(ContextMenuItems.editProduct);
  }
  if (hasPermission(userGroups, UserPermissions.EDIT_DESIGN_ELEMENT_POLICIES)) {
    moreOptions.push(ContextMenuItems.configurePolicies);
  }

  const onClick = useCallback(() => {
    if (goBack) {
      return goBack();
    }

    navigate('/product');
  }, [navigate, goBack]);

  const addIntegration = useCallback(() => {
    analytics.track('PD - Setup Integration', {
      productId: product?.id,
    });
    Intercom('showNewMessage', 'I want to add an integration to Design Controls');
  }, [product, analytics]);

  const hasIntegrations = useMemo(() => {
    return configs.some((config) => config.integrations.length !== 0);
  }, [configs]);

  if (loading) {
    return null;
  }

  return (
    <>
      <QHeading as="h1" size={'lg'} data-cy="heading">
        <QFlex justify={'space-between'}>
          <QFlex>
            <QBox data-cy="heading-back-btn">
              <QIconButton iconName="ArrowLeft" aria-label="Back" onClick={onClick} />
            </QBox>
            <QText noOfLines={1}>{product?.name}</QText>
          </QFlex>
          <QBox>
            <QFlex>
              {!hasIntegrations && (
                <FeatureToggle feature={FeatureFlags.SELF_SERVICE}>
                  <DisabledFeatureFlag>
                    <QButton variant={'link'} onClick={addIntegration}>
                      Setup integration
                    </QButton>
                  </DisabledFeatureFlag>
                </FeatureToggle>
              )}
              {moreOptions.length > 0 && (
                <QBox data-cy="heading-more-btn" marginTop={'-5px'}>
                  <QMenuButton
                    variant="icon"
                    buttonLabel="Header More"
                    iconName="MoreVertical"
                    items={moreOptions}
                    onItemClick={optionClicked}
                  />
                </QBox>
              )}
            </QFlex>
          </QBox>
        </QFlex>
      </QHeading>
    </>
  );
};
