import React, { useCallback } from 'react';

import { QBadge, QButton, QStack, QTag } from '@qualio/ui-components';

import { ChangeHistory } from '../../types/changeHistory';
import { changeControlLabelAndVariantColor } from '../../views_new/Review/List/statusLabelAndColorTag';
import { DisplayElement, DisplayElementBody } from '../DisplayElement';

type ReviewDisplayElementProps = {
  reviews: ChangeHistory;
  reviewClick?: (id: string) => void;
};

export const ReviewDisplayElement: React.FC<ReviewDisplayElementProps> = ({ reviews, reviewClick }) => {
  const handleClickEvent = useCallback(
    (e: React.MouseEvent) => {
      if (reviewClick) {
        reviewClick(e.currentTarget.getAttribute('review-id') as string);
      }
    },
    [reviewClick],
  );

  return (
    <>
      {reviews.map((review) => {
        const statusLabelAndColor = changeControlLabelAndVariantColor(review.status);
        return (
          <DisplayElement key={review.id}>
            <DisplayElementBody width="100%">
              <QStack direction="column" width="100%">
                <QStack direction="row" justify="space-between">
                  <QBadge data-cy={`selected-review-code-${review.id}`}>{review.code}</QBadge>
                  <QStack direction="row" spacing="10px" alignItems="center">
                    <QTag
                      data-cy={`selected-review-status-${review.id}`}
                      variantColor={statusLabelAndColor.variantColor}
                    >
                      {statusLabelAndColor.label}
                    </QTag>
                  </QStack>
                </QStack>
              </QStack>
              {reviewClick && (
                <QButton
                  review-id={review.id}
                  data-cy={`selected-review-click-${review.id}`}
                  rightIcon={'ArrowRight'}
                  onClick={handleClickEvent}
                  variant="link"
                >
                  &nbsp;
                </QButton>
              )}
            </DisplayElementBody>
          </DisplayElement>
        );
      })}
    </>
  );
};
