import React, { ReactNode } from 'react';

import {
  CheckCircleFilled,
  ExclamationCircleFilled,
  InfoCircleFilled,
  InfoCircleOutlined,
  WarningFilled,
} from '@ant-design/icons';

import styles from './Messages.module.less';
import { MessageType } from './types';

export function messageTypeCls(type: MessageType): string {
  switch (type) {
    case MessageType.SUCCESS:
      return styles.success;
    case MessageType.DANGER:
      return styles.danger;
    case MessageType.INFO:
      return styles.info;
    case MessageType.WARNING:
      return styles.warning;
    case MessageType.NEUTRAL:
      return styles.neutral;
  }
}

export function messageTypeIcon(type: MessageType): ReactNode {
  switch (type) {
    case MessageType.SUCCESS:
      return <CheckCircleFilled />;
    case MessageType.DANGER:
      return <WarningFilled />;
    case MessageType.INFO:
      return <InfoCircleFilled />;
    case MessageType.WARNING:
      return <ExclamationCircleFilled />;
    case MessageType.NEUTRAL:
      return <InfoCircleOutlined />;
  }
}
