import { useFlags } from 'launchdarkly-react-client-sdk';

import { FeatureFlags } from '../FeatureToggle.enum';
import { hasFeatureCookie } from '../hasFeatureCookie';

const useFeatureFlag = (features: Array<FeatureFlags>): Record<string, boolean> => {
  const flags = useFlags();

  return features.reduce((table: Record<string, boolean>, feature): Record<string, boolean> => {
    table[feature] = hasFeatureCookie(feature) || flags[feature];

    return table;
  }, {});
};

export default useFeatureFlag;
