import { DESIGN_CONTROLS_SOURCE } from '../../lib/source';
import { DesignElementType, QualityItemStatus } from '../../types/baseQualityItem';
import { Requirement } from '../../types/requirement';
import { RequirementTrace } from '../../types/trace';
import { makeResponse } from './generic';
import { req1QualityItems } from './qualityItems';

export const requirementTraceResponse = makeResponse<RequirementTrace[]>([
  {
    ...req1QualityItems[0],
    children: [
      {
        id: '63cbaf7e-440a-4c7f-ac16-8069d8d0ca31',
        created: new Date().toISOString(),
        updated: new Date().toISOString(),
        statusLabel: 'Some other Label',
        source: 'Jira',
        type: DesignElementType.REQ2,
        code: 'DI-5',
        url: 'http://example.com',
        title: 'This is a mid level requirement 1',
        description: 'Very interesting description',
        changeControlStatus: 'unstaged',
        itemStatus: QualityItemStatus.NEW,
        children: [
          {
            id: '63cbaf7e-440a-4c7f-ac16-8069d8d0ca32',
            created: new Date().toISOString(),
            updated: new Date().toISOString(),
            statusLabel: 'Some further  Label',
            source: 'Jira',
            type: DesignElementType.REQ3,
            url: 'http://example.com',
            title: 'This is a bottom level requirement 2',
            description: 'Very interesting description',
            children: [],
            testedBy: [],
            changeControlStatus: 'unstaged',
            itemStatus: QualityItemStatus.NEW,
            version: 'v2',
          },
        ],
        testedBy: [],
        version: 'v2',
      },
      {
        id: '63cbaf7e-440a-4c7f-ac16-8069d8d0ca32',
        created: new Date().toISOString(),
        updated: new Date().toISOString(),
        statusLabel: 'Some other Label',
        source: 'Jira',
        type: DesignElementType.REQ2,
        code: 'DI-2',
        url: 'http://example.com',
        title: 'This is a mid level requirement 3',
        description: 'Very interesting description',
        changeControlStatus: 'unstaged',
        itemStatus: QualityItemStatus.NEW,
        children: [
          {
            id: '63cbaf7e-440a-4c7f-ac16-8069d8d0ca32',
            created: new Date().toISOString(),
            updated: new Date().toISOString(),
            statusLabel: 'Some further  Label',
            source: 'Jira',
            type: DesignElementType.REQ3,
            url: 'http://example.com',
            title: 'This is a bottom level requirement',
            description: 'Very interesting description',
            children: [],
            testedBy: [],
            changeControlStatus: 'unstaged',
            itemStatus: QualityItemStatus.NEW,
            version: 'v2',
          },
        ],
        testedBy: [],
        version: 'v2',
      },
      {
        id: '63cbaf7e-440a-4c7f-ac16-8069d8d0ca33',
        created: new Date().toISOString(),
        updated: new Date().toISOString(),
        statusLabel: 'Some other Label',
        source: 'Jira',
        type: DesignElementType.REQ2,
        code: 'DI-1',
        url: 'http://example.com',
        title: 'This is a mid level requirement 4',
        description: 'Very interesting description',
        changeControlStatus: 'unstaged',
        itemStatus: QualityItemStatus.NEW,
        children: [
          {
            id: '63cbaf7e-440a-4c7f-ac16-8069d8d0ca32',
            created: new Date().toISOString(),
            updated: new Date().toISOString(),
            statusLabel: 'Some further  Label',
            source: 'Jira',
            type: DesignElementType.REQ3,
            url: 'http://example.com',
            title: 'This is a bottom level requirement',
            description: 'Very interesting description',
            children: [],
            testedBy: [],
            changeControlStatus: 'unstaged',
            itemStatus: QualityItemStatus.NEW,
            version: 'v2',
          },
        ],
        testedBy: [],
        version: 'v2',
      },
      {
        id: '63cbaf7e-440a-4c7f-ac16-8069d8d0ca34',
        created: new Date().toISOString(),
        updated: new Date().toISOString(),
        statusLabel: 'Some other Label',
        source: 'Jira',
        type: DesignElementType.REQ2,
        code: 'DI-10',
        url: 'http://example.com',
        title: 'This is a mid level requirement 5',
        description: 'Very interesting description',
        changeControlStatus: 'unstaged',
        itemStatus: QualityItemStatus.NEW,
        children: [
          {
            id: '63cbaf7e-440a-4c7f-ac16-8069d8d0ca32',
            created: new Date().toISOString(),
            updated: new Date().toISOString(),
            statusLabel: 'Some further  Label',
            source: 'Jira',
            type: DesignElementType.REQ3,
            url: 'http://example.com',
            title: 'This is a bottom level requirement',
            description: 'Very interesting description',
            children: [],
            testedBy: [],
            changeControlStatus: 'unstaged',
            itemStatus: QualityItemStatus.NEW,
            version: 'v2',
          },
        ],
        testedBy: [],
        version: 'v2',
      },
    ],
    testedBy: [],
    version: 'v2',
  },
  {
    id: '63cbaf7e-440a-4c7f-ac16-8069d8d0ca29',
    created: new Date().toISOString(),
    updated: new Date().toISOString(),
    statusLabel: 'Design Review',
    source: DESIGN_CONTROLS_SOURCE,
    type: DesignElementType.REQ1,
    url: 'http://example.com',
    title: 'This is another top level requirement',
    description: 'Very interesting description',
    changeControlStatus: 'unstaged',
    itemStatus: QualityItemStatus.NEW,
    code: 'UN-2',
    testedBy: [
      {
        id: '63cbaf7e-440a-4c7f-ac16-8069d8d0ca28',
        created: new Date().toISOString(),
        updated: new Date().toISOString(),
        statusLabel: 'Some other Label',
        source: DESIGN_CONTROLS_SOURCE,
        code: 'VAL-1',
        type: DesignElementType.TEST_CASE_1,
        url: 'http://example.com',
        title: 'Very important test',
        changeControlStatus: 'unstaged',
        itemStatus: QualityItemStatus.NEW,
        log: {
          id: 'test-log',
          title: '',
          created: new Date().toISOString(),
          updated: new Date().toISOString(),
          statusLabel: 'Passing',
          source: DESIGN_CONTROLS_SOURCE,
          type: DesignElementType.TEST_LOG,
          url: 'http://example.com/test-log',
          changeControlStatus: 'unstaged',
          itemStatus: QualityItemStatus.NEW,
        },
      },
    ],
    children: [
      {
        id: '63cbaf7e-440a-4c7f-ac16-8069d8d0ca27',
        created: new Date().toISOString(),
        updated: new Date().toISOString(),
        statusLabel: 'Some other Label',
        source: DESIGN_CONTROLS_SOURCE,
        type: DesignElementType.REQ2,
        url: 'http://example.com',
        title: 'This is another mid level requirement',
        description: 'Very interesting description',
        code: 'DI-20',
        children: [],
        testedBy: [
          {
            id: '0a2e38e3-2416-4b73-97d5-dd766064506e',
            created: new Date().toISOString(),
            updated: new Date().toISOString(),
            statusLabel: 'Some other Label',
            source: DESIGN_CONTROLS_SOURCE,
            type: DesignElementType.TEST_CASE_2,
            url: 'http://example.com',
            title: 'Very important test 2',
            changeControlStatus: 'unstaged',
            itemStatus: QualityItemStatus.NEW,
            code: 'VER-10',
          },
        ],
        changeControlStatus: 'unstaged',
        itemStatus: QualityItemStatus.NEW,
        version: 'v2',
      },
    ],
    version: 'v2',
  },
  {
    id: '63cbaf7e-440a-4c7f-ac16-aa123',
    created: new Date().toISOString(),
    updated: new Date().toISOString(),
    statusLabel: 'Passed',
    source: 'Jira',
    type: DesignElementType.REQ1,
    url: 'http://example.com',
    title: 'This one has no tests or children',
    description: 'Very interesting description',
    testedBy: [],
    children: [],
    changeControlStatus: 'unstaged',
    itemStatus: QualityItemStatus.NEW,
    code: 'UN-1',
    version: 'v2',
  },
]);

export const makeRequirementTrace = (requirement: Requirement): RequirementTrace => {
  return {
    parent: [],
    children: [],
    sourcedItems: [],
    mitigates: [],
    ...requirement,
    version: 'v2',
  } as unknown as RequirementTrace;
};
