import styled from 'styled-components';

export const Container = styled.div`
  background-color: #fff;
  display: flex;
  border-radius: 4px;
  article {
    width: 806px;
  }
`;

export const SettingsBodyContainer = styled.div`
  padding: 0 42px;
  width: 100%;
`;
