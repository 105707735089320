import { useMemo } from 'react';

import { useQueries } from 'react-query';

import itemsApi from '../../api/items';
import { sortByCode } from '../../lib/sortByCode';
import { Requirement } from '../../types/requirement';
import { TestCase } from '../../types/testCase';

export type RiskSourceMitigationType = Requirement | TestCase;

type useQualityItemsNewData = {
  isLoading: boolean;
  isError: boolean;
  autoCompleteOptions: Array<RiskSourceMitigationType>;
};

export function useQualityItemsNew(company: number, product: string): useQualityItemsNewData {
  const queries = useQueries([
    {
      queryKey: `company-${company}-product-${product}-requirements`,
      queryFn: () => itemsApi.queryAllRequirements(company, product),
      cacheTime: 0,
    },
    {
      queryKey: `company-${company}-product-${product}-test-cases`,
      queryFn: () => itemsApi.query<TestCase>(company, product, 'testCase'),
      cacheTime: 0,
    },
  ]);

  const isLoading = queries.some((query) => query.isLoading);
  const isError = queries.some((query) => query.isError);

  const autoCompleteOptions = useMemo(() => {
    if (isLoading || isError) {
      return [];
    }
    const requirements: Requirement[] = queries[0].data as Requirement[];
    const testCases: TestCase[] = queries[1].data as TestCase[];
    return [...requirements, ...testCases].sort(sortByCode);
  }, [queries, isLoading, isError]);

  return { isLoading, isError, autoCompleteOptions };
}
