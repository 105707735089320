import React, { useEffect } from 'react';

import { QCheckbox, QFormControl, QInput, QSelect, QText } from '@qualio/ui-components';
import { Controller, useFormContext, useWatch } from 'react-hook-form';

import { BasicConfiguration, RiskLevel, RiskMatrix } from '..';
import { handleValidationErrors } from '../../lib/formValidationUtilities';
import { RequestValidationError } from '../../types/requestValidationError';
import { Card, CardWrapper } from '../Card';
import LevelMapping from '../RiskMatrix/LevelMapping/LevelMapping';
import { MitigationContainer, ScalingContainer } from './RiskConfigurationForm.styles';

type RiskConfigurationFormProps = {
  validationErrors: Array<RequestValidationError>;
  canDisableFmea?: () => void;
};

const RiskConfigurationForm: React.FC<RiskConfigurationFormProps> = ({ validationErrors, canDisableFmea }) => {
  const {
    formState: { errors },
    setError,
  } = useFormContext();

  const enableFmea = useWatch({
    name: 'enableFmea',
  });

  const fmeaScaleOptions = Array(10)
    .fill(1)
    .map((_, index) => ({ value: index + 1, label: index + 1 }));

  useEffect(
    () => handleValidationErrors(setError, validationErrors, '', { codePrefix: 'prefix' }),
    [setError, validationErrors],
  );

  return (
    <CardWrapper data-cy="risk-configuration-form">
      <BasicConfiguration sectionTitle="Risk" data-cy="risk-card" type="risk" />
      <Card>
        <QText fontSize="xl" fontWeight={400}>
          ISO 14971
        </QText>
        <section>
          <RiskLevel riskLevelFieldName="riskLevels" levelMappingFieldName="levelMapping" />
        </section>
        <section>
          <RiskMatrix
            severityFieldName="severity"
            probabilityFieldName="probability"
            levelMappingFieldName="levelMapping"
          >
            <Controller
              name="levelMapping"
              render={({ field: { onChange, value } }) => (
                <LevelMapping onChange={onChange} value={value} riskLevelFieldName="riskLevels" />
              )}
            />
          </RiskMatrix>
        </section>
      </Card>
      <Card>
        <div className="form-header-buttons">
          <QText fontSize="xl" fontWeight={400}>
            FMEA
          </QText>
          <Controller
            name="enableFmea"
            render={(props) => (
              <QCheckbox
                data-cy="enable-fmea"
                isChecked={props.field.value}
                onChange={(data) =>
                  canDisableFmea ? canDisableFmea() : props.field.onChange(data.currentTarget.checked)
                }
                {...{ checked: props.field.value }}
              >
                Enable FMEA
              </QCheckbox>
            )}
          />
        </div>
        {enableFmea ? (
          <section>
            <ScalingContainer>
              <div className="text">Scaling for (Severity, Occurrence, and Detectability)</div>
              <div>
                <div className="text">1 to</div>
                <div data-cy="scaling">
                  <QFormControl
                    label={''}
                    isInvalid={!!errors.scaleMaxValue}
                    error={errors.scaleMaxValue?.label?.message}
                  >
                    <Controller
                      data-cy="scaling"
                      name="scaleMaxValue.label"
                      render={({ field: { onChange, value } }) => (
                        <QSelect
                          isSearchable={false}
                          isDisabled={false}
                          isLoading={false}
                          filterOption={null}
                          data-cy="scaling"
                          value={fmeaScaleOptions.find((option) => option.value === value)?.value.toString(10)}
                          options={fmeaScaleOptions.map(({ value, label }) => ({
                            value: value.toString(10),
                            label: label.toString(10),
                          }))}
                          onChange={(event: any) => onChange(Number(event?.value))}
                        />
                      )}
                    />
                  </QFormControl>
                </div>
              </div>
            </ScalingContainer>
            <MitigationContainer>
              <QFormControl
                label={'Mitigation Threshold (RPN)'}
                isInvalid={!!errors.mitigationThreshold}
                error={errors.mitigationThreshold?.message}
              >
                <Controller
                  name="mitigationThreshold"
                  render={({ field: { onChange, value } }) => (
                    <QInput
                      data-cy="mitigation-threshold"
                      defaultValue={value}
                      onChange={(event) => onChange(parseInt(event.target.value))}
                      type="number"
                    />
                  )}
                />
              </QFormControl>
            </MitigationContainer>
            <QText fontSize="sm" color="gray.500">
              The RPN (Risk Priority Number) of a risk is calculated by multiplying Severity x Occurrence x
              Detectability.
            </QText>
          </section>
        ) : null}
      </Card>
    </CardWrapper>
  );
};

export default RiskConfigurationForm;
