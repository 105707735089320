import React from 'react';

import { QualioQThemeProvider } from '@qualio/ui-components';

import { Loading } from '../../components_new/Loading';
import { useUserGroups } from '../../context/CurrentUserContext';
import InsufficientUserPermissionState from '../InsufficientUserPermissionState/InsufficientUserPermissionState';
import hasPermission from './lib/hasPermission';
import { UserPermissions } from './UserGroupToggle.enum';

type UseGroupToggleProps = {
  userPermission: UserPermissions;
  showInsufficientUserPermissionEmptyState?: boolean;
  showIsLoading?: boolean;
};

const UserGroupToggle: React.FC<UseGroupToggleProps> = ({
  userPermission,
  children,
  showInsufficientUserPermissionEmptyState,
  showIsLoading,
}) => {
  const [userGroups, isLoading] = useUserGroups();

  if (isLoading) {
    return showIsLoading ? (
      <QualioQThemeProvider>
        <Loading />
      </QualioQThemeProvider>
    ) : null;
  }

  if (hasPermission(userGroups, userPermission)) {
    return <>{children}</>;
  }

  if (showInsufficientUserPermissionEmptyState) {
    return <InsufficientUserPermissionState />;
  }

  return null;
};

export default UserGroupToggle;
