import React, { useCallback, useMemo, useState } from 'react';

import { QBox, QButton, QFlex, QSelect, QSelectItem, QSelectPlaceholder, QText } from '@qualio/ui-components';

import DesignElementDisplay, { SelectedElementStatus } from '../DesignElementDisplay/DesignElementDisplay';
import { DesignElementGroupProps } from '../DesignElementGroup/DesignElementGroup';
import { SearchAndSelectElementOptionProps } from './SearchAndSelectElementOption';

export type SearchAndSelectElementType = {
  id: string;
  label: string;
  title: string;
  showTitleInBadge?: boolean;
  selectedItemBody?: string;
  selectedItemLeftStatus?: SelectedElementStatus;
  selectedItemRightStatus?: SelectedElementStatus;
  showWarning?: boolean;
  item: Record<string, any>;
};

interface SearchAndSelectElementsProps {
  title: string;
  name: string;
  placeHolderText: string;
  onChange: (e: SearchAndSelectElementType[]) => void;
  handleSelectedElementOnClick?: (designElementId: string, designElementVersion: string) => void;
  value: SearchAndSelectElementType[];
  data: SearchAndSelectElementType[];
  sorter?: (elementA: SearchAndSelectElementType, elementB: SearchAndSelectElementType) => number;
  isLoading?: boolean;
  displayGroup?: boolean;
  selectedOptionComponent?: React.FC<SearchAndSelectElementOptionProps>;
  selectedOptionGroupComponent?: React.FC<DesignElementGroupProps>;
  titleVariant?: boolean;
  updateDesignElementOnRemoval?: (e: SearchAndSelectElementType) => void;
}

export const SearchAndSelectElement: React.FC<SearchAndSelectElementsProps> = ({
  title,
  name,
  placeHolderText,
  onChange,
  handleSelectedElementOnClick,
  value,
  data,
  sorter,
  isLoading,
  selectedOptionComponent: SelectedOptionComponent,
  selectedOptionGroupComponent: SelectedOptionGroupComponent,
  updateDesignElementOnRemoval,
  titleVariant,
}) => {
  const [allValues, setAllValues] = useState<SearchAndSelectElementType[]>(value);

  const allOptions = useMemo(() => {
    const selectedValues = allValues.map((v) => v.id);
    return data.filter((item) => !selectedValues.includes(item.id)).sort(sorter);
  }, [data, allValues, sorter]);

  const handleOnChange = useCallback(
    (selectedItem: QSelectItem | null) => {
      const selectedOption = allOptions.find((o) => o.id === selectedItem?.value);
      if (selectedOption) {
        const updatedValues = [...allValues, selectedOption];
        setAllValues(updatedValues);
        onChange(updatedValues);
      }
    },
    [allOptions, allValues, onChange],
  );

  const handleClearAll = useCallback(() => {
    setAllValues([]);
    onChange([]);
  }, [onChange]);

  const handleDeleteElement = useCallback(
    (id: string) => {
      const removedItem = allValues.find((item) => item.id === id);
      const newAllValues = allValues.filter((item) => item.id !== id);
      onChange(newAllValues);
      setAllValues(newAllValues);
      if (removedItem && updateDesignElementOnRemoval && removedItem.showWarning) {
        updateDesignElementOnRemoval(removedItem);
      }
    },
    [allValues, onChange, updateDesignElementOnRemoval],
  );

  const sortedValues = useMemo(() => [...allValues].sort(sorter), [allValues, sorter]);

  return (
    <QBox data-cy={`search-select-elements-input-${name}`}>
      <QFlex justify="space-between">
        {titleVariant ? (
          <QText fontSize="md" fontWeight="semibold" data-cy={`search-select-elements-title-${name}`}>
            {title}
          </QText>
        ) : (
          <QText color="gray.700" fontSize="sm" fontWeight="semibold" data-cy={`search-select-elements-title-${name}`}>
            {title}
          </QText>
        )}
        <QButton onClick={handleClearAll} variant="link" size="xs" data-cy={`search-select-elements-clear-all-${name}`}>
          Clear all
        </QButton>
      </QFlex>
      <QBox mt="8px" data-cy={`search-select-elements-search-field-${name}`}>
        <QSelect
          options={allOptions.map((option) => ({ label: option.label, value: option.id }))}
          isDisabled={false}
          isLoading={isLoading}
          isSearchable={true}
          onChange={handleOnChange}
          clearInputOnSelect={true}
        >
          {placeHolderText && (
            <QSelectPlaceholder>
              <QText>{placeHolderText}</QText>
            </QSelectPlaceholder>
          )}
        </QSelect>
      </QBox>

      {sortedValues.length > 0 && (
        <div data-cy={`search-select-elements-${name}`}>
          {SelectedOptionComponent && (
            <SelectedOptionComponent selectedOptions={sortedValues} handleDeleteElement={handleDeleteElement} />
          )}
          {SelectedOptionGroupComponent && (
            <SelectedOptionGroupComponent
              elements={sortedValues}
              selectedElementComponent={DesignElementDisplay}
              elementCallBackFunction={handleDeleteElement}
              handleSelectedElementOnClick={handleSelectedElementOnClick}
              buttonIconName="X"
            />
          )}
        </div>
      )}
    </QBox>
  );
};
