export const extractInnerCKEditorHTMLData = (ckEditorHtmlString: string): string => {
  const documentFragment = new DocumentFragment();
  documentFragment.append(document.createElement('main'));
  const mainSelector = documentFragment.querySelector('main');

  let extractedInnerCKEditorHtmlString = ckEditorHtmlString;

  if (mainSelector) {
    mainSelector.innerHTML = ckEditorHtmlString;

    const ckEditorClassSelector = documentFragment.querySelector('div.ck-content');

    if (ckEditorClassSelector !== null) {
      extractedInnerCKEditorHtmlString = ckEditorClassSelector.innerHTML;
    }
  }

  return extractedInnerCKEditorHtmlString;
};
