import { UserGroups } from '../../components/UserGroupToggle/UserGroupToggle.enum';
import { User } from '../../types/currentUser';
import { QualioUser } from '../../types/qualioUser';
import { makeResponse } from './generic';

export const usersResponse = makeResponse<QualioUser[]>([
  { id: '1', fullName: 'John Doe', groups: [{ name: 'myGroup' }, { name: 'anotherGroup' }] },
  { id: '2', fullName: 'Jane Doe', groups: [] },
]);

const getMockUser = (userGroup: string) => {
  return {
    id: 1,
    companies: [{ id: 1, name: 'Darwin', usergroups: [userGroup] }],
  } as unknown as User;
};

export const basicUserGroupResponse = getMockUser(UserGroups.BASIC);

export const normalUserGroupResponse = getMockUser(UserGroups.NORMAL);

export const qualityUserGroupResponse = getMockUser(UserGroups.QUALITY);

export const unknownUserGroupResponse = getMockUser('unknown');
