import React from 'react';

import { QFlex, QTag, QText } from '@qualio/ui-components';

import { Risk, RiskAssessment } from '../../../types/risk';
import { riskLevelAndColor } from '../../../views_new/Review/List/statusLabelAndColorTag';
import { DisplayKeyValuePair } from '../../DisplayKeyValuePair/DisplayKeyValuePair';
import { ISORiskAssessmentLevels } from './ISORiskAssessmentLevels';

export const ISORiskInitialAssessment: React.FC<{ risk: Risk; riskLevels: string[] }> = ({ risk, riskLevels }) => {
  const initialAssessment: RiskAssessment = risk.preMitigation;
  const riskLevelStatus = initialAssessment.riskLevel
    ? riskLevelAndColor(initialAssessment.riskLevel, riskLevels)
    : undefined;

  return (
    <QFlex data-cy={'iso-risk-initial-assessment'} flexDirection={'column'}>
      <QText fontSize={16} weight="semibold">
        Initial Assessment
      </QText>
      <QFlex marginTop={2} marginBottom={5} flexDirection={'column'}>
        <QText color="gray.700" fontSize={14} weight="semibold">
          Hazard
        </QText>
        <QText data-cy={'iso-risk-initial-assessment-hazard'} fontSize={14}>
          {risk.hazard}
        </QText>
      </QFlex>
      <QFlex marginTop={5} marginBottom={5} flexDirection={'column'}>
        <QText color="gray.700" fontSize={14} weight="semibold">
          Reasonably Foreseeable Misuse / Use
        </QText>
        {risk.foreseeableUseMisuse && (
          <QText data-cy={'iso-risk-initial-assessment-usemisuse'} fontSize={14}>
            {risk.foreseeableUseMisuse}
          </QText>
        )}
      </QFlex>
      <QFlex marginTop={5} marginBottom={5} flexDirection={'column'}>
        <QText color="gray.700" fontSize={14} weight="semibold">
          Hazardous Situation
        </QText>
        <QText data-cy={'iso-risk-initial-assessment-hazard-situation'} fontSize={14}>
          {risk.hazardSituation}
        </QText>
      </QFlex>
      <QFlex marginTop={5} marginBottom={5} flexDirection={'column'}>
        <QText color="gray.700" fontSize={14} weight="semibold">
          Harm
        </QText>
        <QText data-cy={'iso-risk-initial-assessment-harm'} fontSize={14}>
          {risk.harm}
        </QText>
      </QFlex>
      <QFlex data-cy={'iso-risk-initial-assessment-levels'} flexDirection={'column'}>
        <ISORiskAssessmentLevels>
          <DisplayKeyValuePair keyString={'Risk Level'}>
            <QText color="gray.500" fontSize={12} weight="semibold">
              {riskLevelStatus && <QTag variantColor={riskLevelStatus.variantColor}>{riskLevelStatus.label}</QTag>}
            </QText>
          </DisplayKeyValuePair>
          <DisplayKeyValuePair keyString={'Severity'}>
            <QText color="gray.500" fontSize={12} weight="semibold">
              {initialAssessment.severity}
            </QText>
          </DisplayKeyValuePair>
          <DisplayKeyValuePair keyString={'Probability'}>
            <QText color="gray.500" fontSize={12} weight="semibold">
              {initialAssessment.probability}
            </QText>
          </DisplayKeyValuePair>
        </ISORiskAssessmentLevels>
      </QFlex>
    </QFlex>
  );
};
