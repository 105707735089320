import { themeGet } from '@styled-system/theme-get';
import styled from 'styled-components';

export const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Card = styled.div<{ noPadding?: boolean }>`
  width: 806px;
  left: 0;
  top: 0;
  border-width: 1px;
  border-style: solid;
  border-color: ${(props) => props.theme.colors.gray['20']};
  box-sizing: border-box;
  border-radius: 4px;
  padding: ${(props) => props.noPadding || props.theme.space['5']};
  margin: 24px auto 0 auto;
  display: flex;
  flex-direction: column;

  section {
    border-top: ${themeGet('sizes.1')} solid ${(props) => props.theme.colors.gray['20']};
    margin-top: 16px;
    padding: 16px;

    &:first-of-type {
      border-top: none;
    }

    button {
      &:hover {
        background: none;
      }

      svg {
        margin-top: 2px;
        margin-left: 8px;
      }
    }
  }

  /** hacks go here **/

  label {
    margin: 4px 0;

    p {
      display: none;
    }
  }

  /** lists **/

  ul {
    list-style: none;
    margin: 0;

    label div {
      top: 4px;
    }
  }

  table {
    border-collapse: separate !important;
    width: ${(props) => props.theme.sizes.full};

    td {
      padding-right: ${(props) => props.theme.sizes[5]};

      &:last-child {
        padding-right: 0;
      }
    }
  }

  footer {
    border-top: ${themeGet('sizes.1')} solid ${(props) => props.theme.colors.gray['20']};
    background: ${(props) => props.theme.colors.gray['10'] || '#F2F3F5'};
    height: 40px;
    margin: 0 -16px -16px -16px;
  }

  .form-header-buttons {
    display: flex;
    flex-direction: row;

    button:first-child {
      margin-right: 10px;
    }

    p {
      flex-grow: 1;
    }

    label {
      line-height: 16px;
    }
  }
`;
