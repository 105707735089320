import { z } from 'zod';

export const SearchRecord = z.object({
  domain: z.string(),
  companyId: z.number(),
  resource: z.string(),
  resourceSubType: z.string(),
  identifiers: z.object({
    qri: z.string(),
  }),
  displayLabel: z.string(),
});

export type SearchRecord = z.infer<typeof SearchRecord>;

export const SearchResponse = z.object({
  results: SearchRecord.array(),
});

export type SearchResponse = z.infer<typeof SearchResponse>;
