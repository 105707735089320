import React from 'react';

import Pill from '../../../components/Pill';
import Tooltip from '../../../components/Tooltip';
import { PolicyIssueLabels } from '../../../types/openIssue';
import { IssueFilter as TIssueFilter } from '../useIssueFilters';

type Props = TIssueFilter & {
  active: boolean;
  onClick: (policy: TIssueFilter['policy']) => void;
};

const MAX_COUNT_SHOWN = 99;

const IssueFilter: React.FC<Props> = ({ count, policy, active, onClick, ...rest }) => {
  const countExceeded = count && count > MAX_COUNT_SHOWN;

  const pill = (
    <Pill key={policy} active={active} onClick={() => onClick(policy)} {...rest}>
      {PolicyIssueLabels[policy]} {countExceeded ? '99+' : count}
    </Pill>
  );

  if (countExceeded) {
    return <Tooltip content={<>{count} Issues</>}>{pill}</Tooltip>;
  }

  return pill;
};

export default IssueFilter;
