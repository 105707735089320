import React from 'react';

import Icon from '@ant-design/icons';

const Svg = () => (
  <svg width="1em" height="1em" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="22" height="22" fill="white" />
    <rect width="22" height="22" rx="2" fill="#2F80ED" />
    <path
      d="M15.0235 17.8897C13.9329 17.8897 13.0471 16.976 13.0471 15.8511C13.0471 14.9392 13.6312 14.1656 14.4324 13.9054L14.4324 12.1598L7.61177 9.85548L7.61177 15.0302C8.39177 15.3032 8.95294 16.0659 8.95294 16.9614C8.95294 18.0863 8.06706 19 6.97647 19C5.88588 19 5 18.0863 5 16.9614C5 16.0659 5.56118 15.3051 6.34118 15.0302L6.34118 7.96978C5.56118 7.69676 5 6.93411 5 6.03859C5 4.91373 5.88588 4 6.97647 4C8.06706 4 8.95294 4.91373 8.95294 6.03859C8.95294 6.93411 8.39177 7.69494 7.61177 7.96978L7.61177 8.47579L15.0853 11.0004C15.2648 11.0605 15.4211 11.1779 15.5318 11.3356C15.6424 11.4932 15.7018 11.683 15.7012 11.8777L15.7012 13.9363C16.4582 14.2239 17 14.9738 17 15.8511C17 16.976 16.1141 17.8897 15.0235 17.8897ZM6.12941 16.9614C6.13394 17.19 6.22517 17.4077 6.38353 17.5678C6.54189 17.7278 6.75477 17.8174 6.97647 17.8174C7.19818 17.8174 7.41105 17.7278 7.56941 17.5678C7.72777 17.4077 7.81901 17.19 7.82353 16.9614C7.81901 16.7328 7.72777 16.5151 7.56941 16.3551C7.41105 16.195 7.19818 16.1054 6.97647 16.1054C6.75477 16.1054 6.54189 16.195 6.38353 16.3551C6.22517 16.5151 6.13394 16.7328 6.12941 16.9614ZM7.82353 6.04041C7.81901 5.81178 7.72777 5.59409 7.56941 5.43405C7.41105 5.27401 7.19818 5.18438 6.97647 5.18438C6.75476 5.18438 6.54189 5.27401 6.38353 5.43405C6.22517 5.59409 6.13394 5.81178 6.12941 6.04041C6.13394 6.26903 6.22517 6.48672 6.38353 6.64676C6.54189 6.8068 6.75477 6.89644 6.97647 6.89644C7.19818 6.89644 7.41105 6.8068 7.56941 6.64676C7.72777 6.48672 7.81901 6.26903 7.82353 6.04041ZM15.0235 14.9774C14.8019 14.9821 14.5908 15.0762 14.4357 15.2395C14.2805 15.4029 14.1936 15.6224 14.1936 15.8511C14.1936 16.0798 14.2805 16.2993 14.4357 16.4627C14.5908 16.626 14.8019 16.7201 15.0235 16.7248C15.2452 16.7201 15.4562 16.626 15.6114 16.4627C15.7666 16.2993 15.8535 16.0798 15.8535 15.8511C15.8535 15.6224 15.7666 15.4029 15.6114 15.2395C15.4562 15.0762 15.2452 14.9821 15.0235 14.9774Z"
      fill="white"
    />
  </svg>
);

const Req2Icon: React.FC<any> = (props) => <Icon component={Svg} {...props} />;

export default Req2Icon;
