import {
  normalUserPermissions,
  qualityUserPermissions,
  userGroupList,
  UserGroups,
  UserPermissions,
} from '../UserGroupToggle.enum';

const hasPermission = (userGroups: ReadonlyArray<string>, permission: UserPermissions): boolean => {
  if (userGroups.some((userGroup) => !userGroupList.includes(userGroup)) || userGroups.includes(UserGroups.BASIC)) {
    return false;
  }

  if (userGroups.includes(UserGroups.NORMAL)) {
    return normalUserPermissions.includes(permission);
  }

  return qualityUserPermissions.includes(permission) && userGroups.includes(UserGroups.QUALITY);
};

export default hasPermission;
