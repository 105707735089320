import React from 'react';

import {
  QButton,
  QCloseButton,
  QModal,
  QModalActions,
  QModalBody,
  QModalHeader,
  QualioQThemeProvider,
} from '@qualio/ui-components';

export type ConfirmationDetails = {
  title: string;
  message: React.ReactElement;
};
export type Props = {
  onConfirm: () => void;
  onClose: () => void;
  confirmationDetails?: ConfirmationDetails;
};
export const DeleteConfirmationModal: React.FC<Props> = ({ onConfirm, onClose, confirmationDetails }) => {
  const confirmDelete = () => confirmationDetails && onConfirm();

  return (
    <QualioQThemeProvider>
      <QModal isOpen={!!confirmationDetails} onClose={onClose}>
        <QModalHeader>
          <span data-cy="delete-confirmation-modal">{confirmationDetails && confirmationDetails.title}</span>
          <QCloseButton onClick={onClose} />
        </QModalHeader>
        <QModalBody>{confirmationDetails && confirmationDetails.message}</QModalBody>
        <QModalActions>
          <QButton variant="outline" onClick={onClose} data-cy="cancel-delete-btn">
            Cancel
          </QButton>
          <QButton isDestructive={true} onClick={confirmDelete} data-cy="confirm-delete-btn">
            Delete
          </QButton>
        </QModalActions>
      </QModal>
    </QualioQThemeProvider>
  );
};
