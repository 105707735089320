import React from 'react';

import { Relation } from '../../../components/QualityItemDetails/types';
import { DesignElementType } from '../../../types/baseQualityItem';
import { ChangeHistory } from '../../../types/changeHistory';
import { BaseQualityItemTrace } from '../../../types/qualityItemTrace';
import { isRequirementWithAttachments } from '../../../types/requirement';
import { RequirementTestCaseDetailsDrawerBody } from './RequirementTestCaseDetailsDrawerBody';

export const RequirementDetailsDrawerBody: React.FC<{
  designElement: BaseQualityItemTrace;
  relatedDesignElements: Array<Relation>;
  reviewHistory: ChangeHistory;
  configsMap: Record<DesignElementType, string>;
  reviewClick?: (id: string) => void;
  designElementClick: (designElementId: string, designElementVersion?: string) => void;
}> = ({ designElement, relatedDesignElements, reviewHistory, configsMap, reviewClick, designElementClick }) => {
  const attachments = isRequirementWithAttachments(designElement) ? designElement.attachments : [];

  return (
    <RequirementTestCaseDetailsDrawerBody
      attachments={attachments}
      designElement={designElement}
      relatedDesignElements={relatedDesignElements}
      reviewHistory={reviewHistory}
      configsMap={configsMap}
      reviewClick={reviewClick}
      designElementClick={designElementClick}
    />
  );
};
