import React from 'react';

import classNames from 'classnames';

import styles from './BorderedValue.module.less';

type BorderedValueProps = {
  borderType: 'circle' | 'rectangle';
  variant?: 'default' | 'warning';
};

const BorderedValue: React.FC<BorderedValueProps> = ({ variant = 'default', borderType, children, ...rest }) => {
  return (
    <span {...rest} className={classNames(styles[borderType], styles[variant])}>
      {children}
    </span>
  );
};

export default BorderedValue;
