export { sortByCode } from './sortByCode';

export const sortByAlphanumericInsensitive = (firstString?: string, secondString?: string): number => {
  return (firstString || '').toLowerCase().localeCompare((secondString || '').toLowerCase());
};
export const sortByDate = (firstDate: Date, secondDate: Date): number => {
  return firstDate.getTime() - secondDate.getTime();
};
export function sortByTitle<T extends { title?: string }>(elA: T, elB: T): number {
  return (elA.title || '').localeCompare(elB.title || '', undefined, { numeric: true });
}
export const sortByRiskLevel = (riskLevels: string[], firstRiskLevel?: string, secondRiskLevel?: string): number => {
  return riskLevels.indexOf(firstRiskLevel || '') - riskLevels.indexOf(secondRiskLevel || '');
};
