import React from 'react';

import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { components, DropdownIndicatorProps, PlaceholderProps } from 'react-select';

import itemsApi from '../../api/items';
import { ReactComponent as SearchIcon } from '../../assets/Search.svg';
import { useCompanyId } from '../../context/CurrentUserContext';
import { sortByCode } from '../../lib/sortByCode';
import { ProductParam } from '../../paramTypes';
import { Requirement } from '../../types/requirement';
import { StyledSelect } from '../StyledSelect/StyledSelect.styles';
import { SearchIconContainer } from './SearchField.styles';

const EmptyDropdownIndicator = (props: DropdownIndicatorProps) => {
  return (
    <components.DropdownIndicator {...props}>
      <div />
    </components.DropdownIndicator>
  );
};

const SearchIconPlaceholder = (props: PlaceholderProps) => {
  const { children } = props;

  return (
    <components.Placeholder {...props}>
      <SearchIconContainer>
        <SearchIcon /> {children}
      </SearchIconContainer>
    </components.Placeholder>
  );
};

export type SearchElementType = { code: string; label: string; value: string };

type SearchFieldProps = {
  margin: number;
  isSearchable: boolean;
  isMulti: boolean;
  placeHolderText: string;
  onChange: () => void;
  name: string;
  value: SearchElementType[];
};

export const SearchField: React.FC<SearchFieldProps> = ({
  margin,
  isSearchable,
  isMulti,
  placeHolderText,
  onChange,
  name,
  value,
}) => {
  const company = useCompanyId();
  const { product } = useParams<ProductParam>() as ProductParam;

  const { data, isLoading } = useQuery(`searchFields-${name}`, () =>
    itemsApi.query<Requirement>(company, product, name),
  );

  return (
    <StyledSelect
      margin={margin}
      isSearchable={isSearchable}
      isMulti={isMulti}
      components={{
        DropdownIndicator: EmptyDropdownIndicator,
        Placeholder: SearchIconPlaceholder,
      }}
      placeholder={placeHolderText}
      options={data?.sort(sortByCode).map((item: any) => ({ code: item.code, label: item.codeTitle, value: item.id }))}
      onChange={onChange}
      isLoading={isLoading}
      value={value}
      controlShouldRenderValue={false}
      isClearable={false}
    />
  );
};

export default SearchField;
