import { DesignElementType } from '../../../types/baseQualityItem';
import { isRequirement } from '../../../types/requirement';
import { isRisk } from '../../../types/risk';

export const designElementEditActionPath = (productId: string, id: string, type: DesignElementType): string => {
  if (isRequirement(type)) {
    return `/product/${productId}/requirement/edit/${id}`;
  }
  if (isRisk(type)) {
    return `/product/${productId}/risk/edit/${id}`;
  }
  return `/product/${productId}/testCase/edit/${id}`;
};
