import React from 'react';

import { useWatch } from 'react-hook-form';

import { Chip, SearchField } from '..';
import { sortByCode } from '../../lib/sortByCode';
import { SearchElementType } from '../SearchField/SearchField';
import { Container } from './RelatedElements.styles';

interface RelatedElementsProps {
  placeHolderText: string;
  onChange: () => void;
  value: SearchElementType[];
  handleDeleteChip: (element: SearchElementType) => void;
  watchValue: string;
}

const RelatedElements: React.FC<RelatedElementsProps> = ({
  placeHolderText,
  onChange,
  value,
  handleDeleteChip,
  watchValue: name,
}) => {
  const chipWatch = useWatch({ name });
  return (
    <Container data-cy={`related-elements-input-${name}`}>
      <SearchField
        margin={5}
        isSearchable={true}
        isMulti={true}
        placeHolderText={placeHolderText}
        onChange={onChange}
        name={name}
        value={value}
      />
      {chipWatch &&
        value
          .sort(sortByCode)
          .map((element: SearchElementType) => (
            <Chip key={element.label} text={element.label} onClick={() => handleDeleteChip(element)} />
          ))}
    </Container>
  );
};

export default RelatedElements;
