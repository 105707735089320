import React from 'react';

import { QBox, QSpinner } from '@qualio/ui-components';

export const Loading: React.FC = () => {
  return (
    <QBox display="flex" justifyContent="center">
      <QSpinner size="lg" data-cy="loading-spinner" />
    </QBox>
  );
};
