import React from 'react';

import { QAttachment, QAttachmentInfo, QBox, QIcon, QStack, QTheme } from '@qualio/ui-components';

import { AttachmentsViewContainer } from './AttachmentsView.styles';

export type QAttachmentsViewProps = {
  attachments: Array<QAttachment>;
  onClick: (attachmentId: string) => void;
  sort?: (a: QAttachmentInfo, b: QAttachmentInfo) => number;
};

export const QAttachmentsView: React.FC<QAttachmentsViewProps> = ({ attachments, onClick, sort = () => 0 }) => {
  attachments.sort(sort);
  return (
    <AttachmentsViewContainer QTheme={QTheme}>
      <QStack data-cy="q-attachments-view" className="q-attachments-view--wrapper">
        {attachments.map((attachment) => (
          <QBox
            key={attachment.id}
            onClick={() => onClick(attachment.id)}
            data-cy="q-attachments-view--file"
            className="q-attachments-view--file"
          >
            <QBox className="q-attachments-view--file-info">
              <QBox data-cy="q-attachments-view-filename" className="q-attachments-view--file-info-main" noOfLines={1}>
                <QBox className="paperclip">
                  <QIcon iconName={'Paperclip'} />
                </QBox>
                {attachment.filename}
              </QBox>
            </QBox>
          </QBox>
        ))}
      </QStack>
    </AttachmentsViewContainer>
  );
};
