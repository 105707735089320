import {
  ChangeControlQualityItem,
  ChangeControlQualityItemPayloadType,
  DashboardItem,
} from '../../../types/changeControl';
import { Requirement } from '../../../types/requirement';
import { TestCase } from '../../../types/testCase';
import {
  designElementConfigLabelAndColor,
  designElementStatusLabelAndColor,
} from '../../../views_new/Review/List/statusLabelAndColorTag';
import { SearchAndSelectElementType } from '../../SearchAndSelectElements/SearchAndSelectElement';

const hasLatestProperty = (
  item: ChangeControlQualityItem | DashboardItem | ChangeControlQualityItemPayloadType | Requirement | TestCase,
): item is ChangeControlQualityItem | ChangeControlQualityItemPayloadType => {
  return 'latest' in item;
};

export const mapDesignElementToSelectedElementType = (
  designElements: Array<
    ChangeControlQualityItem | DashboardItem | ChangeControlQualityItemPayloadType | Requirement | TestCase
  >,
  configMap: Record<string, string>,
  showWarningIcon = false,
): Array<SearchAndSelectElementType> => {
  return designElements.map((item) => ({
    id: item.id,
    label: `${item.code} - ${item.title}`,
    title: item.code ?? '',
    selectedItemBody: item.title,
    showTitleInBadge: true,
    showWarning: showWarningIcon && hasLatestProperty(item) ? item.latest !== item.version : undefined,
    selectedItemLeftStatus: designElementStatusLabelAndColor(item.itemStatus),
    selectedItemRightStatus: designElementConfigLabelAndColor(
      configMap[item.type],
      (item as ChangeControlQualityItem).subType,
    ),
    item,
  }));
};
