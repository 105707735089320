import { Prefix } from '../context/qualityConfigTypes';

export const prefixDoesNotExist = (
  prefix: string | undefined,
  type: string,
  usedPrefixes: Prefix[],
  archivePrefixes: Array<string>,
): boolean => {
  return (
    !prefix ||
    (usedPrefixes.every((usedPrefix: Prefix) => usedPrefix.value !== prefix || usedPrefix.type === type) &&
      !archivePrefixes.includes(prefix))
  );
};
