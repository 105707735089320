import styled from '@emotion/styled';

export const StyledMatrix = styled.div`
  .probabilityWidth {
    width: 30px;
  }

  .probability {
    transform: rotate(-90deg) translateX(-80%) translateY(45%);
    text-transform: uppercase;
    font-weight: 800;
    overflow-wrap: initial !important;
  }

  .severity {
    text-transform: uppercase;
    font-weight: 800;
    text-align: center;
  }

  .item-column {
    min-height: 30px;
    vertical-align: middle;
    padding: 4px;
  }

  .item-column {
    padding: 0;
  }

  .matrix-3x3 {
    .items {
      width: 525px;
    }

    .severity {
      width: 525px;
      margin-left: 205px;
    }

    .item {
      width: 175px;
    }
  }

  .matrix-5x5 {
    .items {
      width: 590px;
    }

    .severity {
      width: 590px;
      margin-left: 148px;
    }

    .item {
      width: 118px;
    }
  }

  .item {
    padding: 2px 4px;
  }
`;

export const LevelSelectorContainer = styled.div<{ color: string }>`
  .chakra-form-control {
    background: ${(props) => props.color}4d;

    .chakra-divider + div {
      background: none;
    }
  }
`;
