import React, { useEffect } from 'react';

import { useFormContext, useWatch } from 'react-hook-form';

import { handleValidationErrors } from '../../lib/formValidationUtilities';
import { EMPTY_TEST_CASE_CONFIGURATION, INITIAL_TESTCASE_CONFIGURATION } from '../../lib/testcaseConfiguration';
import { BaseConfigurationType } from '../../types/baseConfiguration';
import { DesignElementType, TestCaseType } from '../../types/baseQualityItem';
import { CreateProductFormStateKeys } from '../../types/createProductForm';
import {
  TestCaseConfigurationContainerProps,
  TestCaseConfigurationCreateEditMode,
} from './TestCaseConfigurations.types';
import TestCaseConfigurationsPresentational from './TestCaseConfigurationsPresentational';

const TestCaseConfigurationsContainer: React.FC<TestCaseConfigurationContainerProps> = ({
  validationErrors,
  requirementAndTestCaseTuples,
  onModalDeleteTestCaseCard,
  mode,
  savedConfigTypes,
}) => {
  const { reset, setError } = useFormContext();
  const testCaseFormState = useWatch({ name: CreateProductFormStateKeys.TEST_CASES }) as Record<
    string,
    BaseConfigurationType
  >;

  useEffect(
    () =>
      handleValidationErrors(setError, validationErrors, CreateProductFormStateKeys.TEST_CASES, {
        codePrefix: 'prefix',
      }),
    [setError, validationErrors],
  );

  const deleteTestCaseCard = (testCaseKey: TestCaseType) => {
    if (onModalDeleteTestCaseCard && savedConfigTypes && savedConfigTypes.some((type) => type === testCaseKey)) {
      onModalDeleteTestCaseCard(testCaseKey);
      return;
    }

    const newValue = { ...testCaseFormState };
    delete newValue[testCaseKey];
    reset({ testCases: newValue });
  };

  const canDelete = (testCaseType: TestCaseType) => {
    if (savedConfigTypes) {
      return savedConfigTypes.length > 1 || testCaseType !== savedConfigTypes[0];
    }
    return true;
  };

  const getTestCaseCard = (testCase: TestCaseType) => {
    return testCase === DesignElementType.TEST_CASE_3
      ? EMPTY_TEST_CASE_CONFIGURATION
      : INITIAL_TESTCASE_CONFIGURATION.testCases[testCase];
  };

  const addTestCaseCard = (testCase: TestCaseType) => {
    reset({
      testCases: {
        ...testCaseFormState,
        [testCase]: getTestCaseCard(testCase),
      },
    });
  };

  useEffect(() => {
    if (mode === TestCaseConfigurationCreateEditMode.CREATE_MODE && requirementAndTestCaseTuples.length === 1) {
      const testCase = requirementAndTestCaseTuples[0].testCase;
      reset({
        testCases: {
          [testCase]: getTestCaseCard(testCase),
        },
      });
    }
  }, [requirementAndTestCaseTuples, reset, mode]);

  return (
    <TestCaseConfigurationsPresentational
      requirementAndTestCaseTuples={requirementAndTestCaseTuples}
      addTestCaseCard={addTestCaseCard}
      deleteTestCaseCard={deleteTestCaseCard}
      testCaseFormState={testCaseFormState}
      canDelete={canDelete}
    />
  );
};

export default TestCaseConfigurationsContainer;
