import { Payload } from '../types/payload';
import { Release, ReleaseDocuments } from '../types/release';
import api from './index';

const releaseApi = {
  fetchDetails(company: number, product: string, release: string): Promise<ReleaseDocuments> {
    return Promise.all([
      api
        .get(`/company/${company}/product-development/product/${product}/release/${release}/document`)
        .then((res) => res.data as Payload<ReleaseDocuments>),
      api
        .get(`/company/${company}/product-development/product/${product}/release/${release}`)
        .then((res) => res.data as Payload<Release>),
    ]).then(([releaseReports, release]) => {
      releaseReports.data.reports = releaseReports.data.reports.map((item) => {
        if (
          item.status === 'completed' &&
          (item.type === 'RequirementTrace' || item.type === 'Risk') &&
          !item.url.includes('/product-development/')
        ) {
          const parts = item.url.split('/');
          parts.splice(5, 0, 'product-development');
          item.url = parts.join('/');
        }

        return item;
      });

      return {
        reports: releaseReports.data.reports.map((item) => ({
          ...item,
          status: release.data.status !== 'completed' ? release.data.status : item.status,
        })),
        documents: [],
        reviewPackageAvailable: release.data.reviewPackageAvailable ?? false,
      };
    });
  },

  downloadReviewPackageUrl(company: number, product: string, release: string): string {
    return `${process.env.REACT_APP_API_BASE_URL}/release/company/${company}/product/${product}/release/${release}/download`;
  },
};

export default releaseApi;
