import React, { createContext, useContext, useMemo } from 'react';

import { Loading } from '../components_new/Loading';
import { showConfigurationGroup } from '../lib/designControlNativeEnabled';
import { DesignElementType } from '../types/baseQualityItem';
import { useQualityConfigs } from './qualityConfigs';

type ConfigurationView = { showRequirements: boolean; showTestCases: boolean };

export const ConfigurationViewContext = createContext<ConfigurationView>({
  showRequirements: false,
  showTestCases: false,
});

export const useConfigurationView = (): ConfigurationView => useContext(ConfigurationViewContext);

export const ConfigurationViewProvider: React.FC = ({ children }) => {
  const { configs, loading } = useQualityConfigs();

  const showConfigurations = useMemo(() => {
    if (loading) {
      return { showRequirements: false, showTestCases: false };
    }

    const showRequirements = showConfigurationGroup(configs, [
      DesignElementType.REQ1,
      DesignElementType.REQ2,
      DesignElementType.REQ3,
    ]);

    const showTestCases = showConfigurationGroup(configs, [
      DesignElementType.TEST_CASE_1,
      DesignElementType.TEST_CASE_2,
      DesignElementType.TEST_CASE_3,
    ]);

    return {
      showRequirements: showRequirements || !configs.length,
      showTestCases: showTestCases || !configs.length,
    };
  }, [configs, loading]);

  if (loading) {
    return <Loading />;
  }

  return <ConfigurationViewContext.Provider value={showConfigurations}>{children}</ConfigurationViewContext.Provider>;
};
