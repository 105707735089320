import React from 'react';

import Icon from '@ant-design/icons';

const Svg = () => (
  <svg width="1em" height="1em" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <line x1="5" y1="9.5" x2="17" y2="9.5" stroke="white" />
    <path d="M5 12.5H12" stroke="white" />
    <rect width="22" height="22" rx="2" fill="#2F80ED" />
    <line x1="5" y1="8.5" x2="17" y2="8.5" stroke="white" />
    <path d="M5 11.2727H14" stroke="white" />
    <path d="M5 14H11" stroke="white" />
  </svg>
);

const DocumentIcon: React.FC<any> = (props) => <Icon component={Svg} {...props} />;

export default DocumentIcon;
