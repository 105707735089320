import { useToastProvider } from '@qualio/ui-components';
import { useQuery } from 'react-query';

import requirementsApi from '../../../api/requirementTraces';
import { useCompanyId } from '../../../context/CurrentUserContext';
import { RequirementTrace } from '../../../types/trace';

export type UseQueryRequirementsReturnType = {
  requirements: Array<RequirementTrace>;
  isLoading: boolean;
  refetch: () => void;
};

export const useQueryRequirements = (productId: string): UseQueryRequirementsReturnType => {
  const companyId = useCompanyId();
  const { showToast } = useToastProvider();

  const { data, isLoading, refetch, isRefetching } = useQuery(
    `queryRequirements-company-${companyId}-product-${productId}`,
    () => {
      return requirementsApi.query(companyId, productId);
    },
    {
      onError: (error: any) => {
        showToast({
          title: 'Failed to load requirements',
          status: 'error',
        });
      },
    },
  );

  return {
    requirements: data || [],
    isLoading: isLoading || isRefetching,
    refetch,
  };
};
