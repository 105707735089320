import { FMEARisk, Risk } from '../../types/risk';
import { RiskMitigationTrace, RiskTrace } from '../../types/trace';

export function makeRiskTrace(
  risk: Risk | FMEARisk,
  trace: RiskMitigationTrace = {
    sourceItems: [],
    mitigationItems: [],
    version: 'v2',
  },
): RiskTrace {
  return {
    ...risk,
    ...trace,
  };
}
