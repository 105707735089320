import React from 'react';

import { QButton } from '@qualio/ui-components';

import { UserPermissions } from '../../../components';
import { FeatureFlags } from '../../../components/FeatureToggle/FeatureToggle.enum';
import useFeatureFlag from '../../../components/FeatureToggle/hooks/useFeatureFlag';
import UserGroupToggle from '../../../components/UserGroupToggle/UserGroupToggle';
import { isSoureEditableAndDeletable } from '../../../lib/source';
import { sourceDisplayName } from '../../../lib/sourceDisplayName';
import { QualityItemStatus } from '../../../types/baseQualityItem';
import { BaseQualityItemTrace } from '../../../types/qualityItemTrace';
import { RiskTrace } from '../../../types/trace';

export const DesignElementDetailsDrawerEditButton: React.FC<{
  designElement: BaseQualityItemTrace | RiskTrace;
  editCallBack: () => void;
}> = ({ designElement, editCallBack }) => {
  const features = useFeatureFlag([FeatureFlags.SELF_SERVICE]);
  const editButton = () =>
    designElement.itemStatus !== QualityItemStatus.DELETED ? (
      <QButton data-cy={'design-element-edit-button'} variant={'outline'} onClick={editCallBack}>
        Edit
      </QButton>
    ) : null;

  return (
    <UserGroupToggle userPermission={UserPermissions.EDIT_DESIGN_ELEMENTS}>
      {isSoureEditableAndDeletable(designElement.source, features[FeatureFlags.SELF_SERVICE]) ? (
        editButton()
      ) : (
        <a href={designElement.url} rel={'noopener noreferrer'} target={'_blank'}>
          <QButton data-cy={'integration-design-element-button'} variant={'outline'} rightIcon={'ExternalLink'}>
            {sourceDisplayName(designElement.source)}
          </QButton>
        </a>
      )}
    </UserGroupToggle>
  );
};
