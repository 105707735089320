import React, { useCallback } from 'react';

import { Navigate, useMatch, useNavigate, Outlet } from 'react-router-dom';

import SettingsContainer from '../../components/SettingsContainer/SettingsContainer';
import { ConfigurationViewProvider, useConfigurationView } from '../../context/configurationView';
import { useProduct } from '../../context/product';
import { FormLayout } from '../../Layout/FormLayout';

export const IndexRouter: React.VFC = () => {
  const pathnameBase = useMatch('/product/:productId/config')?.pathnameBase;
  const { showRequirements } = useConfigurationView();

  return <Navigate to={showRequirements ? `${pathnameBase}/requirement` : `${pathnameBase}/risk`} />;
};

const Configuration: React.FC = () => {
  const navigate = useNavigate();
  const product = useProduct();

  const onClose = useCallback(() => {
    navigate(`/product/${product.id}/requirement`);
  }, [navigate, product.id]);

  return (
    <FormLayout title={'Configure Policies'} productName={product.name} onClose={onClose}>
      <SettingsContainer>
        <ConfigurationViewProvider>
          <Outlet />
        </ConfigurationViewProvider>
      </SettingsContainer>
    </FormLayout>
  );
};

export default Configuration;
