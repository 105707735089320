import React, { useEffect, useState } from 'react';

import { QButton } from '@qualio/ui-components';
import { useFormContext, useWatch } from 'react-hook-form';

import { ReactComponent as Arrow } from '../../assets/Arrow.svg';
import { handleValidationErrors } from '../../lib/formValidationUtilities';
import { BaseConfigurationType } from '../../types/baseConfiguration';
import { BaseQualityType, DesignElementType } from '../../types/baseQualityItem';
import { CreateProductFormStateKeys } from '../../types/createProductForm';
import { RequestValidationError } from '../../types/requestValidationError';
import { BasicConfigurationFooterSection } from '../BasicConfiguration/BasicConfiguration.styles';
import { CardWrapper } from '../Card';
import { BasicConfiguration } from '../index';
import { ButtonContainer, RequirementLevelCards } from './RequirementLevelsForm.styles';

type RequirementLevelsFormProps = {
  onDelete?: (type: BaseQualityType) => void;
  validationErrors: Array<RequestValidationError>;
};

const RequirementLevelsForm: React.FC<RequirementLevelsFormProps> = ({ onDelete, validationErrors }) => {
  const { reset, setError } = useFormContext();
  const [isNew, setIsNew] = useState(false);
  const itemsWatch = useWatch({ name: CreateProductFormStateKeys.REQUIREMENT_ITEMS }) as Record<
    string,
    BaseConfigurationType
  >;

  const requirementKeys = itemsWatch ? Object.keys(itemsWatch) : [];

  useEffect(
    () => handleValidationErrors(setError, validationErrors, 'items', { codePrefix: 'prefix' }),
    [setError, validationErrors],
  );

  const deleteCard = (key: string) => {
    const newValue = { ...itemsWatch };
    delete newValue[key];
    reset({ items: newValue });
    setIsNew(false);
  };

  const onDeleteCardClick = (key: string) => {
    if (onDelete && !isNew) {
      onDelete(key as DesignElementType);
    } else {
      deleteCard(key);
    }
  };

  const handleAddCard = () => {
    reset({
      items: {
        ...itemsWatch,
        [`req${requirementKeys.length + 1}`]: {
          prefix: '',
          label: '',
          statuses: [{ label: '' }],
        },
      },
    });
    setIsNew(true);
  };

  return (
    <>
      <CardWrapper data-cy="requirement-levels-form">
        {itemsWatch &&
          requirementKeys.map((key, index) => {
            return (
              <RequirementLevelCards key={key} isLast={index === 2}>
                {index > 0 && <Arrow className="arrow-svg" />}
                <BasicConfiguration
                  key={key}
                  sectionTitle={`Requirement level ${index + 1}`}
                  controllerNamePrefixWithDot={`items.${key}.`}
                  type={key}
                  data-cy="requirement-level-card"
                >
                  {requirementKeys.length > 1 && (
                    <BasicConfigurationFooterSection isDisabled={requirementKeys.length !== index + 1}>
                      <QButton
                        leftIcon={'Trash'}
                        onClick={() => onDeleteCardClick(key)}
                        data-cy="remove-requirement"
                        isDisabled={requirementKeys.length !== index + 1}
                        variant={'link'}
                      >
                        Delete
                      </QButton>
                    </BasicConfigurationFooterSection>
                  )}
                </BasicConfiguration>
              </RequirementLevelCards>
            );
          })}
        {requirementKeys.length !== 3 && (
          <ButtonContainer isLast={requirementKeys.length === 2}>
            <QButton variant="outline" onClick={handleAddCard} data-cy="add-requirement-card">
              Add requirement level
            </QButton>
          </ButtonContainer>
        )}
      </CardWrapper>
    </>
  );
};

export default RequirementLevelsForm;
