import React, { useState } from 'react';

import { Navigate } from 'react-router-dom';

import {
  DeleteDesignElementPolicyModal,
  PolicyToBeDeleted,
  triggerPolicyDeletion,
} from '../../../components/DeleteDesignElementPolicyModal/DeleteDesignElementPolicyModal';
import { Messages, useMessagesContext, withMessages } from '../../../components/Messages';
import { Loading } from '../../../components_new/Loading';
import { useConfigurationView } from '../../../context/configurationView';
import { useCompanyId } from '../../../context/CurrentUserContext';
import { useProduct } from '../../../context/product';
import {
  useArchivePrefixes,
  useDeleteDesignElementConfiguration,
  useQueryDesignElementConfigurations,
  useUpdateDesignElementConfigurations,
} from '../../../hooks';
import { mapBaseQualityTypeToRequirement } from '../../../lib/mapBaseQualityTypeToConfigTypes';
import { allRequirements, DesignElementType } from '../../../types/baseQualityItem';
import { QualityTypeConfig, QualityTypeConfigWithType } from '../../../types/qualityTypeConfig';
import { RequirementConfigurationType } from '../../../types/requirementConfiguration';
import { mapRequirementToBaseQualityType } from '../../../types/riskConfiguration';
import RequirementSettingsForm from './Form';

const RequirementConfigurationView: React.FC = () => {
  const { showRequirements } = useConfigurationView();
  const { id: productId } = useProduct();
  const companyId = useCompanyId();
  const { designElementConfigurations: requirementPolicies, isLoading } =
    useQueryDesignElementConfigurations<QualityTypeConfig>(productId, allRequirements);

  const { updateDesignElementConfigurations, validationErrors, isUpdating } = useUpdateDesignElementConfigurations(
    productId,
    requirementPolicies,
  );
  const { addMessage, clearMessages } = useMessagesContext();
  const { archivedPrefixes } = useArchivePrefixes(productId);

  const { deleteDesignElementConfiguration, isDeleting } = useDeleteDesignElementConfiguration(productId);

  const [policyToBeDeleted, setPolicyToBeDeleted] = useState<PolicyToBeDeleted | undefined>();

  const onDeleteDialogClose = () => {
    setPolicyToBeDeleted(undefined);
  };

  const onDeleteDialogConfirmation = () => {
    if (policyToBeDeleted) {
      setPolicyToBeDeleted(undefined);
      deleteDesignElementConfiguration(policyToBeDeleted?.policyType);
    }
  };

  const handleSubmit = async (data: RequirementConfigurationType) => {
    const items: QualityTypeConfigWithType[] = [
      { type: DesignElementType.REQ1, ...mapRequirementToBaseQualityType(data.items.req1) },
    ];

    if (data.items.req2) {
      items.push({ type: DesignElementType.REQ2, ...mapRequirementToBaseQualityType(data.items.req2) });
    }

    if (data.items.req3) {
      items.push({ type: DesignElementType.REQ3, ...mapRequirementToBaseQualityType(data.items.req3) });
    }

    await updateDesignElementConfigurations(items);
  };

  const data = mapBaseQualityTypeToRequirement(requirementPolicies);
  if (!data.items.req3) {
    delete data.items.req3;
  }

  if (!data.items.req2) {
    delete data.items.req2;
  }

  if (!showRequirements) {
    return <Navigate to={`/product/${productId}/config/risk`} />;
  }

  if (isLoading || isDeleting) {
    return <Loading />;
  }

  return (
    <>
      <Messages />
      <RequirementSettingsForm
        onSubmit={handleSubmit}
        initialValue={data}
        submitIsLoading={isUpdating}
        onDelete={(requirementType) => {
          const reqConfig = Object.entries(data.items)
            .filter(([key, value]) => key === requirementType)
            .map(([key, value]) => value)[0];
          reqConfig &&
            triggerPolicyDeletion(
              companyId,
              productId,
              requirementType,
              reqConfig,
              setPolicyToBeDeleted,
              clearMessages,
              addMessage,
            );
        }}
        archivePrefixes={archivedPrefixes}
        validationErrors={validationErrors}
      />
      <DeleteDesignElementPolicyModal
        onConfirm={onDeleteDialogConfirmation}
        onClose={onDeleteDialogClose}
        policyToBeDeleted={policyToBeDeleted}
      />
    </>
  );
};

export default withMessages(RequirementConfigurationView);
