import React, { useCallback, useMemo, useReducer, useRef, useState } from 'react';

import {
  QBox,
  QButton,
  QCloseButton,
  QDivider,
  QModal,
  QModalActions,
  QModalBody,
  QModalHeader,
  QStack,
  QStep,
  QStepper,
  QStepperProgress,
  QSteps,
  QText,
} from '@qualio/ui-components';
import { useNavigate } from 'react-router-dom';
import * as z from 'zod';

import { useCompanyId } from '../../context/CurrentUserContext';
import { Prefix } from '../../context/qualityConfigTypes';
import { useCreateProduct } from '../../hooks/useCreateProduct';
import { useRouteParams } from '../../hooks/useRouteParams';
import { FormLayout } from '../../Layout/FormLayout';
import { INITIAL_REQUIREMENT_CONFIGURATION } from '../../lib/requirementConfiguration';
import { INITIAL_RISK_CONFIGURATION_VALUES } from '../../lib/riskConfiguration';
import { INITIAL_TESTCASE_CONFIGURATION } from '../../lib/testcaseConfiguration';
import { productConfiguration } from '../../types/product';
import { RequestValidationError } from '../../types/requestValidationError';
import { RequirementConfiguration } from '../../types/requirementConfiguration';
import { RiskConfiguration } from '../../types/riskConfiguration';
import { TestCaseConfiguration } from '../../types/testCaseConfiguration';
import { Product } from './Steps/Product';
import { Requirement } from './Steps/Requirement';
import { Risk } from './Steps/Risk';
import { TestCase } from './Steps/TestCase';

export const createProductFlowConfiguration = z
  .object({
    product: productConfiguration,
    risk: RiskConfiguration,
    requirement: RequirementConfiguration,
    testCase: TestCaseConfiguration,
  })
  .strict();

export type CreateProductFlowConfiguration = z.infer<typeof createProductFlowConfiguration>;

type CreateProductReducer = CreateProductFlowConfiguration & { activeStep: number; isLastStep: boolean };

type ActionState = { type: 'next' | 'back' } & { payload: any };

enum StateSteps {
  Product,
  Requirements,
  TestCases,
  Risk,
}

const stateStepMap: Record<number, string> = {
  [StateSteps.Product]: '',
  [StateSteps.Requirements]: 'requirement',
  [StateSteps.TestCases]: 'testCase',
  [StateSteps.Risk]: 'risk',
};

export const productReducer = (state: CreateProductReducer, newState: ActionState): CreateProductReducer => {
  const activeStep = newState.type === 'next' ? state.activeStep + 1 : Math.max(state.activeStep - 1, 0);
  const isLastStep = activeStep === StateSteps.Risk;

  switch (state.activeStep) {
    case StateSteps.Product:
      return { ...state, product: { ...newState.payload }, activeStep, isLastStep };
    case StateSteps.Requirements:
      return { ...state, requirement: { ...newState.payload }, activeStep, isLastStep };
    case StateSteps.TestCases:
      return { ...state, testCase: { ...newState.payload }, activeStep, isLastStep };
    case StateSteps.Risk:
      return { ...state, risk: { ...newState.payload }, activeStep, isLastStep };
  }

  return state;
};

export const defaultProduct = (): CreateProductReducer => ({
  activeStep: 0,
  isLastStep: false,
  product: {
    name: '',
  },
  requirement: INITIAL_REQUIREMENT_CONFIGURATION,
  testCase: INITIAL_TESTCASE_CONFIGURATION,
  risk: INITIAL_RISK_CONFIGURATION_VALUES,
});

export const CreateProduct: React.FC = () => {
  const navigate = useNavigate();
  const companyId = useCompanyId();
  const previousRef = useRef<HTMLButtonElement>(null);
  const nextRef = useRef<HTMLButtonElement>(null);
  const { step } = useRouteParams<{ step?: string }>('/product/create/:step');

  const [state, dispatcher] = useReducer(productReducer, {}, defaultProduct);
  const [modalIsOpen, setModalState] = useState<boolean>(false);
  const [validationErrors, setValidationErrors] = useState<Array<RequestValidationError>>([]);

  const onClose = useCallback(() => {
    setModalState(false);
  }, [setModalState]);

  const onCancel = useCallback(() => {
    setModalState(true);
  }, [setModalState]);

  const onModalExit = useCallback(() => {
    navigate('/');
  }, [navigate]);

  const onStepItemClicked = useCallback(() => undefined, []);
  const { onSubmit, isLoading } = useCreateProduct(companyId);

  useMemo(() => {
    if (stateStepMap[state.activeStep] !== step) {
      navigate(`/product/create/${stateStepMap[state.activeStep]}`);
    }
  }, [step, state.activeStep, navigate]);

  const onPreviousClick = useCallback(
    (payload: any) => {
      dispatcher({ type: 'back', payload });
      navigate(`/product/create/${stateStepMap[state.activeStep - 1]}`);
    },
    [dispatcher, navigate, state.activeStep],
  );

  const onNextClick = useCallback(
    async (payload: any) => {
      if (state.activeStep === StateSteps.Risk) {
        const nextState = productReducer(state, { type: 'next', payload });
        await onSubmit(nextState, {
          onSaved: (product) => navigate(`/product/${product.id}/requirement`),
          onFailure: setValidationErrors,
        });
      } else {
        dispatcher({ type: 'next', payload });
        navigate(`/product/create/${stateStepMap[state.activeStep + 1]}`);
      }
    },
    [state, onSubmit, navigate],
  );

  const usedPrefixes: Array<Prefix> = useMemo(() => {
    return [
      { value: state.risk.prefix, type: 'risk' },
      ...Object.entries(state.requirement.items).map(([key, value]) => ({
        value: value.prefix,
        type: key,
      })),
      ...Object.entries(state.testCase.testCases).map(([key, value]) => ({
        value: value.prefix,
        type: key,
      })),
    ];
  }, [state]);

  return (
    <FormLayout title={'Create product'} productName={''} onClose={onCancel}>
      <QBox>
        <QStepper activeStep={state.activeStep} onStepItemClicked={onStepItemClicked}>
          <QSteps>
            <QStepperProgress />
            <QStep title="Basic Information">
              <Product nextRef={nextRef} onNextHandler={onNextClick} data={state.product} />
            </QStep>
            <QStep title="Requirement Levels">
              <Requirement
                data={state.requirement}
                prevRef={previousRef}
                nextRef={nextRef}
                onNextHandler={onNextClick}
                onPrevHandler={onPreviousClick}
                usedPrefixes={usedPrefixes}
                validationErrors={validationErrors}
              />
            </QStep>
            <QStep title="Test Case Levels">
              <TestCase
                data={state.testCase}
                requirements={state.requirement.items}
                prevRef={previousRef}
                nextRef={nextRef}
                onNextHandler={onNextClick}
                onPrevHandler={onPreviousClick}
                usedPrefixes={usedPrefixes}
                validationErrors={validationErrors}
              />
            </QStep>
            <QStep title="Risk">
              <Risk
                data={state.risk}
                prevRef={previousRef}
                nextRef={nextRef}
                onNextHandler={onNextClick}
                onPrevHandler={onPreviousClick}
                validationErrors={validationErrors}
                usedPrefixes={usedPrefixes}
              />
            </QStep>
          </QSteps>
        </QStepper>
      </QBox>
      <QDivider mb="30px" mt="30px" />
      <QStack spacing="20px" direction="row" justifyContent="flex-end">
        <QButton variant={'link'} onClick={onCancel} data-cy="cancel-button" isDisabled={false}>
          Cancel
        </QButton>
        <QButton
          variant={'outline'}
          leftIcon="ArrowLeft"
          data-cy="previous-button"
          isDisabled={state.activeStep === 0}
          isLoading={isLoading}
          onClick={() => {
            previousRef.current?.click();
          }}
        >
          Previous
        </QButton>

        <QButton
          variant={'solid'}
          rightIcon={!state.isLastStep ? 'ArrowRight' : undefined}
          data-cy="submit-button"
          isLoading={isLoading}
          onClick={() => {
            nextRef.current?.click();
          }}
        >
          {state.isLastStep ? 'Create Product' : 'Next'}
        </QButton>
      </QStack>
      <QModal isOpen={modalIsOpen} onClose={onClose} data-cy="delete-confirmation-modal">
        <QModalHeader>
          <span>Cancel product creation</span>
          <QCloseButton onClick={onClose} />
        </QModalHeader>
        <QModalBody>
          <QText>Unsaved progress will be lost if you cancel at this point.</QText>
          <QText>Are you sure you want to cancel?</QText>
        </QModalBody>
        <QModalActions>
          <QButton variant="outline" onClick={onClose} data-cy="cancel-btn">
            Continue editing
          </QButton>
          <QButton variant={'solid'} onClick={onModalExit} data-cy="confirm-delete-btn">
            Yes, cancel
          </QButton>
        </QModalActions>
      </QModal>
    </FormLayout>
  );
};
