import React from 'react';

import { createQColumnHelper, DataProvider, QDataTable, QStack, QTag, QText } from '@qualio/ui-components';

import { QRIAttribute } from '../../../types/baseQualityItem';
import { useCurrentConfigs } from '../../hooks/useCurrentConfigs';

type Requirement = { code: string; title: string; type: string; category?: QRIAttribute; component?: QRIAttribute };
const columnHelper = createQColumnHelper<Requirement>();

type Props = {
  requirements: Requirement[];
};

export const RequirementTable: React.FC<Props> = ({ requirements }) => {
  const configs = useCurrentConfigs();

  if (!requirements.length) {
    return null;
  }

  const columns = [
    columnHelper.text('code', { header: 'Code', maxWidth: '100px' }),
    columnHelper.text('title', { header: 'Title' }),
    columnHelper.text((record) => record.component?.label, { header: 'Component', maxWidth: '150px' }),
    columnHelper.text((record) => record.category?.label, { header: 'Category', maxWidth: '150px' }),
    {
      header: 'Type',
      meta: {
        maxWidth: '150px',
      },
      cell: ({ row: { original: row } }: { row: { original: Requirement } }) => {
        return (
          <QTag variantColor="gray">
            {(configs.find((config) => config.type === row.type) as { label: string } | undefined)?.label}
          </QTag>
        );
      },
    },
  ];

  return (
    <QStack borderRadius="2px" borderWidth="1px" borderColor="gray.200" padding="16px">
      <QText fontWeight="semibold">Related requirements</QText>
      <DataProvider.Fixed data={requirements}>
        <QDataTable columns={columns} withHeaders={false} />
      </DataProvider.Fixed>
    </QStack>
  );
};
