import React, { useCallback } from 'react';

import { QBox, QSelect, QSelectItem, QSelectPlaceholder, QText } from '@qualio/ui-components';

import { StateWorkflow } from '../../../types/qualityTypeConfig';

type RiskStatusLabelSelectProps = {
  value: string;
  options: Array<StateWorkflow>;
  onChange: (e: string) => void;
};
export const RiskStatusLabelSelect: React.FC<RiskStatusLabelSelectProps> = ({ value, options, onChange }) => {
  const handleChange = useCallback(
    (selected: QSelectItem | null) => {
      if (selected) {
        onChange(selected.value);
      }
    },
    [onChange],
  );

  return (
    <QBox data-cy="statusLabel-input">
      <QSelect
        options={options.map((item) => ({ label: item.name, value: item.name }))}
        onChange={handleChange}
        value={value}
        isDisabled={false}
        isLoading={false}
        isSearchable={false}
      >
        <QSelectPlaceholder>
          <QText>Select status label</QText>
        </QSelectPlaceholder>
      </QSelect>
    </QBox>
  );
};
