import React, { useLayoutEffect, useRef, useState } from 'react';

import { QBox, QStack, QTag, QText, QTooltip } from '@qualio/ui-components';

type TagWrapperProps = {
  tags: readonly string[];
};

export const TagWrapper: React.FC<TagWrapperProps> = ({ tags }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const tagRefs = useRef<(HTMLSpanElement | null)[]>([]);

  const [isComputingLayout, setIsComputingLayout] = useState(true);
  const [numHiddenTags, setNumHiddenTags] = useState(0);

  useLayoutEffect(() => {
    if (!isComputingLayout || !containerRef.current) {
      return;
    }

    const containerWidth = containerRef.current?.getBoundingClientRect().width ?? 0;

    const tagWidths = tagRefs.current.map((el) => el?.getBoundingClientRect().width);

    // Count the number of tags that will fit within the container.
    let i = 0;
    for (let remainingWidth = containerWidth; i < tags.length; i++) {
      remainingWidth -= tagWidths[i] ?? 0;
      if (remainingWidth < 0) {
        break;
      }
    }
    const numVisibleTags = Math.max(1, i);
    setNumHiddenTags(tags.length - numVisibleTags);
    setIsComputingLayout(false);
  }, [tags, tagRefs, containerRef, isComputingLayout]);

  return (
    <QBox ref={containerRef} flexGrow={1}>
      <QStack direction="row" spacing="8px" position={isComputingLayout ? 'absolute' : undefined}>
        {tags.slice(0, tags.length - numHiddenTags).map((tag, i) => (
          <QTag key={tag} ref={(el) => (tagRefs.current[i] = el)} maxWidth="200px">
            {tag}
          </QTag>
        ))}
        {numHiddenTags > 0 && <OverflowTags tags={tags.slice(tags.length - numHiddenTags)} />}
      </QStack>
    </QBox>
  );
};

const StackedTags: React.VFC<TagWrapperProps> = ({ tags }) => (
  <QBox>
    {tags.map((tag) => (
      <QText key={tag}>{tag}</QText>
    ))}
  </QBox>
);

const OverflowTags: React.VFC<TagWrapperProps> = ({ tags }) => (
  <QTooltip label={<StackedTags tags={tags} />}>
    <QText color="blue.500" cursor="pointer">
      +{tags.length}
    </QText>
  </QTooltip>
);
