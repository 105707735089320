import { ReactNode } from 'react';

import { ButtonProps } from 'antd/lib/button/button';

export enum MessageType {
  INFO,
  SUCCESS,
  WARNING,
  DANGER,
  NEUTRAL,
}

export enum MessageStyle {
  FULL,
  COMPACT,
}

export type MessageId = number | string;

type ActionParams = {
  ctaProps: ButtonProps;
  messageId?: MessageId;
};

export type Message = {
  type: MessageType;
  title?: ReactNode;
  text?: ReactNode;
  actions?: (params: ActionParams) => ReactNode;
};

export type MessageWithId = Message & {
  id: MessageId;
};
