import React from 'react';

import styled from 'styled-components';

const Container = styled.div`
  width: 806px;
  margin: 0 auto;
`;

const FormContainer: React.FC = ({ children }) => {
  return <Container>{children}</Container>;
};

export default FormContainer;
