import styled from 'styled-components';

export const ChipContainer = styled.div`
  display: flex;
  margin: ${({ theme }) => theme.space[4]} 0px;
  height: 28px;
  div {
    display: flex;
    padding: ${({ theme }) => theme.space[3]} ${({ theme }) => theme.space[4]};
    border: 1px solid ${({ theme }) => theme.colors.gray[20]};
    border-radius: 18px;
    align-self: center;
    align-items: center;
    max-width: 100%;
    p {
      margin-bottom: 0 !important;
      padding-right: ${({ theme }) => theme.space[3]};
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    button {
      height: 20px;
      width: 20px;
    }
  }

  .__react_component_tooltip.show {
    opacity: 1;
  }

  .__react_component_tooltip.place-top::after {
    border-top: 8px solid ${({ theme }) => theme.colors.gray[20]} !important;
    bottom: -8px;
  }

  .tooltip {
    max-width: 700px;
    opacity: 1;
    background-color: ${({ theme }) => theme.colors.primary[100]};
    background-color: white;
    color: black;
    border-radius: 4px;
    padding: ${({ theme }) => theme.space[5]};
    -webkit-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.12);
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.12);
  }
`;
