import { BaseQualityType } from '../types/baseQualityItem';
import { OpenIssue } from '../types/openIssue';
import { Payload } from '../types/payload';
import api from './index';

export const openIssueApi = {
  query(company: number, product: string, type?: BaseQualityType): Promise<OpenIssue[]> {
    const elementTypeQuery = type ? `?type=${type}` : '';

    return api
      .get<Payload<OpenIssue[]>>(
        `/company/${company}/product-development/product/${product}/openIssue${elementTypeQuery}`,
      )
      .then((res) => res.data.data);
  },
};

export default openIssueApi;
