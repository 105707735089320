import React from 'react';

import { QDivider } from '@qualio/ui-components';

import { Relation } from '../../../components/QualityItemDetails/types';
import { DesignElementType } from '../../../types/baseQualityItem';
import { ChangeHistory } from '../../../types/changeHistory';
import { BaseQualityItemTrace } from '../../../types/qualityItemTrace';
import { TestCaseWithResult } from '../../../types/testCase';
import { TestResult } from '../../TestResult/TestResult';
import { RequirementTestCaseDetailsDrawerBody } from './RequirementTestCaseDetailsDrawerBody';

export const TestCaseDetailsDrawerBody: React.FC<{
  designElement: BaseQualityItemTrace;
  relatedDesignElements: Array<Relation>;
  reviewHistory: ChangeHistory;
  configsMap: Record<DesignElementType, string>;
  reviewClick?: (id: string) => void;
  designElementClick: (designElementId: string, designElementVersion?: string) => void;
}> = ({ designElement, relatedDesignElements, reviewHistory, configsMap, reviewClick, designElementClick }) => {
  const attachments = (designElement as TestCaseWithResult).attachments ?? [];
  const testResult = (designElement as TestCaseWithResult).testResult;

  return (
    <>
      <RequirementTestCaseDetailsDrawerBody
        attachments={attachments}
        designElement={designElement}
        relatedDesignElements={relatedDesignElements}
        reviewHistory={reviewHistory}
        configsMap={configsMap}
        reviewClick={reviewClick}
        designElementClick={designElementClick}
      >
        {testResult && (
          <>
            <TestResult testResult={testResult} />
            <QDivider />
          </>
        )}
      </RequirementTestCaseDetailsDrawerBody>
    </>
  );
};
