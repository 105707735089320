import React from 'react';

import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import { DesignElementDrawer } from './components/DesignElementDrawer/DesignElementDrawer';
import { CurrentProductProvider } from './context/currentProduct';
import { ListLayout } from './Layout/ListLayout';
import { RiskListLayout } from './Layout/RiskListLayout';
import { FMEARiskList } from './views/FMEARiskList/FMEARiskList';
import { ISORiskList } from './views/ISORiskList/ISORiskList';
import { Products } from './views/Products/Products';
import { RequirementList } from './views/RequirementList/RequirementList';
import { TestList } from './views/TestList/TestList';

export const App: React.FC = () => {
  return (
    <BrowserRouter basename="/design-controls">
      <Routes>
        <Route
          path="product/:product"
          element={
            <CurrentProductProvider>
              <ListLayout />
            </CurrentProductProvider>
          }
        >
          <Route path="requirement" element={<RequirementList />}>
            <Route path=":selectedItemId" element={<DesignElementDrawer />} />
          </Route>
          <Route path="risk" element={<RiskListLayout />}>
            <Route path="iso" element={<ISORiskList />}>
              <Route path=":selectedItemId" element={<DesignElementDrawer />} />
            </Route>
            <Route path="fmea" element={<FMEARiskList />}>
              <Route path=":selectedItemId" element={<DesignElementDrawer />} />
            </Route>
          </Route>
          <Route path="test-case" element={<TestList />}>
            <Route path=":selectedItemId" element={<DesignElementDrawer />} />
          </Route>
          <Route index element={<Navigate to="requirement" />} />
        </Route>

        <Route path="product" element={<Products />} />
        <Route index element={<Navigate to="/product" />} />
      </Routes>
    </BrowserRouter>
  );
};
