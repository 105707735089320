import React from 'react';

import { Policy, RequirementPolicies, TestCasePolicies } from '@design-controls/types';
import { QFormControl, QSelect } from '@qualio/ui-components';
import { Controller } from 'react-hook-form';

import { usePolicyGroupResourceQuickSearch } from '../../hooks/qri/usePolicyGroupResourceQuickSearch';

type Props = {
  policyFilterFn: (policy: Policy) => policy is RequirementPolicies | TestCasePolicies;
};

export const CategoryFilter: React.FC<Props> = ({ policyFilterFn }) => {
  const { isLoading, data } = usePolicyGroupResourceQuickSearch(policyFilterFn);

  return (
    <Controller
      name="category"
      render={({ field: { onChange, value } }) => (
        <QFormControl label="Category">
          <QSelect
            value={value}
            onChange={(selectedItem) => selectedItem && onChange(selectedItem.value)}
            options={data.map((item) => ({ value: item, label: item.displayLabel }))}
            isLoading={isLoading}
          />
        </QFormControl>
      )}
    />
  );
};
