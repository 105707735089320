import { QualityTypeConfigWithIntegrations } from '../../../context/qualityConfigTypes';
import { DesignElementType } from '../../../types/baseQualityItem';
import { FormRelatedElementType, Requirement } from '../../../types/requirement';
import { NativeTestCaseContainerFormType, TestCaseSegmentedControlType } from '../assets/NativeTestCase.zod';

export const toTestCaseSegmentedControlType = (
  testCase: QualityTypeConfigWithIntegrations,
): TestCaseSegmentedControlType => {
  return {
    prefix: testCase.codePrefix ?? '',
    title: testCase.label,
    type: testCase.type as DesignElementType,
  };
};

export const toDefaultTestCaseFormValue = (
  testCaseSegmentedControlType: TestCaseSegmentedControlType,
): NativeTestCaseContainerFormType => {
  return {
    title: '',
    statusLabel: { label: '', value: '' },
    description: '',
    category: undefined,
    type: testCaseSegmentedControlType,
    req1: [],
    req2: [],
    req3: [],
  };
};

export const toFormRelatedElementType = (designElementTrace: Requirement): FormRelatedElementType => {
  return {
    code: designElementTrace.code ?? '',
    label: designElementTrace.codeTitle ?? '',
    value: designElementTrace.id,
  };
};
