import { SegmentedControlItemTextDataCy, SegmentedControlMenuItemDataCy } from '../assets/SegmentedControl.enum';
import { SegmentedControlItem } from '../types/SegmentedControl.types';

export const areSegmentedControlItemsEqual = (first: SegmentedControlItem, second: SegmentedControlItem): boolean => {
  return first.prefix === second.prefix && first.title === second.title;
};

export const textItemDataCy = (isDisabled?: boolean): SegmentedControlItemTextDataCy => {
  return isDisabled
    ? SegmentedControlItemTextDataCy.SEGMENTED_CONTROL_DISABLED_ITEM_TEXT
    : SegmentedControlItemTextDataCy.SEGMENTED_CONTROL_ITEM_TEXT;
};

export const menuItemDataCy = (isItemSelected: boolean, isDisabled?: boolean): SegmentedControlMenuItemDataCy => {
  if (isDisabled) {
    if (isItemSelected) {
      return SegmentedControlMenuItemDataCy.SEGMENTED_CONTROL_DISABLED_ITEM_SELECTED;
    }

    return SegmentedControlMenuItemDataCy.SEGMENTED_CONTROL_DISABLED_ITEM;
  } else {
    if (isItemSelected) {
      return SegmentedControlMenuItemDataCy.SEGMENTED_CONTROL_ITEM_SELECTED;
    }

    return SegmentedControlMenuItemDataCy.SEGMENTED_CONTROL_ITEM;
  }
};
