import { useMemo } from 'react';

import { useQueries } from 'react-query';

import itemsApi from '../../../api/items';
import { getRelations } from '../../../components/QualityItemDetails/getRelations';
import { Relation } from '../../../components/QualityItemDetails/types';
import { useRiskConfig } from '../../../components/RiskForm/useRiskConfig';
import { useQualityConfigs } from '../../../context/qualityConfigs';
import { sortByCode } from '../../../lib/sortByCode';
import { DesignElementType, QualityItemStatus } from '../../../types/baseQualityItem';
import { ChangeHistory } from '../../../types/changeHistory';
import { BaseQualityItemTrace } from '../../../types/qualityItemTrace';
import { RiskTrace } from '../../../types/trace';

type BaseTrace = BaseQualityItemTrace | RiskTrace;

type UseDesignElementDetailsDrawerData = {
  isLoading: boolean;
  isError: boolean;
  designElement: BaseTrace | undefined;
  relatedDesignElements: Array<Relation>;
  reviewHistory: ChangeHistory;
  configsMap: Record<DesignElementType, string>;
  isoRiskQualityConfigLevels: string[];
  fmeaThreshold: number | undefined;
};

export function useDesignElementDetailsDrawerData(
  companyId: number,
  productId: string,
  designElementId: string,
  designElementVersion?: string,
): UseDesignElementDetailsDrawerData {
  const { configs } = useQualityConfigs();
  const { config: riskConfig } = useRiskConfig();
  const version = designElementVersion ?? 'v0';

  const queries = useQueries([
    {
      queryKey: `company-${companyId}-product-${productId}-designelementid-${designElementId}-version-${version}`,
      queryFn: () => itemsApi.queryById(companyId, productId, designElementId, version),
      cacheTime: 0,
    },
    {
      queryKey: `company-${companyId}-product-${productId}-designelementid-${designElementId}`,
      queryFn: () => itemsApi.getHistoryByItemId(companyId, productId, designElementId),
      cacheTime: 0,
    },
  ]);

  const isLoading = queries.some((query) => query.isLoading);
  const isError = queries.some((query) => query.isError);

  const designElement = queries[0].data as BaseTrace;
  const reviewHistory = queries[1].data as ChangeHistory;
  const relatedDesignElements = useMemo(
    () =>
      designElement
        ? getRelations(configs, designElement)
            .filter((relation) => relation.itemStatus !== QualityItemStatus.DELETED)
            .sort(sortByCode)
        : [],
    [configs, designElement],
  );

  const configsMap = useMemo(() => {
    if (!configs) {
      return {} as Record<DesignElementType, string>;
    }

    return configs.reduce((typeToLabelMap, currentConfig) => {
      typeToLabelMap[currentConfig.type] = currentConfig.label;
      return typeToLabelMap;
    }, {} as Record<DesignElementType, string>);
  }, [configs]);

  return {
    isLoading,
    isError,
    designElement,
    relatedDesignElements,
    reviewHistory,
    configsMap,
    isoRiskQualityConfigLevels: riskConfig?.assessment.iso.levels ?? [],
    fmeaThreshold: riskConfig?.assessment.fmea?.mitigationThreshold,
  };
}
