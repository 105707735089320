import { Destination, QAttachmentDetails, QAttachmentUploadInfo } from '@qualio/ui-components';

import { downloadFile } from '../lib/downloadFile';
import { AttachmentWithIdType, PresignedUrlRequestType, PresignedUrlResponseType } from '../types/generatePresignedUrl';
import api from './index';

export const attachmentsApi = {
  generatePutUrl(
    company: number,
    product: string,
    presignedUrlRequest: PresignedUrlRequestType,
  ): Promise<PresignedUrlResponseType> {
    return api
      .post(`attachments/company/${company}/product/${product}/generatePresignedUrl`, { data: presignedUrlRequest })
      .then((res) => res.data.data);
  },

  getDownloadUrl(company: number, product: string, attachmentId: string): string {
    return `${process.env.REACT_APP_API_BASE_URL}/attachments/company/${company}/product/${product}/download/attachment/${attachmentId}`;
  },

  downloadAttachment(
    company: number,
    product: string,
    attachments: Array<AttachmentWithIdType>,
    attachmentId: string,
  ): void {
    const selectedAttachment = attachments.find((a) => a.id === attachmentId);
    if (selectedAttachment) {
      downloadFile(attachmentsApi.getDownloadUrl(company, product, attachmentId));
    }
  },

  uploadInfoProvider(companyId: number, productId: string) {
    return (fileDetails: QAttachmentDetails): Promise<QAttachmentUploadInfo> => {
      return this.generatePutUrl(companyId, productId, {
        filename: fileDetails.filename,
        filesize: fileDetails.filesize,
        contentType: fileDetails.contentType,
        md5Sum: fileDetails.md5Sum,
      })
        .then((presignedUrlResponse) => ({
          destination: Destination.S3,
          url: presignedUrlResponse.presignedUrl,
          id: presignedUrlResponse.attachment.id,
        }))
        .catch((error) => {
          console.log('error', error);
          throw error;
        });
    };
  },
};
