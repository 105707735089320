import * as React from 'react';

import styled from 'styled-components';

const StyledHorizontalLine = styled.div`
  border-top: 1px solid ${(props) => props.theme.colors.gray[20]};
  margin-bottom: ${({ theme }) => theme.space[6]};
`;

const HorizontalLine: React.FC = () => {
  return <StyledHorizontalLine />;
};

export default HorizontalLine;
