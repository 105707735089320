import { DesignElement, ElementList } from '@design-controls/types';
import axios from 'axios';

const api = axios.create({
  headers: {
    'Content-Type': 'application/json',
  },
  baseURL: process.env.REACT_APP_API_BASE_URL,
  withCredentials: true,
});

export const designControlsApi = {
  async query(company: number, product: string, type?: string): Promise<Array<ElementList>> {
    const elementTypeQuery = type ? `?type=${type}` : '';

    const response = await api.get<{ data: Array<ElementList> }>(
      `/company/${company}/product-development/product/${product}/openIssue${elementTypeQuery}`,
    );

    return ElementList.array().parse(response.data.data);
  },
  async getById(company: number, product: string, qualityItemId: string, qualityItemVersion?: string) {
    let url = `/company/${company}/product-development/product/${product}/qualityItem/${qualityItemId}`;
    if (qualityItemVersion !== undefined) {
      url = `${url}/version/${qualityItemVersion}`;
    }

    const response = await api.get<{ data: Array<DesignElement> }>(url);
    return DesignElement.parse(response.data.data);
  },
} as const;
