import React, { createContext } from 'react';

export type ContainerContextValue = {
  id: string;
  selector: string;
  element: HTMLElement;
};

function provideValue(containerId: string): ContainerContextValue {
  return {
    id: containerId,
    selector: `#${containerId}`,
    get element() {
      return document.getElementById(containerId) as HTMLElement;
    },
  };
}

export const ContainerContext = createContext<ContainerContextValue>(provideValue(''));

const AppContainer: React.FC<{ containerId: string }> = ({ containerId, children }) => (
  <ContainerContext.Provider value={provideValue(containerId)}>{children}</ContainerContext.Provider>
);

export default AppContainer;
