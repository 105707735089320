import { useCallback } from 'react';

import { useNavigate } from 'react-router-dom';

export type UseRouteBasedDrawerReturn = {
  handleItemSelected: (itemId?: string, state?: Record<string, string>) => void;
  handleClose: () => void;
  handleGoBack: () => void;
};

export function useRouteBasedDrawer(baseLink: string): UseRouteBasedDrawerReturn {
  const navigate = useNavigate();

  const handleItemSelected = useCallback(
    (itemId?: string, customState?: Record<string, string>) => {
      let path = baseLink;

      if (!itemId) {
        return;
      }

      if (itemId) {
        path += `/${itemId}`;
      }

      navigate(path, { state: { customState, designElementsVisited: 0 }, replace: false });
    },
    [navigate, baseLink],
  );

  const handleClose = useCallback(() => {
    navigate(baseLink);
  }, [baseLink, navigate]);

  const handleGoBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  return {
    handleItemSelected,
    handleClose,
    handleGoBack,
  };
}
