import React, { ReactElement, useCallback, useMemo } from 'react';

import { ColumnsType } from 'antd/es/table';
import { TableProps } from 'antd/lib/table';
import styled from 'styled-components';

import { useDrawerProvider } from '../../components_new/DesginElementDetailsDrawer/context/useDrawerProvider';
import { useLocalisingDateFormatter } from '../../lib/dateFormat';
import { designControlStatus } from '../../lib/designControlStatus';
import { sortByCode } from '../../lib/sortByCode';
import { BaseQualityItem } from '../../types/baseQualityItem';
import BorderedValue from '../BorderedValue';
import { FeatureFlags } from '../FeatureToggle/FeatureToggle.enum';
import useFeatureFlag from '../FeatureToggle/hooks/useFeatureFlag';
import ItemCode from '../ItemCode';
import StyledTable, { StyledTableProps } from '../StyledTable';
import commonTableStyles from '../StyledTable/commonStyles.module.less';

type ItemType = Pick<
  BaseQualityItem,
  'id' | 'code' | 'title' | 'category' | 'updated' | 'changeControlStatus' | 'itemStatus' | 'statusLabel'
>;

export type QualityItemsTableProps<TItem extends ItemType> = {
  items: TItem[];
  selectedItemId?: string;
} & StyledTableProps;

const itemRowClassname = 'itemRow';

const StylesWrapper = styled.div`
  .${itemRowClassname}:hover {
    cursor: pointer;
  }
`;

export function QualityItemsTable<TItem extends ItemType>({
  items,
  selectedItemId,
  ...tableProps
}: QualityItemsTableProps<TItem>): ReactElement<any, any> | null {
  const dateFormat = useLocalisingDateFormatter();
  const { handleItemSelected } = useDrawerProvider();
  const features = useFeatureFlag([FeatureFlags.DESIGN_CONTROLS_CATEGORIES]);

  const columns: ColumnsType<TItem> = useMemo(
    () => [
      {
        title: 'Code',
        key: 'id',
        dataIndex: 'code',
        width: 100,
        render: (code) => (
          <span className={`${commonTableStyles.primaryColumn}`}>
            <main className={commonTableStyles.primaryColumnContent}>{code && <ItemCode code={code} />}</main>
          </span>
        ),
        defaultSortOrder: 'ascend',
        sorter: sortByCode,
      },
      {
        title: 'Title',
        key: 'title',
        dataIndex: 'title',
        render: (title) => (
          <span className={`${commonTableStyles.primaryColumn}`}>
            <span className={commonTableStyles.primaryColumnContent}>{title}</span>
          </span>
        ),
        sorter: (elA, elB) => elA.title?.localeCompare(elB.title),
      },
      {
        title: 'Last updated',
        dataIndex: 'updated',
        width: 150,
        render: (updated) => dateFormat(updated),
        sorter: (elA, elB) => elA.updated?.localeCompare(elB.updated),
      },
      {
        title: 'Status',
        key: 'statusLabel',
        width: 100,
        dataIndex: 'statusLabel',
        sorter: (elA, elB) => (elA.statusLabel || '').localeCompare(elB.statusLabel || ''),
      },
      {
        title: 'Design Control',
        key: 'itemStatus',
        dataIndex: 'itemStatus',
        width: 150,
        render: (_, item) => {
          return <BorderedValue borderType={'rectangle'}>{designControlStatus(item)}</BorderedValue>;
        },
        sorter: (elA, elB) => (elA.itemStatus || '').localeCompare(elB.itemStatus || ''),
      },
    ],
    [dateFormat],
  );

  if (features[FeatureFlags.DESIGN_CONTROLS_CATEGORIES]) {
    columns.splice(2, 0, {
      title: 'Category',
      key: 'category',
      dataIndex: 'category',
      render: (_, item) => <span data-cy={`${item.id}-category`}>{item.category?.label}</span>,
      sorter: (elA, elB) => (elA.category?.label || '').localeCompare(elB.category?.label || ''),
    });
  }

  const rowHandlers: TableProps<TItem>['onRow'] = useCallback(
    (row) => {
      return {
        onClick: () => handleItemSelected(row.id),
        className: itemRowClassname,
      };
    },
    [handleItemSelected],
  );

  const rowSelection: TableProps<TItem>['rowSelection'] = useMemo(() => {
    if (selectedItemId) {
      return {
        selectedRowKeys: [selectedItemId],
        renderCell: () => null,
        hideSelectAll: true,
      };
    }
  }, [selectedItemId]);

  return (
    <StylesWrapper>
      <StyledTable
        dataSource={items}
        columns={columns}
        onRow={rowHandlers}
        rowSelection={rowSelection}
        {...tableProps}
      />
    </StylesWrapper>
  );
}

export default QualityItemsTable;
