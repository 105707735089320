import React from 'react';

import Icon from '@ant-design/icons';

const Svg = () => (
  <svg width="1em" height="1em" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="22" height="22" fill="white" />
    <rect width="22" height="22" rx="2" fill="#2F80ED" />
    <circle cx="11" cy="11" r="4" fill="white" />
  </svg>
);

const Req1Icon: React.FC<any> = (props) => <Icon component={Svg} {...props} />;

export default Req1Icon;
