import React from 'react';

import { createQColumnHelper, DataProvider, QDataTable, QStack, QTag, QText } from '@qualio/ui-components';

import { QRIAttribute } from '../../../types/baseQualityItem';
import { TestResultElement } from '../../../types/testCase';
import { ResultOptions } from '../../../views/TestCaseResult/TestCaseResult.enums';
import { ResultValueToLabel } from '../../../views/TestCaseResult/testResultValueToLabel';

type Requirement = {
  code: string;
  title: string;
  type: string;
  category?: QRIAttribute;
  testResult?: { result: ResultOptions; executedBy: string; date: string };
};
const columnHelper = createQColumnHelper<Requirement>();

type Props = {
  testCases: Requirement[];
};

const testResultVariants: Record<TestResultElement['result'], 'green' | 'red' | 'gray'> = {
  passed: 'green',
  failed: 'red',
  blocked: 'gray',
};

export const TestCaseTable: React.FC<Props> = ({ testCases }) => {
  if (!testCases.length) {
    return null;
  }

  const columns = [
    columnHelper.text('code', { header: 'Code', maxWidth: '100px' }),
    columnHelper.text('title', { header: 'Title' }),
    columnHelper.text((record) => record.testResult?.executedBy, { header: 'Executed By', maxWidth: '150px' }),
    columnHelper.date((record) => new Date(record.testResult?.date ?? 0), { header: 'Date', maxWidth: '130px' }),
    {
      header: 'Last results',
      meta: {
        maxWidth: '100px',
      },
      cell: ({ row: { original: row } }: { row: { original: Requirement } }) => {
        if (!row.testResult) {
          return '';
        }

        return (
          <QTag variantColor={testResultVariants[row.testResult.result]}>
            {ResultValueToLabel[row.testResult.result]}
          </QTag>
        );
      },
    },
  ];

  return (
    <QStack borderRadius="2px" borderWidth="1px" borderColor="gray.200" padding="16px">
      <QText fontWeight="semibold">Test cases</QText>
      <DataProvider.Fixed data={testCases}>
        <QDataTable columns={columns} withHeaders={false} />
      </DataProvider.Fixed>
    </QStack>
  );
};
