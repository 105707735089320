import { BaseConfigurationType } from '../../types/baseConfiguration';
import { DesignElementType, TestCaseType } from '../../types/baseQualityItem';
import { RequestValidationError } from '../../types/requestValidationError';

export type RequirementAndTestCaseTupleType = { requirement: BaseConfigurationType; testCase: TestCaseType };

export type TestCaseConfigurationsPresentationalProps = {
  requirementAndTestCaseTuples: Array<RequirementAndTestCaseTupleType>;
  addTestCaseCard: (testCase: TestCaseType) => void;
  deleteTestCaseCard: (testCase: TestCaseType) => void;
  testCaseFormState: Record<string, BaseConfigurationType>;
  canDelete: (testCaseType: TestCaseType) => boolean;
};

export type TestCaseConfigurationContainerProps = {
  validationErrors: Array<RequestValidationError>;
  requirementAndTestCaseTuples: Array<RequirementAndTestCaseTupleType>;
  onModalDeleteTestCaseCard?: (type: TestCaseType) => void;
  mode: TestCaseConfigurationCreateEditMode;
  savedConfigTypes?: Array<TestCaseType>;
};

export enum TestCaseConfigurationsPresentationalDataCy {
  TESTCASE_FORM = 'testcase_form',
  TESTCASE1_CARD = 'testcase1_card',
  TESTCASE2_CARD = 'testcase2_card',
  TESTCASE3_CARD = 'testcase3_card',
  ENABLE_TESTCASE_1 = 'enable_testCase1',
  ENABLE_TESTCASE_2 = 'enable_testCase2',
  ENABLE_TESTCASE_3 = 'enable_testCase3',
  DISABLE_TESTCASE_1 = 'disable_testCase1',
  DISABLE_TESTCASE_2 = 'disable_testCase2',
  DISABLE_TESTCASE_3 = 'disable_testCase3',
}

export enum TestCaseConfigurationCreateEditMode {
  CREATE_MODE = 'CREATE_MODE',
  EDIT_MODE = 'EDIT_MODE',
}

export const TestCaseCardDataCyMapper: Record<string, string> = {
  [DesignElementType.TEST_CASE_1]: TestCaseConfigurationsPresentationalDataCy.TESTCASE1_CARD,
  [DesignElementType.TEST_CASE_2]: TestCaseConfigurationsPresentationalDataCy.TESTCASE2_CARD,
  [DesignElementType.TEST_CASE_3]: TestCaseConfigurationsPresentationalDataCy.TESTCASE3_CARD,
};

export const testCaseButtonsDataCy = {
  testCase1: {
    enableDataCy: TestCaseConfigurationsPresentationalDataCy.ENABLE_TESTCASE_1,
    disableDataCy: TestCaseConfigurationsPresentationalDataCy.DISABLE_TESTCASE_1,
  },
  testCase2: {
    enableDataCy: TestCaseConfigurationsPresentationalDataCy.ENABLE_TESTCASE_2,
    disableDataCy: TestCaseConfigurationsPresentationalDataCy.DISABLE_TESTCASE_2,
  },
  testCase3: {
    enableDataCy: TestCaseConfigurationsPresentationalDataCy.ENABLE_TESTCASE_3,
    disableDataCy: TestCaseConfigurationsPresentationalDataCy.DISABLE_TESTCASE_3,
  },
};

export type EditTestCaseConfigurationContainerProps = {
  validationErrors: Array<RequestValidationError>;
  requirementAndTestCaseTuples: Array<RequirementAndTestCaseTupleType>;
  savedConfigTypes: Array<TestCaseType>;
  onModalDeleteTestCaseCard?: (type: TestCaseType) => void;
};
