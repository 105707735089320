import React, { useCallback } from 'react';

import { QButton, QMenuButton, QMenuItem } from '@qualio/ui-components';
import { useNavigate } from 'react-router-dom';

import { UserPermissions } from '../../components';
import UserGroupToggle from '../../components/UserGroupToggle/UserGroupToggle';

export type CreateRiskButtonProps = {
  tabs: Array<{ id: string; label: string }>;
  product: string;
};

export const CreateRiskButton: React.FC<CreateRiskButtonProps> = ({ tabs, product }) => {
  const navigate = useNavigate();

  const navigateToForm = useCallback(
    (e: React.MouseEvent) => {
      let url = `/product/${product}/risk/add`;
      if (e.currentTarget.getAttribute('target-id') === 'fmea') {
        url = `${url}/fmea`;
      }

      navigate(url);
    },
    [navigate, product],
  );

  return (
    <UserGroupToggle userPermission={UserPermissions.CREATE_DESIGN_ELEMENTS}>
      {tabs.length > 1 ? (
        <div data-cy="create-risk-button">
          <QMenuButton buttonLabel={'Create risk'}>
            {tabs.map((item) => (
              <QMenuItem
                key={item.id}
                data-cy={`item-${item.id.toLowerCase()}`}
                target-id={item.id}
                onClick={navigateToForm}
              >
                {item.label}
              </QMenuItem>
            ))}
          </QMenuButton>
        </div>
      ) : (
        <QButton onClick={navigateToForm} target-id={tabs[0].id} data-cy="create-risk-button">
          Create risk
        </QButton>
      )}
    </UserGroupToggle>
  );
};
