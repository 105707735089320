import React from 'react';

import { QIconButton, QText } from '@qualio/ui-components';
import ReactTooltip from 'react-tooltip';

import { ChipContainer } from './Chip.styles';

interface ChipProps {
  text: string;
  onClick?: () => void;
}

const Chip: React.FC<ChipProps> = ({ text, onClick }) => {
  return (
    <ChipContainer>
      <div>
        <QText fontSize="sm" data-tip="" data-for={text} data-type="info" data-effect="solid">
          {text}
        </QText>
        <QIconButton iconName={'XCircle'} aria-label={'Remove'} onClick={onClick} data-cy={`remove-${text}`} />
      </div>
      <ReactTooltip className="tooltip" id={text} multiline={true}>
        {text}
      </ReactTooltip>
    </ChipContainer>
  );
};

export default Chip;
