import { isRequirementList, RequirementList } from '@design-controls/types';
import { useCurrentUser } from '@qualio/ui-components';
import { useQuery } from 'react-query';

import { designControlsApi } from '../appV2/api/designControls';
import { useCurrentProduct } from '../appV2/hooks/useCurrentProduct';

export const useRequirementList = () => {
  const { companyId } = useCurrentUser();
  const { id: productId } = useCurrentProduct();

  const { isLoading, data } = useQuery<any, any, RequirementList[]>({
    queryFn: () => designControlsApi.query(companyId, productId),
    queryKey: ['OPEN_ISSUE_LIST', companyId, productId],
    select: (records) => records.filter(isRequirementList),
  });

  return {
    isLoading,
    data,
  };
};
