import React from 'react';

import { Policy } from '@design-controls/types';

import { CurrentProductContext } from '../context/currentProduct';

export const useCurrentConfigs = (): Policy[] => {
  const currentProduct = React.useContext(CurrentProductContext);

  if (currentProduct === null) {
    throw new Error('Must use CurrentProductContext');
  }

  return currentProduct.policy;
};
