import React, { HTMLAttributes } from 'react';

import { QAlert, QualioQThemeProvider } from '@qualio/ui-components';
import styled from 'styled-components';

const Container = styled.div`
  margin-bottom: ${({ theme }) => theme.space[5]};
  p {
    margin-bottom: 0 !important;
  }
  article {
    margin: 0 !important;
  }
`;

type MessageAlertProps = {
  title: string;
  type?: 'info' | 'warning' | 'success' | 'error';
} & HTMLAttributes<HTMLElement>;

const MessageAlert: React.FC<MessageAlertProps> = (props) => {
  return (
    <Container {...props}>
      <QualioQThemeProvider>
        <QAlert description={props.title} status={props.type ?? 'info'} />
      </QualioQThemeProvider>
    </Container>
  );
};

export default MessageAlert;
