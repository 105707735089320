import React, { useCallback, useMemo } from 'react';

import { useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

import itemsApi from '../../api/items';
import { FormHeader } from '../../components_new/FormHeader';
import { Loading } from '../../components_new/Loading';
import { FMEAForm } from '../../components_new/RiskForm/FMEAForm';
import { ISOForm } from '../../components_new/RiskForm/ISOForm';
import { RiskFormProps } from '../../components_new/RiskForm/type';
import { useQualityItemsNew } from '../../components_new/RiskForm/useQualityItemsNew';
import { useCompanyId } from '../../context/CurrentUserContext';
import { useProduct } from '../../context/product';
import { useQualityConfigs } from '../../context/qualityConfigs';
import { useRouteParams } from '../../hooks/useRouteParams';
import { RiskParameters } from '../../paramTypes';
import { DesignElementType } from '../../types/baseQualityItem';
import { ControlledDocument } from '../../types/controlledDocument';
import { RiskQualityTypeConfig } from '../../types/qualityTypeConfig';
import { Requirement } from '../../types/requirement';
import { RiskSubType } from '../../types/risk';
import { TestCase } from '../../types/testCase';
import { RiskTrace } from '../../types/trace';
import { filterDeletedElements } from './lib/filterDeletedElements';

const riskMapping: Record<RiskSubType, { title: string; component: React.FC<RiskFormProps<any>> }> = {
  iso: {
    title: 'Edit ISO 14971 risk',
    component: ISOForm,
  },
  fmea: {
    title: 'Edit FMEA risk',
    component: FMEAForm,
  },
};

export const EditRisk: React.FC = () => {
  const { itemId } = useRouteParams<RiskParameters>('/product/:product/risk/edit/:itemId');
  const company = useCompanyId();
  const navigate = useNavigate();
  const { loading, configs } = useQualityConfigs();
  const product = useProduct();

  const { isLoading: isItemsLoading, autoCompleteOptions } = useQualityItemsNew(company, product.id);

  const { isLoading, data: risk } = useQuery<RiskTrace | undefined>(
    `risk-${company}-${product.id}-${itemId}`,
    () =>
      itemsApi.queryById<RiskTrace | undefined>(company, product.id, itemId).then((risk) => {
        if (risk) {
          const mitigationItems = filterDeletedElements(
            risk.mitigationItems as Array<ControlledDocument | Requirement>,
          );
          const sourceItems = filterDeletedElements(
            risk.sourceItems as Array<ControlledDocument | Requirement | TestCase>,
          );
          return { ...risk, sourceItems, mitigationItems };
        }
        return undefined;
      }),
    { cacheTime: 0 },
  );

  const { mutate, isLoading: isSubmitting } = useMutation(
    (payload: any) => itemsApi.update(company, product.id, { data: payload }),
    {
      onSuccess: () => {
        navigate(`/product/${product.id}/risk/${risk?.subType ?? RiskSubType.ISO}`);
      },
      onError: () => undefined,
    },
  );

  const qualityConfigs: Record<string, string> = useMemo(() => {
    return Object.fromEntries(configs.map((item) => [item.type, item.label]));
  }, [configs]);

  const riskConfig = useMemo(() => {
    const config: any = configs.find((item) => item.type === DesignElementType.RISK);

    if (config?.riskLevel) {
      config.assessment = config.assessment || {};
      config.assessment.iso = config.riskLevel;
    }

    return config as RiskQualityTypeConfig;
  }, [configs]);

  const onClose = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const formIsLoading = useMemo(() => loading || isLoading || isItemsLoading, [loading, isLoading, isItemsLoading]);

  const [title, Component] = useMemo(() => {
    if (formIsLoading || !risk) {
      return ['Edit', Loading];
    }

    const subType = risk.subType ?? 'iso';

    return [`${riskMapping[subType].title} - ${risk.code}`, riskMapping[subType].component];
  }, [formIsLoading, risk]);

  return (
    <>
      <FormHeader title={title} productName={product.name} onClose={onClose} />
      <Component
        onSubmit={mutate}
        isSubmitting={isSubmitting}
        riskConfig={riskConfig}
        configs={qualityConfigs}
        onClose={onClose}
        initialValue={risk}
        autoCompleteOptions={autoCompleteOptions}
        itemId={itemId}
      />
    </>
  );
};
