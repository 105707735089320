import { useMutation } from 'react-query';

import configApi from '../../api/config';
import { useMessagesContext } from '../../components/Messages';
import { MessageType } from '../../components/Messages/types';
import { useCompanyId } from '../../context/CurrentUserContext';
import { useResetQualityConfigs } from '../../context/qualityConfigs';
import { BaseQualityType } from '../../types/baseQualityItem';

type UseDeleteDesignElementConfigurationReturnType = {
  deleteDesignElementConfiguration: (designElementType: BaseQualityType) => Promise<void>;
  isDeleting: boolean;
};

type UseDeleteDesignElementConfigurationMutationType = {
  designElementType: BaseQualityType;
};

export const useDeleteDesignElementConfiguration = (
  productId: string,
): UseDeleteDesignElementConfigurationReturnType => {
  const companyId = useCompanyId();
  const resetConfiguration = useResetQualityConfigs();
  const { addMessage, clearMessages } = useMessagesContext();

  const { mutate, isLoading } = useMutation(
    ({ designElementType }: UseDeleteDesignElementConfigurationMutationType) =>
      configApi.deleteConfig(companyId, productId, designElementType),
    {
      mutationKey: `deleteDesignElementConfiguration-company-${companyId}-product-${productId}`,
      onMutate: () => clearMessages(),
      onSuccess: () => resetConfiguration(),
      onError: () =>
        addMessage({
          type: MessageType.DANGER,
          title: 'Configuration failed to delete',
          text: 'Please try again',
        }),
    },
  );

  return {
    deleteDesignElementConfiguration: async (designElementType: BaseQualityType) => mutate({ designElementType }),
    isDeleting: isLoading,
  };
};
