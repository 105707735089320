import { ChangeControlStatus } from '../../types/changeControl';
import { ChangeHistory } from '../../types/changeHistory';
import { makeResponse } from './generic';

export const changeHistoryResponse = makeResponse<ChangeHistory>([
  {
    status: ChangeControlStatus.APPROVED,
    date: '2021-04-16T16:16:39.041Z',
    id: 'cc-1',
    code: 'CC-1',
    title: 'Some requirement (but new title!)',
  },
  {
    status: ChangeControlStatus.IN_PROGRESS,
    date: '2021-04-14T14:16:39.041Z',
    id: 'cc-2',
    code: 'CC-2',
    title: 'Some requirement',
  },
]);
