import { ItemRelationType, Relation } from '../../../components/QualityItemDetails/types';
import { DesignElementType } from '../../../types/baseQualityItem';
import {
  designElementConfigLabelAndColor,
  designElementStatusLabelAndColor,
} from '../../../views_new/Review/List/statusLabelAndColorTag';
import { SearchAndSelectElementType } from '../../SearchAndSelectElements/SearchAndSelectElement';

export const mapRelationToDesignElementDisplayItem = (
  item: Relation,
  configsMap: Record<DesignElementType, string>,
): SearchAndSelectElementType => ({
  id: item.id,
  label: item.label,
  title: item.code as string,
  showTitleInBadge: true,
  selectedItemBody: item.title,
  selectedItemLeftStatus: designElementStatusLabelAndColor(item.itemStatus),
  selectedItemRightStatus: designElementConfigLabelAndColor(configsMap[item.type], item.subType),
  item: item,
});

export const riskRelatedItemsByType = (
  relatedDesignElements: Array<Relation>,
  configsMap: Record<DesignElementType, string>,
  type: ItemRelationType,
): Array<SearchAndSelectElementType> => {
  return relatedDesignElements
    .filter((item) => item.relationLabel === type)
    .map((item) => mapRelationToDesignElementDisplayItem(item, configsMap));
};
