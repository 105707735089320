import React, { FunctionComponent, useState } from 'react';

import { DownCircleFilled, RightCircleOutlined } from '@ant-design/icons';
import { Table } from 'antd';
import { TableProps } from 'antd/lib/table/Table';

import styles from './StyledTable.module.less';

export type StyledTableProps<RecordType = any> = TableProps<RecordType> & {
  isLoading?: boolean;
  loadingRowsCount?: number;
};
type StyledTableType<RecordType = any> = FunctionComponent<StyledTableProps<RecordType>>;

const StyledTable: StyledTableType = (props) => {
  const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);

  const expandableProps = props.expandable;

  if (expandableProps && !expandableProps.expandIcon) {
    expandableProps.expandIcon = ({ expanded, onExpand, record }) =>
      expanded ? (
        <div data-cy={`${record.id}-collapse`}>
          <DownCircleFilled className={styles.topLevelExpandIcon} onClick={(e) => onExpand(record, e)} />
        </div>
      ) : (
        <div data-cy={`${record.id}-expand`}>
          <RightCircleOutlined className={styles.topLevelExpandIcon} onClick={(e) => onExpand(record, e)} />
        </div>
      );
  }

  if (expandableProps && !expandableProps.onExpandedRowsChange) {
    expandableProps.onExpandedRowsChange = (keys) => setExpandedRowKeys(keys as string[]);
  }

  if (props.isLoading) {
    const columns = (props.columns || []).map((col) => ({
      ...col,
      dataIndex: 'id',
      render: () => <span>&nbsp;</span>,
    }));
    const rows = [
      {
        id: 1,
      },
      {
        id: 2,
      },
      {
        id: 3,
      },
      {
        id: 4,
      },
      {
        id: 5,
      },
    ].slice(0, props.loadingRowsCount || 5);

    return (
      <Table
        pagination={false}
        {...props}
        rowKey="id"
        dataSource={rows}
        columns={columns}
        expandable={expandableProps}
        showSorterTooltip={false}
        className={`${styles.table} ${styles.loading}`}
      />
    );
  }

  return (
    <Table
      id="styled-table"
      pagination={false}
      rowKey="id"
      rowClassName={(row: any, index) =>
        `${expandedRowKeys.includes(row.id) ? 'table-row-expanded' : ''} ${
          index === (props.dataSource || []).length - 1 ? 'table-row-last' : ''
        }`
      }
      {...props}
      expandable={expandableProps}
      className={`${styles.table} ${props.className}`}
      showSorterTooltip={false}
    />
  );
};

export default StyledTable;
