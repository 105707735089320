import { themeGet } from '@styled-system/theme-get';
import styled from 'styled-components';

const ARROW_SIZE = '4px';
const TOOLTIP_BACKGROUND_COLOR = `rgba(0, 0, 0, 0.8)`;

export const StyledTooltip = styled.aside`
  display: none;

  position: absolute;
  left: 50%;
  bottom: 100%;
  transform: translateX(-50%);

  margin-bottom: ${ARROW_SIZE};

  padding: ${themeGet('sizes.2')} ${themeGet('sizes.4')};
  background: ${TOOLTIP_BACKGROUND_COLOR};
  border-radius: ${themeGet('radii.2')};
  width: max-content;
  max-width: 90%;

  color: ${themeGet('colors.white')};
  text-align: center;

  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 100%;
    left: 50%;

    margin-left: -${ARROW_SIZE};

    width: 0;
    height: 0;

    border: ${ARROW_SIZE} solid transparent;
    border-top-color: ${TOOLTIP_BACKGROUND_COLOR};
  }
`;

export const StyledWrapper = styled.span`
  display: inline-block;
  position: relative;

  &:hover ${StyledTooltip} {
    display: block;
  }
`;
