import { Payload } from '../../types/payload';

export function makeResponse<T extends any>(body: T = {} as T): Payload<T> {
  return {
    data: body,
  };
}

export function makeEmptyResponse(): Payload<Record<string, never>> {
  return makeResponse();
}
