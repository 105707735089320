import { useMemo } from 'react';

import { useQuery } from 'react-query';

import itemsApi from '../../../api/items';
import { useCompanyId } from '../../../context/CurrentUserContext';
import { useQualityConfigs } from '../../../context/qualityConfigs';
import { testCaseTypes } from '../../../lib/designControlNativeEnabled';
import { TestCaseType } from '../../../types/baseQualityItem';
import { TestCaseTrace } from '../../../types/trace';

type UseTestCasesType = {
  loading: boolean;
  testCases: Array<TestCaseTrace>;
};

export const useTestCases = (productId: string, config?: string): UseTestCasesType => {
  const companyId = useCompanyId();
  const { loading, configs } = useQualityConfigs();

  const testCaseConfigs = useMemo<Array<TestCaseType>>(() => {
    if (loading || !configs.length) {
      return [];
    }

    return configs.filter((item) => testCaseTypes.has(item.type)).map((item) => item.type as TestCaseType);
  }, [loading, configs]);

  const { isLoading, data } = useQuery<Array<TestCaseTrace>>(
    `test-cases-${config}-${JSON.stringify(testCaseConfigs)}`,
    () => {
      return itemsApi.queryTestCases(companyId, productId, config !== '' ? [config as TestCaseType] : testCaseConfigs);
    },
    { cacheTime: 0 },
  );

  return { loading: isLoading, testCases: data ?? [] };
};
