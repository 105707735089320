import React, { ReactNode } from 'react';

import { StyledTooltip, StyledWrapper } from './Tooltip.styles';

export type TooltipProps = {
  content: string | ReactNode;
};

const Tooltip: React.FC<TooltipProps> = ({ content, children }) => {
  return (
    <StyledWrapper>
      {children}
      <StyledTooltip>{content}</StyledTooltip>
    </StyledWrapper>
  );
};

export default Tooltip;
