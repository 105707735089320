import React, { useCallback, useMemo } from 'react';

import {
  QButton,
  QButtonGroup,
  QCloseButton,
  QDateMetadataItem,
  QDrawer,
  QHeader,
  QHorizontalMetadata,
  QPageLoader,
  QStack,
  QTag,
  QTitle,
  QVersionMetadata,
} from '@qualio/ui-components';
import { useNavigate } from 'react-router';

import { useDeleteElementDrawer } from '../../hooks/useDesignElementDrawer';
import { GapAnalysis } from '../GapAnalysis/GapAnalysis';
import { DesignElement } from './DesignElement';
import { RequirementTable } from './RequirementTable';
import { ReviewTable } from './ReviewTable';
import { TestCaseTable } from './TestCaseTable';

export const DesignElementDrawer: React.FC = () => {
  const navigate = useNavigate();
  const { isLoading, designElement, designElementHistory } = useDeleteElementDrawer();

  const onClose = useCallback(() => {
    navigate('..');
  }, [navigate]);

  const requirements = useMemo(() => {
    if (!designElement) {
      return [];
    }

    return [...((designElement as any).children ?? []), ...((designElement as any).parent ?? [])].filter((element) =>
      element.type.startsWith('req'),
    );
  }, [designElement]);

  const testCases = useMemo(() => {
    if (!designElement) {
      return [];
    }

    return [...((designElement as any).children ?? [])].filter((element) => element.type.startsWith('testCase'));
  }, [designElement]);

  return (
    <QDrawer isOpen={true} onClose={onClose} size="xl">
      <QHeader>
        {!isLoading && <QTitle>{designElement?.title}</QTitle>}
        <QButtonGroup>
          {!isLoading && designElement?.source === 'product-development' && (
            <QButton variant="outline" leftIcon="Edit2">
              Edit
            </QButton>
          )}
          <QCloseButton onClick={onClose} />
        </QButtonGroup>
        {!isLoading && (
          <QHorizontalMetadata>
            <QTag variantColor="gray">NEW</QTag>
            <QVersionMetadata value={designElement?.version} />
            <QDateMetadataItem label="Created" value={new Date(designElement?.created ?? 0)} />
            <QDateMetadataItem label="Last modified" value={new Date(designElement?.updated ?? 0)} />
          </QHorizontalMetadata>
        )}
      </QHeader>
      <QStack gap={4}>
        {isLoading || !designElement ? (
          <QPageLoader />
        ) : (
          <>
            <GapAnalysis policyIssues={(designElement as any).policyIssues} type={designElement.type} />
            <DesignElement {...designElement} />
            <RequirementTable requirements={requirements} />
            <TestCaseTable testCases={testCases} />
            <ReviewTable changeHistory={designElementHistory ?? []} />
          </>
        )}
      </QStack>
    </QDrawer>
  );
};
