import React, { useMemo } from 'react';

import { useLocation, useParams } from 'react-router-dom';

import { ChangeControlParam } from '../../../paramTypes';
import { DrawerAwarenessContext } from './useDrawerAwareness';

const changeControl = 'change-control';
export const DrawerAwarenessProvider: React.FC = ({ children }) => {
  const { changeControlId } = useParams() as Partial<ChangeControlParam>;
  const { pathname } = useLocation();

  const value = useMemo(() => {
    let shouldBeEnabled = true;
    const isChangeControlInPath = pathname.includes(changeControl);
    if (isChangeControlInPath) {
      const [, changeControlForwardPath] = pathname.split(changeControl);
      const appendedIds = changeControlForwardPath.replace(`/${changeControlId}`, '');
      if (appendedIds !== '') {
        shouldBeEnabled = false;
      }
    }

    return {
      shouldDesignElementRedirectButtonBeEnabled: shouldBeEnabled,
    };
  }, [changeControlId, pathname]);

  return <DrawerAwarenessContext.Provider value={value}>{children}</DrawerAwarenessContext.Provider>;
};
