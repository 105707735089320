import {
  BaseChangeControlStatus,
  BaseQualityItem,
  DesignElementType,
  QualityItemStatus,
} from '../../types/baseQualityItem';
import { ChangeControl, ChangeControlStatus, DashboardItem } from '../../types/changeControl';
import { makeResponse } from './generic';
import { req1QualityItems, testCase1QualityItems } from './qualityItems';
import { FMEARisks } from './risks';

function qualityItemToDashboardItem(
  qualityItem: BaseQualityItem,
  missingFields: { version: string; changeControlStatus?: BaseChangeControlStatus },
): DashboardItem {
  const {
    id,
    code,
    title,
    created,
    updated,
    type,
    codeTitle,
    itemStatus,
    statusLabel,
    changeControlStatus,
    testResult,
  } = qualityItem as BaseQualityItem & { testResult?: any };

  return {
    id,
    code,
    title,
    created,
    updated,
    type,
    codeTitle,
    itemStatus,
    statusLabel,
    changeControlStatus,
    testResult,
    attachments: [],
    ...missingFields,
  } as DashboardItem;
}

function dashboardItemToEmbeddedItem({
  id,
  code,
  type,
  title,
  version,
}: DashboardItem): ChangeControl['qualityItems'][0] {
  return { id, code, type, title, version, latest: 'v10' };
}

const dashboardItems: DashboardItem[] = [
  qualityItemToDashboardItem(req1QualityItems[1], { version: 'v1' }),
  qualityItemToDashboardItem(FMEARisks[0], { version: 'v1' }),
  qualityItemToDashboardItem(testCase1QualityItems[0], { version: 'v1' }),
];

export const dashboardResponse = makeResponse<DashboardItem[]>(dashboardItems);

export const dashboardResponseWithStagedAndUnstaged = makeResponse<DashboardItem[]>([
  qualityItemToDashboardItem(req1QualityItems[0], {
    version: 'v1',
    changeControlStatus: 'unstaged',
  }),
  qualityItemToDashboardItem(FMEARisks[0], { version: 'v1', changeControlStatus: 'staged' }),
  qualityItemToDashboardItem(testCase1QualityItems[0], { version: 'v1', changeControlStatus: 'unstaged' }),
]);

export const createReviewResponse = makeResponse<ChangeControl>({
  id: '353237ab-2b15-4d70-b01b-0fd4ffccc8c4',
  code: 'CC-1',
  qualityItems: [
    {
      code: 'REQ-1',
      type: DesignElementType.REQ1,
      id: req1QualityItems[0].id,
      itemStatus: QualityItemStatus.NEW,
      title: 'Some Title',
      version: 'v1',
    },
    {
      code: 'RSK-1',
      itemStatus: QualityItemStatus.NEW,
      type: DesignElementType.RISK,
      id: '269c7e43-0d34-4f2e-a896-45c398372d08',
      title: 'Some Hazard',
      version: 'v1',
    },
  ],
  created: '2021-01-05',
  updated: '2021-01-08',
  linkedDocuments: [
    {
      id: '12',
      title: 'Default document',
      code: 'DEF-12',
      uuid: 'c35a84e8-5be8-44fe-9833-d239d8b713d0',
      status_id: 'draft',
    },
  ],
  approvers: [{ id: '99', fullName: 'Jane Doe' }],
});

export const changeControlApproved: ChangeControl = {
  id: 'approved-release',
  code: 'DCC-2',
  qualityItems: dashboardItems.map(dashboardItemToEmbeddedItem),
  releaseId: 'ab8eb442-23bc-41bc-bfd6-bcbe0e885f0a',
  created: '2021-01-06',
  updated: '2021-01-11',
  status: ChangeControlStatus.APPROVED,
  approvers: [
    { id: '1', fullName: 'John Doe', status: ChangeControlStatus.APPROVED, modified: '2021-02-01T09:16:03.948Z' },
    { id: '2', fullName: 'Jane Doe', status: ChangeControlStatus.APPROVED, modified: '2021-02-02T09:16:03.948Z' },
  ],
  linkedDocuments: [],
};

export const changeControlApprovedWithoutRelease: ChangeControl = {
  id: 'approved-release-2',
  code: 'DCC-3',
  qualityItems: dashboardItems.map(dashboardItemToEmbeddedItem),
  releaseId: undefined,
  created: '2021-01-06',
  updated: '2021-01-11',
  status: ChangeControlStatus.APPROVED,
  approvers: [
    { id: '1', fullName: 'John Doe', status: ChangeControlStatus.APPROVED, modified: '2021-02-01T09:16:03.948Z' },
    { id: '2', fullName: 'Jane Doe', status: ChangeControlStatus.APPROVED, modified: '2021-02-02T09:16:03.948Z' },
  ],
  linkedDocuments: [],
};

export const changeControlInProgress: ChangeControl = {
  id: 'release-in-progress',
  code: 'CC-4',
  qualityItems: dashboardItems.map(dashboardItemToEmbeddedItem),
  created: '2021-01-06',
  updated: '2021-01-11',
  status: ChangeControlStatus.IN_PROGRESS,
  approvers: [{ id: '1', fullName: 'John Doe', status: ChangeControlStatus.IN_PROGRESS }],
  linkedDocuments: [],
};

export const changeControlInProgressWithOwner1: ChangeControl = {
  id: 'release-in-progress-2',
  code: 'CC-5',
  qualityItems: dashboardItems.map(dashboardItemToEmbeddedItem),
  description: 'some description',
  created: '2021-01-06',
  updated: '2021-01-11',
  status: ChangeControlStatus.IN_PROGRESS,
  approvers: [{ id: '1', fullName: 'John Doe', status: ChangeControlStatus.IN_PROGRESS }],
  linkedDocuments: [
    {
      id: '1',
      title: 'Product durability policy',
      code: 'SOP-1',
      uuid: 'uuid',
      status_id: 'COMPLETE',
    },
  ],
  createdBy: {
    id: '1',
    fullName: 'Kaz Mac',
  },
};

export const changeControlInProgressWithOwner2: ChangeControl = {
  id: 'release-in-progress-3',
  code: 'CC-6',
  qualityItems: dashboardItems.map(dashboardItemToEmbeddedItem),
  created: '2021-01-06',
  updated: '2021-01-11',
  status: ChangeControlStatus.IN_PROGRESS,
  approvers: [{ id: '1', fullName: 'John Doe', status: ChangeControlStatus.IN_PROGRESS }],
  linkedDocuments: [],
  createdBy: {
    id: '2',
    fullName: 'Kaz Mac',
  },
};

const changeControlInProgressWithUserNotInApproversList: ChangeControl = {
  id: 'release-in-progress',
  code: 'CC-4',
  qualityItems: dashboardItems.map(dashboardItemToEmbeddedItem),
  created: '2021-01-06',
  updated: '2021-01-11',
  status: ChangeControlStatus.IN_PROGRESS,
  approvers: [{ id: '99', fullName: 'John Doe', status: ChangeControlStatus.IN_PROGRESS }],
  linkedDocuments: [],
};

const changeControlInProgressWithUserInListAndApproved: ChangeControl = {
  id: 'release-in-progress',
  code: 'CC-4',
  qualityItems: dashboardItems.map(dashboardItemToEmbeddedItem),

  created: '2021-01-06',
  updated: '2021-01-11',
  status: ChangeControlStatus.IN_PROGRESS,
  approvers: [
    {
      id: '1',
      fullName: 'John Doe',
      status: ChangeControlStatus.APPROVED,
      modified: '2021-01-11T09:16:03.948Z',
      note: 'This is a simple note',
    },
    { id: '2', fullName: 'Jane Doe', status: ChangeControlStatus.IN_PROGRESS },
  ],
  linkedDocuments: [],
};

const changeControlWithReleaseInProgress: ChangeControl = {
  id: 'approved-with-release-in-progress',
  code: 'CC-3',
  qualityItems: dashboardItems.map(dashboardItemToEmbeddedItem),

  releaseId: 'release-in-progress',
  status: ChangeControlStatus.APPROVED,
  linkedDocuments: [],
  created: '2021-01-05',
  updated: '2021-01-08',
};

const approvalFlow: Record<ChangeControlStatus.IN_PROGRESS | ChangeControlStatus.APPROVED, ChangeControl> = {
  [ChangeControlStatus.IN_PROGRESS]: {
    id: 'approval-flow-review',
    code: 'CC-1',
    qualityItems: dashboardItems.map(dashboardItemToEmbeddedItem),

    status: ChangeControlStatus.IN_PROGRESS,
    description: 'This is some rather short description',
    created: '2021-01-05',
    updated: '2021-01-08',
    approvers: [
      { id: '1', fullName: 'John Doe', status: ChangeControlStatus.APPROVED },
      { id: '2', fullName: 'Jane Doe', status: ChangeControlStatus.IN_PROGRESS },
    ],
    linkedDocuments: [
      {
        id: '1',
        title: 'Product durability policy',
        code: 'SOP-1',
        uuid: 'uuid',
        status_id: 'COMPLETE',
      },
    ],
  },
  [ChangeControlStatus.APPROVED]: {
    id: 'approval-flow-review',
    code: 'CC-1',
    description: 'This is some rather short description',
    qualityItems: dashboardItems.map(dashboardItemToEmbeddedItem),

    releaseId: 'release-in-progress',
    status: ChangeControlStatus.APPROVED,
    created: '2021-01-05',
    updated: '2021-01-08',
    linkedDocuments: [
      {
        id: '1',
        title: 'Product durability policy',
        code: 'SOP-1',
        uuid: 'uuid',
        status_id: 'COMPLETE',
      },
    ],
    approvers: [
      {
        id: '1',
        fullName: 'John Doe',
        status: ChangeControlStatus.APPROVED,
        modified: '2021-01-11T09:16:03.948Z',
        note: 'This is a simple note',
      },
      {
        id: '2',
        fullName: 'Jane Doe',
        status: ChangeControlStatus.APPROVED,
        modified: '2021-01-12T19:06:03.948Z',
      },
    ],
  },
};

export const changeControlResponse = makeResponse<ChangeControl[]>([
  approvalFlow[ChangeControlStatus.IN_PROGRESS],
  changeControlApprovedWithoutRelease,
  changeControlApproved,
  changeControlWithReleaseInProgress,
  changeControlInProgress,
  changeControlInProgressWithOwner1,
  changeControlInProgressWithOwner2,
]);

export const changeControlAfterApprovalResponse = makeResponse<ChangeControl>(
  approvalFlow[ChangeControlStatus.APPROVED],
);
export const fetchApprovedChangeControlResponse = makeResponse<ChangeControl>(changeControlApproved);

export const fetchApprovedChangeControlWithoutReleaseResponse = makeResponse<ChangeControl>(
  changeControlApprovedWithoutRelease,
);

export const changeControlWithReleaseInProgressResponse = makeResponse<ChangeControl>(
  changeControlWithReleaseInProgress,
);
export const fetchChangeControlInProgressResponse = makeResponse<ChangeControl>(changeControlInProgress);
export const fetchChangeControlInProgressWithOwner1Response = makeResponse<ChangeControl>(
  changeControlInProgressWithOwner1,
);
export const fetchChangeControlInProgressWithOwner2Response = makeResponse<ChangeControl>(
  changeControlInProgressWithOwner2,
);
export const fetchChangeControlInProgressWithUserNotInApproversListResponse = makeResponse(
  changeControlInProgressWithUserNotInApproversList,
);
export const fetchChangeControlInProgressWithUserInListAndApprovedResponse = makeResponse(
  changeControlInProgressWithUserInListAndApproved,
);
