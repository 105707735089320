import { useUserGroups } from '../../../context/CurrentUserContext';
import hasPermission from '../lib/hasPermission';
import { UserPermissions } from '../UserGroupToggle.enum';

export type HasPermissionType = Record<string, boolean>;

const useHasPermission = (permissions: Array<UserPermissions>): HasPermissionType => {
  const [userGroups] = useUserGroups();

  return permissions.reduce((table: HasPermissionType, permission): HasPermissionType => {
    table[permission] = hasPermission(userGroups, permission);
    return table;
  }, {});
};

export default useHasPermission;
