import React, { createContext, useContext, useMemo } from 'react';

import { useCurrentUser as useQCurrentUser } from '@qualio/ui-components';
import { useQuery } from 'react-query';

import qualioApi from '../api/qualio';
import { Company, CurrentUser } from '../types/currentUser';

export const DefaultCurrentUser: CurrentUser = {
  companyId: 0,
  userId: 0,
  permissions: {},
  fullName: '',
  companyName: '',
  email: '',
  company: {
    ai_enabled: false,
    createdTime: 0,
    status: '',
    pricingPlan: null,
  },
  tz: 'Europe/Dublin',
  userGroups: [],
  capabilities: null,
};

export const CurrentUserContext = createContext<CurrentUser & { isLoading: boolean }>({
  ...DefaultCurrentUser,
  isLoading: true,
});

export const CurrentUserProvider: React.FC = ({ children }) => {
  const { userId, companyId, ...rest } = useQCurrentUser();

  const { data, isLoading } = useQuery<CurrentUser>({
    queryKey: ['user-context', userId, companyId],
    queryFn: () =>
      qualioApi.getUserById(companyId, userId).then((response) => {
        const userGroups = response?.companies?.find((company: Company) => company.id === companyId)?.usergroups || [];

        return { userId, companyId, ...rest, userGroups: userGroups as CurrentUser['userGroups'] };
      }),
  });

  const userData = useMemo(() => {
    if (data) {
      return { ...data, isLoading };
    }
    return { userId, companyId, ...rest, userGroups: [], isLoading };
  }, [data, userId, companyId, rest, isLoading]);

  return <CurrentUserContext.Provider value={userData}>{children}</CurrentUserContext.Provider>;
};

export function useUserId(): number {
  const currentUser = useContext(CurrentUserContext);

  return currentUser.userId;
}

export function useCompanyId(): number {
  const currentUser = useContext(CurrentUserContext);

  return currentUser.companyId;
}

export function useUserGroups(): [ReadonlyArray<string>, boolean] {
  const currentUser = useContext(CurrentUserContext);

  return [currentUser.userGroups, currentUser.isLoading];
}
