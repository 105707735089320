import React from 'react';

import { QButton } from '@qualio/ui-components';
import { useParams } from 'react-router-dom';

import PageLoading from '../../../../../components/PageLoading/PageLoading';
import Panel from '../../../../../components/Panel';
import { useCompanyId } from '../../../../../context/CurrentUserContext';
import { useQualityItem } from '../../../../../hooks/useQualityItem';
import { DESIGN_CONTROLS_SOURCE } from '../../../../../lib/source';
import { ProductParam } from '../../../../../paramTypes';
import { Requirement } from '../../../../../types/requirement';
import { RequirementTrace } from '../../../../../types/trace';
import styles from './ExpandedRowItemDetails.module.less';

type ExpandedRowItemDetailsProps = {
  requirement: RequirementTrace;
};

const ExpandedRowItemDetails: React.FC<ExpandedRowItemDetailsProps> = ({ requirement }) => {
  const company = useCompanyId();
  const { product } = useParams<ProductParam>() as ProductParam;
  const { loadingItem, item } = useQualityItem<Requirement>(company, product, requirement.id);

  let content: React.ReactElement;
  if (loadingItem) {
    content = <PageLoading className={styles.loading} />;
  } else if (item?.description) {
    content = <div dangerouslySetInnerHTML={{ __html: item.description }} />;
  } else {
    content = (
      <div>
        <p>No description.</p>
      </div>
    );
  }

  const actions =
    requirement.source !== DESIGN_CONTROLS_SOURCE ? (
      <QButton
        variant="outline"
        rightIcon={'ExternalLink'}
        onClick={() => window.open(requirement.url, '', 'noopener,noreferrer')}
      >
        View more
      </QButton>
    ) : null;

  return (
    <Panel actions={actions}>
      <section className={styles.description}>{content}</section>
    </Panel>
  );
};

export default ExpandedRowItemDetails;
