import { useQuery } from 'react-query';

import itemsApi from '../../api/items';
import { useQualityConfigs } from '../../context/qualityConfigs';
import { DesignElementType } from '../../types/baseQualityItem';
import { TestResultElement } from '../../types/testCase';
import { TestLog } from '../../types/testLog';
import { ResultOptions } from '../../views/TestCaseResult/TestCaseResult.enums';

export type UseTestResultLogElement = {
  isLoading: boolean;
  testResult: TestResultElement | undefined;
};

export const useTestLogElement = (companyId: number, productId: string, resultId?: string): UseTestResultLogElement => {
  const { configs } = useQualityConfigs();

  const { isLoading, data: testResult } = useQuery(
    `company-${companyId}-${productId}-element-${resultId}`,
    () =>
      itemsApi
        .queryById<TestLog>(companyId, productId, resultId as string)
        .then((data): TestResultElement | undefined => {
          if (!data) {
            return;
          }

          const config = configs.find((item) => item.type === DesignElementType.TEST_LOG);
          const result = config?.workflow.states.some((item) => item.name === data.statusLabel && item.isReleasable)
            ? ResultOptions.PASSED
            : ResultOptions.FAILED;

          return {
            result,
            date: data.created,
            executedBy: 'Unknown',
          };
        }),
    { enabled: resultId !== undefined },
  );

  return { isLoading, testResult };
};
