import { useState } from 'react';

import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

import configApi from '../../api/config';
import { useCompanyId } from '../../context/CurrentUserContext';
import { useResetQualityConfigs } from '../../context/qualityConfigs';
import { filterValidationErrors, scrollWindowToTop } from '../../lib/formValidationUtilities';
import { QualityTypeConfigWithType } from '../../types/qualityTypeConfig';
import { RequestValidationError } from '../../types/requestValidationError';

type UseUpdateDesignElementConfigurationsReturnType = {
  updateDesignElementConfigurations: (
    designElementUpdatedConfigurations: Array<QualityTypeConfigWithType>,
  ) => Promise<void>;
  validationErrors: Array<RequestValidationError>;
  isUpdating: boolean;
};

type UseUpdateDesignElementConfigurationsMutationType = {
  designElementUpdatedConfigurations: Array<QualityTypeConfigWithType>;
};

export const useUpdateDesignElementConfigurations = (
  productId: string,
  existingDesignElementConfigurations: Array<QualityTypeConfigWithType>,
): UseUpdateDesignElementConfigurationsReturnType => {
  const companyId = useCompanyId();
  const resetConfiguration = useResetQualityConfigs();
  const navigate = useNavigate();
  const [validationErrors, setValidationErrors] = useState<Array<RequestValidationError>>([]);

  const { mutate, isLoading } = useMutation<any, any, UseUpdateDesignElementConfigurationsMutationType, any>(
    ({ designElementUpdatedConfigurations }: UseUpdateDesignElementConfigurationsMutationType) =>
      Promise.allSettled(
        designElementUpdatedConfigurations.map(({ type, ...config }) => {
          if (existingDesignElementConfigurations.find((item) => item.type === type)) {
            return configApi.updateConfig(companyId, productId, type, config);
          }
          return configApi.createConfig(companyId, productId, type, config);
        }),
      ).then((results: any) => {
        const rejected = results
          .filter((result: any) => result.status === 'rejected')
          .map((result: any) => result.reason);
        if (rejected.length > 0) {
          throw rejected;
        }
        return results;
      }),
    {
      mutationKey: `updateDesignElementConfigurations-company-${companyId}-product-${productId}-designElementTypes-${JSON.stringify(
        existingDesignElementConfigurations,
      )}`,
      onSuccess: () => {
        resetConfiguration();
        navigate(`/product/${productId}`);
      },
      onError: (error: any) => {
        setValidationErrors(filterValidationErrors(error));
        scrollWindowToTop();
      },
    },
  );

  return {
    updateDesignElementConfigurations: async (designElementUpdatedConfigurations: Array<QualityTypeConfigWithType>) =>
      mutate({ designElementUpdatedConfigurations }),
    validationErrors,
    isUpdating: isLoading,
  };
};
