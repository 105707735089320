import styled from '@emotion/styled';

export const CreateProductContainer = styled.div`
  div div:first-of-type {
    margin-top: 0;
  }

  .basic-configuration-card,
  div[data-cy='risk-configuration-form'] > div {
    width: 100%;
  }
`;
