import { useMemo } from 'react';

import { useQualityConfigs } from '../../context/qualityConfigs';
import { RiskQualityTypeConfig } from '../../types/qualityTypeConfig';

export type UseRiskConfig = { loading: boolean; config: RiskQualityTypeConfig | undefined };

export function useRiskConfig(): UseRiskConfig {
  const { configs, loading } = useQualityConfigs();

  return useMemo(() => {
    const config: any = configs.find((config) => config.type === 'risk');

    if (config?.riskLevel) {
      config.assessment = config.assessment || {};
      config.assessment.iso = config.riskLevel;
    }

    return {
      loading,
      config: config as RiskQualityTypeConfig,
    };
  }, [configs, loading]);
}
