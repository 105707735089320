import React from 'react';

import { QFlex, QTag, QText } from '@qualio/ui-components';

import { Risk } from '../../../types/risk';
import { riskLevelAndColor } from '../../../views_new/Review/List/statusLabelAndColorTag';
import { DisplayKeyValuePair } from '../../DisplayKeyValuePair/DisplayKeyValuePair';
import { ISORiskAssessmentLevels } from './ISORiskAssessmentLevels';

export const ISORiskMitigation: React.FC<{ risk: Risk; riskLevels: string[] }> = ({ risk, riskLevels }) => {
  const finalAssessment = risk.postMitigation;
  const riskLevelStatus =
    finalAssessment && finalAssessment.riskLevel ? riskLevelAndColor(finalAssessment.riskLevel, riskLevels) : undefined;

  return (
    <>
      {risk.riskControl && (
        <QFlex marginTop={5} data-cy={'iso-risk-mitigation'} flexDirection={'column'}>
          <QText fontSize={16} weight="semibold">
            Mitigation
          </QText>
          <QFlex marginTop={2} marginBottom={5} flexDirection={'column'}>
            <QText color="gray.700" fontSize={14} weight="semibold">
              Description
            </QText>
            <QText data-cy={'iso-risk-mitigation-description'} fontSize={14}>
              {risk.riskControl.mitigation}
            </QText>
          </QFlex>
          {finalAssessment && (
            <QFlex data-cy={'iso-risk-mitigation-final-assessment'} marginTop={5} flexDirection={'column'}>
              <QText mb="5px" fontSize={16} weight="semibold">
                Final Assessment
              </QText>
              <ISORiskAssessmentLevels>
                <DisplayKeyValuePair keyString={'Risk Level'}>
                  <QText color="gray.500" fontSize={12} weight="semibold">
                    {riskLevelStatus && (
                      <QTag variantColor={riskLevelStatus.variantColor}>{riskLevelStatus.label}</QTag>
                    )}
                  </QText>
                </DisplayKeyValuePair>
                <DisplayKeyValuePair keyString={'Severity'}>
                  <QText color="gray.500" fontSize={12} weight="semibold">
                    {finalAssessment?.severity}
                  </QText>
                </DisplayKeyValuePair>
                <DisplayKeyValuePair keyString={'Probability'}>
                  <QText color="gray.500" fontSize={12} weight="semibold">
                    {finalAssessment.probability}
                  </QText>
                </DisplayKeyValuePair>
              </ISORiskAssessmentLevels>
            </QFlex>
          )}
        </QFlex>
      )}
    </>
  );
};
