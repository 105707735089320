import { useToastProvider } from '@qualio/ui-components';
import { useMutation, useQuery } from 'react-query';

import itemsApi, { designElementApi } from '../../api/items';
import { useCompanyId } from '../../context/CurrentUserContext';
import { BaseQualityItem, DesignElementType } from '../../types/baseQualityItem';

export type UseDesignElement<T> = {
  isLoading: boolean;
  designElements: Array<T>;
  deleteDesignElement: (element: DeleteDesignElement) => void;
  isDeleting: boolean;
};

type DeleteDesignElement = { designElementId: string; type: DesignElementType };

export function useDesignElement<T extends BaseQualityItem>(
  productId: string,
  type: DesignElementType,
  subType?: 'iso' | 'fmea',
): UseDesignElement<T> {
  const companyId = useCompanyId();
  const { showToast } = useToastProvider();

  const {
    data: designElements,
    isLoading,
    refetch,
    isRefetching,
  } = useQuery(
    `query-design-elements-${companyId}-${productId}-${type}-${subType}`,
    () => itemsApi.query<T>(companyId, productId, type, subType),
    {
      cacheTime: 0,
    },
  );

  const { isLoading: isDeleting, mutate: deleteDesignElement } = useMutation(
    ({ designElementId }: DeleteDesignElement) =>
      designElementApi.deleteRiskById(companyId, productId, designElementId),
    {
      onSuccess: (data, { designElementId }) => {
        const element = (designElements as Array<T>).find((item) => item.id === designElementId) as T;
        showToast({
          title: 'Element deleted.',
          description: `You have deleted ${element.code}`,
          status: 'success',
          duration: 5000,
        });
        return refetch();
      },
      onError: (error: any, { designElementId }) => {
        const element = (designElements as Array<T>).find((item) => item.id === designElementId) as T;
        showToast({
          title: 'Unable to delete element.',
          description: `Unable to delete ${element.code}`,
          status: 'error',
          duration: 5000,
        });
      },
    },
  );

  return {
    isLoading: isLoading || isRefetching,
    designElements: designElements ?? [],
    isDeleting,
    deleteDesignElement,
  };
}
