import React, { useCallback, useEffect } from 'react';

import { QButton } from '@qualio/ui-components';
import { Space, Typography } from 'antd';
import { TableProps } from 'antd/lib/table';
import { Outlet, useNavigate, useParams } from 'react-router-dom';

import Header from '../../components/Header';
import { Messages, withMessages } from '../../components/Messages';
import QualityItemsTable from '../../components/QualityItemsTable/items';
import UserGroupToggle from '../../components/UserGroupToggle/UserGroupToggle';
import { UserPermissions } from '../../components/UserGroupToggle/UserGroupToggle.enum';
import { DrawerProvider } from '../../components_new/DesginElementDetailsDrawer/context/DrawerProvider';
import { Loading } from '../../components_new/Loading';
import { useAnalytics } from '../../context/analytics';
import { useCompanyId } from '../../context/CurrentUserContext';
import { scrollWindowToElementTop } from '../../lib/formValidationUtilities';
import { ProductParam, SelectedItemParam } from '../../paramTypes';
import { DashboardItem } from '../../types/changeControl';
import { useDashboardItems } from './useDashboardItems';

const Dashboard: React.FC = () => {
  const company = useCompanyId();
  const { product, selectedItemId } = useParams() as ProductParam & Partial<SelectedItemParam>;

  const analytics = useAnalytics();
  const navigate = useNavigate();

  const dashboardItems = useDashboardItems(company, product);

  useEffect(() => {
    analytics.track('PD Dashboard View', {
      company,
      product,
    });
  }, [analytics, company, product]);

  useEffect(() => {
    if (!selectedItemId) {
      return;
    }
    scrollWindowToElementTop('styled-table');
  }, [selectedItemId]);

  const handleSortChanged: TableProps<DashboardItem>['onChange'] = useCallback(
    (pagination, filters, sorter, extra) => {
      if (extra.action === 'sort') {
        analytics.track('PD Dashboard Sort', {
          company,
          product,
          field: sorter.field,
          direction: sorter.direction,
        });
      }
    },
    [analytics, company, product],
  );

  const createChangeControl = useCallback(() => {
    navigate(`/product/${product}/create-review`);
  }, [navigate, product]);

  const createReviewButton = () => {
    return (
      <QButton leftIcon={'PlusCircle'} data-cy="create-review-button" onClick={createChangeControl}>
        Create review
      </QButton>
    );
  };

  return (
    <>
      <Messages />
      <DrawerProvider url={`/product/${product}/dashboard`}>
        {dashboardItems.isLoading ? (
          <Loading />
        ) : (
          <Space direction={'vertical'} size={'large'} style={{ width: '100%' }}>
            <section>
              <Header
                actions={
                  <UserGroupToggle userPermission={UserPermissions.CREATE_CHANGE_CONTROL}>
                    {createReviewButton()}
                  </UserGroupToggle>
                }
              >
                <Typography.Title level={4}>Changes awaiting review</Typography.Title>
              </Header>

              <QualityItemsTable
                items={dashboardItems.unstagedItems}
                selectedItemId={selectedItemId}
                onChange={handleSortChanged}
                data-cy="dashboard-table-unstaged"
              />
            </section>

            <section>
              <Header>
                <Typography.Title level={4}>Changes awaiting approval</Typography.Title>
              </Header>

              <QualityItemsTable
                items={dashboardItems.stagedItems}
                selectedItemId={selectedItemId}
                onChange={handleSortChanged}
                data-cy="dashboard-table-staged"
              />
            </section>
          </Space>
        )}
        <Outlet />
      </DrawerProvider>
    </>
  );
};

export default withMessages(Dashboard);
