import React, { useMemo } from 'react';

import { sortByAlphanumericInsensitive } from '../../lib/sortFunctions';
import { ChangeControlApprover } from '../../types/changeControl';
import { ApproverDisplayElement } from './ApproverDisplayElement';

type ApproverDisplayElementProps = {
  approvers: Array<ChangeControlApprover>;
};

export const ApproverDisplayElementList: React.FC<ApproverDisplayElementProps> = ({ approvers }) => {
  const sortedApprovers = useMemo(
    () => [...approvers].sort((a, b) => sortByAlphanumericInsensitive(a.fullName, b.fullName)),
    [approvers],
  );

  return (
    <>
      {sortedApprovers.map((approver) => (
        <ApproverDisplayElement key={approver.id} approver={approver} />
      ))}
    </>
  );
};
