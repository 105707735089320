import React, { useCallback } from 'react';

import { QBox, QSelect, QSelectItem, QSelectPlaceholder, QText } from '@qualio/ui-components';

type FMEARiskScoreSelectProps = {
  name: string;
  value: string;
  placeholder: string;
  options: Array<number>;
  onChange: (e: number) => void;
};
export const FMEARiskScoreSelect: React.FC<FMEARiskScoreSelectProps> = ({
  name,
  value,
  options,
  placeholder,
  onChange,
}) => {
  const handleChange = useCallback(
    (selected: QSelectItem | null) => {
      if (selected) {
        onChange(Number(selected.value));
      }
    },
    [onChange],
  );

  return (
    <QBox width="250px" data-cy={`${name}`}>
      <QSelect
        options={options.map((option) => ({
          label: option.toString(),
          value: option.toString(),
        }))}
        value={value}
        isDisabled={false}
        isLoading={false}
        isSearchable={false}
        onChange={handleChange}
        clearInputOnSelect={false}
      >
        {placeholder && (
          <QSelectPlaceholder>
            <QText>{placeholder}</QText>
          </QSelectPlaceholder>
        )}
      </QSelect>
    </QBox>
  );
};
