import { FeatureFlags } from './FeatureToggle.enum';

const getFeatureCookie = (feature: string): string => {
  const cookie = document.cookie
    .split('; ')
    .map((item) => item.split('='))
    .reduce((item: Record<string, string>, [key, value]) => {
      item[key] = value;
      return item;
    }, {});

  return cookie[`feature${feature}`];
};

export const hasFeatureCookie = (feature: FeatureFlags): boolean => {
  const cookieValue = getFeatureCookie(feature);
  return cookieValue === 'true';
};
