import React from 'react';

import { getChildrenOfType } from '@qualio/ui-components';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { FeatureFlags } from '../../components/FeatureToggle/FeatureToggle.enum';

type FeatureFlagProps = {
  feature: FeatureFlags;
};

export const EnabledFeatureFlag: React.FC = ({ children }) => <>{children}</>;
EnabledFeatureFlag.displayName = 'EnabledFeatureFlag';

export const DisabledFeatureFlag: React.FC = ({ children }) => <>{children}</>;
DisabledFeatureFlag.displayName = 'DisabledFeatureFlag';

export const FeatureToggle: React.FC<FeatureFlagProps> = ({ children, feature }) => {
  const features = useFlags();

  return <>{getChildrenOfType(children, features[feature] ? 'EnabledFeatureFlag' : 'DisabledFeatureFlag')}</>;
};
