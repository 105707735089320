import MockAdapter from 'axios-mock-adapter';

import api from '../api';
import { BaseQualityType, DesignElementType } from '../types/baseQualityItem';
import { ControlledDocument } from '../types/controlledDocument';
import { User } from '../types/currentUser';
import { Payload } from '../types/payload';
import { BaseQualityTypeConfig, QualityTypeConfigWithType, RiskQualityTypeConfig } from '../types/qualityTypeConfig';
import { ReleaseDocuments } from '../types/release';
import { Requirement } from '../types/requirement';
import { FMEARisk, Risk } from '../types/risk';
import { SourceConfigResponse } from '../types/sourceConfig';
import { TestCase } from '../types/testCase';
import { RequirementTrace, RiskTrace, TestCaseTrace } from '../types/trace';
import {
  changeControlAfterApprovalResponse,
  changeControlResponse,
  changeControlWithReleaseInProgressResponse,
  createReviewResponse,
  dashboardResponse,
  dashboardResponseWithStagedAndUnstaged,
  fetchApprovedChangeControlResponse,
  fetchApprovedChangeControlWithoutReleaseResponse,
  fetchChangeControlInProgressResponse,
  fetchChangeControlInProgressWithOwner1Response,
  fetchChangeControlInProgressWithOwner2Response,
} from './fixtures/changeControl';
import { changeHistoryResponse } from './fixtures/changeHistory';
import {
  fmeaConfigResponse,
  getAllConfigsWithFMEAResponse,
  qualityTypeConfigs,
  testCase1Config,
  testCase2Config,
} from './fixtures/config';
import { makeEmptyResponse, makeResponse } from './fixtures/generic';
import { allOpenIssuesResponse, openIssuesByTypeResponse } from './fixtures/openIssues';
import { productsResponse } from './fixtures/products';
import {
  documentQualityItems,
  req1QualityItems,
  req2QualityItems,
  req3QualityItems,
  testCase1QualityItems,
  testCase2QualityItems,
  testCaseQualityItems,
} from './fixtures/qualityItems';
import { qualityItemsById } from './fixtures/qualityItemsById';
import { releaseDocumentResponse, releaseInProgressDocumentResponse } from './fixtures/releaseDocument';
import { requirementTraceResponse } from './fixtures/requirementTrace';
import { FMEARisksResponse, ISORisksResponse } from './fixtures/risks';
import { makeRiskTrace } from './fixtures/riskTrace';
import { searchDocumentsResponse } from './fixtures/suggestions';
import { makeTestTrace } from './fixtures/testcaseTrace';
import { usersResponse } from './fixtures/users';

const mock = new MockAdapter(api, { delayResponse: 1000, onNoMatch: 'throwException' });

mock.onGet('/company/1/product-development/users').reply(200, usersResponse);
mock.onGet(/\/company\/1\/product-development\/smartlink$/).reply(200, searchDocumentsResponse);
mock.onGet('/company/1/product-development/product').reply(200, productsResponse);
mock.onPost('/company/1/product-development/product').reply(200, makeEmptyResponse());

mock
  .onGet('/company/1/product-development/product/test-1/requirement')
  .reply<Payload<RequirementTrace[]>>(200, requirementTraceResponse);

mock
  .onGet(/\/company\/1\/product-development\/product\/test-1\/qualityItem$/, {
    params: {
      type: 'risk',
    },
  })
  .reply<Payload<Risk[]>>(200, ISORisksResponse);

mock
  .onGet(/\/company\/1\/product-development\/product\/test-1\/qualityItem$/, {
    params: {
      type: 'risk',
      subType: 'iso',
    },
  })
  .reply<Payload<Risk[]>>(200, ISORisksResponse);

mock
  .onGet(/\/company\/1\/product-development\/product\/test-1\/qualityItem$/, {
    params: {
      type: 'risk',
      subType: 'fmea',
    },
  })
  .reply<Payload<FMEARisk[]>>(200, FMEARisksResponse);

mock
  .onGet(/\/company\/1\/product-development\/product\/test-1\/qualityItem$/, {
    params: {
      type: 'req1',
    },
  })
  .reply<Payload<Requirement[]>>(200, makeResponse(req1QualityItems));

mock
  .onGet(/\/company\/1\/product-development\/product\/test-1\/qualityItem$/, {
    params: {
      type: 'req2',
    },
  })
  .reply<Payload<Requirement[]>>(200, makeResponse(req2QualityItems));

mock
  .onGet(/\/company\/1\/product-development\/product\/test-1\/qualityItem$/, {
    params: {
      type: 'req3',
    },
  })
  .reply<Payload<Requirement[]>>(200, makeResponse(req3QualityItems));

mock
  .onGet(/\/company\/1\/product-development\/product\/test-1\/qualityItem$/, {
    params: {
      type: 'document',
    },
  })
  .reply<Payload<ControlledDocument[]>>(200, makeResponse(documentQualityItems));

mock
  .onGet(/\/company\/1\/product-development\/product\/test-1\/qualityItem$/, {
    params: {
      type: 'testCase',
    },
  })
  .reply<Payload<TestCase[]>>(200, makeResponse(testCaseQualityItems));

mock
  .onGet(`/company/1/product-development/product/test-1/qualityItem/${testCase1QualityItems[0].id}`)
  .reply<Payload<TestCaseTrace>>(200, makeResponse(makeTestTrace(testCase1QualityItems[0])));

mock
  .onGet(`/company/1/product-development/product/test-1/qualityItem/${testCase1QualityItems[0].id}/version/v0`)
  .reply<Payload<TestCaseTrace>>(200, makeResponse(makeTestTrace(testCase1QualityItems[0])));

mock.onGet(/\/company\/1\/product-development\/product\/test-1\/qualityItem\/[^/]+?$/).reply((config) => {
  const [, id] = config.url?.match(/qualityItem\/(.+?)$/) || [];
  return [200, makeResponse(qualityItemsById[id])];
});

mock
  .onGet(/\/company\/1\/product-development\/product\/test-1\/qualityItem\/.+?\/history$/)
  .reply(200, changeHistoryResponse);

mock
  .onGet('/company/1/product-development/product/test-1/release/ab8eb442-23bc-41bc-bfd6-bcbe0e885f0a/document')
  .reply<Payload<ReleaseDocuments>>(200, releaseDocumentResponse);

mock
  .onGet('/company/1/product-development/product/test-1/release/release-in-progress/document')
  .reply<Payload<ReleaseDocuments>>(200, releaseInProgressDocumentResponse);

mock
  .onGet('/company/1/product-development/product/test-1/sourceConfig')
  .reply<Payload<SourceConfigResponse[]>>(200, { data: [] });

mock
  .onGet('/company/1/product-development/product/test-2/sourceConfig')
  .reply<Payload<SourceConfigResponse[]>>(200, { data: [{ type: 'req2', source: 'jira' }] });

mock
  .onGet('/company/1/product-development/product/test-1/config')
  .reply<Payload<QualityTypeConfigWithType[]>>(200, getAllConfigsWithFMEAResponse);

mock
  .onGet('/company/1/product-development/product/test-1/archivePrefixes')
  .reply<Payload<Array<string>>>(200, makeResponse<Array<string>>([]));

mock
  .onGet('/company/1/product-development/product/test-1/config/risk')
  .reply<Payload<RiskQualityTypeConfig>>(200, fmeaConfigResponse);

mock
  .onGet('/company/1/product-development/product/test-1/config/risk')
  .reply<Payload<RiskQualityTypeConfig>>(200, fmeaConfigResponse);

mock
  .onGet('/company/1/product-development/product/test-1/config/req1')
  .reply<Payload<BaseQualityTypeConfig>>(200, makeResponse(qualityTypeConfigs[0]));

mock
  .onGet('/company/1/product-development/product/test-1/config/req2')
  .reply<Payload<BaseQualityTypeConfig>>(200, makeResponse(qualityTypeConfigs[1]));

mock
  .onGet('/company/1/product-development/product/test-1/config/testCase1')
  .reply<Payload<BaseQualityTypeConfig>>(200, makeResponse(testCase1Config));

mock
  .onGet('/company/1/product-development/product/test-1/config/testCase2')
  .reply<Payload<BaseQualityTypeConfig>>(200, makeResponse(testCase2Config));

mock.onDelete(/\/company\/1\/product-development\/product\/test-1\/config\/.+?/).reply(204);

mock.onPost('/company/1/product-development/product/test-1/config').reply((config) => {
  return [201, config.data];
});

mock.onPost('/company/1/product-development/product').reply((product) => {
  return [201, makeResponse({ ...JSON.parse(product.data).data, id: 'test-1' })];
});

mock.onPost(/\/company\/1\/product-development\/product\/test-1\/config\/risk$/).reply((config) => {
  return [200, makeResponse({ ...JSON.parse(config.data).data, productId: 'test-1' })];
});

mock.onPut(/\/company\/1\/product-development\/product\/test-1\/config\/risk$/).reply((config) => {
  return [200, config.data];
});

mock
  .onGet('/company/1/product-development/product/test-1/qualityItem/63cbaf7e-440a-4c7f-ac16-8069d8d0ca30')
  .reply<Payload<RiskTrace>>(200, makeResponse(makeRiskTrace(ISORisksResponse.data[0])));

mock
  .onGet('/company/1/product-development/product/test-1/qualityItem/63cbaf7e-440a-4c7f-ac16-8069d8d0ca30')
  .reply<Payload<RiskTrace>>(200, makeResponse(makeRiskTrace(ISORisksResponse.data[0])));

mock
  .onGet('/company/1/product-development/product/test-1/qualityItem/64a97675-d7fd-435e-bd6a-4bef12711000')
  .reply<Payload<TestCaseTrace>>(
    200,
    makeResponse({ ...testCase2QualityItems[0], parent: [requirementTraceResponse.data[0]], version: 'v2' }),
  );

mock
  .onGet('/company/1/product-development/product/test-1/change-control/quality-item', {
    params: {
      'status.gte': 'staged',
    },
  })
  .reply(200, dashboardResponseWithStagedAndUnstaged);
mock.onGet('/company/1/product-development/product/test-1/change-control/quality-item').reply(200, dashboardResponse);

mock
  .onGet(`/company/1/product-development/product/test-1/change-control/${createReviewResponse.data.id}`)
  .reply(200, createReviewResponse);

mock.onPost('/company/1/product-development/product/test-1/change-control').reply(200, createReviewResponse);
mock.onPut(/\/company\/1\/product-development\/product\/test-1\/change-control\/.+/).reply(200, makeEmptyResponse());

mock.onGet('/company/1/product-development/product/test-1/change-control').reply(200, changeControlResponse);
mock
  .onPost(/\/company\/1\/product-development\/product\/test-1\/change-control\/.+\/approve/)
  .reply(204, makeEmptyResponse());

mock
  .onGet(`/company/1/product-development/product/test-1/change-control/${changeControlAfterApprovalResponse.data.id}`)
  .reply(200, changeControlAfterApprovalResponse);

mock
  .onGet(
    `/company/1/product-development/product/test-1/change-control/${fetchApprovedChangeControlWithoutReleaseResponse.data.id}`,
  )
  .reply(200, fetchApprovedChangeControlWithoutReleaseResponse);

mock
  .onGet(
    `/company/1/product-development/product/test-1/change-control/${fetchChangeControlInProgressWithOwner1Response.data.id}`,
  )
  .reply(200, fetchChangeControlInProgressWithOwner1Response);

mock
  .onGet(
    `/company/1/product-development/product/test-1/change-control/${fetchChangeControlInProgressWithOwner2Response.data.id}`,
  )
  .reply(200, fetchChangeControlInProgressWithOwner2Response);

mock
  .onGet(`/company/1/product-development/product/test-1/change-control/${fetchApprovedChangeControlResponse.data.id}`)
  .reply(200, fetchApprovedChangeControlResponse);

mock
  .onGet(`/company/1/product-development/product/test-1/change-control/${fetchChangeControlInProgressResponse.data.id}`)
  .reply(200, fetchChangeControlInProgressResponse);

mock
  .onGet(
    `/company/1/product-development/product/test-1/change-control/${changeControlWithReleaseInProgressResponse.data.id}`,
  )
  .reply(200, changeControlWithReleaseInProgressResponse);

const openIssuesUrl = /\/company\/1\/product-development\/product\/test-1\/openIssue(?:\?type=(.+))?$/;
mock.onGet(openIssuesUrl).reply((config) => {
  const response = (config.url || '').match(openIssuesUrl);
  if (!response) {
    return [200, allOpenIssuesResponse];
  }

  const [, type] = response;

  if (!type) {
    return [200, allOpenIssuesResponse];
  }

  return [200, openIssuesByTypeResponse[type as BaseQualityType]];
});

mock.onPost(/\/company\/1\/product-development\/product\/test-1\/qualityItem$/).reply(201, makeEmptyResponse());
mock.onPut(/\/company\/1\/product-development\/product\/test-1\/qualityItem\/.+/).reply(200, makeEmptyResponse());
mock.onPatch(/\/company\/1\/product-development\/product\/test-1\/qualityItem\/.+/).reply(200, makeEmptyResponse());

mock
  .onGet(/\/company\/1\/product-development\/product\/test-1\/qualityItem$/, {
    params: {
      type: DesignElementType.TEST_CASE_1,
    },
  })
  .reply<Payload<TestCaseTrace[]>>(
    200,
    makeResponse([...testCase1QualityItems.map((tc) => ({ ...tc, version: 'v2' }))]),
  );
mock
  .onGet(/\/company\/1\/product-development\/product\/test-1\/qualityItem$/, {
    params: {
      type: DesignElementType.TEST_CASE_2,
    },
  })
  .reply<Payload<TestCaseTrace[]>>(
    200,
    makeResponse([...testCase2QualityItems.map((tc) => ({ ...tc, version: 'v2' }))]),
  );
mock
  .onGet(/\/company\/1\/product-development\/product\/test-1\/qualityItem$/, {
    params: {
      type: DesignElementType.TEST_CASE_3,
    },
  })
  .reply<Payload<TestCaseTrace[]>>(200, makeResponse([]));

mock
  .onGet(/\/company\/1\/product-development\/users\/1$/)
  .reply<User>(200, { id: 1, companies: [{ id: 1, name: 'Darwin', usergroups: ['quality'] }] });
