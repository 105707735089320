import { useMemo } from 'react';

import { OpenIssue, PolicyIssues } from '../../types/openIssue';
import { QualityTypeConfigWithType } from '../../types/qualityTypeConfig';

export type IssueFilter = {
  policy: PolicyIssues;
  count?: number;
};

export type UseIssueFilters = IssueFilter[];

function issueCountPerPolicy(openIssues: OpenIssue[]): Record<PolicyIssues, number> {
  return openIssues.reduce(($, { policyIssues }) => {
    (Object.keys(policyIssues) as PolicyIssues[]).forEach((policy) => {
      if (policyIssues[policy]) {
        $[policy] = ($[policy] || 0) + 1;
      }
    });

    return $;
  }, {} as Record<PolicyIssues, number>);
}

export function useIssueFilters(openIssues: OpenIssue[], configForType?: QualityTypeConfigWithType): UseIssueFilters {
  const counts = useMemo(() => issueCountPerPolicy(openIssues), [openIssues]);

  return useMemo(() => {
    const policies = configForType?.policy ? configForType.policy : Object.values(PolicyIssues);

    return policies
      .map((policy) => {
        return {
          policy,
          count: counts[policy],
        };
      })
      .filter(({ count }) => count > 0);
  }, [counts, configForType]);
}
