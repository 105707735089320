import * as z from 'zod';

import { verifyLabelsUnique } from '../lib/formValidationUtilities';

export const ResourceIdentifier = z.object({
  label: z.string(),
  resourceIdentifier: z.string(),
});

export type ResourceIdentifier = z.infer<typeof ResourceIdentifier>;

export const BaseConfiguration = z.object({
  prefix: z
    .string()
    .min(1, { message: 'Min. 1 character' })
    .max(5, { message: 'Max. 5 characters' })
    .refine((data) => data.match(/^[A-Z]+$/), 'Uppercase only'),
  label: z.string().min(1).max(100),
  statuses: z
    .array(z.object({ label: z.string().min(1).max(30) }))
    .min(1)
    .superRefine((items, ctx) => verifyLabelsUnique(items, ctx, 'Status')),
});

export type BaseConfigurationType = z.infer<typeof BaseConfiguration>;
