import React from 'react';

import { QButton, QEmptyState, QEmptyStateButtonGroup, QEmptyStatePrimaryButton } from '@qualio/ui-components';

export type ProductEmptyStateProps = {
  canManageProduct: boolean;
  onClick: () => void;
};

export const ProductEmptyState: React.FC<ProductEmptyStateProps> = ({ canManageProduct, onClick }) => (
  <QEmptyState
    title="Your products will appear here"
    subtitle="Design Controls is the all-in-one tool to manage quality compliance in product design."
  >
    <QEmptyStateButtonGroup>
      <QEmptyStatePrimaryButton>
        {canManageProduct && (
          <QButton onClick={onClick} data-cy="create-product">
            Create Product
          </QButton>
        )}
      </QEmptyStatePrimaryButton>
    </QEmptyStateButtonGroup>
  </QEmptyState>
);
