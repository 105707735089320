import { QualityItemStatus } from '../../../types/baseQualityItem';
import { ChangeControlStatus } from '../../../types/changeControl';
import { FMEARisk, ISORiskType } from '../../../types/risk';
import { convertBaseChangeControlToChangeControlStatus } from '../../Tag/DesignControlTag';

export const getDesignControlStatus = (
  risk: ISORiskType | FMEARisk,
): ChangeControlStatus | QualityItemStatus | undefined => {
  return risk.changeControlStatus !== 'unstaged'
    ? convertBaseChangeControlToChangeControlStatus(risk.changeControlStatus)
    : risk.itemStatus;
};
