import * as z from 'zod';

export const Attachment = z.object({
  filename: z.string(),
  filesize: z.number(),
  contentType: z.string(),
});

export const AttachmentWithId = Attachment.merge(z.object({ id: z.string().uuid() }));
export type AttachmentWithIdType = z.infer<typeof AttachmentWithId>;

export const PresignedUrlResponse = z.object({
  attachment: AttachmentWithId,
  presignedUrl: z.string(),
});

export type PresignedUrlResponseType = z.infer<typeof PresignedUrlResponse>;

export const PresignedUrlRequest = Attachment.merge(
  z.object({
    md5Sum: z.string(),
  }),
);

export type PresignedUrlRequestType = z.infer<typeof PresignedUrlRequest>;
