import { useCurrentUser } from '@qualio/ui-components';
import { useQuery } from 'react-query';

import { designControlsApi } from '../api/designControls';
import { useCurrentProduct } from './useCurrentProduct';

export const useRetrieveDesignElementById = (designElementId: string) => {
  const { companyId } = useCurrentUser();
  const { id: productId } = useCurrentProduct();

  return useQuery({
    queryKey: ['DESIGN_ELEMENT_BY_ID', companyId, productId, designElementId, 'v0'],
    queryFn: () => designControlsApi.getById(companyId, productId, designElementId, 'v0'),
  });
};
