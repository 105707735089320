import { User } from '../types/currentUser';
import { Payload } from '../types/payload';
import { QualioUser } from '../types/qualioUser';
import { QualioSmartLink, Smartlink } from '../types/smartlink';
import api from './index';

const qualioApi = {
  fetchUsersForCompany(company: number): Promise<QualioUser[]> {
    return api
      .get(`/company/${company}/product-development/users`)
      .then((res) => res.data as Payload<QualioUser[]>)
      .then((res) => res.data.map((item) => ({ ...item, id: item.id.toString() })));
  },
  searchDocuments(company: number, search: string): Promise<Smartlink[]> {
    return api
      .get(`/company/${company}/product-development/smartlink`, {
        params: { search },
      })
      .then((res) => res.data as QualioSmartLink[])
      .then((res) =>
        res
          .filter((item) => item.status_id === 'effective')
          .map((item) => ({
            id: item.id.toString(),
            title: item.title,
            uuid: item.uuid,
            code: item.code,
            status_id: item.status_id,
          })),
      );
  },
  getUserById(companyId: number, userId: number): Promise<User> {
    return api.get(`/company/${companyId}/product-development/users/${userId}`).then((response) => response.data);
  },
};

export default qualioApi;
