import styled from 'styled-components';

export const SearchIconContainer = styled.div`
  display: flex;
  align-items: center;

  svg {
    height: 20px;
    width: 20px;
    margin-right: 5px;
  }
`;
