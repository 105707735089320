import React, { useEffect } from 'react';

import { QButton, QFormControl, QIconButton, QInput, QStack, QText } from '@qualio/ui-components';
import { Controller, DeepMap, FieldError, useFieldArray, useFormContext } from 'react-hook-form';

import { deepGet } from '../../deepGet';
import { triggerFieldValuesValidation } from '../../lib/formValidationUtilities';

type ConfigStatusesProps = {
  name: string;
};

const ConfigStatuses: React.FC<ConfigStatusesProps> = ({ name }) => {
  const {
    control,
    formState: { errors },
    setError,
    clearErrors,
    trigger,
  } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name,
  });

  const hasStatusInputError = (
    formErrors: DeepMap<Record<string, any>, FieldError>,
    objectPath: string,
    index: number,
  ): boolean => {
    return getStatusInputError(formErrors, objectPath, index) !== undefined;
  };

  const getStatusInputError = (
    formErrors: DeepMap<Record<string, any>, FieldError>,
    objectPath: string,
    index: number,
  ): any => {
    const statusesErrors = deepGet(formErrors, objectPath);
    return statusesErrors?.[index] && statusesErrors?.[index].label;
  };

  useEffect(() => {
    if (fields.length === 0) {
      setError(name, {
        type: 'min',
        message: 'You need at least 1 status',
      });
    } else {
      clearErrors(name);
    }
    return () => {
      clearErrors(name);
    };
  }, [name, setError, fields.length, clearErrors]);

  return (
    <>
      {fields.length ? (
        <QStack data-cy={`${name}-statuses-table`} direction={'column'}>
          {fields.map((option, index) => {
            return (
              <QStack direction={'row'} key={option.id}>
                <QFormControl
                  label={''}
                  isInvalid={hasStatusInputError(errors, name, index)}
                  error={getStatusInputError(errors, name, index)?.message}
                >
                  <Controller
                    name={`${name}[${index}].label`}
                    defaultValue={(option as any).label}
                    render={({ field: { onChange, value } }) => (
                      <QInput
                        data-cy={`${name}-status`}
                        onChange={onChange}
                        value={value}
                        onBlur={() => triggerFieldValuesValidation(fields.length, name, trigger as any)}
                      />
                    )}
                  />
                </QFormControl>
                <QIconButton
                  aria-label="Delete row"
                  iconName={'Trash'}
                  data-cy={`${name}-remove-status`}
                  onClick={() => remove(index)}
                />
              </QStack>
            );
          })}
        </QStack>
      ) : null}
      {fields.length === 0 && (
        <QText fontSize="xs" color="red.900">
          Add at least 1 status
        </QText>
      )}
      <QButton variant="link" rightIcon="Plus" data-cy="add-config-status-button" onClick={() => append({ label: '' })}>
        Add
      </QButton>
    </>
  );
};

export default ConfigStatuses;
