import React, { useMemo } from 'react';

import { QBox, QFlex, QTag, QText } from '@qualio/ui-components';

import { calculateFmeaRisk } from '../../../components/RiskForm/formHelpers';
import { FMEARisk } from '../../../types/risk';
import { fmeaRiskScoreColor } from '../../../views_new/Review/List/statusLabelAndColorTag';

export const FmeaRiskInitialAssessment: React.FC<{ risk: FMEARisk; fmeaThreshold: number | undefined }> = ({
  risk,
  fmeaThreshold,
}) => {
  const riskScore = useMemo(() => calculateFmeaRisk(risk.preMitigation), [risk.preMitigation]);

  return (
    <QFlex data-cy={'fmea-risk-initial-assessment'} marginBottom={5} flexDirection={'column'}>
      <QText fontSize={16} weight="semibold">
        Initial Assessment
      </QText>
      <QFlex marginTop={2} marginBottom={5} flexDirection={'column'}>
        <QText color="gray.700" fontSize={14} weight="semibold">
          Failure Mode
        </QText>
        <QText data-cy={'fmea-risk-failure-mode'} fontSize={14}>
          {risk.failureMode}
        </QText>
      </QFlex>
      <QFlex marginTop={5} marginBottom={5} flexDirection={'column'}>
        <QText color="gray.700" fontSize={14} weight="semibold">
          Effects of Failure Mode
        </QText>
        <QText data-cy={'fmea-risk-failure-effects'} fontSize={14}>
          {risk.failureModeEffects}
        </QText>
      </QFlex>
      <QFlex marginTop={5} marginBottom={5} flexDirection={'column'}>
        <QText color="gray.700" fontSize={14} weight="semibold">
          Causes of Failure
        </QText>
        <QText data-cy={'fmea-risk-failure-causes'} fontSize={14}>
          {risk.failureCauses}
        </QText>
      </QFlex>
      <QFlex marginTop={5} marginBottom={5} flexDirection={'column'}>
        <QText color="gray.700" fontSize={14} weight="semibold">
          Current Controls / Evaluation Method
        </QText>
        <QText data-cy={'fmea-risk-evaluation-method'} fontSize={14}>
          {risk.evaluationMethod}
        </QText>
      </QFlex>
      <QFlex marginTop={5} flexDirection={'column'}>
        <QBox mb="5px">
          <QText color="gray.700" fontSize={14} weight="semibold">
            Risk Score
          </QText>
        </QBox>
        <QText color="gray.500" fontSize={14} weight="semibold">
          <QTag data-cy={'fmea-risk-calculated-risk-level'} variantColor={fmeaRiskScoreColor(riskScore, fmeaThreshold)}>
            {riskScore}
          </QTag>
        </QText>
      </QFlex>
    </QFlex>
  );
};
