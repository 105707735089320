export enum SegmentedControlMenuItemDataCy {
  SEGMENTED_CONTROL_DISABLED_ITEM_SELECTED = 'segmented-control-disabled-item-selected',
  SEGMENTED_CONTROL_DISABLED_ITEM = 'segmented-control-disabled-item',
  SEGMENTED_CONTROL_ITEM_SELECTED = 'segmented-control-item-selected',
  SEGMENTED_CONTROL_ITEM = 'segmented-control-item',
}

export enum SegmentedControlItemTextDataCy {
  SEGMENTED_CONTROL_DISABLED_ITEM_TEXT = 'segmented-control-disabled-item-text',
  SEGMENTED_CONTROL_ITEM_TEXT = 'segmented-control-item-text',
}
