import React, { useCallback } from 'react';

import { QText } from '@qualio/ui-components';
import { Controller, useFormContext } from 'react-hook-form';

import { RadioGroup } from '../../components_new/RadioGroup/RadioGroup';
import {
  INITIAL_RISK_CONFIGURATION_VALUES,
  INITIAL_RISK_LEVEL_MAPPING,
  INITIAL_RISK_PROBABILITY,
  INITIAL_RISK_SEVERITY,
  RISK_MATRIX_DIMENSION,
  RiskMatrixSize,
} from '../../lib/riskConfiguration';
import MatrixLabels from './MatrixLabels/MatrixLabels';
import { StyledMatrix } from './RiskMatrix.styles';

type RiskMatrixProps = {
  probabilityFieldName: string;
  severityFieldName: string;
  levelMappingFieldName: string;
};

const RiskMatrix: React.FC<RiskMatrixProps> = ({
  probabilityFieldName,
  severityFieldName,
  levelMappingFieldName,
  children,
}) => {
  const { setValue, watch } = useFormContext();
  const matrixDimension = watch('matrixDimension', INITIAL_RISK_CONFIGURATION_VALUES.matrixDimension);

  const dimensionOnChange = useCallback(
    (dimension: RiskMatrixSize) => {
      setValue(probabilityFieldName, INITIAL_RISK_PROBABILITY[dimension]);
      setValue(severityFieldName, INITIAL_RISK_SEVERITY[dimension]);
      setValue(levelMappingFieldName, INITIAL_RISK_LEVEL_MAPPING[dimension]);
    },
    [levelMappingFieldName, probabilityFieldName, setValue, severityFieldName],
  );

  return (
    <>
      <QText fontSize="lg" fontWeight={400}>
        Risk Matrix
      </QText>
      <Controller
        name="matrixDimension"
        render={({ field: { onChange, value, name } }) => (
          <RadioGroup
            defaultValue={value}
            name={name}
            onChange={(e: string) => {
              onChange(e);
              dimensionOnChange(e as RiskMatrixSize);
            }}
            options={RISK_MATRIX_DIMENSION.map((item) => ({ label: item, value: item }))}
            data-cy="risk-matrix-radio-button"
          />
        )}
      />

      <StyledMatrix>
        <div className={`matrix-${matrixDimension}`} style={{ width: '100%' }}>
          <div className="severity">Severity</div>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
            <div className="probabilityWidth" />
            <div className="item" />
            <MatrixLabels name={severityFieldName} />
          </div>
          <div style={{ display: 'flex' }}>
            <div className="probability probabilityWidth">Probability</div>
            <div className="item-column">
              <MatrixLabels name={probabilityFieldName} />
            </div>
            <div data-cy="matrix" className="items">
              {children}
            </div>
          </div>
        </div>
      </StyledMatrix>
    </>
  );
};

export default RiskMatrix;
