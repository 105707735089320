import React from 'react';

import {
  EditTestCaseConfigurationContainerProps,
  TestCaseConfigurationCreateEditMode,
} from '../TestCaseConfigurations.types';
import TestCaseConfigurationsContainer from '../TestCaseConfigurationsContainer';

const EditTestCaseConfigurationsContainer: React.FC<EditTestCaseConfigurationContainerProps> = ({
  validationErrors,
  requirementAndTestCaseTuples,
  onModalDeleteTestCaseCard,
  savedConfigTypes,
}) => {
  return (
    <TestCaseConfigurationsContainer
      validationErrors={validationErrors}
      requirementAndTestCaseTuples={requirementAndTestCaseTuples}
      onModalDeleteTestCaseCard={onModalDeleteTestCaseCard}
      mode={TestCaseConfigurationCreateEditMode.EDIT_MODE}
      savedConfigTypes={savedConfigTypes}
    />
  );
};

export default EditTestCaseConfigurationsContainer;
