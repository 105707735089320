import styled from 'styled-components';

export const BasicConfigurationFooterSection = styled.div<{ isDisabled?: boolean }>`
  background-color: ${(props) => props.theme.colors.secondary['background']};
  padding: ${(props) => `${props.theme.space['4']} ${props.theme.space['6']}`};
  border-top: 1px solid ${(props) => props.theme.colors.gray['20']};

  p {
    margin-bottom: 0 !important;
    color: ${(props) => props.theme.colors.primary[props.isDisabled ? 40 : 100]};
  }
`;

export const BasicConfigurationBody = styled.div`
  padding: ${(props) => props.theme.space['5']};
`;
