import { useQuery } from 'react-query';

import qualioApi from '../../api/qualio';
import { QualioUser } from '../../types/qualioUser';

export type UseQualityUser = {
  isLoading: boolean;
  isError: boolean;
  qualityUsers: QualioUser[];
};

export function useQualityUsers(company: number): UseQualityUser {
  const { data, isLoading, isError } = useQuery(
    `retrieve-quality-users-company-${company}`,
    () => qualioApi.fetchUsersForCompany(company),
    {
      cacheTime: 0,
    },
  );

  return {
    isLoading,
    isError,
    qualityUsers: data ?? [],
  };
}
