import { QToastProps } from '@qualio/ui-components';

export class AttachmentEventsHelper {
  static handleWarning = (
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    warning: any,
    showToast?: (options: QToastProps) => void,
  ): void => {
    if (warning.body === 'Max files') {
      showToast &&
        showToast({
          title: `Too many files`,
          description: 'The total number of files selected exceeds the maximum allowed',
          status: 'error',
          duration: 5000,
        });
    }
  };
}
