import React from 'react';

import Icon from '@ant-design/icons';

const Svg = () => (
  <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="20" height="20" />
    <path
      d="M10.473 15.7686L18.8692 4.99062C19.1812 4.58871 18.8999 4 18.3962 4L1.60375 4C1.10008 4 0.818839 4.58871 1.13076 4.99062L9.52701 15.7686C9.76734 16.0771 10.2327 16.0771 10.473 15.7686Z"
      fill="#0869EE"
    />
  </svg>
);

const DefaultExpandedIcon: React.FC<any> = (props) => <Icon component={Svg} {...props} />;

export default DefaultExpandedIcon;
