import { ChangeControlApprover, ChangeControlStatus } from '../../../types/changeControl';

export const isChangeControlStatusInProgress = (status?: ChangeControlStatus): boolean =>
  ChangeControlStatus.IN_PROGRESS === status;

export const isChangeControlStatusApproved = (status?: ChangeControlStatus): boolean =>
  ChangeControlStatus.APPROVED === status;

export const isUserApproverAndNotApprovedYet = (
  userId: string,
  changeControlApprovers: Array<ChangeControlApprover>,
): boolean =>
  changeControlApprovers.some((approver) => approver.id === userId && isChangeControlStatusInProgress(approver.status));
