import React from 'react';

import { createQColumnHelper, DataProvider, QDataTable, QStack, QText } from '@qualio/ui-components';

import { ChangeControlStatus } from '../../../types/changeControl';
import { ChangeHistory, ChangeHistoryItem } from '../../../types/changeHistory';

const columnHelper = createQColumnHelper<ChangeHistoryItem>();

type Props = {
  changeHistory: ChangeHistory;
};

const ReviewStatusTable: Record<ChangeControlStatus, string> = {
  [ChangeControlStatus.IN_PROGRESS]: 'In progress',
  [ChangeControlStatus.APPROVED]: 'Approved',
  [ChangeControlStatus.NOT_APPROVED]: 'Declined',
};

export const ReviewTable: React.FC<Props> = ({ changeHistory }) => {
  if (!changeHistory.length) {
    return null;
  }

  const columns = [
    columnHelper.code('code', { header: 'Code', maxWidth: '100px' }),
    columnHelper.text('title', { header: 'Title' }),
    columnHelper.date((record) => new Date(record.date), { header: 'Update', maxWidth: '120px' }),
    columnHelper.status((record) => (record.status ? ReviewStatusTable[record.status] : ''), {
      header: 'Status',
      statuses: {
        'In progress': 'gray',
        Approved: 'green',
        Declined: 'red',
      },
      maxWidth: '120px',
    }),
  ];

  return (
    <QStack borderRadius="2px" borderWidth="1px" borderColor="gray.200" padding="16px">
      <QText fontWeight="semibold">Design review</QText>
      <DataProvider.Fixed data={changeHistory}>
        <QDataTable columns={columns} withHeaders={false} />
      </DataProvider.Fixed>
    </QStack>
  );
};
