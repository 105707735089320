import React from 'react';

import { UserOutlined } from '@ant-design/icons';
import { Col, Row, Typography } from 'antd';

import FileIcon from '../../../../components/CustomIcons/File';
import Panel from '../../../../components/Panel';
import expandedRowStyles from '../../../../components/StyledTable/ExpandedRowCommonStyles.module.less';
import { resolveFileType } from '../../../../lib/resolveExtensionToType';
import { ControlledDocument, User } from '../../../../types/controlledDocument';
import styles from './ExpandedDocument.module.less';

type ExpandedDocumentProps = {
  qualityDocument: ControlledDocument;
};

const ExpandedDocument: React.FC<ExpandedDocumentProps> = ({ qualityDocument }) => {
  const { reviewer, approver, url } = qualityDocument;

  return (
    <section className={expandedRowStyles.expandedContentContainer}>
      <Panel>
        <header className={styles.header}>
          <Typography.Title level={5} style={{ marginBottom: 10 }}>
            Documents
          </Typography.Title>
          <FileIcon fileType={resolveFileType(url)} />{' '}
          <a href={qualityDocument.url} target={'_blank'} rel={'noopener noreferrer'}>
            {qualityDocument.title}
          </a>
        </header>

        <section className={styles.body}>
          <Row>
            {reviewer && (
              <Col span={8}>
                <Typography.Title level={5} style={{ marginBottom: 10 }}>
                  Reviewers
                </Typography.Title>
                <ul className={styles.actionList}>
                  {reviewer.map((user: User) => (
                    <li className={styles.user} key={user.email}>
                      <UserOutlined />
                      {user.name ? user.name : user.email}
                    </li>
                  ))}
                </ul>
              </Col>
            )}

            {approver && (
              <Col span={8}>
                <Typography.Title level={5} style={{ marginBottom: 10 }}>
                  Approvers
                </Typography.Title>

                <ul className={styles.actionList}>
                  {approver.map((user: User) => (
                    <li className={styles.user} key={user.email}>
                      <UserOutlined />
                      {user.name ? user.name : user.email}
                    </li>
                  ))}
                </ul>
              </Col>
            )}
          </Row>
        </section>
      </Panel>
    </section>
  );
};

export default ExpandedDocument;
