import { BaseQualityItem } from '../../types/baseQualityItem';

export enum ItemRelationType {
  PARENT = 'PARENT',
  CHILD = 'CHILD',
  MITIGATED_BY = 'MITIGATED_BY',
  MITIGATES = 'MITIGATES',
  SOURCES = 'SOURCES',
  SOURCED_BY = 'SOURCED_BY',
}

export type Relation = Pick<BaseQualityItem, 'id' | 'code' | 'type' | 'title' | 'subType' | 'itemStatus'> & {
  label: string;
  relationLabel: ItemRelationType;
};
