import React, { ReactNode } from 'react';

import { Space } from 'antd';
import classNames from 'classnames';

import styles from './Header.module.less';

type Props = {
  actions?: ReactNode;
} & React.HTMLAttributes<HTMLElement>;

const Header: React.FC<Props> = ({ actions, children, ...rest }) => {
  return (
    <header {...rest} className={classNames(styles.header, rest.className)}>
      <span className={styles.title}>{children}</span>
      {actions ? (
        <Space className={styles.actions} size={'small'}>
          {actions}
        </Space>
      ) : null}
    </header>
  );
};

export default Header;
