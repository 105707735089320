import { useQuery } from 'react-query';

import itemsApi from '../api/items';
import { BaseQualityItem } from '../types/baseQualityItem';
import { BaseQualityItemTrace } from '../types/qualityItemTrace';
import { RiskTrace } from '../types/trace';

export type UseQualityItem<T = BaseQualityItemTrace | RiskTrace> = {
  loadingItem: boolean;
  item?: T;
};

export function useQualityItem<T extends BaseQualityItem = BaseQualityItemTrace | RiskTrace>(
  company: number,
  product: string,
  itemId: string,
  itemVersion?: string,
): UseQualityItem<T> {
  let queryKey = `qualityItemsFetchById-company-${company}-product-${product}-itemId-${itemId}`;
  if (itemVersion !== undefined) {
    queryKey = queryKey + `-itemVersion-${itemVersion}`;
  }

  const { data, isLoading } = useQuery<T | undefined>(
    queryKey,
    () => itemsApi.queryById(company, product, itemId, itemVersion),
    {
      cacheTime: 0,
    },
  );

  return {
    item: data,
    loadingItem: isLoading,
  };
}
