import { BaseQualityItemTrace } from '../../types/qualityItemTrace';
import { RiskTrace } from '../../types/trace';
import { documentQualityItems, req1QualityItems, req2QualityItems, testCaseQualityItems } from './qualityItems';
import { FMEARisks, ISORisks } from './risks';
import { makeRiskTrace } from './riskTrace';

export const qualityItemsById: Record<string, BaseQualityItemTrace | RiskTrace> = {
  [req1QualityItems[0].id]: {
    ...req1QualityItems[0],
    children: [req2QualityItems[0], testCaseQualityItems[0]],
    mitigates: [FMEARisks[0]],
    sourcedItems: [],
    policyIssues: {
      REQUIRES_PARENT: false,
      UNMITIGATED: false,
      REQUIRES_CHILD: true,
      UNTESTED_REQUIREMENT: true,
      FAILING_TEST: false,
      OUTDATED_TESTRESULT: false,
    },
    version: 'v2',
  },
  [FMEARisks[0].id]: makeRiskTrace(FMEARisks[0], {
    mitigationItems: [req1QualityItems[0]],
    sourceItems: [req2QualityItems[0]],
    version: 'v2',
  }),
  [ISORisks[3].id]: makeRiskTrace(ISORisks[3], {
    mitigationItems: [req2QualityItems[0]],
    sourceItems: [documentQualityItems[0]],
    version: 'v2',
  }),
  [testCaseQualityItems[0].id]: {
    ...testCaseQualityItems[0],
    children: [],
    mitigates: [],
    sourcedItems: [],
    policyIssues: {
      REQUIRES_PARENT: true,
      UNMITIGATED: false,
      REQUIRES_CHILD: false,
      UNTESTED_REQUIREMENT: false,
      FAILING_TEST: true,
      OUTDATED_TESTRESULT: false,
    },
    version: 'v2',
  },
};
