import { createContext, useContext } from 'react';

type DrawerAwareness = {
  shouldDesignElementRedirectButtonBeEnabled: boolean;
};

export const DrawerAwarenessContext = createContext<DrawerAwareness>({
  shouldDesignElementRedirectButtonBeEnabled: true,
});

export const useDrawerAwareness = (): DrawerAwareness => {
  return useContext(DrawerAwarenessContext);
};
