import { useToastProvider } from '@qualio/ui-components';
import { useMutation } from 'react-query';

import { designElementApi } from '../../api/items';
import { useCompanyId } from '../../context/CurrentUserContext';
import { DesignElementType } from '../../types/baseQualityItem';
import { isRequirement } from '../../types/requirement';

type UseDeleteDesignElementReturnType = {
  deleteDesignElement: (designElementId: string, type: DesignElementType) => Promise<void>;
  isDeleting: boolean;
};

type UseDeleteDesignElementMutationType = {
  designElementId: string;
  type: DesignElementType;
};

export const useDeleteDesignElement = (productId: string, onSettled?: () => void): UseDeleteDesignElementReturnType => {
  const companyId = useCompanyId();
  const { showToast } = useToastProvider();

  const { isLoading, mutate } = useMutation(
    ({ designElementId, type }: UseDeleteDesignElementMutationType) => {
      if (isRequirement(type)) {
        return designElementApi.deleteRequirementById(companyId, productId, designElementId);
      }

      return designElementApi.deleteTestCaseById(companyId, productId, designElementId);
    },
    {
      mutationKey: `deleteDesignElement-company-${companyId}-product-${productId}`,
      onSettled: () => {
        if (onSettled) {
          onSettled();
        }
      },
      onError: () => {
        showToast({
          title: 'Failed to delete design element',
          status: 'error',
        });
      },
    },
  );

  return {
    deleteDesignElement: async (designElementId, type) => mutate({ designElementId, type }),
    isDeleting: isLoading,
  };
};
