import * as z from 'zod';

import { baseQualityItem, QRIAttribute as FormCategory, DesignElementType } from '../../../types/baseQualityItem';
import { AttachmentWithId } from '../../../types/generatePresignedUrl';

const TestCaseStatusSchema = z.object({ value: z.string().min(1), label: z.string().min(1) });

export type TestCaseStatusType = z.infer<typeof TestCaseStatusSchema>;

const TestCaseSegmentedControlSchema = z.object({
  prefix: z.string().min(1),
  title: z.string().min(1),
  type: z.nativeEnum(DesignElementType),
});

export type TestCaseSegmentedControlType = z.infer<typeof TestCaseSegmentedControlSchema>;

export const LinkedDesignElementSchema = z.object({
  code: z.string().min(1),
  label: z.string().min(1),
  value: z.string().min(1),
});

export type LinkedDesignElementType = z.infer<typeof LinkedDesignElementSchema>;

export const TestCaseLinkedDesignElementsSchema = z.object({
  req1: z.array(LinkedDesignElementSchema).optional(),
  req2: z.array(LinkedDesignElementSchema).optional(),
  req3: z.array(LinkedDesignElementSchema).optional(),
});

export type TestCaseLinkedDesignElementsType = z.infer<typeof TestCaseLinkedDesignElementsSchema>;

export const NativeTestCaseContainerFormSchema = z
  .object({
    title: z.string().min(1).max(255),
    description: z.string().max(150_000, 'Description is too long').optional(),
    statusLabel: TestCaseStatusSchema,
    type: TestCaseSegmentedControlSchema,
    attachments: z.array(AttachmentWithId).optional(),
    category: FormCategory.optional(),
  })
  .merge(TestCaseLinkedDesignElementsSchema);

export type NativeTestCaseContainerFormType = z.infer<typeof NativeTestCaseContainerFormSchema>;

const testCaseSchema = baseQualityItem
  .merge(z.object({ description: z.string().optional() }))
  .merge(z.object({ requirements: z.array(z.string()).optional() }))
  .merge(z.object({ attachments: z.array(AttachmentWithId).optional() }))
  .strict();

const testCasePayloadSchema = testCaseSchema.pick({
  title: true,
  type: true,
  description: true,
  statusLabel: true,
  requirements: true,
  attachments: true,
  category: true,
});

export type TestCasePayload = z.infer<typeof testCasePayloadSchema>;
