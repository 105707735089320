import React from 'react';

import { QBox, QStack, QText } from '@qualio/ui-components';

export const DisplayKeyValuePair: React.FC<{ keyString: string }> = ({ keyString, children }) => {
  return (
    <QStack direction="column" alignItems="center">
      <QBox mb="5px">
        <QText fontSize={14} color="gray.700" weight="semibold">
          {keyString}
        </QText>
      </QBox>
      {children}
    </QStack>
  );
};
