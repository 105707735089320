import { ResultOptions } from '../views/TestCaseResult/TestCaseResult.enums';
import { allTestCases, BaseQualityItem, DesignElementType } from './baseQualityItem';
import { AttachmentWithIdType } from './generatePresignedUrl';
import { TestLog } from './testLog';
import { TestResultType } from './testResult';

export type TestCase = BaseQualityItem & {
  description?: string;
  log?: TestLog;
};

export type TestResultElement = Omit<TestResultType, 'result'> & {
  result: ResultOptions;
  date: string;
};

export type TestCaseWithResult = BaseQualityItem & {
  description?: string;
  attachments?: Array<AttachmentWithIdType>;
  testResult?: TestResultElement;
};

export function isTestCase(type: DesignElementType): boolean {
  return allTestCases.includes(type);
}

export function isTestCaseElement(item?: BaseQualityItem): item is TestCaseWithResult {
  return item !== undefined && isTestCase(item.type);
}
