import React, { ReactElement } from 'react';

import { QualioQThemeProvider } from '@qualio/ui-components';
import { useLocation, useParams, Outlet } from 'react-router-dom';

import Container from '../../components/Container/Container';
import { Header } from '../../components_new/Header';
import { Loading } from '../../components_new/Loading';
import { Navigation } from '../../components_new/Navigation';
import { QViewsStylingFix } from '../../components_new/QViewsStylingFix/QViewsStylingFix';
import { useCompanyId } from '../../context/CurrentUserContext';
import { ProductContext } from '../../context/product';
import { QualityConfigsProvider } from '../../context/qualityConfigs';
import { useProducts } from '../../hooks/useProducts';
import { ProductParam } from '../../paramTypes';

export function ProductView(): ReactElement<any, any> | null {
  const company = useCompanyId();
  const { product } = useParams<ProductParam>() as ProductParam;
  const { pathname } = useLocation();

  const { product: selectedProduct, isLoading } = useProducts({ productId: product });

  const isForm =
    pathname.includes('/review') ||
    pathname.includes('/create-review') ||
    pathname.includes('/config/') ||
    pathname.includes('/testCase/') ||
    pathname.includes('/requirement/add') ||
    pathname.includes('/requirement/edit') ||
    pathname.includes('/risk/add') ||
    pathname.includes('/risk/edit') ||
    pathname.includes('self-service');

  if (isLoading) {
    return (
      <Container>
        <Loading />
      </Container>
    );
  }

  return (
    <QualioQThemeProvider>
      <QualityConfigsProvider product={product} company={company}>
        <ProductContext.Provider value={selectedProduct}>
          <Container>
            {!isForm && (
              <QViewsStylingFix>
                <QualioQThemeProvider>
                  <Header product={selectedProduct} />
                  <Navigation selectedKeys={[pathname.split('/').slice(3, 4)[0]]} />
                </QualioQThemeProvider>
              </QViewsStylingFix>
            )}
            <Outlet />
          </Container>
        </ProductContext.Provider>
      </QualityConfigsProvider>
    </QualioQThemeProvider>
  );
}
