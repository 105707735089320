import { useMemo } from 'react';

import { useQuery } from 'react-query';

import releaseApi from '../../../api/release';
import { ReleaseDocument, Report } from '../../../types/release';

export enum ReleaseDocumentsStatus {
  COMPLETED = 'COMPLETED',
  LOADING = 'LOADING',
  RELEASE_ERROR = 'RELEASE_ERROR',
  RELEASE_IN_PROGRESS = 'RELEASE_IN_PROGRESS',
}

export type UseReleaseDocuments = {
  hasQueryBeenCalled: boolean;
  isLoading: boolean;
  isRefetching: boolean;
  isError: boolean;
  releaseDocuments: Array<Report | ReleaseDocument>;
  releaseStatus: ReleaseDocumentsStatus;
  isReviewPackageAvailable: boolean;
  refetch: () => void;
};

export function useRetrieveReleaseDocuments(
  companyId: number,
  productId: string,
  releaseId?: string,
): UseReleaseDocuments {
  const { isLoading, isRefetching, data, isError, refetch, isFetching } = useQuery({
    queryKey: `queryReleaseDocuments-company-${companyId}-product-${productId}-releaseId-${releaseId}`,
    queryFn: () => releaseApi.fetchDetails(companyId, productId, String(releaseId)),
    cacheTime: 0,
    enabled: releaseId !== undefined,
  });

  // https://tanstack.com/query/v4/docs/react/guides/disabling-queries
  const hasQueryBeenCalled = isLoading && isFetching;

  return useMemo(() => {
    let allDocuments: Array<Report | ReleaseDocument> = [];
    let isReviewPackageAvailable = false;

    if (isError) {
      return {
        hasQueryBeenCalled,
        isLoading,
        isError,
        isRefetching,
        releaseStatus: ReleaseDocumentsStatus.RELEASE_ERROR,
        releaseDocuments: allDocuments,
        isReviewPackageAvailable,
        refetch,
      };
    }

    let releaseStatus: ReleaseDocumentsStatus = ReleaseDocumentsStatus.LOADING;
    if (!releaseId) {
      return {
        hasQueryBeenCalled,
        isLoading: true,
        isError,
        isRefetching,
        releaseStatus,
        releaseDocuments: allDocuments,
        isReviewPackageAvailable,
        refetch,
      };
    }

    if (isLoading || isRefetching) {
      return {
        hasQueryBeenCalled,
        isLoading,
        isError,
        isRefetching,
        releaseStatus,
        releaseDocuments: allDocuments,
        isReviewPackageAvailable,
        refetch,
      };
    }

    if (data && data.reports.length > 0) {
      const { reports, documents, reviewPackageAvailable } = data;
      allDocuments = [...reports, ...documents];

      if (allDocuments.some((document) => document.status === 'error')) {
        releaseStatus = ReleaseDocumentsStatus.RELEASE_ERROR;
      }

      if (allDocuments.some((doc) => doc.status === 'processing')) {
        releaseStatus = ReleaseDocumentsStatus.RELEASE_IN_PROGRESS;
      }

      if (
        releaseStatus !== ReleaseDocumentsStatus.RELEASE_IN_PROGRESS &&
        releaseStatus !== ReleaseDocumentsStatus.RELEASE_ERROR &&
        releaseStatus === ReleaseDocumentsStatus.LOADING
      ) {
        allDocuments = allDocuments.filter((document) => document.status === 'completed');
        isReviewPackageAvailable = reviewPackageAvailable;
        releaseStatus = ReleaseDocumentsStatus.COMPLETED;
      }
    }

    return {
      hasQueryBeenCalled,
      isLoading,
      isError,
      isRefetching,
      releaseStatus,
      releaseDocuments: allDocuments,
      isReviewPackageAvailable,
      refetch,
    };
  }, [data, hasQueryBeenCalled, isError, isLoading, isRefetching, refetch, releaseId]);
}
