import React, { useMemo } from 'react';

import { Product } from '@design-controls/types';
import { QTbody, QSelectItem, QTable, QTh, QThead, QTr } from '@qualio/ui-components';

import { FeatureFlags } from '../../../components/FeatureToggle/FeatureToggle.enum';
import useFeatureFlag from '../../../components/FeatureToggle/hooks/useFeatureFlag';
import { sortByCode } from '../../../lib/sortByCode';
import { TestCaseTrace } from '../../../types/trace';
import { ResultValueToLabel } from '../testResultValueToLabel';
import TestResultRow from './TestResultRow';

type TestResultTableProps = {
  product: Product;
  testCases: Array<TestCaseTrace>;
  onRowClick?: (testCaseId: string) => void;
  selectedItemId?: string;
};

const TestResultTable: React.FC<TestResultTableProps> = ({ product, testCases, onRowClick, selectedItemId }) => {
  const resultOptions: Array<QSelectItem> = useMemo(() => {
    return Object.entries(ResultValueToLabel).map(([key, value]) => ({
      label: value,
      value: key,
    }));
  }, []);

  const features = useFeatureFlag([FeatureFlags.DESIGN_CONTROLS_CATEGORIES]);
  const thStyle = { textAlign: 'left' as const };
  return (
    <>
      <QTable>
        <QThead>
          <QTr>
            <QTh width="95px" style={thStyle} key={'Header_ID'}>
              ID
            </QTh>
            <QTh width="30%" style={thStyle} key={'Header_Title'}>
              Test case title
            </QTh>

            {features[FeatureFlags.DESIGN_CONTROLS_CATEGORIES] && (
              <QTh width="105px" style={thStyle} key={'Header_Category'}>
                Category
              </QTh>
            )}
            <QTh width="105px" style={thStyle} key={'Header_Result'}>
              Result
            </QTh>
            <QTh width="180px" style={thStyle} key={'Header_Responsible'}>
              Responsible
            </QTh>
            <QTh style={thStyle} key={'Header_Note'}>
              Note
            </QTh>
            <QTh width="38px" style={thStyle} key={'Header_Empty1'} />
            <QTh width="60px" style={thStyle} key={'Header_Empty2'} />
          </QTr>
        </QThead>
        <QTbody
          onClick={(event: any) => {
            if (event.nativeEvent.target instanceof Element) {
              const drawerValue =
                event.nativeEvent.target.getAttribute('data-drawer') ||
                (event.nativeEvent.target.parentElement as HTMLElement).getAttribute('data-drawer');

              if (drawerValue && onRowClick) {
                onRowClick(drawerValue);
              }
            }
          }}
          data-cy={'result-records'}
        >
          {[...testCases].sort(sortByCode).map((testCase) => (
            <TestResultRow
              productId={product.id}
              options={resultOptions}
              key={testCase.id}
              testCase={testCase}
              selectedItemId={selectedItemId}
            />
          ))}
        </QTbody>
      </QTable>
    </>
  );
};

export default TestResultTable;
