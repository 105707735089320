import React, { ReactNode } from 'react';

import styles from './Panel.module.less';

type PanelProps = {
  title?: ReactNode | null;
  actions?: ReactNode | null;
};

const Panel: React.FC<PanelProps> = ({ children, title, actions, ...rest }) => {
  return (
    <section className={styles.panel} {...rest}>
      <main className={styles.body}>
        {title && <h5 className={styles.title}>{title}</h5>}
        {children}
      </main>
      {actions && <aside>{actions}</aside>}
    </section>
  );
};

export default Panel;
