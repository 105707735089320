import React from 'react';

import { QFlex } from '@qualio/ui-components';

export const ISORiskAssessmentLevels: React.FC = ({ children }) => {
  return (
    <QFlex marginTop={2} marginBottom={5} justifyContent="space-between" width="100%">
      {children}
    </QFlex>
  );
};
