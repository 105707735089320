import React from 'react';

import { QFlex, QStack, QTag, QText } from '@qualio/ui-components';
import { sanitize } from 'dompurify';

import { DateFormat, useLocalisingDateFormatter } from '../../lib/dateFormat';
import { TestResultElement } from '../../types/testCase';
import { testResultStatusLabelAndColor } from '../../views_new/Review/List/statusLabelAndColorTag';
import { AttachmentDisplayElement } from '../AttachmentDisplayElement/AttachmentDisplayElement';

export const TestResult: React.FC<{ testResult: TestResultElement }> = ({ testResult }) => {
  const testResultAttachments = testResult.attachments ?? [];
  const result = testResultStatusLabelAndColor(testResult.result);

  const dateFormat = useLocalisingDateFormatter();

  return (
    <QFlex data-cy={`test-result`} marginTop={5} marginBottom={5} flexDirection={'column'}>
      <QText color="gray.700" fontSize={14} weight="semibold">
        Test Result
      </QText>

      <QStack direction="column" width="100%" mt="10px">
        <QStack direction="row" justify="space-between" alignItems="center">
          <QStack direction="row" spacing="10px">
            <QText data-cy={`test-result-executed-by`} weight="semibold">
              {testResult.executedBy}
            </QText>
            {testResult.date && (
              <QText data-cy={`test-result-executed-at`} color="gray.500">
                {dateFormat(testResult.date, DateFormat.LONG_WITH_TIME)}
              </QText>
            )}
          </QStack>
          {result && (
            <QTag data-cy={`test-result-result`} variantColor={result.variantColor}>
              {result.label}
            </QTag>
          )}
        </QStack>
        {testResult.notes && (
          <QStack direction="row" spacing="10px" alignItems="top">
            <QText
              data-cy={`test-result-notes`}
              style={{ wordBreak: 'break-word' }}
              dangerouslySetInnerHTML={{ __html: sanitize(testResult?.notes || '') }}
            />
          </QStack>
        )}
        {testResultAttachments.length > 0 && (
          <QStack data-cy={`test-result-attachments`} direction="column" spacing="10px" width="100%">
            <AttachmentDisplayElement attachments={testResultAttachments} />
          </QStack>
        )}
      </QStack>
    </QFlex>
  );
};
