import React from 'react';

import { QBox, QCloseButton, QDivider, QFlex, QHeading, QText } from '@qualio/ui-components';
import { useNavigate } from 'react-router-dom';

export type FormHeaderProps = {
  title: string;
  productName: string;
  onClose?: () => void;
};

export const FormHeader: React.FC<FormHeaderProps> = ({ title, productName, onClose }) => {
  const navigate = useNavigate();

  return (
    <>
      <QFlex justifyContent="space-between" mb={1}>
        <QBox>
          <QHeading mb={1} size="lg" as="h1" data-cy="form-heading">
            {title}
          </QHeading>
          <QText color={'gray.500'} data-cy="sub-heading" fontSize={14}>
            {productName}
          </QText>
        </QBox>
        <QCloseButton data-cy="form-heading-close-btn" onClick={onClose ? onClose : () => navigate(-1)} />
      </QFlex>
      <QDivider mb={5} />
    </>
  );
};
