import React from 'react';

import { zodResolver } from '@hookform/resolvers/zod';
import {
  QButton,
  QCloseButton,
  QFormControl,
  QInput,
  QModal,
  QModalActions,
  QModalBody,
  QModalHeader,
  QStack,
  QText,
  QTextarea,
  useToastProvider,
} from '@qualio/ui-components';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';

import changeControlApi from '../../api/changeControl';
import { useCompanyId } from '../../context/CurrentUserContext';
import { ChangeControl, ChangeControlStatus, errorStatusCodeToErrorMessage } from '../../types/changeControl';
import { approval, Approval } from '../../views/ChangeControlDetails/types';

type ReviewRequestModalProps = {
  isOpen: boolean;
  closeModal: () => void;
  refresh?: (reviewStatus: ChangeControlStatus.APPROVED | ChangeControlStatus.NOT_APPROVED) => void;
  productId: string;
  changeControl: ChangeControl;
  approvalModalStatus: ChangeControlStatus.APPROVED | ChangeControlStatus.NOT_APPROVED;
};

export const ReviewRequestModal: React.FC<ReviewRequestModalProps> = ({
  isOpen,
  closeModal,
  refresh,
  productId,
  changeControl,
  approvalModalStatus,
}) => {
  const companyId = useCompanyId();
  const { showToast } = useToastProvider();

  const methods = useForm<Omit<Approval, 'status'>>({
    defaultValues: {
      note: '',
      signature: {
        email: '',
        password: '',
      },
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: zodResolver(approval.omit({ status: true })),
  });
  const { errors } = methods.formState;

  const { mutate, isLoading } = useMutation(
    (approval: Omit<Approval, 'status'>) =>
      changeControlApi.approve(companyId, productId, changeControl.id, { ...approval, status: approvalModalStatus }),
    {
      onSuccess: () => {
        const successMessage =
          approvalModalStatus === ChangeControlStatus.APPROVED
            ? 'The change control was successfully approved'
            : 'The change control was successfully declined';
        showToast({
          status: 'success',
          description: successMessage,
        });
        closeModal();
        refresh && refresh(approvalModalStatus);
      },
      onError: (error: any) => {
        const errorMessage =
          errorStatusCodeToErrorMessage[error.response?.status] ??
          'Approve/decline could not be processed. Please retry';
        showToast({
          status: 'error',
          description: errorMessage,
        });
      },
    },
  );

  const handleSubmit = (data: Omit<Approval, 'status'>) => {
    mutate(data);
  };

  return (
    <FormProvider {...methods}>
      <QModal size="xl" isOpen={isOpen} onClose={closeModal}>
        <QModalHeader>
          <QText casing="none">{changeControl.code}</QText>
          <QCloseButton data-cy={`close-change-control-review-button`} onClick={closeModal} />
        </QModalHeader>
        <QModalBody>
          <QStack spacing="10px" mt="10px" mb="10px">
            <QText color="gray.700" fontSize="sm">
              Enter your digital signature to confirm your decision
            </QText>
            <QFormControl label="Email" isInvalid={!!errors.signature?.email} error={errors.signature?.email?.message}>
              <Controller
                name={'signature.email'}
                render={({ field: { onChange, value } }) => (
                  <QInput
                    value={value}
                    onChange={onChange}
                    data-cy={`change-control-review-email-input`}
                    placeholder={'Enter email'}
                  />
                )}
              />
            </QFormControl>
            <QFormControl
              label="Password"
              isInvalid={!!errors.signature?.password}
              error={errors.signature?.password?.message}
            >
              <Controller
                name={'signature.password'}
                render={({ field: { onChange, value } }) => (
                  <QInput
                    type="password"
                    value={value}
                    onChange={onChange}
                    data-cy={`change-control-review-password-input`}
                    placeholder={'Enter password'}
                  />
                )}
              />
            </QFormControl>
            <QFormControl label="Notes (Optional)" isInvalid={!!errors.note} error={errors.note?.message}>
              <Controller
                name={'note'}
                render={({ field: { onChange, value } }) => (
                  <QTextarea
                    value={value}
                    onChange={onChange}
                    data-cy={`change-control-review-notes-input`}
                    placeholder={'Enter notes'}
                  />
                )}
              />
            </QFormControl>
          </QStack>
        </QModalBody>
        <QModalActions>
          <QButton
            data-cy={'signature-modal-cancel-button'}
            onClick={closeModal}
            variant="outline"
            isDisabled={isLoading}
          >
            Cancel
          </QButton>
          {approvalModalStatus === ChangeControlStatus.APPROVED ? (
            <QButton
              data-cy={'change-control-details-approve-button'}
              onClick={methods.handleSubmit(handleSubmit)}
              isDisabled={isLoading}
              isLoading={isLoading}
            >
              Approve
            </QButton>
          ) : (
            <QButton
              data-cy={'change-control-details-decline-button'}
              isDestructive
              onClick={methods.handleSubmit(handleSubmit)}
              isDisabled={isLoading}
              isLoading={isLoading}
            >
              Decline
            </QButton>
          )}
        </QModalActions>
      </QModal>
    </FormProvider>
  );
};
