import { QTagProps } from '@qualio/ui-components';
import { z } from 'zod';

export type DocumentFileType = 'qualio' | 'word' | 'excel' | 'pdf';

export const Tag = z.object({
  id: z.number(),
  name: z.string(),
  group_ids: z.number().array(),
});
export type Tag = z.infer<typeof Tag>;

export const Document = z.object({
  author_usercompany_id: z.number(),
  category: z.string(),
  code: z.string(),
  created_time: z.number(),
  document_matrix_id: z.string(),
  effective_date: z.number().nullable(),
  effective_on_approval: z.boolean(),
  id: z.number(),
  major_version: z.number(),
  minor_version: z.number(),
  modified_time: z.number(),
  owner: z.string(),
  review_date: z.number().nullable(),
  review_period: z.number().nullable(),
  status_id: z.string(),
  tag_ids: z.array(z.number()),
  title: z.string(),
  training_available: z.boolean(),
  training_required: z.boolean(),
  type: z.string(),
  version: z.string(),
});

export const RetrieveDocumentsResponse = z.object({
  documents: z.array(Document),
  total: z.number(),
});

export const DOCUMENT_STATUSES = ['draft', 'for_approval', 'for_review', 'approval_declined', 'effective'];

export enum DocumentStatus {
  Effective = 'effective',
  Draft = 'draft',
  For_Review = 'for_review',
  For_Approval = 'for_approval',
  Approved = 'approved',
  Approval_Declined = 'approval_declined',
  Superseded = 'superseded',
  Retired = 'retired',
  Deleted = 'deleted',
  For_Periodic_Review = 'for_periodic_review',
  For_Retirement = 'for_retirement',
}

type DocumentStatusConfig = {
  label: string;
  color: QTagProps['variantColor'];
};

export const DocumentStatusIdToStatusConfigMap: {
  [statusId in DocumentStatus]: DocumentStatusConfig;
} = {
  [DocumentStatus.Approved]: {
    label: 'Approved',
    color: 'purple',
  },
  [DocumentStatus.Approval_Declined]: {
    label: 'Approval declined',
    color: 'orange',
  },
  [DocumentStatus.Deleted]: {
    label: 'Deleted',
    color: 'red',
  },
  [DocumentStatus.Draft]: {
    label: 'Draft',
    color: 'gray',
  },
  [DocumentStatus.Effective]: {
    label: 'Effective',
    color: 'green',
  },
  [DocumentStatus.For_Approval]: {
    label: 'For approval',
    color: 'blue',
  },
  [DocumentStatus.For_Periodic_Review]: {
    label: 'For periodic review',
    color: 'blue',
  },
  [DocumentStatus.For_Retirement]: {
    label: 'For retirement',
    color: 'red',
  },
  [DocumentStatus.For_Review]: {
    label: 'For review',
    color: 'blue',
  },
  [DocumentStatus.Retired]: {
    label: 'Retired',
    color: 'red',
  },
  [DocumentStatus.Superseded]: {
    label: 'Superseded',
    color: 'red',
  },
};

export type Document = z.infer<typeof Document>;
export type RetrieveDocumentsResponse = z.infer<typeof RetrieveDocumentsResponse>;
