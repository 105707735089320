import { useCallback } from 'react';

import { useCurrentUser } from '@qualio/ui-components';
import { format } from 'date-fns';

export enum DateFormat {
  MONTH_YEAR = 'MMM d yyyy',
  LONG_WITH_TIME = 'MMM d yyyy hh:mm a',
}

export function useLocalisingDateFormatter(): (dateString: string, dateFormat?: string | DateFormat) => string {
  const { localiseDate } = useCurrentUser();
  return useCallback(
    (dateString: string, dateFormat: string | DateFormat = DateFormat.MONTH_YEAR) => {
      try {
        return format(localiseDate(dateString), dateFormat);
      } catch (e) {
        console.error(e);
        return dateString;
      }
    },
    [localiseDate],
  );
}
