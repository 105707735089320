import React, { useMemo } from 'react';

import { QFormControl, QSelect } from '@qualio/ui-components';
import { Controller } from 'react-hook-form';

import { useResourceLibraryQuickSearch } from '../../hooks/qri/useResourceLibraryQuickSearch';

type DesignElementResourceItemFormFieldProps = {
  resourceSubType: string;
  name: string;
  label: string;
};

export const DesignElementResourceItemFormField: React.FC<DesignElementResourceItemFormFieldProps> = ({
  resourceSubType,
  name,
  label,
}) => {
  const { isLoading, data } = useResourceLibraryQuickSearch(resourceSubType);

  const options = useMemo((): Array<any> => {
    if (!data || !data.results.length) {
      return [];
    }

    return data.results.map((item) => ({
      value: item.identifiers.qri,
      label: item.displayLabel,
    }));
  }, [data]);

  return (
    <QFormControl label={label} data-cy={name}>
      <Controller
        name={name}
        defaultValue={{ qri: '', label: '' }}
        render={({ field: { onChange, value } }) => {
          return (
            <QSelect
              options={options}
              isLoading={isLoading}
              value={value && options.find((option) => option.value === value.qri)?.value}
              onChange={(item) => {
                onChange(item ? { qri: item.value, label: item.label } : { qri: '', label: '' });
              }}
              key={`resource-item-${resourceSubType}`}
              isClearable
            />
          );
        }}
      />
    </QFormControl>
  );
};
