import { useMatch } from 'react-router-dom';

export function useRouteParams<T>(pattern: string): T {
  const match = useMatch(pattern);
  const match2 = useMatch(pattern.split('/').slice(0, -1).join('/'));

  const matchToUse = match ?? match2;

  return (matchToUse?.params ?? {}) as unknown as T;
}
