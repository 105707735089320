import styled from '@emotion/styled';
import { QTheme } from '@qualio/ui-components';

export const EditorContainer = styled.div`
  padding: 0;
  margin: 0;
  .ck-toolbar_grouping {
    border-radius: 4px !important;
  }
  .ck-editor__editable {
    border-radius: 4px !important;
    border-color: #b3ceff !important;
    padding: 0.2cm 0.3cm;
    min-height: 180px;
  }
  .ck-content hr {
    margin: ${() => QTheme.space[4]} 0;
    height: ${() => QTheme.space[1]} !important;
    background: ${() => QTheme.colors.gray[20]};
    border: 0;
  }
  .css-1grqi52 .ck.ck-editor__editable_inline > * {
    margin-right: 0;
    margin-left: 0;
    margin-top: 0.2cm !important;
    margin-bottom: 0.5cm !important;
  }
  ul,
  ol {
    padding-left: 2rem;
  }
`;
