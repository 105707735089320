import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Select, Space } from 'antd';
import { Outlet, useParams } from 'react-router-dom';

import QualityItemsTable from '../../components/QualityItemsTable/items';
import { DrawerProvider } from '../../components_new/DesginElementDetailsDrawer/context/DrawerProvider';
import { Loading } from '../../components_new/Loading';
import { useQualityConfigs } from '../../context/qualityConfigs';
import { scrollWindowToElementTop } from '../../lib/formValidationUtilities';
import { ProductParam, SelectedItemParam } from '../../paramTypes';
import { PolicyIssues } from '../../types/openIssue';
import IssueFilter from './components/Filter';
import { useIssueFilters } from './useIssueFilters';
import { ALL_ISSUES, ElementTypes, useOpenIssues } from './useOpenIssues';

const OpenIssues: React.FC = () => {
  const { product, selectedItemId } = useParams() as ProductParam & Partial<SelectedItemParam>;
  const [selectedType, setSelectedType] = useState<ElementTypes>(ALL_ISSUES);
  const [selectedFilter, setSelectedFilter] = useState<PolicyIssues | null>(null);
  const { loading: loadingConfigs, configs } = useQualityConfigs();
  const { loading: loadingIssues, data: openIssues } = useOpenIssues(product, selectedType);
  const issueFilters = useIssueFilters(
    openIssues,
    configs.find(({ type }) => type === selectedType),
  );

  useEffect(() => {
    setSelectedFilter(null);
  }, [selectedType]);

  useEffect(() => {
    if (!selectedItemId) {
      return;
    }
    scrollWindowToElementTop('styled-table');
  }, [selectedItemId]);

  const handleSelectedTypeChange = useCallback((value: ElementTypes) => {
    setSelectedType(value);
  }, []);

  const handleFilterChange = useCallback(
    (value: PolicyIssues) => {
      setSelectedFilter(selectedFilter === value ? null : value);
    },
    [selectedFilter],
  );

  const filteredIssues = useMemo(() => {
    if (!selectedFilter) {
      return openIssues;
    }

    return openIssues.filter((issue) => issue.policyIssues[selectedFilter]);
  }, [openIssues, selectedFilter]);

  return (
    <DrawerProvider url={`/product/${product}/open-issues`}>
      {loadingConfigs || loadingIssues ? (
        <Loading />
      ) : (
        <Space direction={'vertical'} size={'large'} style={{ width: '100%' }}>
          <Space size={'large'}>
            <Select
              value={selectedType}
              onChange={handleSelectedTypeChange}
              style={{ width: 200 }}
              data-cy="openIssues-elementsDropdown"
            >
              <Select.Option key={'all'} value={ALL_ISSUES} data-cy={`openIssues-elementsDropdown-${ALL_ISSUES}`}>
                All
              </Select.Option>
              {configs.map((config) => (
                <Select.Option
                  key={config.type}
                  value={config.type}
                  data-cy={`openIssues-elementsDropdown-${config.type}`}
                >
                  {config.label}
                </Select.Option>
              ))}
            </Select>

            <Space data-cy="openIssues-filters">
              {issueFilters.map((filter, index) => (
                <IssueFilter
                  {...filter}
                  key={`issueFilter_${index}`}
                  active={selectedFilter === filter.policy}
                  onClick={handleFilterChange}
                  data-cy={`openIssues-filters-${filter.policy}`}
                />
              ))}
            </Space>
          </Space>
          <QualityItemsTable items={filteredIssues} selectedItemId={selectedItemId} data-cy="openIssues-items" />
          <Outlet />
        </Space>
      )}
    </DrawerProvider>
  );
};

export default OpenIssues;
