import React from 'react';

import Icon from '@ant-design/icons';

const Svg = () => (
  <svg width="1em" height="1em" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect
      x="1.5"
      y="1.5"
      width="19"
      height="19"
      rx="1.5"
      stroke="#1D1D1B"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.2126 4.75H13.7184C13.6491 4.75 13.5833 4.7836 13.5409 4.8411L10.626 8.74116L9.22831 6.87066C9.20717 6.8423 9.18021 6.81938 9.14948 6.8036C9.11875 6.78782 9.08503 6.7796 9.05086 6.77956H8.55667C8.50931 6.77956 8.48315 6.83705 8.51213 6.87588L10.4486 9.46697C10.5391 9.58793 10.713 9.58793 10.8042 9.46697L14.2571 4.84558C14.2861 4.8075 14.2599 4.75 14.2126 4.75V4.75Z"
      fill="#1D1D1B"
      stroke="#1D1D1B"
      strokeWidth="0.5"
    />
    <circle cx="11.3846" cy="15.1346" r="2.38462" stroke="#1D1D1B" />
  </svg>
);

const TestCaseIcon: React.FC<any> = (props) => <Icon component={Svg} {...props} />;

export default TestCaseIcon;
