import React from 'react';

import { QText } from '@qualio/ui-components';

import { Header } from './BasicFormSectionHeader.styles';

interface BasicFormSectionHeaderProps {
  title: string;
  subTitle?: string;
}

const BasicFormSectionHeader: React.FC<BasicFormSectionHeaderProps> = ({ title, subTitle }) => {
  return (
    <Header>
      <QText fontSize="lg" fontWeight={400} role="heading">
        {title}
      </QText>
      {subTitle && (
        <QText fontSize="sm" {...{ role: 'subheading', name: 'subheading' }}>
          {subTitle}
        </QText>
      )}
    </Header>
  );
};

export default BasicFormSectionHeader;
