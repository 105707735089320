import React, { useCallback, useEffect, useMemo } from 'react';

import { isRiskPolicy } from '@design-controls/types';
import { QStack, QTabHeader, QTabs } from '@qualio/ui-components';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import { useCurrentConfigs } from '../hooks/useCurrentConfigs';
import { useCurrentProduct } from '../hooks/useCurrentProduct';

const TABS: { id: string; route: string; title: string }[] = [
  { id: 'iso', route: 'iso', title: 'ISO' },
  { id: 'fmea', route: 'fmea', title: 'FMEA' },
];

export const RiskListLayout: React.FC = ({ children }) => {
  const configs = useCurrentConfigs();
  const product = useCurrentProduct();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const onItemChange = useCallback(
    (header: QTabHeader) => {
      const item = TABS.find((tab) => tab.id === header.id);
      if (!item) {
        return;
      }

      navigate(`/product/${product.id}/risk/${item.route}`);
    },
    [navigate, product],
  );

  const activeTabId = useMemo(() => {
    const item = TABS.find((item) => pathname.includes(item.route));

    if (!item) {
      return 'iso';
    }

    return item.id;
  }, [pathname]);

  const fmeaEnabled = useMemo(() => {
    return !!configs.find(isRiskPolicy)?.assessment.fmea;
  }, [configs]);

  useEffect(() => {
    if (!pathname.match(/iso|fmea/)) {
      navigate('iso');
    }
  }, [pathname, navigate]);

  return (
    <QStack gap={4}>
      {fmeaEnabled && (
        <QTabs activeTabId={activeTabId} onClick={onItemChange} tabs={TABS.map(({ id, title }) => ({ id, title }))} />
      )}
      <Outlet />
    </QStack>
  );
};
