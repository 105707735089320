import { useMemo } from 'react';

export const useFmeaRiskCalculateScore = (
  mitigationLevel: 'preMitigation' | 'postMitigation',
  watchValue: { severity?: number; occurrence?: number; detectability?: number },
): number | undefined => {
  return useMemo(() => {
    const severityValue = watchValue.severity;
    const occurrenceValue = watchValue.occurrence;
    const detectabilityValue = watchValue.detectability;

    if (!severityValue || !occurrenceValue || !detectabilityValue) {
      return undefined;
    }

    return severityValue * occurrenceValue * detectabilityValue;
  }, [watchValue]);
};
