import { useCurrentUser, useToastProvider } from '@qualio/ui-components';
import { useMutation, useQueryClient } from 'react-query';

import { designElementApi } from '../../api/items';
import { useCurrentProduct } from '../../appV2/hooks/useCurrentProduct';

type DesignElement = {
  id: string;
  code: string;
  title: string;
};

export const useDeleteRequirement = () => {
  const { companyId } = useCurrentUser();
  const { id: productId } = useCurrentProduct();
  const { showToast } = useToastProvider();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (element: DesignElement) => designElementApi.deleteRequirementById(companyId, productId, element.id),
    onSuccess: async (_: unknown, element: DesignElement) => {
      showToast({
        title: 'Item deleted',
        description: `${element.code} ${element.title} was successfully deleted.`,
        status: 'success',
      });
      await queryClient.invalidateQueries(['REQUIREMENTS_LIST', companyId, productId]);
    },
    onError: (_: unknown, element: DesignElement) => {
      showToast({
        title: 'Item failed to deleted',
        description: `${element.code} ${element.title} could not be deleted, try again.`,
        status: 'error',
      });
    },
  });
};
