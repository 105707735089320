import { useQuery } from 'react-query';

import configApi from '../api/config';
import { useCompanyId } from '../context/CurrentUserContext';

type UseArchivePrefixesReturnType = {
  archivedPrefixes: Array<string>;
  isLoading: boolean;
};

export const useArchivePrefixes = (productId: string): UseArchivePrefixesReturnType => {
  const companyId = useCompanyId();

  const { data, isLoading } = useQuery<Array<string>>(
    `queryArchivePrefixes-company-${companyId}-product-${productId}`,
    () => configApi.retrieveArchivePrefixes(companyId, productId),
    { cacheTime: 0 },
  );

  return { archivedPrefixes: data || [], isLoading };
};
