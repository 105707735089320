import { useEffect, useMemo, useState } from 'react';

import changeControlApi from '../../../api/changeControl';
import configApi from '../../../api/config';
import { DashboardItem, ChangeControl, ChangeControlQualityItem } from '../../../types/changeControl';

export type UseReviewData = {
  loading: boolean;
  isError: boolean;
  review?: ChangeControl;
  qualityItems: (ChangeControlQualityItem | DashboardItem)[];
  qualityConfigs: Record<string, string>;
};

export function useReviewData(company: number, product: string, changeControlId?: string): UseReviewData {
  const [loading, setLoading] = useState<boolean>(false);
  const [isError, setError] = useState<boolean>(false);
  const [review, setReview] = useState<ChangeControl>();
  const [unstagedItems, setUnstagedItems] = useState<DashboardItem[]>([]);
  const [qualityConfigs, setQualityConfigs] = useState<Record<string, string>>({});

  useEffect(() => {
    setLoading(true);

    const promises = [
      configApi
        .query(company, product)
        .then((configs) => {
          const configRecords: Record<string, string> = {};
          configs.forEach((config) => {
            configRecords[config.type] = config.label;
          });
          setQualityConfigs(configRecords);
        })
        .catch(() => setError(true)),
      changeControlApi
        .retrieveUnstaged(company, product)
        .then(setUnstagedItems)
        .catch(() => setError(true)),
    ];
    if (changeControlId) {
      promises.push(
        changeControlApi
          .retrieveChangeControlRecord(company, product, changeControlId)
          .then(setReview)
          .catch(() => setError(true)),
      );
    }

    Promise.all(promises).finally(() => setLoading(false));
  }, [company, product, changeControlId]);

  const qualityItems = useMemo(
    () =>
      [...unstagedItems, ...(review?.qualityItems || [])].filter((qualityItem, idx, allItems) => {
        return allItems.findIndex((el) => el.id === qualityItem.id) === idx;
      }),
    [unstagedItems, review],
  );

  return {
    loading,
    isError,
    qualityItems,
    review,
    qualityConfigs,
  };
}
