import { useToastProvider } from '@qualio/ui-components';
import { useQuery } from 'react-query';

import { MedtechAPI } from '../../api/medtech/medtech';
import { FeatureFlags } from '../../components/FeatureToggle/FeatureToggle.enum';
import useFeatureFlag from '../../components/FeatureToggle/hooks/useFeatureFlag';
import { useCompanyId } from '../../context/CurrentUserContext';

export const useTags = () => {
  const features = useFeatureFlag([FeatureFlags.SELF_SERVICE_DOCUMENTS]);
  const { showToast } = useToastProvider();
  const companyId = useCompanyId();
  return useQuery({
    queryKey: [companyId, 'tags'],
    queryFn: () => MedtechAPI.getDocumentTags(companyId),
    onError: () => {
      showToast({
        title: 'Error',
        description: 'Unable to fetch document tags',
        status: 'error',
      });
    },
    enabled: features[FeatureFlags.SELF_SERVICE_DOCUMENTS],
  });
};
