import { RequirementTrace } from '../../../types/trace';

export enum RequirementTraceFilterTypes {
  ALL = 'ALL',
  NO_TESTS = 'NO_TESTS',
}

export const requirementFilters: Record<RequirementTraceFilterTypes, string> = {
  [RequirementTraceFilterTypes.ALL]: 'All requirements',
  [RequirementTraceFilterTypes.NO_TESTS]: 'Requirements with no tests',
};

type RequirementTraceFilterFn = (data: RequirementTrace[]) => any[];

export const requirementFilterFns: Record<RequirementTraceFilterTypes, RequirementTraceFilterFn> = {
  [RequirementTraceFilterTypes.ALL]: (data) => data,
  [RequirementTraceFilterTypes.NO_TESTS]: (data) => {
    return data.filter(hasRequirementsWithNoTests);
  },
};

export function requirementHasNoTests(el: RequirementTrace): boolean {
  return el.testedBy === undefined || el.testedBy.length === 0;
}

export function hasRequirementsWithNoTests(trace: RequirementTrace): boolean {
  return Boolean(requirementHasNoTests(trace) || trace.children?.some(hasRequirementsWithNoTests));
}
