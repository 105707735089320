import React from 'react';

import { QStack, QTag, QText } from '@qualio/ui-components';

import { DateFormat, useLocalisingDateFormatter } from '../../lib/dateFormat';
import { ChangeControlApprover } from '../../types/changeControl';
import { approverStatusLabelAndColor } from '../../views_new/Review/List/statusLabelAndColorTag';
import { DisplayElement, DisplayElementBody } from '../DisplayElement';

type ApproverDisplayElementProps = {
  approver: ChangeControlApprover;
  isApproverNameInRegularText?: boolean;
};

export const ApproverDisplayElement: React.FC<ApproverDisplayElementProps> = ({
  approver,
  children,
  isApproverNameInRegularText,
}) => {
  const dateFormat = useLocalisingDateFormatter();
  const status = approver.status ? approverStatusLabelAndColor(approver.status) : undefined;

  return (
    <DisplayElement>
      <DisplayElementBody width="100%">
        <QStack direction="column" width="100%">
          <QStack direction="row" justify="space-between" alignItems="center">
            <QStack direction="row" spacing="10px">
              <QText
                weight={isApproverNameInRegularText ? 'regular' : 'semibold'}
                data-cy={`selected-item-title-${approver.id}`}
              >
                {approver.fullName}
              </QText>
              {approver.modified && (
                <QText data-cy={`selected-item-timestamp-${approver.id}`} color="gray.500">
                  {dateFormat(approver.modified, DateFormat.LONG_WITH_TIME)}
                </QText>
              )}
            </QStack>

            {status && (
              <QTag data-cy={`selected-item-status-right-${approver.id}`} variantColor={status.color}>
                {status.label}
              </QTag>
            )}
            {children}
          </QStack>
          {approver.note && (
            <QStack direction="row" spacing="10px" alignItems="top">
              <QText style={{ wordBreak: 'break-word' }} data-cy={`selected-item-note-${approver.id}`}>
                {approver.note}
              </QText>
            </QStack>
          )}
        </QStack>
      </DisplayElementBody>
    </DisplayElement>
  );
};
