import React, { ReactElement } from 'react';

import TestCaseIcon from '../../../../components/CustomIcons/TestCase';
import { TestCase } from '../../../../types/testCase';
import { RequirementTrace } from '../../../../types/trace';
import { isRequirement } from './helpers';

type RowIconProps = {
  row: RequirementTrace | TestCase;
  requirementIcon: ReactElement<any, any> | null;
};

const RowIcon: React.FC<RowIconProps> = ({ row, requirementIcon }) => {
  if (isRequirement(row)) {
    return requirementIcon;
  } else {
    return <TestCaseIcon style={{ fontSize: 20 }} />;
  }
};

export default RowIcon;
