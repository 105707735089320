import { QualityItemStatus } from '../../../types/baseQualityItem';
import { ControlledDocument } from '../../../types/controlledDocument';
import { Requirement } from '../../../types/requirement';
import { TestCase } from '../../../types/testCase';

export const filterDeletedElements = (
  elements: Array<ControlledDocument | Requirement | TestCase>,
): Array<ControlledDocument | Requirement | TestCase> => {
  return elements ? [...elements.filter((element) => element.itemStatus !== QualityItemStatus.DELETED)] : [];
};
