import styled from '@emotion/styled';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const AttachmentsViewContainer = styled.div<{ QTheme: any }>`
  .q-attachments-view--wrapper {
    .q-attachments-view--file {
      color: ${({ QTheme }) => QTheme.colors.gray[800]};
      cursor: pointer;
      border: 1px solid ${({ QTheme }) => QTheme.colors.gray[200]};
      border-radius: 4px;
      padding: 7px;

      &:hover {
        border: 1px solid ${({ QTheme }) => QTheme.colors.gray[400]};
      }

      .q-attachments-view--file-info {
        .q-attachments-view--file-info-main {
          font-size: 14px;
          overflow-wrap: anywhere;
          word-break: break-all;
          color: #2f80ed; //TODO: this needs to be a color from QTheme, this value is not there...
          .paperclip {
            display: inline;
            position: relative;
            top: 2px;
            margin-right: 5px;
          }
        }
      }
    }
  }
`;
