import { useCallback, useMemo, useState } from 'react';

import { QManualSortBy } from '@qualio/ui-components/lib/QAtoms/Table/DataTable';

export type UseCustomSortingReturn<T> = {
  sortedData: Array<T>;
  handleSortByCallback: QManualSortBy['sortByCallback'];
};

type SortableColumnFunctions<T> = Record<string, (firstT: T, secondT: T) => number>;

export function useCustomSorting<T>(
  defaultSortingColumn: { id: keyof SortableColumnFunctions<T>; desc: boolean },
  sortableColumnFunctions: SortableColumnFunctions<T>,
  data: Array<T>,
): UseCustomSortingReturn<T> {
  const [sortBy, setSortBy] = useState(defaultSortingColumn);

  const handleSortByCallback: QManualSortBy['sortByCallback'] = useCallback(
    (sortParams: Array<{ id: string; desc: boolean }>) => {
      sortParams.length <= 0 ? setSortBy(defaultSortingColumn) : setSortBy(sortParams[0]);
    },
    [defaultSortingColumn],
  );

  const sortedData = useMemo(() => {
    const sortingFunction = sortableColumnFunctions[sortBy.id];

    const sorted = [...data].sort(sortingFunction);

    if (sortBy.desc) {
      sorted.reverse();
    }

    return sorted;
  }, [sortBy, sortableColumnFunctions, data]);

  return { sortedData, handleSortByCallback };
}
