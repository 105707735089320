import React from 'react';

import { Product } from '@design-controls/types';
import { QBox, QCloseButton, QDivider, QHeading, QSelect, QSelectItem, QText } from '@qualio/ui-components';

type TestCaseResultsPresentationProps = {
  product: Product;
  onClose: () => void;
  onTypeChange: (item: QSelectItem | null) => void;
  typeOptions: Array<QSelectItem>;
  selectedType: QSelectItem | null;
};

const TestCaseResultsPresentation: React.FC<TestCaseResultsPresentationProps> = ({
  product,
  onClose,
  onTypeChange,
  typeOptions,
  selectedType,
  children,
}) => {
  return (
    <>
      <QBox display="flex" flexDirection="row" justifyContent="space-between" mb={1}>
        <QBox>
          <QHeading mb={1} size="lg" as="h1" data-cy="heading">
            Add test results
          </QHeading>
          <QText color={'gray.500'} data-cy="sub-heading" fontSize={14}>
            {product.name}
          </QText>
        </QBox>
        <QCloseButton data-cy="on-close-btn" onClick={onClose} />
      </QBox>
      <QDivider mb={5} />
      <QBox display="flex" flexDirection="row" justifyContent="space-between" mb={4}>
        <QBox width={200} data-cy="type-filter">
          <QSelect onChange={onTypeChange} options={typeOptions} value={selectedType?.value} />
        </QBox>
      </QBox>
      {children}
    </>
  );
};

export default TestCaseResultsPresentation;
