import { QTheme } from '@qualio/ui-components';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Table = styled.table<{ width: string }>`
  display: flex;
  flex-direction: column;
  width: ${({ width }) => width} !important;
  .row {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin: 8px 0;
  }
  .col {
    p {
      margin-bottom: 0 !important;
    }
  }
  .col:first-child {
    margin-left: 0;
  }
  .col-1 {
    width: 60%;
  }
  .col-2 {
    width: 30%;
  }
  .col-3 {
    width: 5%;
  }
`;

export const SliderContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 20%;
  background-color: #f8f9fa;
  border-radius: 4px;
  padding: 10px;
  margin: 40px 20px 50px 20px;
`;

export const Slider = styled.div<{ marks: number }>`
  width: 8px;
  background-image: ${({ marks }) => {
    return `linear-gradient(
          180deg,
          ${QTheme.colors.blue['500']}4d 0%,
          ${QTheme.colors.green['500']}4d ${marks > 4 ? '20' : '25'}%,
          ${QTheme.colors.yellow['400']}4d 50%,
          ${QTheme.colors.red['500']}4d 100%
        )`;
  }};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 6px;
  margin: 3px 0;
`;

export const Mark = styled.div<{ backgroundColor: string }>`
  width: 8px;
  height: 8px;
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-radius: 6px;
`;

export const Titles = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 10px;
  p {
    margin-bottom: 0 !important;
  }
`;
