import React from 'react';

import { isRequirementPolicy } from '@design-controls/types';
import { QFormControl, QSelect } from '@qualio/ui-components';
import { Controller } from 'react-hook-form';

import { useResourceLibraryQuickSearch } from '../../../../hooks/qri/useResourceLibraryQuickSearch';
import { useCurrentConfigs } from '../../../hooks/useCurrentConfigs';

export const ComponentFilter: React.FC = () => {
  const configs = useCurrentConfigs();
  const requirementPolicy = configs.find(isRequirementPolicy);
  const { isLoading, data } = useResourceLibraryQuickSearch(
    requirementPolicy?.component?.[0].resourceIdentifier as string,
  );

  return (
    <Controller
      name="component"
      render={({ field: { onChange, value } }) => (
        <QFormControl label="Component">
          <QSelect
            value={value}
            onChange={(selectedItem) => selectedItem && onChange(selectedItem.value)}
            options={(data?.results ?? []).map((item) => ({ value: item.identifiers.qri, label: item.displayLabel }))}
            isLoading={isLoading}
          />
        </QFormControl>
      )}
    />
  );
};
