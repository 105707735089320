import React from 'react';

import { QText } from '@qualio/ui-components';

export const DotSeparator: React.FC = () => {
  return (
    <QText fontSize="xs" color="gray.500">
      &nbsp; • &nbsp;
    </QText>
  );
};
