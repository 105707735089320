import React from 'react';

import { QButton, QMenuButton, QMenuItem } from '@qualio/ui-components';
import { useNavigate } from 'react-router-dom';

import { CreateDesignElementButtonProps } from './types';

export const CreateDesignElementButton: React.FC<CreateDesignElementButtonProps> = ({
  product,
  isTestCasesConfigured,
  requirements,
}) => {
  const navigate = useNavigate();

  if (isTestCasesConfigured) {
    return (
      <div data-cy="create-requirement-button">
        <QMenuButton buttonLabel="Create requirement" itemSize="md" variant="solid" iconName={'PlusCircle'}>
          {requirements && (
            <QMenuItem onClick={() => navigate(`/product/${product}/requirement/add`)} data-cy={`item-requirement`}>
              Requirements
            </QMenuItem>
          )}
          <QMenuItem onClick={() => navigate(`/product/${product}/testCase/add`)} data-cy={`item-testCase`}>
            Test Cases
          </QMenuItem>
        </QMenuButton>
      </div>
    );
  }

  return (
    <QButton
      rightIcon={'PlusCircle'}
      data-cy="create-requirement-button-native"
      onClick={() => navigate(`/product/${product}/requirement/add`)}
    >
      Create requirement
    </QButton>
  );
};
