import React from 'react';

import { QAlertBox, QFlex, QTag, QText } from '@qualio/ui-components';

import { PolicyIssueLabels, PolicyIssues } from '../../types/openIssue';

type GapAnalysisElementProps = { policyIssueRecords: Record<PolicyIssues, boolean> };
export const GapAnalysisElement: React.FC<GapAnalysisElementProps> = ({ policyIssueRecords }) => {
  const policiesDefaultOrder = Object.values(PolicyIssues);
  const policyIssues = policiesDefaultOrder.filter((policy) => policyIssueRecords[policy]);

  if (!policyIssues.length) {
    return null;
  }

  return (
    <QAlertBox status={'warning'} marginBottom={6}>
      <QFlex data-cy={'gap-analysis-alert'} justifyContent={'space-between'} width={'100%'}>
        <QFlex columnGap={3} alignItems={'center'}>
          {policyIssues.map((policyIssue) => {
            return (
              <QTag key={policyIssue} data-cy={`gap-analysis-issue-${policyIssue}`} variantColor={'invertedOrange'}>
                <QText>{PolicyIssueLabels[policyIssue]}</QText>
              </QTag>
            );
          })}
        </QFlex>
      </QFlex>
    </QAlertBox>
  );
};
