import { TestCaseWithResult } from '../../types/testCase';
import { TestCaseTrace } from '../../types/trace';
import { makeResponse } from './generic';
import { testCase1QualityItems } from './qualityItems';

export const testcaseTraceResponse = makeResponse<TestCaseTrace[]>([
  {
    ...testCase1QualityItems[0],
    version: 'v2',
  },
]);

export const makeTestTrace = (test: TestCaseWithResult): TestCaseTrace => {
  return {
    ...test,
    parent: [],
    children: [],
    sourcedItems: [],
    mitigates: [],
    version: 'v2',
  } as TestCaseTrace;
};
