import { useQuery } from 'react-query';

import changeControlApi from '../../api/changeControl';
import { useCompanyId } from '../../context/CurrentUserContext';
import { sortByCode } from '../../lib/sortFunctions';
import { ChangeControl } from '../../types/changeControl';

export type UseRetrieveChangeControlReturn = {
  isLoading: boolean;
  isError: boolean;
  changeControl: ChangeControl | undefined;
  refetch?: () => void;
};

export const useRetrieveChangeControl = (
  productId: string,
  changeControlId: string,
): UseRetrieveChangeControlReturn => {
  const companyId = useCompanyId();

  const { isFetching, data, isError, refetch } = useQuery({
    queryKey: `queryChangeControl-company-${companyId}-product-${productId}-changeControlId-${changeControlId}`,
    queryFn: () => {
      return changeControlApi
        .retrieveChangeControlRecord(companyId, productId, changeControlId)
        .then((changeControl) => {
          changeControl?.linkedDocuments.sort(sortByCode);
          changeControl?.qualityItems.sort(sortByCode);
          return changeControl;
        });
    },
    cacheTime: 0,
  });

  return { isLoading: isFetching, isError, changeControl: data, refetch };
};
