import { useQualityConfigs } from '../../../context/qualityConfigs';
import { QualityTypeConfigWithIntegrations } from '../../../context/qualityConfigTypes';
import { designControlNativeEnabled, requirementsTypes, testCaseTypes } from '../../../lib/designControlNativeEnabled';
import { isTestCase } from '../../../types/testCase';

type UseFilteredRequirementsTpe = {
  isRequirementConfigured: boolean;
  isTestCasesConfigured: boolean;
  configuredTestCases: QualityTypeConfigWithIntegrations[];
};

export const useRelatedDesignElementsForRequirements = (): UseFilteredRequirementsTpe => {
  const configTypes = useQualityConfigs();

  const isRequirementConfigured = designControlNativeEnabled(configTypes.configs).some((item) =>
    requirementsTypes.has(item),
  );

  const isTestCasesConfigured = designControlNativeEnabled(configTypes.configs).some((item) => testCaseTypes.has(item));

  const configuredTestCasesWithIntegrations = configTypes.configs.filter((config) => isTestCase(config.type));

  return {
    isRequirementConfigured,
    isTestCasesConfigured,
    configuredTestCases: configuredTestCasesWithIntegrations,
  };
};
