import React from 'react';

import { QEmptyState } from '@qualio/ui-components';

export const ReviewEmptyState: React.FC = () => (
  <div data-cy="review-list-empty">
    <QEmptyState
      title="Design reviews will appear here"
      subtitle="Start by creating design elements first. All new or modified design elements should be submitted through a design review."
    />
  </div>
);
