import React, { useCallback, useMemo, useState } from 'react';

import {
  QAlertBox,
  QButton,
  QDivider,
  QDrawer,
  QDrawerHeader,
  QDrawerProps,
  QFlex,
  QSpinner,
  QText,
  useToastProvider,
} from '@qualio/ui-components';
import { sanitize } from 'dompurify';
import { useParams } from 'react-router-dom';

import { useCompanyId } from '../../context/CurrentUserContext';
import { useQualityConfigs } from '../../context/qualityConfigs';
import { useRetrieveChangeControl } from '../../hooks/changeControl/useRetrieveChangeControl';
import { ProductParam } from '../../paramTypes';
import { DesignElementType } from '../../types/baseQualityItem';
import { ChangeControlStatus } from '../../types/changeControl';
import { isChangeControlStatusApproved } from '../../views_new';
import { documentsStatusLabelAndColor } from '../../views_new/Review/List/statusLabelAndColorTag';
import { ApproverDisplayElementList } from '../ApproverDisplayElement/ApproverDisplayElementList';
import { DetailsDrawerFooterPadding } from '../DesginElementDetailsDrawer/DetailsDrawerFooter/DetailsDrawerFooterPadding';
import DesignElementDisplay from '../DesignElementDisplay/DesignElementDisplay';
import { mapDesignElementToSelectedElementType } from '../DesignElementDisplay/lib/mapDesignElementToSelectedElementType';
import DesignElementGroup from '../DesignElementGroup/DesignElementGroup';
import { ReviewRequestModal } from '../ReviewRequestModal';
import changeControlDetailsDrawerStyles from './ChangeControlDetailsDrawer.module.less';
import { ChangeControlDetailsDocuments, ChangeControlDrawerHeader } from './components';
import { useRetrieveReleaseDocuments } from './hooks';
import { toReportDisplayName } from './lib';

export const ChangeControlDetailsDrawer: React.FC<
  QDrawerProps & {
    changeControlId: string;
    onUpdate: () => void;
    goBack: () => void;
    onDesignElementClick: (designElementId: string, designElementVersion?: string) => void;
  }
> = ({ isOpen, onClose, onUpdate, goBack, changeControlId, onDesignElementClick }) => {
  const companyId = useCompanyId();
  const { product: productId } = useParams<ProductParam>() as ProductParam;

  const {
    isLoading: isLoadingChangeControl,
    isError: isErrorRetrieveChangeControl,
    changeControl,
    refetch: refetchChangeControl,
  } = useRetrieveChangeControl(productId, changeControlId);
  const {
    hasQueryBeenCalled,
    isLoading: isLoadingReleaseDocument,
    isRefetching: isRefetchingReleaseDocument,
    isError: isErrorReleaseDocuments,
    releaseDocuments,
    releaseStatus,
    isReviewPackageAvailable,
    refetch: refetchReleaseDocuments,
  } = useRetrieveReleaseDocuments(companyId, productId, changeControl?.releaseId);
  const designElementPolicies = useQualityConfigs();

  const designElementPoliciesTypeToLabelMap = useMemo(() => {
    if (!designElementPolicies) {
      return {} as Record<DesignElementType, string>;
    }

    return designElementPolicies.configs.reduce((typeToLabelMap, currentConfig) => {
      typeToLabelMap[currentConfig.type] = currentConfig.label;
      return typeToLabelMap;
    }, {} as Record<DesignElementType, string>);
  }, [designElementPolicies]);

  const { showToast } = useToastProvider();
  const [reviewModalStatus, setReviewModalStatus] = useState<
    ChangeControlStatus.APPROVED | ChangeControlStatus.NOT_APPROVED | undefined
  >(undefined);

  const openLinkedDocument = useCallback((linkedDocumentId: string) => {
    return () => window.open(`/workspace/documents/${linkedDocumentId}`, '_blank', 'noopener,noreferrer');
  }, []);

  const closeModal = useCallback(() => {
    setReviewModalStatus(undefined);
  }, []);

  const refresh = useCallback(
    (reviewStatus: ChangeControlStatus.APPROVED | ChangeControlStatus.NOT_APPROVED) => {
      onUpdate();
      refetchChangeControl && refetchChangeControl();
      if (reviewStatus === ChangeControlStatus.APPROVED) {
        refetchReleaseDocuments && refetchReleaseDocuments();
      }
    },
    [onUpdate, refetchChangeControl, refetchReleaseDocuments],
  );

  const renamedReleasedDocuments = useMemo(() => {
    return releaseDocuments.map((document) => ({
      ...document,
      name: toReportDisplayName(document.name, changeControl),
    }));
  }, [releaseDocuments, changeControl]);

  if (isErrorRetrieveChangeControl) {
    showToast({
      title: `Oops. An error occurred`,
      description: 'Unable to retrieve change control details',
      status: 'error',
      duration: 5000,
    });
  }

  if (isErrorReleaseDocuments) {
    showToast({
      title: `Oops. An error occurred`,
      description: 'Unable to retrieve release documents',
      status: 'error',
      duration: 5000,
    });
  }

  const hasIssueWithRelease = !hasQueryBeenCalled && !!changeControl && changeControl.releaseId === undefined;
  const showAlert =
    isLoadingChangeControl ||
    !changeControl ||
    (isChangeControlStatusApproved(changeControl.status) && isLoadingReleaseDocument && hasIssueWithRelease);

  if (
    isLoadingChangeControl ||
    !changeControl ||
    (isChangeControlStatusApproved(changeControl.status) && isLoadingReleaseDocument && !hasIssueWithRelease)
  ) {
    return (
      <QDrawer isOpen={isOpen} onClose={onClose} size={'xl'} variant={'clickThrough'}>
        <QDrawerHeader>{null}</QDrawerHeader>
        <QFlex justifyContent="center" data-cy="change-control-details-loading">
          <QSpinner size="lg" data-cy="loading-spinner" />
        </QFlex>
      </QDrawer>
    );
  }

  return (
    <>
      <QDrawer isOpen={isOpen} onClose={onClose} size={'xl'} variant={'clickThrough'}>
        <QDrawerHeader>
          <ChangeControlDrawerHeader
            companyId={companyId}
            changeControl={changeControl}
            setReviewModalStatus={setReviewModalStatus}
            reviewPackageAvailable={isReviewPackageAvailable}
            onClose={onClose}
            goBack={goBack}
          />
        </QDrawerHeader>
        <QFlex data-cy={`details-drawer`} flexDirection={'column'}>
          {showAlert && (
            <QFlex justifyContent="center" data-cy="change-control-details-contact-support">
              <QAlertBox status={'error'}>
                <QFlex data-cy={`change-control-contact-support`} width={'100%'}>
                  <QText fontSize={13} weight="semibold">
                    Review documents not generated, please contact support to resolve.
                  </QText>
                </QFlex>
              </QAlertBox>
            </QFlex>
          )}
          <QDivider />
          <QFlex marginTop={5} marginBottom={3} rowGap={3} flexDirection={'column'}>
            <QText color="gray.700" fontSize={14} weight="semibold">
              Description
            </QText>
            {changeControl.description && (
              <QText
                fontSize={14}
                dangerouslySetInnerHTML={{ __html: sanitize(changeControl.description) }}
                className={`${changeControlDetailsDrawerStyles.stylizedDescription}`}
              />
            )}
          </QFlex>
          <QDivider />
          {changeControl.releaseId && changeControl.status === ChangeControlStatus.APPROVED && (
            <>
              <ChangeControlDetailsDocuments
                isRefetching={isRefetchingReleaseDocument}
                status={releaseStatus}
                documents={renamedReleasedDocuments}
                refresh={refetchReleaseDocuments}
              />
              <QDivider marginTop={2} />
            </>
          )}
          <QFlex flexDirection={'column'}>
            <DesignElementGroup
              elements={mapDesignElementToSelectedElementType(
                (changeControl?.qualityItems ?? []).filter((item) => item.type !== DesignElementType.TEST_LOG),
                designElementPoliciesTypeToLabelMap,
                changeControl.status === ChangeControlStatus.IN_PROGRESS,
              )}
              selectedElementComponent={DesignElementDisplay}
              elementCallBackFunction={onDesignElementClick}
            >
              <QDivider marginTop={4} />
            </DesignElementGroup>
          </QFlex>
          {changeControl.linkedDocuments.length > 0 && (
            <>
              <QFlex marginTop={5} marginBottom={3} rowGap={3} flexDirection={'column'}>
                <QText color="gray.700" fontSize={14} weight="semibold">
                  Linked Documents
                </QText>
                <QFlex direction={'column'} rowGap={1}>
                  {changeControl.linkedDocuments.map((document) => (
                    <DesignElementDisplay
                      key={document.id}
                      element={{
                        id: document.id,
                        label: document.title,
                        title: document.code,
                        showTitleInBadge: true,
                        selectedItemBody: document.title,
                        selectedItemLeftStatus: documentsStatusLabelAndColor(document.status_id),
                        item: document,
                      }}
                    >
                      <QButton
                        data-cy={`design-element-${document.id}-redirect`}
                        rightIcon={'ArrowRight'}
                        onClick={openLinkedDocument(document.id)}
                        variant="link"
                      >
                        &nbsp;
                      </QButton>
                    </DesignElementDisplay>
                  ))}
                </QFlex>
              </QFlex>
              <QDivider marginTop={4} />
            </>
          )}
          <QFlex marginTop={5} marginBottom={3} rowGap={3} flexDirection={'column'} data-cy={'approvers'}>
            <QText color="gray.700" fontSize={14} weight="semibold">
              Approvers
            </QText>
            <ApproverDisplayElementList approvers={changeControl.approvers ?? []} />
          </QFlex>
          <DetailsDrawerFooterPadding />
        </QFlex>
      </QDrawer>
      {reviewModalStatus && (
        <ReviewRequestModal
          isOpen={true}
          closeModal={closeModal}
          refresh={refresh}
          productId={productId}
          changeControl={changeControl}
          approvalModalStatus={reviewModalStatus}
        />
      )}
    </>
  );
};
