import { z } from "zod";

export const ResourceType = z.object({
  label: z.string(),
  resourceIdentifier: z.string(),
})

export const BasePolicy = z.object({
  codePrefix: z
    .string()
    .min(1, { message: 'Min. 1 character' })
    .max(5, { message: 'Max. 5 characters' })
    .refine((data) => data.match(/^[A-Z]+$/), 'Uppercase only'),
  codeStrategy: z.string(), // get values
  label: z.string().min(1).max(100),
  category: ResourceType.array().optional(),
});

export type BasePolicy = z.infer<typeof BasePolicy>;

const RiskLevel = z.object({
  acceptableLevels: z.string().array(),
  levelMapping: z.string().array().array(),
  levels: z.string().array(),
  probability: z.string().array(),
  severity: z.string().array(),
})

const FMEAAssessment = z.object({
  scaleMaxValue: z.number(),
  mitigationThreshold: z.number(),
});

export const RiskPolicy = BasePolicy.extend({
  type: z.literal("risk"),
  assessment: z.object({
    iso: RiskLevel,
    fmea: FMEAAssessment.optional(),
  })
})

export type RiskPolicy = z.infer<typeof RiskPolicy>;

export const isRiskPolicy = (policy: Policy): policy is RiskPolicy => {
  return policy.type === 'risk';
}

export const RequirementLevel1 = BasePolicy.extend({
  type: z.literal("req1"),
  component: ResourceType.array().optional(),
});
export type RequirementLevel1 = z.infer<typeof RequirementLevel1>;

export const RequirementLevel2 = BasePolicy.extend({
  type: z.literal("req2"),
  component: ResourceType.array().optional(),
});
export type RequirementLevel2 = z.infer<typeof RequirementLevel2>;

export const RequirementLevel3 = BasePolicy.extend({
  type: z.literal("req3"),
  component: ResourceType.array().optional(),
});
export type RequirementLevel3 = z.infer<typeof RequirementLevel3>;

export type RequirementPolicies = RequirementLevel1 | RequirementLevel2 | RequirementLevel3;

export const isRequirementPolicy = (policy: Policy): policy is RequirementPolicies => {
  return policy.type.startsWith("req");
}

export const TestCaseLevel1 = BasePolicy.extend({
  type: z.literal("testCase1"),
})
export type TestCaseLevel1 = z.infer<typeof TestCaseLevel1>;

export const TestCaseLevel2 = BasePolicy.extend({
  type: z.literal("testCase2"),
});
export type TestCaseLevel2 = z.infer<typeof TestCaseLevel2>;

export const TestCaseLevel3 = BasePolicy.extend({
  type: z.literal("testCase3"),
})
export type TestCaseLevel3 = z.infer<typeof TestCaseLevel3>;

export const TestCaseLevel = BasePolicy.extend({
  type: z.literal("testCase"),
});
export type TestCaseLevel = z.infer<typeof TestCaseLevel>;

export type TestCasePolicies = TestCaseLevel1 | TestCaseLevel2 | TestCaseLevel3 | TestCaseLevel;

export const isTestCasePolicy = (policy: Policy): policy is TestCasePolicies => {
  return policy.type.startsWith("testCase");
}

export const Policy = z.discriminatedUnion("type", [
  RequirementLevel1,
  RequirementLevel2,
  RequirementLevel3,
  BasePolicy.extend({
    type: z.literal("testCase1"),
  }),
  BasePolicy.extend({
    type: z.literal("testCase2"),
  }),
  BasePolicy.extend({
    type: z.literal("testCase3"),
  }),
  BasePolicy.extend({
    type: z.literal("testCase"),
  }),
  BasePolicy.extend({
    type: z.literal("testLog"),
  }),
  RiskPolicy,
]);

export type Policy = z.input<typeof Policy>;
