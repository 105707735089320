import { DesignElementType, QualityItemStatus } from '../../types/baseQualityItem';
import { Payload } from '../../types/payload';
import { FMEARisk, Risk, RiskSubType } from '../../types/risk';
import { makeResponse } from './generic';

export const FMEARisks: FMEARisk[] = [
  {
    code: 'RK-1',
    failureCauses: 'Damaged Isolation',
    created: '2021-04-14T14:16:39.041Z',
    source: 'product-development',
    title: 'Updated Sample FMEA Risk',
    type: DesignElementType.RISK,
    statusLabel: 'Validated',
    url: 'http://example.com',
    failureMode: 'Electricity',
    evaluationMethod: 'Manual Inspection',
    subType: RiskSubType.FMEA,
    preMitigation: {
      severity: 4,
      occurrence: 3,
      detectability: 3,
    },
    id: '3c9fbd8c-dca5-46b2-ab0a-a4fe11a10780',
    updated: '2021-04-14T14:17:01.638Z',
    failureModeEffects: 'Short-Circuit',
    changeControlStatus: 'unstaged',
    itemStatus: QualityItemStatus.NEW,
  },
  {
    code: 'RK-7',
    failureCauses: 'Cause',
    created: '2021-04-12T16:28:12.624Z',
    source: 'product-development',
    title: 'FMEA test',
    type: DesignElementType.RISK,
    statusLabel: 'Assesment',
    url: 'http://example.com',
    failureMode: 'Failure Mode Active',
    evaluationMethod: 'Control',
    subType: RiskSubType.FMEA,
    preMitigation: {
      severity: 1,
      occurrence: 1,
      detectability: 1,
    },
    id: '5a995ef1-f3d7-4f6e-b520-6191f4425c14',
    updated: '2021-04-12T16:28:12.624Z',
    failureModeEffects: 'Effect',
    changeControlStatus: 'unstaged',
    itemStatus: QualityItemStatus.NEW,
  },
  {
    code: 'RK-8',
    failureCauses: 'Sample Cause',
    created: '2021-04-14T09:16:03.948Z',
    postMitigation: {
      severity: 10,
      occurrence: 2,
      detectability: 2,
    },
    source: 'product-development',
    title: 'Threshold exceeded',
    riskControl: {
      mitigation: 'Sample Mitigation',
    },
    type: DesignElementType.RISK,
    statusLabel: 'Assesment',
    url: 'http://example.com',
    failureMode: 'Threshold exceeded',
    evaluationMethod: 'Sample Control',
    subType: RiskSubType.FMEA,
    preMitigation: {
      severity: 10,
      occurrence: 10,
      detectability: 10,
    },
    id: '731a50a9-3626-4023-8cb3-b69f095e74fd',
    updated: '2021-04-14T09:16:03.948Z',
    failureModeEffects: 'Sample Effect',
    changeControlStatus: 'unstaged',
    itemStatus: QualityItemStatus.NEW,
  },
  {
    code: 'RK-11',
    failureCauses: 'Change Control Test',
    created: '2021-04-14T14:14:39.433Z',
    postMitigation: {
      severity: 7,
      occurrence: 4,
      detectability: 3,
    },
    source: 'product-development',
    title: 'Change Control Test',
    riskControl: {
      mitigation: 'Change Control Test',
    },
    type: DesignElementType.RISK,
    statusLabel: 'Mitigation',
    url: 'http://example.com',
    failureMode: 'Change Control Test',
    evaluationMethod: 'Change Control Test',
    subType: RiskSubType.FMEA,
    preMitigation: {
      severity: 7,
      occurrence: 8,
      detectability: 8,
    },
    id: '90a151d2-ea8c-4d17-a1bb-c9e2a2008ac9',
    updated: '2021-04-14T14:14:39.433Z',
    failureModeEffects: 'Change Control Test',
    changeControlStatus: 'unstaged',
    itemStatus: QualityItemStatus.NEW,
  },
];

export const ISORisks: Risk[] = [
  {
    id: '63cbaf7e-440a-4c7f-ac16-8069d8d0ca30',
    created: '2021-05-04T23:26:41.838Z',
    updated: '2021-05-04T23:26:41.838Z',
    statusLabel: 'Some Label',
    source: 'product-development',
    title: 'Cillum enim non sit dolore aliquip cupidatat ea.',
    url: 'http://example.com',
    type: DesignElementType.RISK,
    hazard: 'some hazard',
    foreseeableUseMisuse: '',
    hazardSituation: 'some bad situation',
    harm: 'some harm',
    preMitigation: {
      probability: 'Improbable',
      severity: 'Minor',
    },
    subType: RiskSubType.ISO,
    code: 'RSK-1',
    changeControlStatus: 'unstaged',
    itemStatus: QualityItemStatus.NEW,
  },
  {
    id: 'test',
    created: '2021-05-04T23:26:41.838Z',
    updated: '2021-05-04T23:26:41.838Z',
    statusLabel: 'Some Label',
    source: 'product-development',
    title: 'Test enim non sit dolore aliquip cupidatat ea.',
    url: 'http://example.com',
    type: DesignElementType.RISK,
    hazard: 'some hazard',
    foreseeableUseMisuse: '',
    hazardSituation: 'some bad situation',
    harm: 'some harm',
    preMitigation: {
      probability: '1',
      severity: '2',
    },
    subType: RiskSubType.ISO,
    code: 'RSK-2',
    changeControlStatus: 'unstaged',
    itemStatus: QualityItemStatus.NEW,
  },
  {
    id: 'test-10',
    created: '2021-05-04T23:26:41.838Z',
    updated: '2021-05-04T23:26:41.838Z',
    statusLabel: 'Some Label',
    source: 'product-development',
    title: 'Test enim non sit dolore aliquip cupidatat ea.',
    url: 'http://example.com',
    type: DesignElementType.RISK,
    hazard: 'some hazard',
    foreseeableUseMisuse: '',
    hazardSituation: 'some bad situation',
    harm: 'some harm',
    preMitigation: {
      probability: '1',
      severity: '2',
    },
    subType: RiskSubType.ISO,
    code: 'RSK-10',
    changeControlStatus: 'unstaged',
    itemStatus: QualityItemStatus.NEW,
  },
  {
    changeControlStatus: 'unstaged',
    itemStatus: QualityItemStatus.NEW,
    code: 'RSK-3',
    harm: 'GDPR issues',
    created: '2020-12-22T04:51:04.577Z',
    postMitigation: {
      severity: 'Serious',
      probability: 'Improbable',
      riskLevel: 'Low',
    },
    hazardSituation: 'patient receives wrong scan',
    foreseeableUseMisuse: '',
    source: 'product-development',
    title: 'Scan result data retrieval',
    riskControl: {
      mitigation: 'The Login security requirement verifies this is not an issue',
    },
    type: DesignElementType.RISK,
    statusLabel: 'Mitigation',
    hazard: 'unauthorised access to data',
    preMitigation: {
      severity: 'Serious',
      probability: 'Occasional',
      riskLevel: 'Medium',
    },
    id: '4fc99d19-efe2-473c-98fc-279940db3b13',
    updated: '2021-06-29T04:38:17.213Z',
    codeTitle: 'RK-3 Scan result data retrieval',
    url: '',
    subType: RiskSubType.ISO,
  },
  {
    changeControlStatus: 'approved',
    itemStatus: QualityItemStatus.NEW,
    code: 'RSK-11',
    harm: 'Some issues',
    created: '2020-12-22T04:51:04.577Z',
    postMitigation: {
      severity: 'Serious',
      probability: 'Improbable',
      riskLevel: 'Low',
    },
    hazardSituation: 'patient receives wrong scan',
    foreseeableUseMisuse: '',
    source: 'product-development',
    title: 'Scan result data retrieval',
    riskControl: {
      mitigation: 'The Login security requirement verifies this is not an issue',
    },
    type: DesignElementType.RISK,
    statusLabel: 'Mitigation',
    hazard: 'unauthorised access to data',
    preMitigation: {
      severity: 'Serious',
      probability: 'Occasional',
      riskLevel: 'Medium',
    },
    id: '4fc99d19-efe2-473c-98fc-279940db3b13',
    updated: '2021-06-29T04:38:17.213Z',
    codeTitle: 'RK-3 Scan result data retrieval',
    url: '',
    subType: RiskSubType.ISO,
  },
];

export const FMEARisksResponse: Payload<FMEARisk[]> = makeResponse(FMEARisks);
export const ISORisksResponse: Payload<Risk[]> = makeResponse(ISORisks);
