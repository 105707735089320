import React, { useCallback, useMemo } from 'react';

import { deprecated } from '@qualio/ui-components';

import { Loading } from '../Loading';

export type PageTableProps = {
  isLoading: boolean;
  data?: Array<deprecated.QDataRow>;
  columns: Array<deprecated.QDataColumn>;
  onRowClick: (row: deprecated.QDataRow) => void;
  emptyStateComponent: React.ReactElement;
  pageSize?: number;
  defaultSelectedRow?: string;
  manualSortBy?: deprecated.QDataTableProps['manualSortBy'];
};

export const PageTable: React.FC<PageTableProps> = ({
  isLoading,
  data,
  columns,
  onRowClick,
  emptyStateComponent: EmptyStateComponent,
  pageSize,
  defaultSelectedRow,
  manualSortBy,
}) => {
  const onRowClickHandler = useCallback(
    (dataRow: deprecated.QDataRow) => {
      if (defaultSelectedRow === dataRow['id']) {
        return;
      }

      onRowClick(dataRow);
    },
    [onRowClick, defaultSelectedRow],
  );

  const parsedRows = useMemo(() => {
    if (!data) {
      return [];
    }

    return [...data].map((item) => ({ ...item, isSelected: item.id === defaultSelectedRow }));
  }, [data, defaultSelectedRow]);

  if (isLoading) {
    return <Loading />;
  }

  if (!parsedRows || !parsedRows.length) {
    return EmptyStateComponent;
  }

  return (
    <deprecated.QDataTable
      columns={columns}
      data={parsedRows}
      onRowClick={onRowClickHandler}
      pageSize={pageSize}
      hasPageSizeOptions={pageSize !== undefined}
      manualSortBy={manualSortBy}
    />
  );
};
