import React, { useMemo } from 'react';

import { zodResolver } from '@hookform/resolvers/zod';
import { QAlert, QBox } from '@qualio/ui-components';
import { FormProvider, useForm } from 'react-hook-form';

import {
  RequirementAndTestCaseTupleType,
  TestCaseConfigurationCreateEditMode,
} from '../../../components/TestCaseConfigurations/TestCaseConfigurations.types';
import TestCaseConfigurationsContainer from '../../../components/TestCaseConfigurations/TestCaseConfigurationsContainer';
import { Prefix } from '../../../context/qualityConfigTypes';
import { DesignElementType } from '../../../types/baseQualityItem';
import { RequestValidationError } from '../../../types/requestValidationError';
import { RequirementConfigurationType } from '../../../types/requirementConfiguration';
import {
  TestCaseConfigurationType,
  testCaseTypeConfigurationSchemaProvider,
} from '../../../types/testCaseConfiguration';
import { CreateProductContainer } from './CreateProductContainer';

type TestCaseProps = {
  prevRef: React.RefObject<any>;
  nextRef: React.RefObject<any>;
  data: TestCaseConfigurationType;
  onNextHandler: (item: TestCaseConfigurationType) => void;
  onPrevHandler: (item: TestCaseConfigurationType) => void;
  requirements: RequirementConfigurationType['items'];
  usedPrefixes: Array<Prefix>;
  validationErrors: Array<RequestValidationError>;
};

export const TestCase: React.FC<TestCaseProps> = ({
  prevRef,
  nextRef,
  data,
  requirements,
  onNextHandler,
  onPrevHandler,
  usedPrefixes,
  validationErrors,
}) => {
  const methods = useForm<TestCaseConfigurationType>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: data,
    resolver: zodResolver(testCaseTypeConfigurationSchemaProvider(usedPrefixes, [])),
  });

  const requirementAndTestCaseTuples = useMemo(() => {
    const list: RequirementAndTestCaseTupleType[] = [];

    const { req1, req2, req3 } = requirements;

    if (req1) {
      list.push({ requirement: req1, testCase: DesignElementType.TEST_CASE_1 });
    }

    if (req2) {
      list.push({ requirement: req2, testCase: DesignElementType.TEST_CASE_2 });
    }

    if (req3) {
      list.push({ requirement: req3, testCase: DesignElementType.TEST_CASE_3 });
    }

    return list;
  }, [requirements]);

  const {
    formState: { errors },
  } = methods;

  return (
    <FormProvider {...methods}>
      {Object.keys(errors).length ? (
        <QBox mb={'16px'}>
          <QAlert description={'There were some errors'} data-cy="error-alert" status={'error'} />
        </QBox>
      ) : null}
      <CreateProductContainer>
        <TestCaseConfigurationsContainer
          validationErrors={validationErrors}
          requirementAndTestCaseTuples={requirementAndTestCaseTuples}
          mode={TestCaseConfigurationCreateEditMode.CREATE_MODE}
        />
      </CreateProductContainer>
      <button style={{ display: 'none' }} ref={prevRef} onClick={methods.handleSubmit(onPrevHandler)} />
      <button style={{ display: 'none' }} ref={nextRef} onClick={methods.handleSubmit(onNextHandler)} />
    </FormProvider>
  );
};
