import React from 'react';

import { QCheckbox } from '@qualio/ui-components';
import { Controller } from 'react-hook-form';

export const ShowOnlyOpen: React.FC = () => {
  return (
    <Controller
      name="show_only_open"
      render={({ field: { value, onChange } }) => (
        <QCheckbox isChecked={value} onChange={onChange}>
          Show open issues only
        </QCheckbox>
      )}
    />
  );
};
