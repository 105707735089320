import React from 'react';

import { QFlex, QFlexProps } from '@qualio/ui-components';

type DetailsDrawerHeaderActionProps = QFlexProps;

export const DetailsDrawerHeaderAction: React.FC<DetailsDrawerHeaderActionProps> = ({ children, ...props }) => {
  return (
    <QFlex data-cy={'detail-drawer-header-action'} {...props}>
      {children}
    </QFlex>
  );
};

DetailsDrawerHeaderAction.displayName = DetailsDrawerHeaderAction.name;
