import React from 'react';

import { CloseOutlined } from '@ant-design/icons';

import { messageTypeCls, messageTypeIcon } from './helpers';
import styles from './Messages.module.less';
import { MessageWithId } from './types';

type Props = {
  message: MessageWithId;
  onDismiss?(messageId: MessageWithId['id']): void;
};

export const FullMessage: React.FC<Props> = ({ message, onDismiss }) => {
  return (
    <div
      className={`${styles.message} ${styles.fullMessage} ${messageTypeCls(message.type)}`}
      data-cy={`issue-${message.id}`}
    >
      <aside className={styles.icon}>{messageTypeIcon(message.type)}</aside>
      <main className={styles.body}>
        {message.title && <h5 className={styles.title}>{message.title}</h5>}
        <div className={styles.content}>{message.text}</div>
      </main>
      {message.actions && (
        <aside className={styles.actions}>
          {message.actions({
            messageId: message.id,
            ctaProps: {
              className: styles.actionLink,
              type: 'link',
            },
          })}
        </aside>
      )}
      {onDismiss && (
        <aside className={styles.dismissMessage} onClick={() => onDismiss(message.id)}>
          <CloseOutlined />
        </aside>
      )}
    </div>
  );
};
