import { QTheme } from '@qualio/ui-components';

export const riskLevelBlue = `${QTheme.colors.blue['500']}`;
export const riskLevelGreen = `${QTheme.colors.green['500']}`;
export const riskLevelYellow = `${QTheme.colors.yellow['400']}`;
export const riskLevelOrange = `${QTheme.colors.orange['500']}`;
export const riskLevelRed = `${QTheme.colors.red['500']}`;

export const riskLevelColorsQTheme: Record<number, string[]> = {
  2: [riskLevelBlue, riskLevelRed],
  3: [riskLevelBlue, riskLevelYellow, riskLevelRed],
  4: [riskLevelBlue, riskLevelGreen, riskLevelYellow, riskLevelRed],
  5: [riskLevelBlue, riskLevelGreen, riskLevelYellow, riskLevelOrange, riskLevelRed],
};

export const riskLevelColorsQTag: Record<number, string[]> = {
  2: ['blue', 'red'],
  3: ['blue', 'yellow', 'red'],
  4: ['blue', 'green', 'yellow', 'red'],
  5: ['blue', 'green', 'yellow', 'orange', 'red'],
};
