import * as z from 'zod';

import { Prefix } from '../context/qualityConfigTypes';
import { prefixDoesNotExist } from '../lib/prefixDoesNotExist';
import { BaseConfiguration } from './baseConfiguration';
import { DesignElementType } from './baseQualityItem';

const TESTCASE_UNIQUE_ERR_MSG = 'Prefix must be unique';
const MIN_TESTCASE_ERR_MSG = 'Minimum one test case needs to be configured';

export const TestCaseConfiguration = z.object({
  testCases: z.object({
    testCase1: BaseConfiguration.optional(),
    testCase2: BaseConfiguration.optional(),
    testCase3: BaseConfiguration.optional(),
  }),
});

export const isTestCaseConfigurationInvalid = (data: TestCaseConfigurationType): boolean => {
  return (
    data.testCases === undefined ||
    (data.testCases.testCase1 === undefined &&
      data.testCases.testCase2 === undefined &&
      data.testCases.testCase3 === undefined)
  );
};

export const testCaseTypeConfigurationSchemaProvider = (
  usedPrefixes: Prefix[],
  archivePrefixes: Array<string>,
): any => {
  const listOfUsedPrefixes = usedPrefixes.filter((prefix) => !prefix.type.startsWith('testCase'));

  return TestCaseConfiguration.refine(
    (data: any) => {
      return !isTestCaseConfigurationInvalid(data);
    },
    {
      message: MIN_TESTCASE_ERR_MSG,
      path: ['testCases'],
    },
  )
    .refine(
      (data: any) => {
        const prefixNotUsedOnForm =
          !data.testCases.testCase1?.prefix ||
          ![data.testCases.testCase2?.prefix, data.testCases.testCase3?.prefix].includes(
            data.testCases.testCase1?.prefix,
          );
        return (
          prefixNotUsedOnForm &&
          prefixDoesNotExist(
            data.testCases.testCase1?.prefix,
            DesignElementType.TEST_CASE_1,
            listOfUsedPrefixes,
            archivePrefixes,
          )
        );
      },
      {
        message: TESTCASE_UNIQUE_ERR_MSG,
        path: ['testCases', DesignElementType.TEST_CASE_1, 'prefix'],
      },
    )
    .refine(
      (data: any) => {
        const prefixNotUsedOnForm =
          !data.testCases.testCase2?.prefix ||
          ![data.testCases.testCase1?.prefix, data.testCases.testCase3?.prefix].includes(
            data.testCases.testCase2?.prefix,
          );
        return (
          prefixNotUsedOnForm &&
          prefixDoesNotExist(
            data.testCases.testCase2?.prefix,
            DesignElementType.TEST_CASE_2,
            listOfUsedPrefixes,
            archivePrefixes,
          )
        );
      },
      {
        message: TESTCASE_UNIQUE_ERR_MSG,
        path: ['testCases', DesignElementType.TEST_CASE_2, 'prefix'],
      },
    )
    .refine(
      (data: any) => {
        const prefixNotUsedOnForm =
          !data.testCases.testCase3?.prefix ||
          ![data.testCases.testCase2?.prefix, data.testCases.testCase1?.prefix].includes(
            data.testCases.testCase3?.prefix,
          );
        return (
          prefixNotUsedOnForm &&
          prefixDoesNotExist(
            data.testCases.testCase3?.prefix,
            DesignElementType.TEST_CASE_3,
            listOfUsedPrefixes,
            archivePrefixes,
          )
        );
      },
      {
        message: TESTCASE_UNIQUE_ERR_MSG,
        path: ['testCases', DesignElementType.TEST_CASE_3, 'prefix'],
      },
    );
};

export type TestCaseConfigurationType = z.infer<typeof TestCaseConfiguration>;
