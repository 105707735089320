import React, { useCallback, useMemo } from 'react';

import {
  QBodyLayout,
  QButton,
  QButtonGroup,
  QHeader,
  QMenuButton,
  QMenuItem,
  QStack,
  QTabs,
  QTitle,
} from '@qualio/ui-components';
import { Outlet, useLocation, useNavigate } from 'react-router';

import { useCurrentProduct } from '../hooks/useCurrentProduct';

const TABS: [string, string, string][] = [
  ['requirement', 'requirement', 'Requirements'],
  ['testCase', 'test-case', 'Tests'],
  ['risk', 'risk', 'Risks'],
  ['document', 'document', 'Documents'],
  ['reviews', 'change-control', 'Reviews'],
];

export const ListLayout: React.FC = ({ children }) => {
  const product = useCurrentProduct();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const onItemChange = useCallback(
    (index: number) => {
      const item = TABS.at(index);
      if (!item) {
        return;
      }

      navigate(`/product/${product.id}/${item[1]}`);
    },
    [navigate, product],
  );

  const activeTabId = useMemo(() => {
    const item = TABS.find(([, routePath]) => pathname.includes(routePath));

    if (!item) {
      return 'requirement';
    }

    return item[0];
  }, [pathname]);

  return (
    <QBodyLayout.Default>
      <>
        <QButton variant="link" leftIcon="ArrowLeft" onClick={() => navigate('/product')}>
          Back
        </QButton>
        <QHeader showDivider={false}>
          <QTitle>{product.name}</QTitle>
          <QButtonGroup>
            <QMenuButton buttonLabel="Create new" variant="solid">
              <QMenuItem>Requirement</QMenuItem>
              <QMenuItem>Test case</QMenuItem>
              <QMenuItem>Risk</QMenuItem>
            </QMenuButton>
            <QMenuButton iconName="MoreVertical" variant="icon">
              <QMenuItem>Configure product</QMenuItem>
              <QMenuItem color="red.500">Delete this product</QMenuItem>
            </QMenuButton>
          </QButtonGroup>
        </QHeader>
      </>
      <QStack gap={4}>
        <QTabs activeTabId={activeTabId} onChange={onItemChange} tabs={TABS.map(([id, , title]) => ({ id, title }))} />
        <Outlet />
      </QStack>
    </QBodyLayout.Default>
  );
};
