import { z } from "zod";

export enum PolicyIssueEnum {
  REQUIRES_PARENT = 'REQUIRES_PARENT',
  UNMITIGATED = 'UNMITIGATED',
  REQUIRES_CHILD = 'REQUIRES_CHILD',
  UNTESTED_REQUIREMENT = 'UNTESTED_REQUIREMENT',
  FAILING_TEST = 'FAILING_TEST',
  OUTDATED_TESTRESULT = 'OUTDATED_TESTRESULT',
}

export const PolicyIssue = z.nativeEnum(PolicyIssueEnum);
export type PolicyIssue = z.infer<typeof PolicyIssue>;
