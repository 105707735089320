import React, { useState } from 'react';

import { useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

import configApi from '../../../api/config';
import { Loading } from '../../../components_new/Loading';
import { useCompanyId } from '../../../context/CurrentUserContext';
import { useProduct } from '../../../context/product';
import { useResetQualityConfigs } from '../../../context/qualityConfigs';
import { filterValidationErrors, scrollWindowToTop } from '../../../lib/formValidationUtilities';
import { INITIAL_RISK_CONFIGURATION_VALUES } from '../../../lib/riskConfiguration';
import { DesignElementType } from '../../../types/baseQualityItem';
import { RiskQualityTypeConfig } from '../../../types/qualityTypeConfig';
import { RequestValidationError } from '../../../types/requestValidationError';
import {
  mapRiskConfigurationTORiskQualityType,
  mapRiskQualityTypeToRiskConfiguration,
  RiskConfigurationType,
} from '../../../types/riskConfiguration';
import RiskSettingsForm from './Form';

const RiskConfigurationView: React.FC = () => {
  const company = useCompanyId();
  const { id: product } = useProduct();
  const navigate = useNavigate();
  const resetConfiguration = useResetQualityConfigs();
  const [validationErrors, setValidationErrors] = useState<Array<RequestValidationError>>([]);

  const riskConfig = useQuery<RiskQualityTypeConfig>(
    'fetchRiskConfigForm',
    () => configApi.retrieveConfig<RiskQualityTypeConfig>(company, product, DesignElementType.RISK),
    { cacheTime: 0 },
  );

  const archivePrefixes = useQuery<Array<string>>(
    'fetchArchivePrefixes',
    () => configApi.retrieveArchivePrefixes(company, product),
    { cacheTime: 0 },
  );

  const { mutate: updateConfig, isLoading } = useMutation(
    ({ payload }: { payload: RiskQualityTypeConfig }) =>
      riskConfig.data
        ? configApi.updateConfig(company, product, DesignElementType.RISK, payload)
        : configApi.createConfig(company, product, DesignElementType.RISK, payload),
    {
      onSuccess: () => {
        resetConfiguration();
        navigate(`/product/${product}`);
      },
      onError: (error: any) => {
        setValidationErrors(filterValidationErrors([error]));
        scrollWindowToTop();
      },
    },
  );

  const handleSubmit = async (data: RiskConfigurationType) => {
    updateConfig({ payload: mapRiskConfigurationTORiskQualityType(data) });
    return false;
  };

  if (riskConfig.isLoading) {
    return <Loading />;
  }

  return (
    <RiskSettingsForm
      initialValue={
        riskConfig.data ? mapRiskQualityTypeToRiskConfiguration(riskConfig.data) : INITIAL_RISK_CONFIGURATION_VALUES
      }
      archivePrefixes={archivePrefixes.data ?? []}
      onSubmit={handleSubmit}
      submitIsLoading={isLoading}
      validationErrors={validationErrors}
    />
  );
};

export default RiskConfigurationView;
