import React, { useCallback } from 'react';

import { DataProvider, Filtering, Pagination, Sorting, useCurrentUser } from '@qualio/ui-components';

import { MedtechAPI } from '../../api/medtech/medtech';
import type { Company } from '../../types/currentUser';
import { DOCUMENT_STATUSES, Tag } from '../../types/document';
import { FILTER_DEFINITIONS } from './filtering';

export type DocumentsDataProviderProps = {
  allTags: readonly Tag[];
  productTagIds?: number[];
};
export const DocumentsDataProvider: React.FC<DocumentsDataProviderProps> = ({ allTags, productTagIds, children }) => {
  const { companyId } = useCurrentUser();

  const LimitOffsetPagination = Pagination.useLimitOffset();

  const fetchDocuments = useCallback(
    async ({
      queryKey: [, companyId],
      pageParam: { searchTerm, pagination, sorting },
    }: DataProvider.QueryFunctionContext<DocumentsQueryKeyBase, DataProvider.PageParams>) => {
      // If not tags, bail early (otherwise we'll start fetching ALL docs).
      if (productTagIds && productTagIds.length === 0) {
        return Promise.resolve({ data: [], itemCount: 0 });
      }

      const limit = pagination?.pageSize ?? 15;

      const response = await MedtechAPI.retrieveDocuments(companyId, {
        tagIds: productTagIds,
        searchTerm: searchTerm ?? undefined,
        limit,
        offset: (pagination?.pageIndex ?? 0) * limit,
        statuses: DOCUMENT_STATUSES,
        sort: sorting ?? undefined,
      });

      return {
        data: response.documents.map((d) => ({
          ...d,
          tag_ids: d.tag_ids.map((tagId) => allTags.find((tag) => tag.id === tagId)?.name),
        })),
        itemCount: response.total,
      };
    },
    [allTags, productTagIds],
  );

  return (
    <LimitOffsetPagination.Provider>
      <Sorting.DefaultSortingProvider sortByKey="order_by">
        <Filtering.FilterProvider definitions={FILTER_DEFINITIONS} searchTermKey="search">
          <DataProvider.Remote
            queryKey={['documents', companyId]}
            queryParamKeys={QUERY_PARAM_KEYS}
            queryFn={fetchDocuments}
          >
            {children}
          </DataProvider.Remote>
        </Filtering.FilterProvider>
      </Sorting.DefaultSortingProvider>
    </LimitOffsetPagination.Provider>
  );
};

type DocumentsQueryKeyBase = ['documents', Company['id']];

const QUERY_PARAM_KEYS = {
  searchTerm: 'query',
  pageIndex: 'offset',
  pageSize: 'limit',
  sortBy: 'order_by',
} as const;
