import React from 'react';

import { getChildrenOfType, QFlex, QFlexProps } from '@qualio/ui-components';

import { DisplayElementBody, DisplayElementHeader } from './components';

type DisplayElementProps = QFlexProps;

export const DisplayElement: React.FC<DisplayElementProps> = ({ children, ...props }) => {
  const header = getChildrenOfType(children, DisplayElementHeader.name);
  const body = getChildrenOfType(children, DisplayElementBody.name);

  return (
    <QFlex
      data-cy={'display-element'}
      padding="10px"
      mt="5px"
      borderRadius="4px"
      border="1px"
      borderColor="gray.200"
      alignItems="center"
      {...props}
    >
      {header}
      {body}
    </QFlex>
  );
};
