import React, { useCallback, useState } from 'react';

import { useToastProvider } from '@qualio/ui-components';
import { useNavigate, useParams } from 'react-router-dom';

import changeControlApi from '../../../api/changeControl';
import { DeleteConfirmationModal } from '../../../components/DeleteDesignElementModal/DeleteConfirmationModal';
import { DrawerProvider } from '../../../components_new/DesginElementDetailsDrawer/context/DrawerProvider';
import { FormHeader } from '../../../components_new/FormHeader';
import { Loading } from '../../../components_new/Loading';
import { useCompanyId } from '../../../context/CurrentUserContext';
import { useProduct } from '../../../context/product';
import { useQualityUsers } from '../../../hooks/qualio/useQualityUsers';
import { ChangeControlParam } from '../../../paramTypes';
import {
  ChangeControl,
  ChangeControlPayloadType,
  ChangeControlQualityItemPayloadType,
} from '../../../types/changeControl';
import { ReviewForm } from './reviewForm';
import { useReviewData } from './useReviewData';

export const EditReviewForm: React.FC = () => {
  const navigate = useNavigate();
  const product = useProduct();
  const company = useCompanyId();
  const { showToast } = useToastProvider();
  const { changeControlId } = useParams() as ChangeControlParam;
  const { isError, loading, qualityItems, review, qualityConfigs } = useReviewData(
    company,
    product.id,
    changeControlId,
  );
  const { qualityUsers: approvers, isLoading: isApproverLoading } = useQualityUsers(company);

  const [reviewToBeDeleted, setReviewToBeDeleted] = useState<ChangeControl | undefined>(undefined);
  const [isDeleting, setIsDeleting] = useState(false);

  const goToReviewList = useCallback(() => navigate(`/product/${product.id}/change-control`), [navigate, product]);

  const onSubmitForm = useCallback(
    async (reviewPayload: ChangeControlPayloadType) => {
      return changeControlApi
        .saveReview(company, product.id, changeControlId, reviewPayload)
        .then(goToReviewList)
        .catch((e) => {
          showToast({
            id: 'submit-error',
            title: "We couldn't save your review.",
            description: 'Please try again later.',
            status: 'error',
            duration: 5000,
          });
        });
    },
    [company, product, changeControlId, goToReviewList, showToast],
  );

  const onDeleteReview = useCallback(async () => {
    setReviewToBeDeleted(undefined);
    setIsDeleting(true);
    try {
      if (!review) {
        return;
      }
      const payload: ChangeControlPayloadType = {
        title: review.title ?? '',
        description: review.description ?? '',
        qualityItems: [],
        approvers: (review.approvers || []).map((item) => ({
          id: item.id,
          fullName: item.fullName,
        })),
        linkedDocuments: [],
      };

      await changeControlApi.saveReview(company, product.id, review.id, payload);
      await changeControlApi.deleteReview(company, product.id, review.id).then(goToReviewList);
    } catch (err: any) {
      showToast({
        id: 'submit-error',
        title: "We couldn't delete your review.",
        description: 'Please try again later.',
        status: 'error',
        duration: 5000,
      });
      setIsDeleting(false);
    }
  }, [company, showToast, goToReviewList, product, review]);

  const confirmDeleteReview = useCallback(async () => {
    setReviewToBeDeleted(review);
  }, [review, setReviewToBeDeleted]);

  if (isError) {
    showToast({
      id: 'loading-error',
      title: "We couldn't load the form.",
      description: 'Please try again later.',
      status: 'error',
      duration: 500000,
    });
  }

  if (loading || isError || !review || isDeleting) {
    return <Loading />;
  }

  const initialValues: ChangeControlPayloadType = {
    title: review.title ?? '',
    description: review.description ?? '',
    qualityItems: review.qualityItems as Array<ChangeControlQualityItemPayloadType>,
    approvers: review.approvers ?? [],
    linkedDocuments: review.linkedDocuments,
  };

  return (
    <>
      <FormHeader title={`Edit design review - ${review.code}`} productName={product.name} onClose={goToReviewList} />

      <DrawerProvider url={`/product/${product.id}/review/${changeControlId}`}>
        <ReviewForm
          product={product}
          configs={qualityConfigs}
          availableQualityItems={qualityItems}
          approvers={approvers}
          isApproversLoading={isApproverLoading}
          onSubmitForm={onSubmitForm}
          initialValues={initialValues}
          onDeleteReview={confirmDeleteReview}
        />
      </DrawerProvider>
      <DeleteConfirmationModal
        onConfirm={onDeleteReview}
        onClose={() => setReviewToBeDeleted(undefined)}
        confirmationDetails={
          reviewToBeDeleted
            ? {
                title: 'Delete review',
                message: (
                  <>
                    Are you sure you want to delete <b>{review.code}</b>? The review ID will not be reused.
                  </>
                ),
              }
            : undefined
        }
      />
    </>
  );
};
