import { DocumentFileType } from '../types/document';

export const excelFileExtensions = [
  '.xls',
  '.xlt',
  '.xlm',
  '.xlsx',
  '.xlsm',
  '.xltx',
  '.xltm',
  '.xlsb',
  '.xla',
  '.xlam',
  '.xll',
  '.xlw',
  '.csv',
];

export const wordFileExtensions = ['.doc', '.dot', '.docx', '.docm', '.dotx', '.dotm', '.docb'];

export function resolveFileType(fileUri: string): DocumentFileType {
  if (fileUri.startsWith('https://app.qualio')) {
    return 'qualio';
  } else if (excelFileExtensions.some((ext) => fileUri.endsWith(ext))) {
    return 'excel';
  } else if (fileUri.endsWith('.pdf')) {
    return 'pdf';
  } else if (wordFileExtensions.some((ext) => fileUri.endsWith(ext))) {
    return 'word';
  }

  return 'qualio';
}
