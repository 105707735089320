import React, { useCallback } from 'react';

import { QDivider, QDrawer, QDrawerHeader, QDrawerProps, QFlex, QSpinner } from '@qualio/ui-components';
import { useNavigate, useParams } from 'react-router-dom';

import { useCompanyId } from '../../context/CurrentUserContext';
import { ProductParam } from '../../paramTypes';
import { DesignElementType } from '../../types/baseQualityItem';
import { BaseQualityItemTrace } from '../../types/qualityItemTrace';
import { isRequirement } from '../../types/requirement';
import { isRisk } from '../../types/risk';
import { isTestCase } from '../../types/testCase';
import { RiskTrace } from '../../types/trace';
import { designElementStatusLabelAndColor } from '../../views_new/Review/List/statusLabelAndColorTag';
import { DetailsDrawerHeader, DetailsDrawerHeaderAction } from '../DetailsDrawerHeader';
import { GapAnalysisElement } from '../GapAnalysisElement/GapAnalysisElement';
import { DesignElementDetailsDrawerEditButton } from './components/DesignElementDetailsDrawerEditButton';
import { OldTestCaseDetailsDrawerBody } from './components/OldTestCaseDetailsDrawerBody';
import { RequirementDetailsDrawerBody } from './components/RequirementDetailsDrawerBody';
import { RiskDetailsDrawerBody } from './components/RiskDetailsDrawer';
import { TestCaseDetailsDrawerBody } from './components/TestCaseDetailsDrawerBody';
import { DetailsDrawerFooterPadding } from './DetailsDrawerFooter/DetailsDrawerFooterPadding';
import { useDesignElementDetailsDrawerData } from './hooks/useDesignElementDetailsDrawerData';
import { designElementEditActionPath } from './lib/DesignElementEditActionPath';

const DesignElementDetailsDrawer: React.FC<
  QDrawerProps & {
    designElementId: string;
    goBack: () => void;
    changeControlClick?: (id: string) => void;
    designElementClick: (id: string, version?: string) => void;
    designElementVersion?: string;
  }
> = ({ isOpen, onClose, goBack, designElementId, changeControlClick, designElementClick, designElementVersion }) => {
  const navigate = useNavigate();
  const companyId = useCompanyId();
  const { product: productId } = useParams<ProductParam>() as ProductParam;
  const {
    designElement,
    isLoading,
    relatedDesignElements,
    reviewHistory,
    configsMap,
    isoRiskQualityConfigLevels,
    fmeaThreshold,
  } = useDesignElementDetailsDrawerData(companyId, productId, designElementId, designElementVersion);

  const editElementAction = useCallback(() => {
    if (designElement) {
      navigate(designElementEditActionPath(productId, designElement.id, designElement.type));
    }
  }, [navigate, designElement, productId]);

  if (isLoading || !designElement) {
    return (
      <QDrawer isOpen={isOpen} onClose={onClose} size={'xl'} variant={'clickThrough'}>
        <QDrawerHeader>{null}</QDrawerHeader>
        <QFlex justifyContent="center" data-cy="change-control-details-loading">
          <QSpinner size="lg" data-cy="loading-spinner" />
        </QFlex>
      </QDrawer>
    );
  }

  return (
    <QDrawer isOpen={isOpen} onClose={onClose} size={'xl'} variant={'clickThrough'}>
      <QDrawerHeader>
        <DetailsDrawerHeader
          code={designElement.code as string}
          title={designElement.title}
          status={designElementStatusLabelAndColor(designElement.itemStatus)}
          updated={{ date: designElement.updated }}
          created={{ date: designElement.created }}
          onBack={goBack}
          onClose={onClose}
          statusLabel={designElement.statusLabel}
        >
          <DetailsDrawerHeaderAction>
            <DesignElementDetailsDrawerEditButton designElement={designElement} editCallBack={editElementAction} />
          </DetailsDrawerHeaderAction>
        </DetailsDrawerHeader>
      </QDrawerHeader>
      {designElement.policyIssues && <GapAnalysisElement policyIssueRecords={designElement.policyIssues} />}
      <QDivider />
      {isRequirement(designElement.type) && (
        <RequirementDetailsDrawerBody
          designElement={designElement as BaseQualityItemTrace}
          relatedDesignElements={relatedDesignElements}
          reviewHistory={reviewHistory}
          configsMap={configsMap}
          reviewClick={changeControlClick}
          designElementClick={designElementClick}
        />
      )}
      {isTestCase(designElement.type) && (
        <TestCaseDetailsDrawerBody
          designElement={designElement as BaseQualityItemTrace}
          relatedDesignElements={relatedDesignElements}
          reviewHistory={reviewHistory}
          configsMap={configsMap}
          reviewClick={changeControlClick}
          designElementClick={designElementClick}
        />
      )}
      {designElement.type === DesignElementType.TEST_CASE && (
        <OldTestCaseDetailsDrawerBody
          designElement={designElement as BaseQualityItemTrace}
          relatedDesignElements={relatedDesignElements}
          reviewHistory={reviewHistory}
          configsMap={configsMap}
          reviewClick={changeControlClick}
          designElementClick={designElementClick}
        />
      )}
      {isRisk(designElement.type) && (
        <RiskDetailsDrawerBody
          designElement={designElement as RiskTrace}
          relatedDesignElements={relatedDesignElements}
          reviewHistory={reviewHistory}
          configsMap={configsMap}
          reviewClick={changeControlClick}
          designElementClick={designElementClick}
          riskLevels={isoRiskQualityConfigLevels}
          fmeaThreshold={fmeaThreshold}
        />
      )}
      <DetailsDrawerFooterPadding />
    </QDrawer>
  );
};

export default DesignElementDetailsDrawer;
