import React from 'react';

import {
  createQColumnHelper,
  QBodyLayout,
  QBox,
  QButton,
  QDataTable,
  QEmptyState,
  QEmptyStateButtonGroup,
  QEmptyStatePrimaryButton,
  QPageLoader,
} from '@qualio/ui-components';
import type { TagStatusMapping } from '@qualio/ui-components/lib/QOrganisms/Table/Cells/types';
import { useNavigate } from 'react-router-dom';

import { useProduct } from '../../context/product';
import { useTags } from '../../hooks/documents/useTags';
import { Document, DocumentStatus, DocumentStatusIdToStatusConfigMap } from '../../types/document';
import { DocumentsDataProvider } from './DocumentsDataProvider';
import { DocumentSearchControls } from './DocumentSearchControls';

export const SelfServiceDocuments: React.FC = () => {
  const navigate = useNavigate();
  const { tags: productTags, id: productId } = useProduct();
  const { data: tags, isLoading: isLoadingTags } = useTags();

  // NOTE: If/when CL is bumped to >= 30, we can move this to the Fixed data provider `isLoading` prop.
  if (isLoadingTags) {
    return <QPageLoader />;
  }

  if (!tags || tags.length === 0 || !productTags || productTags.length === 0) {
    return (
      <QEmptyState
        title="No documents found"
        subtitle="Documents that contain all your selected tags will appear in the documents tab."
      >
        <QEmptyStateButtonGroup>
          <QEmptyStatePrimaryButton>
            <QButton onClick={() => navigate(`/product/edit/${productId}`)}>Add a document tag</QButton>
          </QEmptyStatePrimaryButton>
        </QEmptyStateButtonGroup>
      </QEmptyState>
    );
  }

  return (
    <DocumentsDataProvider allTags={tags} productTagIds={productTags}>
      <QBox
        sx={{
          // NOTE: This is a fix for a CSS clash for <p> tags within a #design-controls-container.
          '& p': {
            marginTop: '0px !important',
            marginBottom: '0px !important',
          },
        }}
      >
        <QBodyLayout.Default>
          <DocumentSearchControls allTags={tags} productTagIds={productTags} />
          <QDataTable columns={COLUMNS} />
        </QBodyLayout.Default>
      </QBox>
    </DocumentsDataProvider>
  );
};

const columnHelper = createQColumnHelper<Document>();
const COLUMNS = [
  columnHelper.text('code', { header: 'ID' }),
  columnHelper.textLink('title', ({ id }) => `${window.location.origin}/workspace/documents/${id}`, {
    header: 'Title',
    isExternal: true,
  }),
  columnHelper.text('type', { header: 'Type' }),
  columnHelper.text('owner', { header: 'Owner' }),
  columnHelper.tag((document: Document) => document.tag_ids.map((tag) => tag.toString()), {
    id: 'tag_ids',
    header: 'Tags',
    statuses: { '*': 'default' },
    enableSorting: false,
  }),
  columnHelper.text('version', { header: 'Version' }),
  columnHelper.status((document) => DocumentStatusIdToStatusConfigMap[document.status_id as DocumentStatus].label, {
    id: 'status_id',
    header: 'Status',
    width: '140px',
    statuses: Object.values(DocumentStatusIdToStatusConfigMap).reduce((acc: TagStatusMapping, statusConfig) => {
      acc[statusConfig.label] = statusConfig.color;
      return acc;
    }, {}),
  }),
];
