import React from 'react';

import { QText } from '@qualio/ui-components';

import { Menu, MenuItem } from './SegmentedControl.styles';
import { SegmentedControlProps } from './types/SegmentedControl.types';
import { areSegmentedControlItemsEqual, menuItemDataCy, textItemDataCy } from './utils/SegmentedControl.utils';

const SegmentedControl: React.FC<SegmentedControlProps> = ({ items, onClick, active, isDisabled }) => {
  return (
    <Menu>
      {items.map((item, index) => {
        const areItemsEqual = areSegmentedControlItemsEqual(item, active);

        return (
          <MenuItem
            isActive={areItemsEqual}
            key={index}
            onClick={() => (isDisabled ? undefined : onClick(item))}
            isDisabled={isDisabled}
            data-cy={menuItemDataCy(areItemsEqual, isDisabled)}
          >
            <QText fontSize="sm" data-cy={textItemDataCy(isDisabled)}>
              {item.prefix.toUpperCase()} - {item.title}
            </QText>
          </MenuItem>
        );
      })}
    </Menu>
  );
};

export default SegmentedControl;
