import { useMemo } from 'react';

import { QSelectItem } from '@qualio/ui-components';

import { useQualityConfigs } from '../../../context/qualityConfigs';
import { testCaseTypes } from '../../../lib/designControlNativeEnabled';

type UseTestCaseOptionsType = {
  loading: boolean;
  testCaseSelectOptions: Array<QSelectItem>;
};

export const useTestCaseOptions = (): UseTestCaseOptionsType => {
  const { loading, configs } = useQualityConfigs();

  const testCaseSelectOptions: Array<QSelectItem> = useMemo(() => {
    const allTypesOption = { label: 'All types', value: '' };
    return loading
      ? [allTypesOption]
      : [
          allTypesOption,
          ...configs
            .filter((item) => testCaseTypes.has(item.type))
            .map((item) => ({
              label: item.label,
              value: item.type,
            })),
        ];
  }, [loading, configs]);

  return { loading, testCaseSelectOptions };
};
