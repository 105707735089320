import * as z from 'zod';

import { ChangeControlStatus } from '../../types/changeControl';
import { signature } from '../../types/singature';

export const approval = z
  .object({
    status: z.union([z.literal(ChangeControlStatus.APPROVED), z.literal(ChangeControlStatus.NOT_APPROVED)]),
    note: z.string().max(750, 'Note too long').optional(),
    signature: signature,
  })
  .strict();

export type Approval = z.infer<typeof approval>;
