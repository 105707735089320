import React from 'react';

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { QRadio, QRadioGroup, QTheme } from '@qualio/ui-components';

export type ISORiskRadioProps = {
  onChange: (...event: any[]) => void;
  riskLevels: Array<string>;
  value: string;
  radioGroupName: string;
};

const RadioDivBase = css`
  .chakra-radio-group {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 16px;
  }

  .chakra-radio {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    z-index: 1;
  }

  .chakra-radio__control {
    z-index: 1;
    background: #fff;
  }

  .chakra-radio__control[data-checked] {
    z-index: 2 !important;
    border-color: ${QTheme.colors.blue[500]};
    color: #fff;
    background: ${QTheme.colors.blue[500]};
  }

  .chakra-radio__label {
    margin-top: 4px;
  }

  .chakra-radio:before,
  .chakra-radio:after {
    content: '';
    display: block;
    height: 10px;
    opacity: 0.4;
    position: absolute;
    top: 3px;
    z-index: -1;
    width: auto;
  }

  .chakra-radio:after {
    right: -50%;
    left: 50%;
  }

  .chakra-radio:first-of-type:before,
  .chakra-radio:last-of-type:after {
    display: none;
  }
`;

const RadioDivWith5 = styled.div`
  ${RadioDivBase}

  .chakra-radio {
    width: 20%;
  }

  .chakra-radio:nth-of-type(1):after {
    background: linear-gradient(90deg, ${QTheme.colors.blue[500]} 0%, ${QTheme.colors.green['500']} 100%);
  }

  .chakra-radio:nth-of-type(2):after {
    background: linear-gradient(90deg, ${QTheme.colors.green[500]} 0%, ${QTheme.colors.yellow['500']} 100%);
  }

  .chakra-radio:nth-of-type(3):after {
    background: linear-gradient(90deg, ${QTheme.colors.yellow[500]} 0%, ${QTheme.colors.orange['500']} 100%);
  }

  .chakra-radio:nth-of-type(4):after {
    background: linear-gradient(90deg, ${QTheme.colors.orange[500]} 0%, ${QTheme.colors.red['500']} 100%);
  }
`;
const RadioDivWith3 = styled.div`
  ${RadioDivBase}

  .chakra-radio {
    width: 33%;
  }

  .chakra-radio:nth-of-type(1):after {
    background: linear-gradient(90deg, ${QTheme.colors.blue[500]} 0%, ${QTheme.colors.yellow['500']} 100%);
  }

  .chakra-radio:nth-of-type(2):after {
    background: linear-gradient(90deg, ${QTheme.colors.yellow[500]} 0%, ${QTheme.colors.red['500']} 100%);
  }
`;

export const ISORiskRadio: React.FC<ISORiskRadioProps> = ({ onChange, riskLevels, value, radioGroupName }) => {
  const RadioDiv = riskLevels.length === 5 ? RadioDivWith5 : RadioDivWith3;

  return (
    <RadioDiv>
      <QRadioGroup onChange={onChange} value={value} data-cy={radioGroupName}>
        {riskLevels.map((item) => (
          <QRadio key={item} value={item} data-cy={item}>
            {item}
          </QRadio>
        ))}
      </QRadioGroup>
    </RadioDiv>
  );
};
