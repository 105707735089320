import { Payload } from '../types/payload';
import { SourceConfigResponse } from '../types/sourceConfig';
import api from './index';

const sourceConfigApi = {
  query(company: number, product: string): Promise<SourceConfigResponse[]> {
    return api
      .get<Payload<SourceConfigResponse[]>>(`/company/${company}/product-development/product/${product}/sourceConfig`)
      .then((res) => res.data.data);
  },
};

export default sourceConfigApi;
