import React from 'react';

import {
  DesignElement as DesignElementType,
  isDesignElementRequirement,
  isDesignElementTestCase,
} from '@design-controls/types';

import { Requirement } from './Requirement';
import { TestCase } from './TestCase';

export const DesignElement: React.FC<DesignElementType> = (prop: DesignElementType) => {
  if (isDesignElementRequirement(prop)) {
    return <Requirement {...prop} />;
  }

  if (isDesignElementTestCase(prop)) {
    return <TestCase {...prop} />;
  }

  return null;
};
