import React from 'react';

import { QBox, QCloseButton, QDivider, QFlex, QHeading, QText } from '@qualio/ui-components';

import { Container } from '../components';

export type FormLayoutProps = {
  title: string;
  productName: string;
  onClose: () => void;
};

export const FormLayout: React.FC<FormLayoutProps> = ({ children, title, productName, onClose }) => {
  return (
    <Container>
      <QFlex justifyContent="space-between" mb={1}>
        <QBox>
          <QHeading mb={1} size="lg" as="h1" data-cy="form-heading">
            {title}
          </QHeading>
          <QText color={'gray.500'} data-cy="sub-heading" fontSize={14}>
            {productName}
          </QText>
        </QBox>
        <QCloseButton data-cy="form-heading-close-btn" onClick={onClose} />
      </QFlex>
      <QDivider mb={5} />
      {children}
    </Container>
  );
};
