import { ReleaseDocuments } from '../../types/release';
import { makeResponse } from './generic';

export const releaseDocumentResponse = makeResponse<ReleaseDocuments>({
  reports: [
    {
      url: 'https://app.qualio.com/workspace/documents/181683',
      name: 'Test product - DCC-2 - User Need',
      status: 'completed',
      type: 'RequirementReport',
    },
    {
      url: 'https://app.qualio.com/workspace/documents/181683',
      name: 'Test report 2',
      status: 'completed',
      type: 'TestCaseReport',
    },
  ],
  documents: [
    {
      name: 'PDF Document',
      url: 'http://example.com/test-document.pdf',
      id: 'pdf-document',
      status: 'completed',
    },
  ],
  reviewPackageAvailable: true,
});

export const releaseInProgressDocumentResponse = makeResponse<ReleaseDocuments>({
  reports: [
    {
      url: 'https://app.qualio.com/workspace/documents/181683',
      name: 'Software Requirements Specification - Pre Submission Release',
      status: 'processing',
      type: 'RequirementReport',
    },
    {
      url: 'https://app.qualio.com/workspace/documents/181683',
      name: 'Test report 2',
      status: 'completed',
      type: 'RequirementReport',
    },
  ],
  documents: [
    {
      name: 'PDF Document',
      url: 'http://example.com/test-document.pdf',
      id: 'pdf-document',
      status: 'completed',
    },
  ],
  reviewPackageAvailable: false,
});

export const releaseErrorDocumentResponse = makeResponse<ReleaseDocuments>({
  reports: [
    {
      url: 'https://app.qualio.com/workspace/documents/181683',
      name: 'Software Requirements Specification - Pre Submission Release',
      status: 'error',
      type: 'RequirementReport',
    },
    {
      url: 'https://app.qualio.com/workspace/documents/181683',
      name: 'Test report 2',
      status: 'completed',
      type: 'RequirementReport',
    },
  ],
  documents: [
    {
      name: 'PDF Document',
      url: 'http://example.com/test-document.pdf',
      id: 'pdf-document',
      status: 'completed',
    },
  ],
  reviewPackageAvailable: false,
});
