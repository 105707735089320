import { QualioSmartLink } from '../../types/smartlink';

export const searchDocumentsResponse: QualioSmartLink[] = [
  {
    id: '142',
    modified_time: 1395754833,
    status_id: 'for_review',
    uuid: '620eb1f8-47af-11e7-b6f8-b72707f4e3da',
    code: 'SOP-1',
    smartlink_search_key: 'Procedure SOP-1 Test review process',
    rank: 1,
    title: 'Test review process',
  },
  {
    modified_time: 1395754833,
    id: '143',
    status_id: 'for_review',
    uuid: '620eb1f8-47af-11e7-b6f8-b72707f4e3db',
    code: 'SOP-2',
    smartlink_search_key: 'Procedure SOP-2 Test review process',
    rank: 1,
    title: 'Test review process',
  },
  {
    modified_time: 1395754833,
    id: '144',
    status_id: 'effective',
    uuid: '620eb1f8-47af-11e7-b6f8-b72707f4e3dc',
    code: 'SOP-3',
    smartlink_search_key: 'Procedure SOP-3 Test review process',
    rank: 1,
    title: 'Test review process',
  },
  {
    modified_time: 1395754833,
    id: '145',
    status_id: 'effective',
    uuid: '620eb1f8-47af-11e7-b6f8-b72707f4e3dd',
    code: 'SOP-4',
    smartlink_search_key: 'Procedure SOP-4 Test review process',
    rank: 1,
    title: 'Test review process',
  },
];
