import React, { useCallback, useMemo } from 'react';

import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

import itemsApi from '../../api/items';
import { FormHeader } from '../../components_new/FormHeader';
import { Loading } from '../../components_new/Loading';
import { FMEAForm } from '../../components_new/RiskForm/FMEAForm';
import { ISOForm } from '../../components_new/RiskForm/ISOForm';
import { RiskFormProps } from '../../components_new/RiskForm/type';
import { useQualityItemsNew } from '../../components_new/RiskForm/useQualityItemsNew';
import { useAnalytics } from '../../context/analytics';
import { useCompanyId } from '../../context/CurrentUserContext';
import { useProduct } from '../../context/product';
import { useQualityConfigs } from '../../context/qualityConfigs';
import { DesignElementType } from '../../types/baseQualityItem';
import { RiskQualityTypeConfig } from '../../types/qualityTypeConfig';
import { RiskSubType } from '../../types/risk';

type CreateRiskProps = {
  subType: 'ISO' | 'FMEA';
};

const riskMapping: Record<'ISO' | 'FMEA', { title: string; component: React.FC<RiskFormProps<any>> }> = {
  ISO: {
    title: 'Create ISO 14971 risk',
    component: ISOForm,
  },
  FMEA: {
    title: 'Create FMEA risk',
    component: FMEAForm,
  },
};

export const CreateRisk: React.FC<CreateRiskProps> = ({ subType }) => {
  const navigate = useNavigate();
  const analytics = useAnalytics();
  const { loading: isQualityConfigLoading, configs } = useQualityConfigs();
  const companyId = useCompanyId();
  const product = useProduct();

  const { isLoading: isSourceMitigationItemsLoading, autoCompleteOptions } = useQualityItemsNew(companyId, product.id);

  const { mutate, isLoading: isSubmitting } = useMutation(
    (payload: any) => itemsApi.create(companyId, product.id, { data: payload }),
    {
      onSuccess: () => {
        navigate(`/product/${product.id}/risk/${subType.toLowerCase()}`);
      },
      onSettled: (data) => {
        analytics.track('PD Create risk', {
          companyId,
          productId: product,
          subType: data.subType ?? RiskSubType.ISO,
        });
      },
    },
  );

  const onClose = useCallback(() => {
    navigate(`/product/${product.id}/risk/${subType.toLowerCase()}`);
  }, [navigate, product.id, subType]);

  const qualityConfigs: Record<string, string> = useMemo(() => {
    return Object.fromEntries(configs.map((item) => [item.type, item.label]));
  }, [configs]);

  const riskConfig = useMemo(() => {
    const config: any = configs.find((item) => item.type === DesignElementType.RISK);

    if (config?.riskLevel) {
      config.assessment = config.assessment || {};
      config.assessment.iso = config.riskLevel;
    }

    return config as RiskQualityTypeConfig;
  }, [configs]);

  const Component = riskMapping[subType].component;
  const isFormLoading = isQualityConfigLoading || isSourceMitigationItemsLoading;

  return (
    <>
      <FormHeader title={riskMapping[subType].title} productName={product.name} onClose={onClose} />
      {isFormLoading ? (
        <Loading />
      ) : (
        <Component
          isSubmitting={isSubmitting}
          riskConfig={riskConfig}
          configs={qualityConfigs}
          onClose={onClose}
          onSubmit={mutate}
          autoCompleteOptions={autoCompleteOptions}
        />
      )}
    </>
  );
};
