import React from 'react';

import styles from './Container.module.less';

export const HorizontalSizing: React.FC = ({ children }) => {
  return <div className={styles.horizontalSizing}>{children}</div>;
};

const Container: React.FC = ({ children }) => {
  return (
    <HorizontalSizing>
      <div data-cy="product-view" className={`${styles.container}`}>
        {children}
      </div>
    </HorizontalSizing>
  );
};

export default Container;
