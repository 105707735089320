import { useQuery } from 'react-query';

import openIssueApi from '../../api/openIssue';
import { useCompanyId } from '../../context/CurrentUserContext';
import { BaseQualityType } from '../../types/baseQualityItem';
import { OpenIssue, PolicyIssues } from '../../types/openIssue';

export type UseOpenIssues = {
  loading: boolean;
  data: OpenIssue[];
};

export const ALL_ISSUES = 'ALL_ISSUES';
export type ElementTypes = BaseQualityType | typeof ALL_ISSUES;

export function useOpenIssues(product: string, type: ElementTypes): UseOpenIssues {
  const company = useCompanyId();

  const { data, isLoading } = useQuery(
    `openIssueFetch-company-${company}-product-${product}-type-${type}`,
    () => openIssueApi.query(company, product, type === ALL_ISSUES ? undefined : type),
    {
      cacheTime: 0,
    },
  );

  const result = data?.filter(hasAnOpenIssue);

  return {
    loading: isLoading,
    data: result ?? [],
  };
}

function hasAnOpenIssue(element: OpenIssue): boolean {
  return (Object.keys(PolicyIssues) as PolicyIssues[]).some((policy) => element.policyIssues[policy]);
}
