import itemsApi from '../../../api/items';
import { ChangeControlQualityItem } from '../../../types/changeControl';

export const getLatestVersion = async (
  company: number,
  product: string,
  designElementId: string,
  newVersion: string,
): Promise<ChangeControlQualityItem | undefined> => {
  return itemsApi
    .queryById<ChangeControlQualityItem>(company, product, designElementId, newVersion)
    .then((item) =>
      item
        ? {
            id: item.id,
            code: item.code,
            itemStatus: item.itemStatus,
            title: item.title,
            type: item.type,
            subType: item.subType,
            version: item.version,
            latest: item.version,
          }
        : undefined,
    )
    .catch((e) => undefined);
};
