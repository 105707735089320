import React, { useCallback } from 'react';

import { QAlertBox, QBox, QButton, QDrawerProps, QFlex, QText } from '@qualio/ui-components';
import { useNavigate, useParams } from 'react-router-dom';

import releaseApi from '../../../api/release';
import { UserPermissions } from '../../../components';
import UserGroupToggle from '../../../components/UserGroupToggle/UserGroupToggle';
import { useUserId } from '../../../context/CurrentUserContext';
import { downloadFile } from '../../../lib/downloadFile';
import { ProductParam } from '../../../paramTypes';
import { ChangeControl, ChangeControlStatus } from '../../../types/changeControl';
import { isChangeControlOwner } from '../../../views/ChangeControlList/isChangeControlOwner';
import {
  isChangeControlStatusApproved,
  isChangeControlStatusInProgress,
  isUserApproverAndNotApprovedYet,
} from '../../../views_new';
import { changeControlLabelAndVariantColor } from '../../../views_new/Review/List/statusLabelAndColorTag';
import { DetailsDrawerHeader, DetailsDrawerHeaderAction, DetailsDrawerHeaderBody } from '../../DetailsDrawerHeader';
import changeControlDetailsDrawerStyles from '../ChangeControlDetailsDrawer.module.less';

type ChangeControlDrawerHeaderProps = {
  companyId: number;
  changeControl: ChangeControl;
  setReviewModalStatus: (changeControlStatus?: ChangeControlStatus.NOT_APPROVED | ChangeControlStatus.APPROVED) => void;
  reviewPackageAvailable: boolean;
  goBack: () => void;
} & Pick<QDrawerProps, 'onClose'>;

export const ChangeControlDrawerHeader: React.FC<ChangeControlDrawerHeaderProps> = ({
  companyId,
  changeControl,
  setReviewModalStatus,
  reviewPackageAvailable,
  onClose,
  goBack,
}) => {
  const navigate = useNavigate();
  const userId = useUserId();
  const { product: productId } = useParams<ProductParam>() as ProductParam;

  const isCurrentUserChangeControlOwner = isChangeControlOwner(changeControl, userId);
  const isCurrentUserApproverAndNotApprovedYet = isUserApproverAndNotApprovedYet(
    String(userId),
    changeControl.approvers ?? [],
  );

  const editChangeControl = useCallback(() => {
    navigate(`/product/${productId}/review/${changeControl.id}`);
  }, [navigate, changeControl, productId]);

  const approvalModal = useCallback(
    (status: ChangeControlStatus.NOT_APPROVED | ChangeControlStatus.APPROVED) => {
      return () => setReviewModalStatus(status);
    },
    [setReviewModalStatus],
  );

  const downloadFileCallback = useCallback(
    () => downloadFile(releaseApi.downloadReviewPackageUrl(companyId, productId, changeControl.releaseId ?? '')),
    [changeControl.releaseId, companyId, productId],
  );

  return (
    <DetailsDrawerHeader
      code={changeControl.code}
      title={changeControl.title}
      status={changeControlLabelAndVariantColor(changeControl.status)}
      created={{ date: changeControl.created, user: changeControl.createdBy?.fullName }}
      updated={{ date: changeControl.updated, user: changeControl.createdBy?.fullName }}
      onBack={goBack}
      onClose={onClose}
    >
      <DetailsDrawerHeaderAction>
        {isChangeControlStatusInProgress(changeControl.status) && isCurrentUserChangeControlOwner && (
          <UserGroupToggle userPermission={UserPermissions.EDIT_CHANGE_CONTROL}>
            <QButton data-cy={`change-control-details-edit-button`} variant={'outline'} onClick={editChangeControl}>
              Edit
            </QButton>
          </UserGroupToggle>
        )}
        {isChangeControlStatusApproved(changeControl.status) && reviewPackageAvailable && (
          <QButton variant="outline" leftIcon="Folder" data-cy="export-review-package" onClick={downloadFileCallback}>
            Export review package
          </QButton>
        )}
      </DetailsDrawerHeaderAction>
      {isChangeControlStatusInProgress(changeControl.status) && isCurrentUserApproverAndNotApprovedYet && (
        <DetailsDrawerHeaderBody width="100%">
          <QBox position={'relative'} className={`${changeControlDetailsDrawerStyles.stylizedAlert}`}>
            <QAlertBox status={'warning'}>
              <QFlex data-cy={`change-control-review-request-alert`} justifyContent={'space-between'} width={'100%'}>
                <QFlex columnGap={2} alignItems={'center'}>
                  <QText fontSize={13} weight="semibold">
                    Review request
                  </QText>
                </QFlex>
                <QFlex columnGap={2}>
                  <UserGroupToggle userPermission={UserPermissions.APPROVE_CHANGE_CONTROL}>
                    <QButton
                      data-cy={`decline-change-control-button`}
                      isDestructive={true}
                      onClick={approvalModal(ChangeControlStatus.NOT_APPROVED)}
                    >
                      Decline
                    </QButton>
                    <QButton
                      data-cy={`approve-change-control-button`}
                      onClick={approvalModal(ChangeControlStatus.APPROVED)}
                    >
                      Approve
                    </QButton>
                  </UserGroupToggle>
                </QFlex>
              </QFlex>
            </QAlertBox>
          </QBox>
        </DetailsDrawerHeaderBody>
      )}
    </DetailsDrawerHeader>
  );
};
