import React from 'react';

import { QText } from '@qualio/ui-components';

export const QTextWithHover: React.FC<{ value: string; noOfLines?: number; dataCy?: string }> = ({
  value,
  noOfLines,
  dataCy,
}) => {
  return (
    <QText noOfLines={noOfLines ?? 2} data-cy={dataCy} title={value}>
      {value}
    </QText>
  );
};
