import { BaseConfigurationType } from '../types/baseConfiguration';
import { TestCaseConfigurationType } from '../types/testCaseConfiguration';

export const INITIAL_TESTCASE_CONFIGURATION: TestCaseConfigurationType = {
  testCases: {
    testCase1: {
      prefix: 'VAL',
      label: 'Validation',
      statuses: [{ label: 'Draft' }, { label: 'Done' }],
    },
    testCase2: {
      prefix: 'VER',
      label: 'Verification',
      statuses: [{ label: 'Draft' }, { label: 'Done' }],
    },
  },
};

export const EMPTY_TEST_CASE_CONFIGURATION: BaseConfigurationType = {
  prefix: '',
  label: '',
  statuses: [{ label: '' }],
};
