import React, { useCallback, useEffect, useMemo } from 'react';

import { deprecated, QFlex, QSpacer, QTab, QTabList, QTabs } from '@qualio/ui-components';
import { Outlet, useNavigate, useParams } from 'react-router-dom';

import { useRiskConfig } from '../../components/RiskForm/useRiskConfig';
import { CreateRiskButton } from '../../components_new/CreateRiskButton/CreateRiskButton';
import { DrawerProvider } from '../../components_new/DesginElementDetailsDrawer/context/DrawerProvider';
import { useDrawerProvider } from '../../components_new/DesginElementDetailsDrawer/context/useDrawerProvider';
import { NoRiskPolicyEmptyState } from '../../components_new/EmptyState/NoRiskPolicy';
import { Loading } from '../../components_new/Loading';
import { FMEARiskTable } from '../../components_new/RiskTable/FMEARiskTable';
import { ISORiskTable } from '../../components_new/RiskTable/ISORiskTable';
import { useAnalytics } from '../../context/analytics';
import { useCompanyId } from '../../context/CurrentUserContext';
import { useDesignElement } from '../../hooks/designElement/useDesignElement';
import { ProductParam, SelectedItemParam, SelectedRiskParam } from '../../paramTypes';
import { DesignElementType } from '../../types/baseQualityItem';
import { FMEARisk, ISORiskType, RiskSubType } from '../../types/risk';

export type RiskListProps = {
  subType: RiskSubType;
};

export const RiskListContainer: React.FC<RiskListProps> = ({ subType }) => {
  const { product } = useParams() as ProductParam;

  return (
    <DrawerProvider url={`/product/${product}/risk/${subType.toLowerCase()}`}>
      <RiskList subType={subType} />
    </DrawerProvider>
  );
};

export const RiskList: React.FC<RiskListProps> = ({ subType }) => {
  const company = useCompanyId();
  const analytics = useAnalytics();
  const { config, loading } = useRiskConfig();

  const { product, selectedRiskId } = useParams() as ProductParam &
    Partial<SelectedRiskParam> &
    Partial<SelectedItemParam>;

  const navigate = useNavigate();
  const { isLoading, designElements, deleteDesignElement, isDeleting } = useDesignElement<ISORiskType | FMEARisk>(
    product,
    DesignElementType.RISK,
    subType,
  );

  const deleteCallback = useCallback(
    (designElementId, type) => deleteDesignElement({ designElementId, type }),
    [deleteDesignElement],
  );

  const tabs: Array<{ label: string; id: string }> = useMemo(() => {
    if (loading || isDeleting || !config) {
      return [];
    }

    return [
      {
        label: 'ISO 14971',
        id: 'iso',
      },
      config?.assessment.fmea
        ? {
            label: 'FMEA',
            id: 'fmea',
          }
        : undefined,
    ].filter((item) => item !== undefined) as Array<{ label: string; id: string }>;
  }, [config, loading, isDeleting]);

  useEffect(() => {
    analytics.track('PD list risks', { company, productId: product, subType });
  }, [analytics, company, product, subType]);

  const { handleItemSelected } = useDrawerProvider();

  const navigateToTab = useCallback(
    (e: React.MouseEvent) => {
      navigate(`/product/${product}/risk/${(e.currentTarget.getAttribute('target-id') ?? '').toLowerCase()}`);
    },
    [navigate, product],
  );

  const handleOnRowClick = useCallback(
    (row: deprecated.QDataRow) => {
      const riskId = row['id'] as string;
      handleItemSelected(riskId);
    },
    [handleItemSelected],
  );

  if (loading || isDeleting) {
    return <Loading />;
  }

  if (!tabs.length) {
    return <NoRiskPolicyEmptyState product={product} />;
  }

  const defaultIndex = subType === RiskSubType.ISO ? 0 : 1;

  return (
    <>
      <QFlex mb="30px" alignItems={'end'}>
        {tabs.length > 1 && (
          <QTabs defaultIndex={defaultIndex}>
            <QTabList>
              {tabs.map((item) => (
                <QTab key={item.id} target-id={item.id} data-cy={`${item.id}-tab-selector`} onClick={navigateToTab}>
                  {item.label}
                </QTab>
              ))}
            </QTabList>
          </QTabs>
        )}
        <QSpacer />
        {designElements.length > 0 && <CreateRiskButton tabs={tabs} product={product} />}
      </QFlex>
      <>
        {subType === RiskSubType.FMEA ? (
          <FMEARiskTable
            productId={product}
            onRowClick={handleOnRowClick}
            selectedItemId={selectedRiskId}
            isLoading={isLoading}
            designElements={designElements as Array<FMEARisk>}
            deleteDesignElement={deleteCallback}
            riskConfig={config}
          />
        ) : (
          <ISORiskTable
            productId={product}
            riskLevels={config?.assessment.iso.levels as Array<string>}
            onRowClick={handleOnRowClick}
            selectedItemId={selectedRiskId}
            isLoading={isLoading}
            designElements={designElements as Array<ISORiskType>}
            deleteDesignElement={deleteCallback}
          />
        )}
        <Outlet />
      </>
    </>
  );
};
