import React, { useState } from 'react';

import { zodResolver } from '@hookform/resolvers/zod';
import { QButton } from '@qualio/ui-components';
import { FormProvider, useForm } from 'react-hook-form';

import { FormContainer, MessageAlert, RiskConfigurationForm } from '../../../components';
import { ButtonContainer } from '../../../components/ButtonContainer';
import {
  DeleteDesignElementPolicyModal,
  PolicyToBeDeleted,
  triggerPolicyDeletion,
} from '../../../components/DeleteDesignElementPolicyModal/DeleteDesignElementPolicyModal';
import { useMessagesContext, withMessages } from '../../../components/Messages';
import { useCompanyId } from '../../../context/CurrentUserContext';
import { useProduct } from '../../../context/product';
import { getExistingConfigPrefixes, useQualityConfigs } from '../../../context/qualityConfigs';
import { DesignElementType } from '../../../types/baseQualityItem';
import { RequestValidationError } from '../../../types/requestValidationError';
import { RiskSubType } from '../../../types/risk';
import { riskConfigurationSchemaProvider, RiskConfigurationType } from '../../../types/riskConfiguration';

type Props = {
  initialValue: RiskConfigurationType;
  onSubmit: (data: RiskConfigurationType) => void;
  submitIsLoading: boolean;
  archivePrefixes: Array<string>;
  validationErrors: Array<RequestValidationError>;
};

const RiskSettingsForm: React.FC<Props> = ({
  onSubmit,
  initialValue,
  submitIsLoading,
  archivePrefixes,
  validationErrors,
}) => {
  const { configs } = useQualityConfigs();
  const company = useCompanyId();
  const { id: product } = useProduct();
  const [policyToBeDeleted, setPolicyToBeDeleted] = useState<PolicyToBeDeleted | undefined>();
  const { addMessage, clearMessages } = useMessagesContext();

  const methods = useForm<RiskConfigurationType>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: initialValue,
    resolver: zodResolver(riskConfigurationSchemaProvider(getExistingConfigPrefixes(configs), archivePrefixes)),
  });

  const onDeleteDialogClose = () => {
    setPolicyToBeDeleted(undefined);
  };

  const onDeleteDialogConfirmation = () => {
    setPolicyToBeDeleted(undefined);
    methods.setValue('enableFmea', false);
  };

  const canDisableFmea = () => {
    if (initialValue.enableFmea) {
      return () => {
        if (methods.getValues().enableFmea) {
          triggerPolicyDeletion(
            company,
            product,
            DesignElementType.RISK,
            initialValue,
            setPolicyToBeDeleted,
            clearMessages,
            addMessage,
            RiskSubType.FMEA,
          );
        } else {
          methods.setValue('enableFmea', true);
        }
      };
    }
  };
  return (
    <>
      <FormProvider {...methods}>
        <FormContainer>
          {!!Object.keys(methods.formState.errors).length && (
            <MessageAlert type="error" title="There were some errors" data-cy="error-alert" />
          )}
          <RiskConfigurationForm validationErrors={validationErrors} canDisableFmea={canDisableFmea()} />
          <ButtonContainer>
            <QButton
              data-cy="save-risk-button"
              onClick={methods.handleSubmit((data) => onSubmit(data))}
              isLoading={submitIsLoading}
            >
              Save
            </QButton>
          </ButtonContainer>
        </FormContainer>
      </FormProvider>
      <DeleteDesignElementPolicyModal
        onConfirm={onDeleteDialogConfirmation}
        onClose={onDeleteDialogClose}
        policyToBeDeleted={policyToBeDeleted}
      />
    </>
  );
};

export default withMessages(RiskSettingsForm);
