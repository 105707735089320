import React, { useMemo, useState } from 'react';

import { useRouteBasedDrawer } from '../../../components/CustomDrawer/useRouteBasedDrawer';
import { DrawerContext } from './useDrawerProvider';

type DrawerProviderProps = {
  url: string;
};

export const DrawerProvider: React.FC<DrawerProviderProps> = ({ url, children }) => {
  const { handleItemSelected, handleClose, handleGoBack } = useRouteBasedDrawer(url);
  const [selectedDesignElement, setSelectedDesignElement] = useState<{ id: string; version?: string } | undefined>(
    undefined,
  );

  const value = useMemo(
    () => ({ handleItemSelected, handleClose, handleGoBack, selectedDesignElement, setSelectedDesignElement }),
    [handleItemSelected, handleClose, handleGoBack, selectedDesignElement, setSelectedDesignElement],
  );

  return <DrawerContext.Provider value={value}>{children}</DrawerContext.Provider>;
};
