import React from 'react';

import { QButton } from '@qualio/ui-components';

import { BasicConfigurationFooterSection } from '../BasicConfiguration/BasicConfiguration.styles';
import { CardWrapper } from '../Card';
import { BasicConfiguration } from '../index';
import {
  testCaseButtonsDataCy,
  TestCaseCardDataCyMapper,
  TestCaseConfigurationsPresentationalDataCy,
  TestCaseConfigurationsPresentationalProps,
} from './TestCaseConfigurations.types';

const TestCaseConfigurationsPresentational: React.FC<TestCaseConfigurationsPresentationalProps> = ({
  requirementAndTestCaseTuples,
  addTestCaseCard,
  deleteTestCaseCard,
  testCaseFormState,
  canDelete,
}) => {
  return (
    <>
      <CardWrapper data-cy={TestCaseConfigurationsPresentationalDataCy.TESTCASE_FORM}>
        {testCaseFormState &&
          requirementAndTestCaseTuples
            .map((tuple) => ({
              ...tuple,
              ...testCaseButtonsDataCy[tuple.testCase],
            }))
            .map(({ requirement, testCase, enableDataCy, disableDataCy }) => {
              const currentTestCase = testCaseFormState[testCase];
              const currentTestCaseExists = currentTestCase !== undefined;
              const numberTestCases = Object.keys(testCaseFormState).length;

              const isDisabled = (currentTestCaseExists && numberTestCases === 1) || !canDelete(testCase);
              return (
                <BasicConfiguration
                  key={testCase}
                  sectionTitle={`Test case for ${requirement.prefix} - ${requirement.label}`}
                  controllerNamePrefixWithDot={`testCases.${testCase}.`}
                  data-cy={TestCaseCardDataCyMapper[testCase]}
                  showSection={currentTestCaseExists}
                  type={testCase}
                >
                  <BasicConfigurationFooterSection isDisabled={isDisabled}>
                    {!currentTestCaseExists ? (
                      <QButton
                        variant={'link'}
                        leftIcon={'Eye'}
                        onClick={() => addTestCaseCard(testCase)}
                        data-cy={enableDataCy}
                      >
                        Enable
                      </QButton>
                    ) : (
                      <QButton
                        variant={'link'}
                        leftIcon={'EyeOff'}
                        onClick={() => deleteTestCaseCard(testCase)}
                        data-cy={disableDataCy}
                        isDisabled={isDisabled}
                      >
                        Disable
                      </QButton>
                    )}
                  </BasicConfigurationFooterSection>
                </BasicConfiguration>
              );
            })}
      </CardWrapper>
    </>
  );
};

export default TestCaseConfigurationsPresentational;
