import React from 'react';

import { QRadio, QRadioGroup, QStack } from '@qualio/ui-components';

type RadioGroupProps = {
  defaultValue?: any;
  name: string;
  onChange: (value: any) => void;
  options: Array<{ label: string; value: any }>;
  direction?: 'row' | 'column';
};

export const RadioGroup: React.VFC<RadioGroupProps> = ({
  defaultValue,
  name,
  onChange,
  options,
  direction = 'column',
  ...htmlProps
}) => {
  return (
    <QRadioGroup onChange={onChange} value={defaultValue}>
      <QStack direction={direction}>
        {options.map(({ label, value }) => (
          <QRadio key={value} value={value} {...htmlProps}>
            {label}
          </QRadio>
        ))}
      </QStack>
    </QRadioGroup>
  );
};
