import { DesignElementType } from '../../../types/baseQualityItem';
import { ChangeControlQualityItem } from '../../../types/changeControl';
import { isRequirement } from '../../../types/requirement';
import { isRisk } from '../../../types/risk';
import { SearchAndSelectElementType } from '../../SearchAndSelectElements/SearchAndSelectElement';

export enum DesignElementGroups {
  REQUIREMENTS = 'Requirements',
  TESTCASES = 'Test cases',
  RISKS = 'Risks',
}

const getGroupKey = (type: DesignElementType): DesignElementGroups => {
  if (isRisk(type)) {
    return DesignElementGroups.RISKS;
  }
  return isRequirement(type) ? DesignElementGroups.REQUIREMENTS : DesignElementGroups.TESTCASES;
};

const getUniqueItems = (items: SearchAndSelectElementType[]) => [
  ...new Map(items.map((item) => [item.id, item])).values(),
];

export const getSelectedElementGroups = (
  elements: SearchAndSelectElementType[],
): Map<string, SearchAndSelectElementType[]> => {
  return elements.reduce((groups, element) => {
    const key = getGroupKey((element.item as ChangeControlQualityItem).type);
    groups.set(key, getUniqueItems([...(groups.get(key) ?? []), element]));
    return groups;
  }, new Map<string, SearchAndSelectElementType[]>());
};
