import React, { useMemo } from 'react';

import { QBox, QFlex, QTag, QText } from '@qualio/ui-components';

import { calculateFmeaRisk } from '../../../components/RiskForm/formHelpers';
import { FMEARisk } from '../../../types/risk';
import { fmeaRiskScoreColor } from '../../../views_new/Review/List/statusLabelAndColorTag';

export const FmeaRiskMitigation: React.FC<{ risk: FMEARisk; fmeaThreshold: number | undefined }> = ({
  risk,
  fmeaThreshold,
}) => {
  const riskScore = useMemo(() => calculateFmeaRisk(risk.postMitigation), [risk.postMitigation]);

  return (
    <>
      {risk.riskControl && (
        <QFlex data-cy={'fmea-risk-mitigation'} marginTop={5} flexDirection={'column'}>
          <QText fontSize={16} weight="semibold">
            Mitigation
          </QText>
          <QFlex marginTop={2} marginBottom={5} flexDirection={'column'}>
            <QText color="gray.700" fontSize={14} weight="semibold">
              Description
            </QText>
            <QText data-cy={'fmea-risk-mitigation-description'} fontSize={14}>
              {risk.riskControl.mitigation}
            </QText>
          </QFlex>
          <QFlex
            data-cy={'iso-risk-mitigation-final-assessment'}
            marginTop={5}
            marginBottom={2}
            flexDirection={'column'}
          >
            <QText fontSize={16} weight="semibold">
              Final Assessment
            </QText>
          </QFlex>
          <QBox mb="5px">
            <QText color="gray.700" fontSize={14} weight="semibold">
              Risk Score
            </QText>
          </QBox>
          <QText color="gray.500" fontSize={14} weight="semibold">
            <QTag
              data-cy={'fmea-risk-final-assessment-risk-level'}
              variantColor={fmeaRiskScoreColor(riskScore, fmeaThreshold)}
            >
              {riskScore}
            </QTag>
          </QText>
        </QFlex>
      )}
    </>
  );
};
