import { createContext, useContext } from 'react';

type DesignElementDrawer = { id: string; version?: string };

type DrawerContextType = {
  handleItemSelected: (itemId?: string, state?: Record<string, string>) => void;
  handleClose: () => void;
  handleGoBack: () => void;
  selectedDesignElement?: DesignElementDrawer;
  setSelectedDesignElement: (element?: DesignElementDrawer) => void;
};

export const DrawerContext = createContext<DrawerContextType>({
  handleClose: () => undefined,
  handleGoBack: () => undefined,
  handleItemSelected: () => undefined,
  setSelectedDesignElement: () => undefined,
});

export const useDrawerProvider = (): DrawerContextType => {
  const context = useContext(DrawerContext);
  if (context === null) {
    throw new Error('DrawerProvider not setup');
  }

  return context;
};
