import React from 'react';

import SettingsLeftNavigation from '../SettingsLeftNavigation/SettingsLeftNavigation';
import { Container, SettingsBodyContainer } from './SettingsContainer.styles';

interface SettingsContainerProps {
  children?: React.ReactNode;
}

const SettingsContainer: React.FC<SettingsContainerProps> = ({ children }) => {
  return (
    <Container data-cy="settings-container">
      <SettingsLeftNavigation />
      <SettingsBodyContainer>{children}</SettingsBodyContainer>
    </Container>
  );
};

export default SettingsContainer;
