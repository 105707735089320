import React from 'react';

import Icon from '@ant-design/icons';

const Svg = () => (
  <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="20" height="20" />
    <path
      d="M15.7686 9.52701L4.99062 1.13076C4.58871 0.81884 4 1.10008 4 1.60375L4 18.3962C4 18.8999 4.58871 19.1812 4.99062 18.8692L15.7686 10.473C16.0771 10.2327 16.0771 9.76734 15.7686 9.52701Z"
      fill="#0869EE"
    />
  </svg>
);

const DefaultCollapsedIcon: React.FC<any> = (props) => <Icon component={Svg} {...props} />;

export default DefaultCollapsedIcon;
