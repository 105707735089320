import { useCurrentUser } from '@qualio/ui-components';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import configApi from '../../api/config';
import productApi from '../../api/product';
import { ProductParam } from '../../paramTypes';

export const useSelectedProduct = () => {
  const { companyId } = useCurrentUser();
  const { product } = useParams<ProductParam>() as ProductParam;

  const productQuery = useQuery({
    queryKey: ['PRODUCT', companyId, product],
    queryFn: () => productApi.fetch(companyId, product),
    retry: false,
  });

  const configQuery = useQuery({
    queryKey: ['PRODUCT_CONFIGS', companyId, product],
    queryFn: () => configApi.queryAll(companyId, product),
    enabled: !!productQuery.data,
  });

  return {
    isLoading: productQuery.isLoading || productQuery.isFetching || configQuery.isLoading || configQuery.isFetching,
    product: productQuery.data,
    configs: configQuery.data,
  };
};
