import React from 'react';

import { QStack, QText } from '@qualio/ui-components';

import { useLocalisingDateFormatter } from '../../lib/dateFormat';

export const DisplayTimestamp: React.FC<{ type: 'modified' | 'created'; date: string; user?: string }> = ({
  type,
  date,
  user,
}) => {
  const dateFormat = useLocalisingDateFormatter();
  const displayHeader = type === 'modified' ? 'Last Modified' : 'Created';
  let displayText = dateFormat(date);
  if (user) {
    displayText = `${displayText} by ${user}`;
  }
  return (
    <QStack direction="row" alignItems="center" data-cy={`display-timestamp-${type}`}>
      <QText fontSize="xs" color="grey.900">
        {displayHeader}
      </QText>
      <QText fontSize="xs" color="gray.500">
        {displayText}
      </QText>
    </QStack>
  );
};
