import React, { useCallback, useMemo } from 'react';

import { QBadge, deprecated, QTag, useToastProvider } from '@qualio/ui-components';
import { useQuery } from 'react-query';
import { useParams, Outlet } from 'react-router-dom';

import changeControlApi from '../../../api/changeControl';
import { DrawerProvider } from '../../../components_new/DesginElementDetailsDrawer/context/DrawerProvider';
import { useDrawerProvider } from '../../../components_new/DesginElementDetailsDrawer/context/useDrawerProvider';
import { ReviewEmptyState } from '../../../components_new/EmptyState/Review';
import { PageTable } from '../../../components_new/PageTable/PageTable';
import { useCompanyId } from '../../../context/CurrentUserContext';
import { useCustomSorting } from '../../../hooks/useCustomSorting';
import { sortByCode } from '../../../lib/sortByCode';
import { sortByAlphanumericInsensitive, sortByDate } from '../../../lib/sortFunctions';
import { ChangeControlParam, ProductParam } from '../../../paramTypes';
import { ChangeControl, ChangeControlStatus } from '../../../types/changeControl';
import { changeControlLabelAndColor } from './statusLabelAndColorTag';

export const ReviewListContainer: React.VFC = () => {
  const { product } = useParams() as ProductParam;
  return (
    <DrawerProvider url={`/product/${product}/change-control`}>
      <ReviewList />
    </DrawerProvider>
  );
};

export const ReviewList: React.VFC = () => {
  const company = useCompanyId();
  const { product, changeControlId } = useParams() as ProductParam & ChangeControlParam;

  const { handleItemSelected } = useDrawerProvider();

  const { showToast } = useToastProvider();
  const {
    isFetching: isLoading,
    data: changeControls,
    isError,
  } = useQuery(`query-change-controls-${product}`, () => changeControlApi.query(company, product));

  const { sortedData, handleSortByCallback } = useCustomSorting<ChangeControl>(
    { id: 'code', desc: true },
    {
      code: sortByCode,
      title: (first, second) => {
        return sortByAlphanumericInsensitive(first.title, second.title);
      },
      status: (first, second) => {
        return sortByAlphanumericInsensitive(first.status, second.status);
      },
      updated: (first, second) => {
        return sortByDate(new Date(first.updated), new Date(second.updated));
      },
    },
    changeControls ?? [],
  );

  const onRowClick = useCallback(
    (dataRow: deprecated.QDataRow) => {
      const changeControlId = dataRow['id'] as string;
      handleItemSelected(changeControlId);
    },
    [handleItemSelected],
  );

  if (isError) {
    showToast({
      title: `Oops. An error occurred`,
      description: 'Could not load the Review list',
      status: 'error',
      duration: 5000,
    });
  }

  const reviews: Array<deprecated.QDataRow> = useMemo<Array<deprecated.QDataRow>>(() => {
    return sortedData.map((changeControl) => ({
      code: changeControl.code,
      title: changeControl.title ?? '',
      updated: changeControl.updated,
      status: changeControl.status ? changeControl.status.toString() : '',
      id: changeControl.id,
    }));
  }, [sortedData]);

  const columns: Array<deprecated.QDataColumn> = [
    {
      Header: 'ID',
      accessor: 'code',
      width: '10%',
      Cell: ({ cell: { value } }: { cell: { value: string } }) => <QBadge>{value}</QBadge>,
    },
    {
      Header: 'Title',
      accessor: 'title',
      width: '65%',
    },
    {
      Header: 'Last Modified',
      accessor: 'updated',
      type: deprecated.QColumnType.DATE,
      width: '15%',
    },
    {
      Header: 'Status',
      accessor: 'status',
      width: '10%',
      Cell: ({ cell: { value } }: { cell: { value: ChangeControlStatus } }) => (
        <QTag variantColor={changeControlLabelAndColor(value).color}>{changeControlLabelAndColor(value).label}</QTag>
      ),
    },
  ];

  return (
    <div data-cy="review-list-table">
      <PageTable
        isLoading={isLoading}
        data={reviews}
        columns={columns}
        onRowClick={onRowClick}
        defaultSelectedRow={changeControlId}
        pageSize={25}
        emptyStateComponent={<ReviewEmptyState />}
        manualSortBy={{
          sortByCallback: handleSortByCallback,
          defaultSortByColumn: [{ id: 'code', desc: true }],
        }}
      />
      <Outlet />
    </div>
  );
};
