import { useToastProvider } from '@qualio/ui-components';
import { useMutation } from 'react-query';

import itemsApi from '../../../api/items';
import { useCompanyId } from '../../../context/CurrentUserContext';
import { TestResultSubmission } from '../../../types/testResult';

type UseTestResultMutationType = { isLoading: boolean; saveTestResult: (testResult: TestResultSubmission) => void };

export const useTestResultMutation = (
  productId: string,
  onSuccess: () => void,
  onError: () => void,
): UseTestResultMutationType => {
  const { showToast } = useToastProvider();
  const companyId = useCompanyId();

  const { isLoading, mutate } = useMutation(
    (testResult: TestResultSubmission) =>
      itemsApi.addTestResult(companyId, productId, {
        data: {
          type: 'testResult',
          result: testResult.result,
          executedBy: testResult.executedBy,
          notes: testResult.notes,
          testCases: [testResult.testCase],
          attachments: testResult.attachments,
        },
      }),
    {
      onSuccess: (data: any, { codeTitle }: TestResultSubmission) => {
        showToast({
          title: 'Test result saved.',
          description: codeTitle,
          status: 'success',
          duration: 5000,
        });
        onSuccess();
      },
      onError: () => {
        showToast({
          title: "We couldn't save your test result.",
          description: 'Please try again later.',
          status: 'error',
          duration: 5000,
        });
        onError();
      },
    },
  );

  return { isLoading, saveTestResult: mutate };
};
