import React, { useState } from 'react';

import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

import itemsApi from '../../../api/items';
import { useCompanyId } from '../../../context/CurrentUserContext';
import { useProduct } from '../../../context/product';
import { filterValidationErrors, scrollWindowToTop } from '../../../lib/formValidationUtilities';
import { RequestValidationError } from '../../../types/requestValidationError';
import { TestCasePayload } from '../assets/NativeTestCase.zod';
import NativeTestCaseContainer from './NativeTestCaseContainer';

const CreateNativeTestCase: React.FC = () => {
  const navigate = useNavigate();
  const companyId = useCompanyId();
  const [validationErrors, setValidationErrors] = useState<Array<RequestValidationError>>([]);
  const product = useProduct();

  const { mutate, isLoading } = useMutation(
    ({ payload }: { payload: TestCasePayload }) => itemsApi.create(companyId, product.id, { data: payload }),
    {
      onSuccess: () => {
        navigate(`/product/${product.id}/requirement`);
      },
      onError: (error: any) => {
        setValidationErrors(filterValidationErrors(error));
        scrollWindowToTop();
      },
    },
  );

  const submitTestCasePayload = async (testCasePayload: TestCasePayload) => mutate({ payload: testCasePayload });

  const cancelCreateTestCase = (): void => {
    navigate(`/product/${product.id}/requirement`);
  };

  return (
    <NativeTestCaseContainer
      product={product}
      title={'Create test case'}
      confirmText={'Create test case'}
      testCaseCode={''}
      isSubmittingPayload={isLoading}
      submitTestCasePayload={submitTestCasePayload}
      cancelCreateTestCase={cancelCreateTestCase}
      validationErrors={validationErrors}
    />
  );
};

export default CreateNativeTestCase;
