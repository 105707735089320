import React, { useCallback, useMemo } from 'react';

import { zodResolver } from '@hookform/resolvers/zod';
import { QBox, QButton, QDivider, QFormControl, QInput, QStack, QTag, QText, QTextarea } from '@qualio/ui-components';
import { Controller, FormProvider, useForm } from 'react-hook-form';

import { FeatureFlags } from '../../components/FeatureToggle/FeatureToggle.enum';
import useFeatureFlag from '../../components/FeatureToggle/hooks/useFeatureFlag';
import { DesignElementResourceItemFormField } from '../../components/QLookup';
import { useFmeaRiskCalculateScore } from '../../hooks/risk/useFmeaRiskCalculateScore';
import { handleFormErrors } from '../../lib/formValidationUtilities';
import { DesignElementType } from '../../types/baseQualityItem';
import { FMEARiskRequest, FMEARiskRequestType, RiskSubType } from '../../types/risk';
import { fmeaRiskScoreColor } from '../../views_new/Review/List/statusLabelAndColorTag';
import { FMEARiskScoreSelect } from './components/FMEARiskScoreSelect';
import { RiskSourceMitigationItemSelect } from './components/RiskSourceMitigationItemSelect';
import { RiskStatusLabelSelect } from './components/RiskStatusLabelSelect';
import { RiskFormProps } from './type';

const defaultValue: FMEARiskRequestType = {
  title: '',
  category: { qri: '', label: '' },
  statusLabel: '',
  failureMode: '',
  failureModeEffects: '',
  failureCauses: '',
  evaluationMethod: '',
  preMitigation: {
    severity: 0,
    occurrence: 0,
    detectability: 0,
  },
  sourceItems: [],
  mitigationItems: [],
};

export const FMEAForm: React.FC<RiskFormProps<FMEARiskRequestType>> = ({
  isSubmitting,
  configs,
  onClose,
  onSubmit,
  riskConfig,
  autoCompleteOptions,
  initialValue = defaultValue,
  itemId,
}) => {
  const features = useFeatureFlag([FeatureFlags.DESIGN_CONTROLS_CATEGORIES]);
  const resourceIdentifier = riskConfig.category?.[0].resourceIdentifier;

  const severityOccurrenceDetectabilityOptions = useMemo(
    () => [...Array(riskConfig.assessment.fmea?.scaleMaxValue).keys()].map((i) => i + 1),
    [riskConfig.assessment.fmea?.scaleMaxValue],
  );

  const methods = useForm<FMEARiskRequestType>({
    mode: 'onChange',
    defaultValues: initialValue,
    reValidateMode: 'onChange',
    resolver: zodResolver(FMEARiskRequest),
  });
  const {
    formState: { errors },
    setError,
    watch,
  } = methods;

  const [preMitigationSeverity, preMitigationOccurence, preMitigationDetectability] = watch([
    'preMitigation.severity',
    'preMitigation.occurrence',
    'preMitigation.detectability',
  ]);
  const [postMitigationSeverity, postMitigationOccurence, postMitigationDetectability] = watch([
    'postMitigation.severity',
    'postMitigation.occurrence',
    'postMitigation.detectability',
  ]);

  const preMitigationRiskScore = useFmeaRiskCalculateScore('preMitigation', {
    severity: preMitigationSeverity,
    occurrence: preMitigationOccurence,
    detectability: preMitigationDetectability,
  });
  const showPostMitigationSections =
    preMitigationRiskScore &&
    riskConfig.assessment.fmea &&
    preMitigationRiskScore >= riskConfig.assessment.fmea?.mitigationThreshold;
  const postMitigationRiskScore = useFmeaRiskCalculateScore('postMitigation', {
    severity: postMitigationSeverity,
    occurrence: postMitigationOccurence,
    detectability: postMitigationDetectability,
  });

  const handleSubmitClick = useCallback(
    (payload: FMEARiskRequestType) => {
      const sourceItemIds = payload.sourceItems?.map((item) => item.id) ?? [];
      const mitigationItemIds = payload.mitigationItems?.map((item) => item.id) ?? [];

      onSubmit(
        {
          ...payload,
          id: itemId,
          subType: RiskSubType.FMEA,
          type: DesignElementType.RISK,
          sourceItems: sourceItemIds,
          mitigationItems: mitigationItemIds,
          category: !!payload.category?.qri ? payload.category : undefined,
        } as any,
        {
          onSuccess: () => undefined,
          onError: handleFormErrors(setError as any),
        },
      );
    },
    [onSubmit, itemId, setError],
  );

  return (
    <QBox margin="auto" data-cy="fmea-risk-form">
      <FormProvider {...methods}>
        <QStack spacing="24px">
          <QFormControl label="Title" isInvalid={!!errors.title} error={errors.title?.message} id={'title'}>
            <Controller
              name={'title'}
              render={({ field: { onChange, value } }) => (
                <QInput onChange={onChange} value={value} data-cy="title-input" />
              )}
            />
          </QFormControl>
          {features[FeatureFlags.DESIGN_CONTROLS_CATEGORIES] && resourceIdentifier && (
            <DesignElementResourceItemFormField name="category" label="Category" resourceSubType={resourceIdentifier} />
          )}
          <QFormControl
            label="Status"
            isInvalid={!!errors.statusLabel}
            error={errors.statusLabel?.message}
            id={'statusLabel'}
          >
            <Controller
              name={'statusLabel'}
              render={({ field: { onChange, value } }) => (
                <RiskStatusLabelSelect value={value} options={riskConfig.workflow.states} onChange={onChange} />
              )}
            />
          </QFormControl>
          <QDivider mb="30px" />
          <QBox mb={2}>
            <QText weight={'semibold'} fontSize="md">
              Initial Assessment
            </QText>
          </QBox>
          <QFormControl
            label="Failure Mode"
            isInvalid={!!errors.failureMode}
            error={errors.failureMode?.message}
            id={'failureMode'}
          >
            <Controller
              name={'failureMode'}
              render={({ field: { onChange, value } }) => (
                <QTextarea onChange={onChange} value={value} data-cy="failureMode-input" />
              )}
            />
          </QFormControl>
          <QFormControl
            label="Effects of Failure Mode"
            isInvalid={!!errors.failureModeEffects}
            error={errors.failureModeEffects?.message}
            id={'failureModeEffects'}
          >
            <Controller
              name={'failureModeEffects'}
              render={({ field: { onChange, value } }) => (
                <QTextarea onChange={onChange} value={value} data-cy="failureModeEffects-input" />
              )}
            />
          </QFormControl>
          <QFormControl
            label="Severity"
            isInvalid={!!errors.preMitigation?.severity}
            error={errors.preMitigation?.severity?.message}
            id={'preMitigation.severity'}
          >
            <Controller
              name={'preMitigation.severity'}
              render={({ field: { onChange, value } }) => (
                <FMEARiskScoreSelect
                  onChange={onChange}
                  value={value}
                  options={severityOccurrenceDetectabilityOptions}
                  name={'preMitigation.severity-input'}
                  placeholder={'Initial severity score'}
                />
              )}
            />
          </QFormControl>
          <QFormControl
            label="Causes of Failure"
            isInvalid={!!errors.failureCauses}
            error={errors.failureCauses?.message}
            id={'failureCauses'}
          >
            <Controller
              name={'failureCauses'}
              render={({ field: { onChange, value } }) => (
                <QTextarea onChange={onChange} value={value} data-cy="failureCauses-input" />
              )}
            />
          </QFormControl>
          <QFormControl
            label="Occurrence"
            isInvalid={!!errors.preMitigation?.occurrence}
            error={errors.preMitigation?.occurrence?.message}
            id={'preMitigation.occurrence'}
          >
            <Controller
              name={'preMitigation.occurrence'}
              render={({ field: { onChange, value } }) => (
                <FMEARiskScoreSelect
                  onChange={onChange}
                  value={value}
                  options={severityOccurrenceDetectabilityOptions}
                  name={'preMitigation.occurrence-input'}
                  placeholder={'Initial occurrence score'}
                />
              )}
            />
          </QFormControl>
          <QFormControl
            label="Current Controls / Evaluation Method"
            isInvalid={!!errors.evaluationMethod}
            error={errors.evaluationMethod?.message}
            id={'evaluationMethod'}
          >
            <Controller
              name={'evaluationMethod'}
              render={({ field: { onChange, value } }) => (
                <QTextarea onChange={onChange} value={value} data-cy="evaluationMethod-input" />
              )}
            />
          </QFormControl>
          <QFormControl
            label="Detectability"
            isInvalid={!!errors.preMitigation?.detectability}
            error={errors.preMitigation?.detectability?.message}
            id={'preMitigation.detectability'}
          >
            <Controller
              name={'preMitigation.detectability'}
              render={({ field: { onChange, value } }) => (
                <FMEARiskScoreSelect
                  onChange={onChange}
                  value={value}
                  options={severityOccurrenceDetectabilityOptions}
                  name={'preMitigation.detectability-input'}
                  placeholder={'Initial detectability score'}
                />
              )}
            />
          </QFormControl>
          {preMitigationRiskScore && (
            <QBox mb="30px" data-cy="pre-mitigation-risk-score">
              <QBox mb="5px">
                <QText weight={'semibold'} fontSize="md">
                  Risk Score
                </QText>
              </QBox>
              <QTag
                variantColor={fmeaRiskScoreColor(
                  preMitigationRiskScore,
                  riskConfig.assessment.fmea?.mitigationThreshold,
                )}
              >
                {preMitigationRiskScore}
              </QTag>
            </QBox>
          )}
          <Controller
            name={'sourceItems'}
            defaultValue={initialValue.sourceItems ?? []}
            {...{ id: 'sourceItems' }}
            render={({ field: { onChange, value } }) => (
              <RiskSourceMitigationItemSelect
                title={'Impacted Design Elements'}
                titleVariant={true}
                name={'sourceItems'}
                placeholder={'Search design elements to add as source'}
                options={autoCompleteOptions}
                values={value}
                onChange={onChange}
                configs={configs}
              />
            )}
          />
          <QDivider mt="30px" mb="30px" />
          {showPostMitigationSections && (
            <>
              <QBox mb={2}>
                <QText weight={'semibold'} fontSize="md">
                  Mitigation
                </QText>
              </QBox>
              <QFormControl
                label="Description"
                isInvalid={!!errors.riskControl?.mitigation}
                error={errors.riskControl?.mitigation?.message}
                id={'riskControl.mitigation'}
              >
                <Controller
                  name={'riskControl.mitigation'}
                  render={({ field: { onChange, value } }) => (
                    <QTextarea onChange={onChange} value={value} data-cy="riskControl.mitigation-input" />
                  )}
                />
              </QFormControl>
              <Controller
                name={'mitigationItems'}
                defaultValue={initialValue.mitigationItems ?? []}
                {...{ id: 'mitigationItems' }}
                render={({ field: { onChange, value } }) => (
                  <RiskSourceMitigationItemSelect
                    title={'Design Elements Associated With Mitigation'}
                    titleVariant={true}
                    name={'mitigationItems'}
                    placeholder={'Search design elements to add as mitigation'}
                    options={autoCompleteOptions}
                    values={value}
                    onChange={onChange}
                    configs={configs}
                  />
                )}
              />
              <QDivider mt="30px" mb="30px" />
              <QBox mb={2}>
                <QText weight={'semibold'} fontSize="md">
                  Final Assessment
                </QText>
              </QBox>
              <QFormControl
                label="Severity"
                isInvalid={!!errors.postMitigation?.severity}
                error={errors.postMitigation?.severity?.message}
                id={'postMitigation.severity'}
              >
                <Controller
                  name={'postMitigation.severity'}
                  render={({ field: { onChange, value } }) => (
                    <FMEARiskScoreSelect
                      onChange={onChange}
                      value={value}
                      options={severityOccurrenceDetectabilityOptions}
                      name={'postMitigation.severity-input'}
                      placeholder={'Final severity score'}
                    />
                  )}
                />
              </QFormControl>
              <QFormControl
                label="Occurrence"
                isInvalid={!!errors.postMitigation?.occurrence}
                error={errors.postMitigation?.occurrence?.message}
                id={'postMitigation.occurrence'}
              >
                <Controller
                  name={'postMitigation.occurrence'}
                  {...{ id: 'title' }}
                  render={({ field: { onChange, value } }) => (
                    <FMEARiskScoreSelect
                      onChange={onChange}
                      value={value}
                      options={severityOccurrenceDetectabilityOptions}
                      name={'postMitigation.occurrence-input'}
                      placeholder={'Final occurrence score'}
                    />
                  )}
                />
              </QFormControl>
              <QFormControl
                label="Detectability"
                isInvalid={!!errors.postMitigation?.detectability}
                error={errors.postMitigation?.detectability?.message}
                id={'postMitigation.detectability'}
              >
                <Controller
                  name={'postMitigation.detectability'}
                  render={({ field: { onChange, value } }) => (
                    <FMEARiskScoreSelect
                      onChange={onChange}
                      value={value}
                      options={severityOccurrenceDetectabilityOptions}
                      name={'postMitigation.detectability-input'}
                      placeholder={'Final detectability score'}
                    />
                  )}
                />
              </QFormControl>
              {postMitigationRiskScore && (
                <QBox mb="30px" data-cy="post-mitigation-risk-score">
                  <QBox mb="5px">
                    <QText weight={'semibold'} fontSize="md">
                      Risk Score
                    </QText>
                  </QBox>
                  <QTag
                    variantColor={fmeaRiskScoreColor(
                      postMitigationRiskScore,
                      riskConfig.assessment.fmea?.mitigationThreshold,
                    )}
                  >
                    {postMitigationRiskScore}
                  </QTag>
                </QBox>
              )}
              <QDivider mb="30px" />
            </>
          )}
          <QStack spacing="20px" direction="row" justifyContent="flex-end">
            <QButton variant={'link'} onClick={onClose} data-cy="cancel-button" isDisabled={isSubmitting}>
              Cancel
            </QButton>
            <QButton
              variant={'solid'}
              data-cy="submit-button"
              onClick={methods.handleSubmit(handleSubmitClick)}
              isLoading={isSubmitting}
            >
              Save
            </QButton>
          </QStack>
        </QStack>
      </FormProvider>
    </QBox>
  );
};
