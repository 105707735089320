import React from 'react';

import Icon from '@ant-design/icons';

const Svg = () => (
  <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="20" height="20" fill="white" />
    <rect
      x="0.5"
      y="0.5"
      width="19"
      height="19"
      rx="2.5"
      stroke="#2F80ED"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle cx="7.375" cy="10.375" r="2.875" stroke="#2F80ED" />
    <line x1="4.375" y1="10.5" x2="1" y2="10.5" stroke="#2F80ED" />
  </svg>
);

const Req3Icon: React.FC<any> = (props) => <Icon component={Svg} {...props} />;

export default Req3Icon;
