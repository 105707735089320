import { useQueries, UseQueryOptions } from 'react-query';

import configApi from '../../api/config';
import { useMessagesContext } from '../../components/Messages';
import { useCompanyId } from '../../context/CurrentUserContext';
import { DesignElementType } from '../../types/baseQualityItem';
import { BaseQualityTypeConfig, QualityTypeConfigWithType } from '../../types/qualityTypeConfig';

export type UseQueryDesignElementConfigurationsReturnType = {
  designElementConfigurations: Array<QualityTypeConfigWithType>;
  isLoading: boolean;
};

export function useQueryDesignElementConfigurations<T extends BaseQualityTypeConfig>(
  productId: string,
  designElementTypes: Array<DesignElementType>,
): UseQueryDesignElementConfigurationsReturnType {
  const companyId = useCompanyId();
  const { clearMessages } = useMessagesContext();

  const queries = designElementTypes.map((designElementType) => {
    return {
      queryKey: `queryDesignElementConfigurations-company-${companyId}-product-${productId}-designElementTypes-${designElementType}`,
      queryFn: () => {
        clearMessages();
        return configApi
          .retrieveConfig<T>(companyId, productId, designElementType)
          .then((response) => {
            if (!response) {
              return;
            }

            return {
              type: designElementType,
              ...response,
            };
          })
          .catch(() => undefined);
      },
      cacheTime: 0,
    } as UseQueryOptions<T>;
  });

  const results = useQueries(queries);

  const isLoading = results.some((result) => result.isLoading);

  const designElementConfigurations = results
    .filter((result) => result.data !== undefined)
    .map((result, index) => {
      if (result.data) {
        return result.data as unknown as QualityTypeConfigWithType;
      }
      return undefined;
    })
    .filter((result) => result !== undefined) as Array<QualityTypeConfigWithType>;

  return { designElementConfigurations, isLoading };
}
