import React, { useEffect, useMemo, useState } from 'react';

import { Product } from '@design-controls/types';
import { zodResolver } from '@hookform/resolvers/zod';
import { QAttachment, QAttachments, QFormControl, QInput, QSelect, useToastProvider } from '@qualio/ui-components';
import { Controller, FormProvider, useForm, useWatch } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import { attachmentsApi } from '../../../api/attachmentsApi';
import {
  BasicForm,
  BasicFormSectionHeader,
  Editor,
  HorizontalLine,
  InputLabel,
  RelatedElements,
  SegmentedControl,
} from '../../../components';
import { FeatureFlags } from '../../../components/FeatureToggle/FeatureToggle.enum';
import useFeatureFlag from '../../../components/FeatureToggle/hooks/useFeatureFlag';
import { DesignElementResourceItemFormField } from '../../../components/QLookup';
import { SearchElementType } from '../../../components/SearchField/SearchField';
import { useCompanyId } from '../../../context/CurrentUserContext';
import { useQualityConfigs } from '../../../context/qualityConfigs';
import { QualityTypeConfigWithIntegrations } from '../../../context/qualityConfigTypes';
import { AttachmentEventsHelper } from '../../../lib/attachments/attachmentEventsHelper';
import {
  getTestCaseTypeOrEmpty,
  retrieveNativeRequirements,
  retrieveRequirements,
} from '../../../lib/designControlNativeEnabled';
import { ProductRequirementParam } from '../../../paramTypes';
import { AttachmentWithIdType } from '../../../types/generatePresignedUrl';
import {
  FormRelatedElementType,
  FormRequirement,
  ProductRequirementForm,
  ProductRequirementFormRelatedElementsType,
  ProductRequirementFormType,
} from '../../../types/requirement';

type StatusType = {
  value: string;
  label: string;
};

type RequirementType = {
  prefix: string;
  title: string;
  type: string;
};

type ResourceIdentifier = {
  categoryResourceIdentifier?: string;
  componentResourceIdentifier?: string;
};

const text: Record<string, { title: string; confirmText: string }> = {
  create: { title: 'Create requirement', confirmText: 'Create requirement' },
  edit: { title: 'Edit requirement', confirmText: 'Save Changes' },
};

type ProductRequirementProps = {
  product: Product;
  values?: {
    title: ProductRequirementFormType['title'];
    type: string;
    description: ProductRequirementFormType['description'];
    statusLabel: string;
    category: ProductRequirementFormType['category'];
    component: ProductRequirementFormType['component'];
  } & ProductRequirementFormRelatedElementsType & { attachments?: Array<AttachmentWithIdType> };
  onSubmit: (requirement: FormRequirement) => Promise<void>;
  isSubmitting?: boolean;
};

const requirementRuleMap: Record<string, Set<string>> = {
  req1: new Set(['req2']),
  req2: new Set(['req1', 'req3']),
  req3: new Set(['req2']),
};

const ProductRequirement: React.FC<ProductRequirementProps> = ({
  product,
  values,
  onSubmit,
  isSubmitting,
  children,
}) => {
  const { configs, loading } = useQualityConfigs();
  const { requirementId } = useParams() as ProductRequirementParam;
  const navigate = useNavigate();
  const companyId = useCompanyId();
  const { showToast } = useToastProvider();
  const features = useFeatureFlag([FeatureFlags.DESIGN_CONTROLS_CATEGORIES]);

  const existingAttachments: Array<QAttachment> = values?.attachments ?? [];

  const nativeRequirements: QualityTypeConfigWithIntegrations[] = useMemo<QualityTypeConfigWithIntegrations[]>(
    () => retrieveNativeRequirements(configs),
    [configs],
  );

  const requirementItems: Array<ProductRequirementFormType['type']> = useMemo<
    Array<ProductRequirementFormType['type']>
  >(
    () =>
      nativeRequirements.map((item) => ({
        prefix: item.codePrefix || '',
        title: item.label,
        type: item.type,
      })),
    [nativeRequirements],
  );

  const [attachments, setAttachments] = useState<Array<QAttachment>>(existingAttachments);
  const [isBusy, setIsBusy] = useState<boolean>(false);

  const defaultValues: ProductRequirementFormType = useMemo<ProductRequirementFormType>(() => {
    let defaultValues: ProductRequirementFormType = {
      title: '',
      category: { qri: '', label: '' },
      component: { qri: '', label: '' },
      type: requirementItems[0],
      description: '',
      statusLabel: { value: '', label: '' },
      req1: [],
      req2: [],
      req3: [],
      testCase: [],
      testCase1: [],
      testCase2: [],
      testCase3: [],
    };
    if (values !== undefined) {
      defaultValues = {
        ...defaultValues,
        ...values,
        statusLabel: {
          value: values.statusLabel,
          label: values.statusLabel,
        },
        type: requirementItems.find((item) => item.type === values.type) || requirementItems[0],
      };
    }
    return defaultValues;
  }, [requirementItems, values]);

  const methods = useForm<ProductRequirementFormType>({
    defaultValues,
    mode: 'onChange',
    resolver: zodResolver(ProductRequirementForm),
  });
  const {
    formState: { errors },
  } = methods;

  const typeWatch: RequirementType = useWatch({
    name: 'type',
    control: methods.control,
    defaultValue: defaultValues.type,
  }) as RequirementType;

  useEffect(() => {
    if (!requirementItems.length && !loading) {
      navigate(`/product/${product.id}/requirement`);
    }
  }, [requirementItems, navigate, product, loading]);

  const statusItems: StatusType[] = useMemo<StatusType[]>(() => {
    const type = typeWatch ?? defaultValues.type;

    if (!type) {
      return [];
    }
    const currentItem = nativeRequirements.find((item) => item.label === type.title);
    if (!currentItem?.workflow) {
      return [];
    }

    return currentItem.workflow.states.map((item) => ({
      label: item.name,
      value: item.name,
    }));
  }, [nativeRequirements, typeWatch, defaultValues.type]);

  const { categoryResourceIdentifier, componentResourceIdentifier } = useMemo<ResourceIdentifier>(() => {
    const type = typeWatch?.type ?? defaultValues.type?.type;
    const config = nativeRequirements.find((item) => (item.type as any) === type);
    return {
      categoryResourceIdentifier: config?.category?.[0].resourceIdentifier,
      componentResourceIdentifier: config?.component?.[0].resourceIdentifier,
    };
  }, [nativeRequirements, typeWatch, defaultValues.type]);

  const handleSubmit = async () => {
    const formValues = methods.getValues();

    const requirements: FormRequirement['requirements'] = Object.entries(requirementRuleMap).reduce(
      (requirements: FormRequirement['requirements'], [key, value]) => {
        if (formValues[key as keyof ProductRequirementFormRelatedElementsType]) {
          return [
            ...(requirements ?? []),
            ...(formValues[key as keyof ProductRequirementFormRelatedElementsType] || [])
              .filter(Boolean)
              .map((item: FormRelatedElementType) => item.value),
          ];
        }
        return requirements;
      },
      [],
    );
    const testCases: FormRequirement['testCases'] = (
      formValues.testCase ||
      formValues.testCase1 ||
      formValues.testCase2 ||
      formValues.testCase3 ||
      []
    ).map((item) => item.value);

    const result: FormRequirement = {
      title: formValues.title,
      type: formValues.type.type,
      category: !!formValues.category?.qri ? formValues.category : undefined,
      component: !!formValues.component?.qri ? formValues.component : undefined,
      description: formValues.description,
      statusLabel: formValues.statusLabel.value,
      requirements,
      testCases,
    };

    result.attachments = attachments;

    await onSubmit(result);
  };

  const handleTypeChange = () => {
    methods.setValue('statusLabel', { label: '', value: '' });
    methods.setValue('category', { qri: '', label: '' });
    methods.setValue('component', { qri: '', label: '' });
    methods.setValue('req1', []);
    methods.setValue('req2', []);
    methods.setValue('req3', []);
    methods.setValue('testCase', []);
    methods.setValue('testCase1', []);
    methods.setValue('testCase2', []);
    methods.setValue('testCase3', []);
  };

  const testCaseType: string = useMemo<string>(() => {
    const type = typeWatch ?? defaultValues.type;
    if (!type) {
      return '';
    }

    return getTestCaseTypeOrEmpty(configs, type.type);
  }, [typeWatch, defaultValues, configs]);

  const allRequirements: QualityTypeConfigWithIntegrations[] = useMemo<QualityTypeConfigWithIntegrations[]>(
    () => retrieveRequirements(configs),
    [configs],
  );

  const otherDesignElementsSearchFields: QualityTypeConfigWithIntegrations[] = useMemo<
    QualityTypeConfigWithIntegrations[]
  >(() => {
    const type = typeWatch ?? defaultValues.type;

    if (!type) {
      return [];
    }
    const currentRequirement = nativeRequirements.find((requirement) => requirement.type === type.type);
    if (currentRequirement) {
      const setFilter = requirementRuleMap[currentRequirement.type];
      return allRequirements.filter((requirement) => setFilter.has(requirement.type));
    } else {
      return [];
    }
  }, [nativeRequirements, allRequirements, typeWatch, defaultValues]);

  const handleDeleteChip = (key: keyof ProductRequirementFormRelatedElementsType, element: SearchElementType) => {
    const values = [...(methods.getValues()?.[key] as SearchElementType[])];
    const index = values.findIndex((item) => item.label === element.label);
    values.splice(index, 1);
    methods.setValue(key, values);
  };

  const testCaseLabelsOnForm: Record<string, string> = useMemo(() => {
    const testCaseLabels: Record<string, string> = {};
    configs.forEach((cnf) => {
      if (cnf.type.startsWith('testCase')) {
        testCaseLabels[cnf.type] = cnf.codePrefix + ' - ' + cnf.label;
      }
    });
    return testCaseLabels;
  }, [configs]);

  return (
    <FormProvider {...methods}>
      <BasicForm
        title={requirementId ? text.edit.title : text.create.title}
        product={product}
        isError={!!Object.keys(errors).length}
        isLoading={loading}
        errorText="Please fill out all the form"
        cancelText="Cancel"
        confirmText={requirementId ? text.edit.confirmText : text.create.confirmText}
        confirmButtonLoading={isSubmitting}
        onCancel={() => navigate(`/product/${product.id}/requirement`)}
        onConfirm={methods.handleSubmit(handleSubmit)}
        isBusy={isBusy}
      >
        {children}
        <QFormControl label={'Title'} isInvalid={!!errors.title?.message} error={errors.title?.message}>
          <div style={{ marginBottom: '32px' }}>
            <Controller
              name={'title'}
              render={({ field: { onChange, value } }) => <QInput data-cy="title" value={value} onChange={onChange} />}
            />
          </div>
        </QFormControl>
        {features[FeatureFlags.DESIGN_CONTROLS_CATEGORIES] && componentResourceIdentifier && (
          <div style={{ marginBottom: '32px' }}>
            <DesignElementResourceItemFormField
              name="component"
              label="Component"
              resourceSubType={componentResourceIdentifier}
            />
          </div>
        )}
        {features[FeatureFlags.DESIGN_CONTROLS_CATEGORIES] && categoryResourceIdentifier && (
          <div style={{ marginBottom: '32px' }}>
            <DesignElementResourceItemFormField
              name="category"
              label="Category"
              resourceSubType={categoryResourceIdentifier}
            />
          </div>
        )}
        <QFormControl label={'Requirement type'}>
          <Controller
            name="type"
            defaultValue={requirementItems[0]}
            render={({ field: { onChange, value } }) => (
              <SegmentedControl
                items={requirementItems}
                onClick={(e) => {
                  onChange(e);
                  handleTypeChange();
                }}
                active={value}
                isDisabled={requirementId !== undefined}
              />
            )}
          />
        </QFormControl>
        <QFormControl
          label={'Description'}
          isInvalid={!!errors.description?.message}
          error={errors.description?.message}
        >
          <div style={{ marginBottom: '32px' }}>
            <Controller
              name="description"
              render={({ field: { onChange, value } }) => <Editor onChange={onChange} value={value} />}
            />
          </div>
        </QFormControl>
        <QFormControl
          label={'Status'}
          isInvalid={!!errors.statusLabel?.label?.message}
          error={errors.statusLabel?.label?.message}
        >
          <Controller
            name={'statusLabel'}
            render={({ field: { onChange, value } }) => (
              <div data-cy="statusLabel-input">
                <QSelect
                  {...{ id: 'statusLabel' }}
                  options={statusItems}
                  onChange={onChange}
                  value={value.value}
                  isDisabled={false}
                  isLoading={false}
                  filterOption={null}
                  isSearchable={false}
                  key={`status-label-${testCaseType}`}
                />
              </div>
            )}
          />
        </QFormControl>

        <HorizontalLine />
        <BasicFormSectionHeader title="Attachments" />
        <QAttachments
          isBusy={setIsBusy}
          attachments={existingAttachments}
          itemInsertLocation={(elA, elB) => elA.filename?.localeCompare(elB.filename)}
          uploadInfoProvider={attachmentsApi.uploadInfoProvider(companyId, product.id)}
          onAdd={(attachment: QAttachment) => {
            setAttachments([...attachments, attachment]);
            return Promise.resolve();
          }}
          onClick={(attachmentId: string) => {
            attachmentsApi.downloadAttachment(companyId, product.id, attachments, attachmentId);
            return Promise.resolve();
          }}
          onRemove={(attachmentId: string) => {
            setAttachments(attachments.filter((a) => a.id !== attachmentId));
            return Promise.resolve();
          }}
          onRevert={(attachmentId: string) => {
            setAttachments(attachments.filter((a) => a.id !== attachmentId));
            return Promise.resolve();
          }}
          onWarning={(warning) => AttachmentEventsHelper.handleWarning(warning, showToast)}
          maxFiles={10}
          maxFileSize="100MB"
          maxTotalFileSize="500MB"
        />

        <HorizontalLine />
        <BasicFormSectionHeader title="Connect related design elements" subTitle="Can be done later" />
        {otherDesignElementsSearchFields.map((item) => (
          <div key={`related-design-element-${item.codePrefix}-${item.label}`}>
            <InputLabel title={`${item.codePrefix?.toUpperCase()} - ${item.label}`} />
            <Controller
              control={methods.control}
              name={item.type as any}
              render={({ field: { onChange, value } }) => (
                <RelatedElements
                  placeHolderText="Search Design Elements"
                  handleDeleteChip={(element: SearchElementType) =>
                    handleDeleteChip(item.type as keyof ProductRequirementFormRelatedElementsType, element)
                  }
                  watchValue={item.type}
                  value={value}
                  onChange={onChange}
                  key={item.label}
                />
              )}
            />
          </div>
        ))}
        {testCaseType && (
          <>
            <InputLabel title={testCaseLabelsOnForm[testCaseType] || 'Test Cases'} />
            <Controller
              name={testCaseType as any}
              control={methods.control}
              render={({ field: { onChange, value } }) => (
                <RelatedElements
                  placeHolderText="Search Test Cases"
                  handleDeleteChip={(element: SearchElementType) =>
                    handleDeleteChip(testCaseType as keyof ProductRequirementFormRelatedElementsType, element)
                  }
                  watchValue={testCaseType}
                  value={value}
                  onChange={onChange}
                />
              )}
            />
          </>
        )}
      </BasicForm>
    </FormProvider>
  );
};

export default ProductRequirement;
