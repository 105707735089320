import React from 'react';

import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import classNames from 'classnames';

import styles from './PageLoading.module.less';

const PageLoading: React.FC<{ className?: string }> = (props) => {
  return (
    <div className={classNames(styles.loadingContainer, props.className)} data-cy={'page-loader'}>
      <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
    </div>
  );
};

export default PageLoading;
