import styled from '@emotion/styled';

export const QViewsStylingFix = styled.div`
  p {
    margin-bottom: unset !important;
  }

  ul {
    margin-bottom: unset !important;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: unset !important;
  }
`;
