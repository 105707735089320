import React, { useCallback, useState } from 'react';

import { QAsyncSelect, QBox, QButton, QFlex, QSelectItem, QSelectPlaceholder, QText } from '@qualio/ui-components';

import { SearchAndSelectElementType } from '../SearchAndSelectElements/SearchAndSelectElement';
import { SearchAndSelectElementOption } from '../SearchAndSelectElements/SearchAndSelectElementOption';

interface AsyncSearchAndSelectElementsProps {
  title: string;
  name: string;
  placeHolderText: string;
  onChange: (e: SearchAndSelectElementType[]) => void;
  value: SearchAndSelectElementType[];
  loadOptions: (inputText: string) => Promise<SearchAndSelectElementType[]>;
  sorter?: (elementA: SearchAndSelectElementType, elementB: SearchAndSelectElementType) => number;
}

export const AsyncSearchAndSelectElement: React.FC<AsyncSearchAndSelectElementsProps> = ({
  title,
  name,
  placeHolderText,
  onChange,
  value,
  loadOptions,
  sorter,
}) => {
  const [availableOptions, setAvailableOptions] = useState<SearchAndSelectElementType[]>([]);
  const [selectedOptions, setSelectedOptions] = useState<SearchAndSelectElementType[]>(value);
  const [isLoading, setIsLoading] = useState(false);

  const handleOnChange = (selectedItem: QSelectItem | null) => {
    const selectedOption = availableOptions.find((o) => o.id === selectedItem?.value);
    if (selectedOption) {
      const selected = [...selectedOptions, selectedOption];
      setSelectedOptions(selected);
      onChange(selected);
    }
  };

  const handleClearAll = () => {
    setSelectedOptions([]);
    setAvailableOptions([]);
    onChange([]);
  };

  const handleDeleteElement = useCallback(
    (id: string) => {
      const newAllValues = selectedOptions.filter((item) => item.id !== id);
      setSelectedOptions(newAllValues);
      onChange(newAllValues);
    },
    [onChange, setSelectedOptions, selectedOptions],
  );

  availableOptions.sort(sorter);
  selectedOptions.sort(sorter);

  const internalLoadOptions = (inputText: string): Promise<QSelectItem[]> => {
    setIsLoading(true);
    return loadOptions(inputText)
      .then((options) => {
        setAvailableOptions(options);
        const selectedIds = selectedOptions.map((v) => v.id);
        return options.filter((o) => !selectedIds.includes(o.id)).map((o) => ({ label: o.label, value: o.id }));
      })
      .finally(() => setIsLoading(false));
  };
  return (
    <QBox data-cy={`search-select-elements-input-${name}`}>
      <QFlex justify="space-between">
        <QText color="gray.700" fontSize="sm" fontWeight="semibold" data-cy={`search-select-elements-title-${name}`}>
          {title}
        </QText>
        <QButton
          onClick={() => handleClearAll()}
          variant="link"
          size="xs"
          data-cy={`search-select-elements-clear-all-${name}`}
        >
          Clear all
        </QButton>
      </QFlex>
      <QBox mt="8px" data-cy={`search-select-elements-search-field-${name}`}>
        <QAsyncSelect
          loadOptions={internalLoadOptions}
          isDisabled={false}
          isLoading={isLoading}
          isSearchable={true}
          clearInputOnSelect={true}
          noOptionsMessage={({ inputValue }) =>
            inputValue ? 'No elements found' : 'Start typing to search for elements'
          }
          isOptionDisabled={() => false}
          onChange={handleOnChange}
        >
          {placeHolderText && (
            <QSelectPlaceholder>
              <QText>Start typing to get options...</QText>
            </QSelectPlaceholder>
          )}
        </QAsyncSelect>
      </QBox>

      {selectedOptions.length > 0 && (
        <div data-cy={`search-select-elements-${name}`}>
          <SearchAndSelectElementOption selectedOptions={selectedOptions} handleDeleteElement={handleDeleteElement} />
        </div>
      )}
    </QBox>
  );
};
