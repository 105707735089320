import React from 'react';

import { TestCaseDesignElement } from '@design-controls/types';
import { QStack, QText } from '@qualio/ui-components';
import { sanitize } from 'dompurify';

export const TestCase: React.FC<TestCaseDesignElement> = ({ category, description }) => {
  return (
    <QStack gap="8px">
      <QStack direction="row">
        {category && (
          <QText fontSize="sm">
            <QText as="span" fontWeight="semibold">
              Category
            </QText>{' '}
            {category.label}
          </QText>
        )}
      </QStack>
      {description && (
        <>
          <QText color="gray.700" fontSize={14} weight="semibold">
            Description
          </QText>
          <QText fontSize={14} dangerouslySetInnerHTML={{ __html: sanitize(description) }} />
        </>
      )}
    </QStack>
  );
};
