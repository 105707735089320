import React from 'react';

import { useToastProvider } from '@qualio/ui-components';
import { useNavigate } from 'react-router-dom';

import changeControlApi from '../../../api/changeControl';
import { DrawerProvider } from '../../../components_new/DesginElementDetailsDrawer/context/DrawerProvider';
import { FormHeader } from '../../../components_new/FormHeader';
import { Loading } from '../../../components_new/Loading';
import { useCompanyId } from '../../../context/CurrentUserContext';
import { useProduct } from '../../../context/product';
import { useQualityUsers } from '../../../hooks/qualio/useQualityUsers';
import { ChangeControlPayloadType, ChangeControlQualityItemPayloadType } from '../../../types/changeControl';
import { ReviewForm } from './reviewForm';
import { useReviewData } from './useReviewData';

export const CreateReviewForm: React.FC = () => {
  const navigate = useNavigate();
  const company = useCompanyId();
  const { showToast } = useToastProvider();
  const product = useProduct();
  const { isError, loading, qualityItems, qualityConfigs } = useReviewData(company, product.id);

  const { qualityUsers: approvers, isLoading: isApproverLoading, isError: isApproverError } = useQualityUsers(company);

  const goToReviewList = () => navigate(`/product/${product.id}/change-control`);

  const onSubmitForm = async (reviewPayload: ChangeControlPayloadType) => {
    return changeControlApi
      .createReview(company, product.id, reviewPayload)
      .then(goToReviewList)
      .catch((e) => {
        showToast({
          id: 'submit-error',
          title: "We couldn't save your review.",
          description: 'Please try again later.',
          status: 'error',
          duration: 5000,
        });
      });
  };

  if (isError || isApproverError) {
    showToast({
      id: 'loading-error',
      title: "We couldn't load the form.",
      description: 'Please try again later.',
      status: 'error',
      duration: 5000,
    });
  }

  if (loading || isError || isApproverError) {
    return <Loading />;
  }

  const initialValues: ChangeControlPayloadType = {
    title: '',
    description: '',
    qualityItems: qualityItems as Array<ChangeControlQualityItemPayloadType>,
    approvers: [],
    linkedDocuments: [],
  };

  return (
    <>
      <FormHeader title="Create design review" productName={product.name} onClose={goToReviewList} />

      <DrawerProvider url={`/product/${product.id}/create-review`}>
        <ReviewForm
          product={product}
          configs={qualityConfigs}
          availableQualityItems={qualityItems}
          approvers={approvers}
          isApproversLoading={isApproverLoading}
          onSubmitForm={onSubmitForm}
          initialValues={initialValues}
        />
      </DrawerProvider>
    </>
  );
};
