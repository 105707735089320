import React, { useMemo } from 'react';

import { isTestCasePolicy } from '@design-controls/types';
import { QFormControl, QSelect } from '@qualio/ui-components';
import { Controller } from 'react-hook-form';

import { useCurrentConfigs } from '../../../hooks/useCurrentConfigs';

export const TestCaseType: React.FC = () => {
  const configs = useCurrentConfigs();

  const options = useMemo(() => {
    return configs.filter(isTestCasePolicy).map((config) => ({ label: config.label, value: config.type }));
  }, [configs]);

  return (
    <Controller
      name="type"
      render={({ field: { onChange, value } }) => (
        <QFormControl label="Test type">
          <QSelect
            value={value}
            onChange={(selectedItem) => selectedItem && onChange(selectedItem.value)}
            options={options}
          />
        </QFormControl>
      )}
    />
  );
};
