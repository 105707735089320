import * as React from 'react';

import { StyledInputLabel } from './Input.styles';

interface InputLabelProps {
  title: string;
  isError?: boolean;
  strong?: boolean;
}

const InputLabel: React.FC<InputLabelProps> = ({ title, isError, strong }) => {
  return (
    <StyledInputLabel size="sm" isError={isError}>
      {strong ? <strong>{title}</strong> : title}
    </StyledInputLabel>
  );
};

export default InputLabel;
