import { useCurrentUser } from '@qualio/ui-components';

import { UserPermissions } from '../../components';
import hasPermission from '../../components/UserGroupToggle/lib/hasPermission';

export type HasPermissionType = Record<string, boolean>;

const useHasPermission = (permissions: Array<UserPermissions>): HasPermissionType => {
  const { userGroups } = useCurrentUser();

  return permissions.reduce((table: HasPermissionType, permission): HasPermissionType => {
    table[permission] = hasPermission(userGroups, permission);
    return table;
  }, {});
};

export default useHasPermission;
