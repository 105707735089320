import React, { useMemo, useState } from 'react';

import { QBox, QButton, QSelectItem } from '@qualio/ui-components';
import { Outlet, useNavigate, useParams } from 'react-router-dom';

import { DrawerProvider } from '../../../components_new/DesginElementDetailsDrawer/context/DrawerProvider';
import { useDrawerProvider } from '../../../components_new/DesginElementDetailsDrawer/context/useDrawerProvider';
import { Loading } from '../../../components_new/Loading';
import { useProduct } from '../../../context/product';
import { useRouteParams } from '../../../hooks/useRouteParams';
import { ProductParam, SelectedItemParam } from '../../../paramTypes';
import { useTestCaseOptions } from '../hooks/useTestCaseOptions';
import { useTestCases } from '../hooks/useTestCases';
import TestCaseResultsPresentation from './TestCaseResultsPresentation';
import TestResultTable from './TestResultTable';

export const TestCaseResults: React.VFC = () => {
  const { product } = useParams() as ProductParam;

  return (
    <DrawerProvider url={`/product/${product}/testCase/results`}>
      <TestCaseResultsContainer />
    </DrawerProvider>
  );
};

const TestCaseResultsContainer: React.VFC = () => {
  const product = useProduct();
  const { selectedItemId } = useRouteParams<SelectedItemParam>('/product/:product/testCase/results/:selectedItemId');
  const { loading: configsLoading, testCaseSelectOptions } = useTestCaseOptions();
  const [selectedType, setSelectedType] = useState<QSelectItem | null>(testCaseSelectOptions[0]);
  const { loading: testCasesLoading, testCases } = useTestCases(product.id, selectedType?.value);
  const navigate = useNavigate();
  const { handleItemSelected, setSelectedDesignElement } = useDrawerProvider();

  const isLoading = useMemo<boolean>(() => {
    return configsLoading || testCasesLoading;
  }, [configsLoading, testCasesLoading]);

  const onClose = () => {
    navigate(`/product/${product.id}/requirement`);
  };

  return (
    <>
      <TestCaseResultsPresentation
        product={product}
        onClose={onClose}
        onTypeChange={setSelectedType}
        typeOptions={testCaseSelectOptions}
        selectedType={selectedType}
      >
        {isLoading ? (
          <Loading />
        ) : (
          <>
            <TestResultTable
              product={product}
              testCases={testCases}
              onRowClick={(testCaseId) => {
                handleItemSelected(testCaseId);
                setSelectedDesignElement({ id: testCaseId, version: 'v0' });
              }}
              selectedItemId={selectedItemId}
            />
            <QBox marginTop={'3'} display="flex" justifyContent="right">
              <QButton variant={'outline'} data-cy="test-result-footer-button" onClick={onClose}>
                Done
              </QButton>
            </QBox>
            <Outlet />
          </>
        )}
      </TestCaseResultsPresentation>
    </>
  );
};

export default TestCaseResultsContainer;
