import { ItemRelationType } from '../components/QualityItemDetails/types';

const relationTypeLabels: Record<ItemRelationType, string> = {
  [ItemRelationType.CHILD]: 'Child',
  [ItemRelationType.PARENT]: 'Parent',
  [ItemRelationType.MITIGATED_BY]: 'Mitigation',
  [ItemRelationType.MITIGATES]: 'Mitigates',
  [ItemRelationType.SOURCED_BY]: 'Source',
  [ItemRelationType.SOURCES]: 'Source of',
};

export function itemRelationTypeLabel(relationType: ItemRelationType): string {
  return relationTypeLabels[relationType];
}
