import React from 'react';

import { zodResolver } from '@hookform/resolvers/zod';
import { QAlert, QBox } from '@qualio/ui-components';
import { FormProvider, useForm } from 'react-hook-form';

import { ProductInfoForm } from '../../../components';
import { productConfiguration, ProductConfiguration } from '../../../types/product';

type ProductProps = {
  nextRef: React.RefObject<any>;
  data: ProductConfiguration;
  onNextHandler: (item: ProductConfiguration) => void;
};

export const Product: React.FC<ProductProps> = ({ nextRef, data, onNextHandler }) => {
  const methods = useForm<ProductConfiguration>({
    defaultValues: data,
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: zodResolver(productConfiguration),
  });

  const {
    formState: { errors },
  } = methods;

  return (
    <FormProvider {...methods}>
      {Object.keys(errors).length ? (
        <QBox mb={'16px'}>
          <QAlert description={'There were some errors'} data-cy="error-alert" status={'error'} />
        </QBox>
      ) : null}
      <ProductInfoForm />
      <button style={{ display: 'none' }} ref={nextRef} onClick={methods.handleSubmit(onNextHandler)} />
    </FormProvider>
  );
};
