import { RiskQualityTypeConfig } from '../../types/qualityTypeConfig';
import { FMEARiskAssessment } from '../../types/risk';

type HazardAssessment<T> = {
  probability: T;
  severity: T;
  riskLevel?: string;
};

export type MitigationValue = Partial<Pick<HazardAssessment<number>, 'probability' | 'severity'>>;

export const calculateRisk = (config: RiskQualityTypeConfig, mitigation: MitigationValue = {}): string | undefined => {
  if (
    mitigation.probability === undefined ||
    mitigation.probability === -1 ||
    mitigation.severity === undefined ||
    mitigation.severity === -1
  ) {
    return undefined;
  }

  return config.assessment.iso.levelMapping[mitigation.probability][mitigation.severity] || '';
};

export const calculateFmeaRisk = (mitigation?: Partial<FMEARiskAssessment>): number => {
  if (!mitigation) {
    return 0;
  }

  return (mitigation.occurrence || 0) * (mitigation.severity || 0) * (mitigation.detectability || 0);
};
