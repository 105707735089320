import React from 'react';

import { QFlex, QFlexProps } from '@qualio/ui-components';

type DetailsDrawerHeaderBodyProps = QFlexProps;

export const DetailsDrawerHeaderBody: React.FC<DetailsDrawerHeaderBodyProps> = ({ children, ...props }) => {
  return (
    <QFlex data-cy={'detail-drawer-header-body'} {...props}>
      {children}
    </QFlex>
  );
};

DetailsDrawerHeaderBody.displayName = DetailsDrawerHeaderBody.name;
