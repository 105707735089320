import { Payload } from '../types/payload';
import { RequirementTrace } from '../types/trace';
import api from './index';

const requirementTracesApi = {
  query(company: number, product: string): Promise<RequirementTrace[]> {
    return api
      .get(`/company/${company}/product-development/product/${product}/requirement`)
      .then((res) => res.data as Payload<RequirementTrace[]>)
      .then((res) => res.data); // unpack the response
  },
};

export default requirementTracesApi;
