import React, { useEffect } from 'react';

import { QBox, QButton, QSelect, QStack } from '@qualio/ui-components';
import { Outlet, useNavigate, useParams } from 'react-router-dom';

import { CreateDesignElementButton } from '../../components/CreateDesignElementButton/CreateDesignElementButton';
import UserGroupToggle from '../../components/UserGroupToggle/UserGroupToggle';
import { UserPermissions } from '../../components/UserGroupToggle/UserGroupToggle.enum';
import { DrawerProvider } from '../../components_new/DesginElementDetailsDrawer/context/DrawerProvider';
import { useAnalytics } from '../../context/analytics';
import { useCompanyId } from '../../context/CurrentUserContext';
import { useDeleteDesignElement } from '../../hooks';
import { ProductParam } from '../../paramTypes';
import { DesignElementType } from '../../types/baseQualityItem';
import { requirementFilters, RequirementTraceFilterTypes } from './components/filters';
import RequirementTraceTable from './components/RequirementTraceTable';
import { useFilteredRequirements } from './hooks/useFilteredRequirements';
import { useQueryRequirements } from './hooks/useQueryRequirements';
import { useRelatedDesignElementsForRequirements } from './hooks/useRelatedDesignElementsForRequirements';

const Requirement: React.FC = () => {
  const company = useCompanyId();
  const { product } = useParams<ProductParam>() as ProductParam;
  const analytics = useAnalytics();
  const navigate = useNavigate();

  useEffect(() => {
    analytics.track('PD List requirements', { company, product });
  }, [analytics, company, product]);

  const { isRequirementConfigured, configuredTestCases, isTestCasesConfigured } =
    useRelatedDesignElementsForRequirements();

  const { requirements, isLoading, refetch } = useQueryRequirements(product);

  const { deleteDesignElement, isDeleting } = useDeleteDesignElement(product, refetch);

  const { filteredRequirements, selectedFilter, setSelectedFilter } = useFilteredRequirements(requirements);

  const renderAddTestResultAndCreateDesignElementButtons = (
    requirementConfigured: boolean,
    testCasesConfigured: boolean,
  ) => {
    if (requirementConfigured || testCasesConfigured) {
      return (
        <>
          {testCasesConfigured && (
            <QButton
              variant={'outline'}
              data-cy="add-test-results-button"
              onClick={() => navigate(`/product/${product}/testCase/results`)}
            >
              Add test results
            </QButton>
          )}
          {requirementConfigured && (
            <CreateDesignElementButton
              product={product}
              requirements={requirementConfigured}
              isTestCasesConfigured={isTestCasesConfigured}
            />
          )}
        </>
      );
    }
    return undefined;
  };

  return (
    <DrawerProvider url={`/product/${product}/requirement`}>
      <QStack w={'full'} direction={'row'} mb={5} justifyContent={'space-between'}>
        <QBox w={200} data-cy="requirement-filter">
          <QSelect
            options={Object.entries(requirementFilters).map(([value, label]) => ({ value, label }))}
            filterOption={null}
            isDisabled={false}
            isLoading={false}
            isSearchable={false}
            value={selectedFilter}
            onChange={(option) => option && setSelectedFilter(option.value as RequirementTraceFilterTypes)}
          />
        </QBox>
        <QStack direction={'row'} alignItems={'flex-end'}>
          <UserGroupToggle userPermission={UserPermissions.CREATE_DESIGN_ELEMENTS}>
            {renderAddTestResultAndCreateDesignElementButtons(
              isRequirementConfigured,
              configuredTestCases.length !== 0,
            )}
          </UserGroupToggle>
        </QStack>
      </QStack>
      <RequirementTraceTable
        isLoading={isLoading || isDeleting}
        items={filteredRequirements}
        onDelete={(id: string, type: DesignElementType) => deleteDesignElement(id, type)}
      />
      <Outlet />
    </DrawerProvider>
  );
};

export default Requirement;
