import React, { useCallback } from 'react';

import { QualioQThemeProvider } from '@qualio/ui-components';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';

import { DrawerAwarenessProvider } from '../../components/CustomDrawer/context/DrawerAwarenessProvider';
import { ChangeControlParam, ProductParam } from '../../paramTypes';
import { useDrawerProvider } from '../DesginElementDetailsDrawer/context/useDrawerProvider';
import { QViewsStylingFix } from '../QViewsStylingFix/QViewsStylingFix';
import { ChangeControlDetailsDrawer } from './ChangeControlDetailsDrawer';

export const ChangeControlDrawerContainer: React.VFC = () => {
  const client = useQueryClient();
  const { handleItemSelected, handleGoBack, handleClose, setSelectedDesignElement } = useDrawerProvider();
  const { product, changeControlId } = useParams() as ProductParam & ChangeControlParam;

  const refetch = useCallback(async () => {
    await client.refetchQueries(`query-change-controls-${product}`);
  }, [client, product]);

  const onDesignElementClick = useCallback(
    (designElementId: string, designElementVersion?: string) => {
      setSelectedDesignElement({ id: designElementId, version: designElementVersion });
      handleItemSelected(`${changeControlId}/${designElementId}`);
    },
    [changeControlId, handleItemSelected, setSelectedDesignElement],
  );

  const onDrawerClose = useCallback(() => {
    setSelectedDesignElement(undefined);
    handleClose();
  }, [setSelectedDesignElement, handleClose]);

  return (
    <QViewsStylingFix>
      <QualioQThemeProvider>
        <DrawerAwarenessProvider>
          <ChangeControlDetailsDrawer
            isOpen={true}
            changeControlId={changeControlId}
            onClose={onDrawerClose}
            onUpdate={refetch}
            goBack={handleGoBack}
            onDesignElementClick={onDesignElementClick}
          />
        </DrawerAwarenessProvider>
      </QualioQThemeProvider>
    </QViewsStylingFix>
  );
};
