import React, { useEffect, useState } from 'react';

import { ColumnsType } from 'antd/lib/table';
import { useParams } from 'react-router-dom';

import itemsApi from '../../api/items';
import BorderedValue from '../../components/BorderedValue';
import DocumentIcon from '../../components/CustomIcons/Document';
import ItemCode from '../../components/ItemCode';
import { Messages, useMessagesContext, withMessages } from '../../components/Messages';
import { MessageType } from '../../components/Messages/types';
import StyledTable from '../../components/StyledTable';
import commonTableStyles from '../../components/StyledTable/commonStyles.module.less';
import { useAnalytics } from '../../context/analytics';
import { useCompanyId } from '../../context/CurrentUserContext';
import { useLocalisingDateFormatter } from '../../lib/dateFormat';
import { designControlStatus } from '../../lib/designControlStatus';
import { ProductParam } from '../../paramTypes';
import { BaseQualityItem } from '../../types/baseQualityItem';
import { ControlledDocument } from '../../types/controlledDocument';
import styles from '../Requirements/components/RequirementTraceTable/RequirementTraceTable.module.less';
import ExpandedDocument from './components/ExpandedDocument';

const Documents: React.FC = () => {
  const company = useCompanyId();
  const { product } = useParams<ProductParam>() as ProductParam;

  const [documents, setDocuments] = useState<ControlledDocument[]>([]);
  const [loading, setLoading] = useState(true);
  const { addMessage } = useMessagesContext();
  const analytics = useAnalytics();
  const dateFormat = useLocalisingDateFormatter();

  useEffect(() => {
    analytics.track('PD List documents', { company, product });
  }, [analytics, company, product]);

  useEffect(() => {
    setDocuments([]);
    setLoading(true);
    itemsApi
      .query<ControlledDocument>(company, product, 'document')
      .then(setDocuments)
      .catch((err) =>
        addMessage({
          type: MessageType.DANGER,
          title: 'Failed to load documents',
          text: err.message,
        }),
      )
      .finally(() => {
        setLoading(false);
      });
  }, [addMessage, company, product]);

  const columns: ColumnsType = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      render: (title: string, item: any) => {
        return (
          <span className={commonTableStyles.primaryColumn}>
            <aside className={commonTableStyles.primaryColumnIcon}>
              <DocumentIcon style={{ fontSize: 20 }} />
            </aside>

            <main className={commonTableStyles.primaryColumnContent}>
              {item.code && <ItemCode code={item.code} />} <strong>{title}</strong>
            </main>
          </span>
        );
      },
    },
    {
      title: 'Approvers',
      dataIndex: 'approver',
      key: 'approver',
      render: (approvers: any[]) => approvers?.length || 0,
    },
    {
      title: 'Reviewers',
      dataIndex: 'reviewer',
      key: 'reviewer',
      render: (reviewers: any[]) => reviewers?.length || 0,
    },
    {
      title: 'Release',
      dataIndex: 'version',
      key: 'version',
    },
    {
      title: 'Last Updated',
      dataIndex: 'updated',
      key: 'updated',
      render: (updated: string) => dateFormat(updated),
    },
    {
      title: 'Status',
      dataIndex: 'statusLabel',
      className: `${styles.topWorkflowColumn} ${commonTableStyles.rightAlignedColumn}`,
      render: (label: string) => <BorderedValue borderType={'rectangle'}>{label}</BorderedValue>,
    },
    {
      title: 'Design Control',
      className: `${styles.topWorkflowColumn} ${commonTableStyles.rightAlignedColumn}`,
      render: (item: BaseQualityItem) => (
        <BorderedValue borderType={'rectangle'}>{designControlStatus(item)}</BorderedValue>
      ),
    },
  ];

  return (
    <>
      <Messages />
      <StyledTable
        data-cy="documents-table"
        isLoading={loading}
        rowKey="id"
        columns={columns}
        dataSource={documents}
        expandable={{
          expandedRowRender: (record) => <ExpandedDocument key={record.id} qualityDocument={record} />,
        }}
      />
    </>
  );
};

export default withMessages(Documents);
