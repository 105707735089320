import React from 'react';

import { QFormControl, QInput } from '@qualio/ui-components';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';

type MatrixLabelsProps = {
  name: string;
};

const MatrixLabels: React.FC<MatrixLabelsProps> = ({ name }) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const { fields } = useFieldArray({
    control,
    name,
  });

  return (
    <>
      {fields.map((field, index) => (
        <div className="item" key={`${name}_${field.id}`}>
          <QFormControl label={''} isInvalid={!!errors?.[name]?.[index]}>
            <Controller
              name={`${name}.${index}.label`}
              defaultValue={(field as any).label}
              render={({ field: { onChange, value } }) => (
                <QInput value={value} onChange={onChange} data-cy={`matrix-label-${name}`} />
              )}
            />
          </QFormControl>
        </div>
      ))}
    </>
  );
};

export default MatrixLabels;
