import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';

import configApi from '../api/config';
import sourceConfigApi from '../api/sourceConfig';
import { FeatureFlags } from '../components/FeatureToggle/FeatureToggle.enum';
import useFeatureFlag from '../components/FeatureToggle/hooks/useFeatureFlag';
import { BaseQualityType, DesignElementType } from '../types/baseQualityItem';
import { SourceConfigResponse } from '../types/sourceConfig';
import { Prefix, QualityConfigs, QualityContextReset, QualityTypeConfigWithIntegrations } from './qualityConfigTypes';

export const QualityConfigsContext = createContext<QualityConfigs & QualityContextReset>({
  loading: false,
  configs: [],
  reset: () => undefined,
});

type QualityConfigsProviderProps = {
  company: number;
  product: string;
};

const ORDER: BaseQualityType[] = [
  DesignElementType.REQ1,
  DesignElementType.REQ2,
  DesignElementType.REQ3,
  DesignElementType.TEST_CASE,
  DesignElementType.TEST_LOG,
  DesignElementType.TEST_CASE_1,
  DesignElementType.TEST_CASE_2,
  DesignElementType.TEST_CASE_3,
  DesignElementType.RISK,
];

export const QualityConfigsProvider: React.FC<QualityConfigsProviderProps> = ({ company, product, children }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [configs, setConfigs] = useState<QualityTypeConfigWithIntegrations[]>([]);
  const [resetValue, setResetValue] = useState<boolean>(false);
  const features = useFeatureFlag([FeatureFlags.SELF_SERVICE]);

  useEffect(() => {
    setLoading(true);

    Promise.all([configApi.query(company, product), sourceConfigApi.query(company, product)])
      .then(([configResult, sourceConfigResult]) => {
        const relevantConfigs = configResult.filter((config) => ORDER.includes(config.type));
        const configsInOrder = relevantConfigs.sort((a, b) => ORDER.indexOf(a.type) - ORDER.indexOf(b.type));

        const sourceConfig = sourceConfigResult.reduce(
          (mappedResult: Record<string, SourceConfigResponse[]>, current) => {
            if (!mappedResult[current.type]) {
              mappedResult[current.type] = [];
            }

            mappedResult[current.type].push(current);

            return mappedResult;
          },
          {},
        );

        setConfigs(
          configsInOrder.map((item) => ({
            ...item,
            integrations: features[FeatureFlags.SELF_SERVICE]
              ? []
              : (sourceConfig[item.type] || []).map((source) => source.source),
          })),
        );
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company, product, resetValue]);

  const value: QualityConfigs & QualityContextReset = useMemo(
    () => ({
      loading,
      configs,
      reset: () => setResetValue(!resetValue),
    }),
    [configs, loading, resetValue],
  );

  return <QualityConfigsContext.Provider value={value}>{children}</QualityConfigsContext.Provider>;
};

export function useQualityConfigs(): QualityConfigs {
  const qualityConfigs = useContext(QualityConfigsContext);

  if (!qualityConfigs) {
    throw new Error('useQualityConfigs can only be used inside QualityConfigsProvider');
  }

  return qualityConfigs;
}

export const useResetQualityConfigs = (): QualityContextReset['reset'] => {
  const { reset } = useContext(QualityConfigsContext);
  return reset;
};

export const getExistingConfigPrefixes = (configs: Array<QualityTypeConfigWithIntegrations>): Prefix[] => {
  return configs.map((config: QualityTypeConfigWithIntegrations) => ({
    value: config.codePrefix,
    type: config.type,
  }));
};
