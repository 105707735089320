import { createContext, useContext } from 'react';

import { Product } from '@design-controls/types';

export const ProductContext = createContext<Product | undefined>(undefined);

export const useProduct = (): Product => {
  const product = useContext(ProductContext);

  if (!product) {
    throw new Error('useProduct can only e used inside ProductProvider');
  }

  return product;
};
