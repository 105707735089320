import React, { useCallback, useContext, useState } from 'react';

import { Message, MessageWithId } from './types';

type MessagesApi = {
  messages: MessageWithId[];
  addMessage(message: Message): void;
  removeMessage(messageId: MessageWithId['id']): void;
  clearMessages(): void;
};

const MessagesContext = React.createContext<MessagesApi | null>(null);

export const MessagesProvider: React.FC = ({ children }) => {
  const [messages, setMessages] = useState<MessageWithId[]>([]);

  const addMessage = useCallback(
    (message: Message) => {
      setMessages((prev) => [
        ...prev,
        {
          ...message,
          id: 1 + messages.length,
        } as MessageWithId,
      ]);
    },
    [setMessages, messages.length],
  );

  const removeMessage = useCallback(
    (messageId: MessageWithId['id']) => {
      setMessages((prev) => prev.filter((m) => messageId !== m.id));
    },
    [setMessages],
  );

  const clearMessages = useCallback(() => {
    setMessages([]);
  }, [setMessages]);

  return (
    <MessagesContext.Provider
      value={{
        messages,
        addMessage,
        removeMessage,
        clearMessages,
      }}
    >
      {children}
    </MessagesContext.Provider>
  );
};

export function useMessagesContext(): MessagesApi {
  const ctx = useContext(MessagesContext);
  if (!ctx) {
    throw new Error('useMessagesContext can only be used inside a MessagesContext provider');
  }

  return ctx;
}
