import { createContext, useContext } from 'react';

const AnalyticsContext = createContext<SegmentAnalytics.AnalyticsJS | null>(null);

export const AnalyticsProvider = AnalyticsContext.Provider;

export const useAnalytics = (): SegmentAnalytics.AnalyticsJS => {
  const analytics = useContext(AnalyticsContext);

  if (!analytics) {
    throw new Error('useAnalytics can only be used inside AnalyticsProvider');
  }

  return analytics;
};
