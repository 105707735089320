import React, { useCallback, useMemo } from 'react';

import { sortByTitle } from '../../../lib/sortFunctions';
import { mapDesignElementToSelectedElementType } from '../../DesignElementDisplay/lib/mapDesignElementToSelectedElementType';
import DesignElementGroup from '../../DesignElementGroup/DesignElementGroup';
import {
  SearchAndSelectElement,
  SearchAndSelectElementType,
} from '../../SearchAndSelectElements/SearchAndSelectElement';
import { RiskSourceMitigationType } from '../useQualityItemsNew';

type FMEARiskScoreSelectProps = {
  title: string;
  name: string;
  values: Array<RiskSourceMitigationType>;
  placeholder: string;
  options: Array<RiskSourceMitigationType>;
  configs: Record<string, string>;
  onChange: (e: Array<RiskSourceMitigationType>) => void;
  titleVariant?: boolean;
};
export const RiskSourceMitigationItemSelect: React.FC<FMEARiskScoreSelectProps> = ({
  title,
  name,
  values,
  options,
  placeholder,
  configs,
  onChange,
  titleVariant,
}) => {
  const qualityItemOptions: Array<SearchAndSelectElementType> = useMemo(() => {
    return mapDesignElementToSelectedElementType(options, configs);
  }, [options, configs]);

  const qualityItemValues: Array<SearchAndSelectElementType> = useMemo(() => {
    return mapDesignElementToSelectedElementType(values, configs);
  }, [values, configs]);

  const handleChange = useCallback(
    (selectedValues: Array<SearchAndSelectElementType>) => {
      onChange(selectedValues.map((value) => value.item as RiskSourceMitigationType));
    },
    [onChange],
  );

  return (
    <SearchAndSelectElement
      title={title}
      titleVariant={titleVariant}
      name={name}
      placeHolderText={placeholder}
      onChange={handleChange}
      value={qualityItemValues}
      data={qualityItemOptions}
      sorter={sortByTitle}
      selectedOptionGroupComponent={DesignElementGroup}
    />
  );
};
