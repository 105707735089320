import React, { useMemo, useState } from 'react';

import { isRequirementPolicy, RequirementList as RequirementListType } from '@design-controls/types';
import {
  createQColumnHelper,
  DataProvider,
  Filtering,
  QDataTable,
  TableMenuItem,
  QDeleteConfirmationModal,
  QText,
  Pagination,
} from '@qualio/ui-components';
import { Outlet } from 'react-router';

import { useDeleteRequirement } from '../../../hooks/designElement/useDeleteRequirement';
import { useRequirementList } from '../../../hooks/useRequirementsList';
import { OpenIssue } from '../../../types/openIssue';
import { CategoryFilter } from '../../filter/CategoryFilter';
import { ShowOnlyOpen } from '../../filter/ShowOnlyOpen';
import { filterDefinitions, ComponentFilter, RequirementType } from './filter';

const columnHelper = createQColumnHelper<RequirementListType>();

const filterOption = ((
  item: Readonly<OpenIssue>,
  searchTerm: string | undefined,
  filters: Filtering.ResolvedFilters<typeof filterDefinitions> | undefined,
) => {
  if (searchTerm && !item.title.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())) {
    return false;
  }

  if (filters && Object.values(filters).some((field) => field.value)) {
    const { show_only_open, type, category, component } = filters;

    return (
      (!show_only_open?.value || Object.values(item.policyIssues).some((issue) => issue)) &&
      (!type?.value || item.type === type.value) &&
      (!category?.value || category.value === item.category?.qri) &&
      (!component?.value || component.value === item.component?.qri)
    );
  }

  return true;
}) as DataProvider.FilterOptionFn<RequirementListType>;

export const RequirementList: React.FC = () => {
  const { isLoading, data } = useRequirementList();
  const { isLoading: isDeleting, mutate: deleteElement } = useDeleteRequirement();
  const [itemToDelete, setItemToDelete] = useState<RequirementListType | null>(null);

  const columns = useMemo(() => {
    return [
      columnHelper.code('code', { header: 'ID', maxWidth: '100px' }),
      columnHelper.textLink('title', (record) => record.id, {
        header: 'Requirement',
        weight: 1,
      }),
      columnHelper.text((record) => record.component?.label, { header: 'Component' }),
      columnHelper.text((record) => record.category?.label, { header: 'Category' }),
      columnHelper.date((record) => new Date(record.updated), { header: 'Last modified', maxWidth: '150px' }),
      columnHelper.menu({
        items: (
          <>
            <TableMenuItem onClick={console.log} data-cy="edit">
              Edit
            </TableMenuItem>
            <TableMenuItem
              onClick={(item: RequirementListType) => setItemToDelete(item)}
              color="red.500"
              data-cy="delete"
            >
              Delete
            </TableMenuItem>
          </>
        ),
        hide: (record) => record.source !== 'product-development',
      }),
    ];
  }, []);

  return (
    <>
      <Pagination.Auto pageSizeKey="size" pageIndexKey="page" clientSide>
        <Filtering.FilterProvider definitions={filterDefinitions} searchTermKey="search">
          <DataProvider.Fixed data={data ?? []} isLoading={isLoading} filterOption={filterOption}>
            <QDataTable columns={columns} data-cy="requirement-list" getRowId={(row) => row.id}>
              <Filtering.FormContent>
                <RequirementType />
                <ComponentFilter />
                <CategoryFilter policyFilterFn={isRequirementPolicy} />
                <ShowOnlyOpen />
              </Filtering.FormContent>
            </QDataTable>
          </DataProvider.Fixed>
        </Filtering.FilterProvider>
      </Pagination.Auto>
      <Outlet />
      {itemToDelete && (
        <QDeleteConfirmationModal
          title="Delete requirement"
          onConfirm={() => {
            itemToDelete &&
              deleteElement(itemToDelete as any, {
                onSettled: () => setItemToDelete(null),
              });
          }}
          inProgress={isDeleting}
          onClose={() => setItemToDelete(null)}
          message={
            <QText fontSize="sm">
              Are you sure you want to delete {itemToDelete.codeTitle}? You cannot undo this action.
            </QText>
          }
        />
      )}
    </>
  );
};
