import Select from 'react-select';
import styled from 'styled-components';

export const StyledSelect = styled(Select)<{ margin?: number; isError?: boolean }>`
  [class*='control'],
  [class*='control']:hover {
    border: 1px solid #6da1ff;
  }

  [class*='control'] {
    ${({ isError, theme }) => isError && `border-color: ${theme.colors.error.text} !important`};
  }

  [class*='indicatorSeparator'] {
    width: 0;
  }

  [class*='indicatorContainer'] svg {
    ${({ isError, theme }) => `fill: ${isError ? theme.colors.error.text : '#6da1ff'}`}
  }

  ${({ theme, margin }) => margin && `margin-bottom: ${theme.space[margin]}`};
`;
