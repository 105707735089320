import React from 'react';

import { QTheme } from '@qualio/ui-components';
import { themeGet } from '@styled-system/theme-get';
import styled, { css } from 'styled-components';

type Props = {
  active?: boolean;
  size?: 'xs';
  variant?: 'success' | 'error' | 'neutral';
} & React.HTMLAttributes<HTMLElement>;

const StyledPill = styled.span<Pick<Props, 'active' | 'size' | 'variant'>>`
  display: inline-flex;
  padding: ${themeGet('sizes.3')} ${themeGet('sizes.5')};
  background-color: ${themeGet('colors.gray.10')};
  border: ${themeGet('sizes.2')} solid ${themeGet('colors.gray.10')};
  border-radius: ${themeGet('radii.full')};
  cursor: pointer;

  &:hover {
    background-color: ${themeGet('colors.primary.10')};
    border-color: ${themeGet('colors.primary.10')};
    color: ${themeGet('colors.primary.100')};
  }

  ${(props) =>
    props.active &&
    css`
      border-color: ${themeGet('colors.primary.80')};
      color: ${themeGet('colors.primary.100')};
      background-color: ${themeGet('colors.primary.10')};

      &:hover {
        background-color: ${themeGet('colors.primary.20')};
        border-color: ${themeGet('colors.primary.80')};
      }
    `}

  ${(props) =>
    props.size === 'xs' &&
    css`
      padding: ${themeGet('sizes.3')} ${themeGet('sizes.4')};
    `}

  ${(props) =>
    props.variant === 'neutral' &&
    css`
      background-color: ${QTheme.colors.gray['100']};
      color: ${QTheme.colors.gray['800']};
      border: 0;
      font-weight: 600;
      &:hover {
        background-color: ${QTheme.colors.gray['100']};
        color: ${QTheme.colors.gray['800']};
        cursor: default;
      }
    `}

  ${(props) =>
    props.variant === 'success' &&
    css`
      background-color: ${QTheme.colors.green['100']};
      color: ${QTheme.colors.green['800']};
      border: 0;
      font-weight: 600;
      &:hover {
        background-color: ${QTheme.colors.green['100']};
        color: ${QTheme.colors.green['800']};
        cursor: default;
      }
    `}

  ${(props) =>
    props.variant === 'error' &&
    css`
      background-color: ${QTheme.colors.red['100']};
      color: ${QTheme.colors.red['800']};
      border: 0;
      font-weight: 600;
      &:hover {
        background-color: ${QTheme.colors.red['100']};
        color: ${QTheme.colors.red['800']};
        cursor: default;
      }
    `}
`;

const Pill: React.FC<Props> = StyledPill;
export default Pill;
