import styled from 'styled-components';

export const ScalingContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: ${(props) => props.theme.space['5']};

  & > div:first-child {
    flex-grow: 3;
  }

  & > div:last-child {
    flex-grow: 2;
    display: flex;

    & > div:first-child {
      margin-right: ${(props) => props.theme.space['5']};
    }

    & > div:last-child {
      flex-grow: 1;
    }
  }

  .text {
    line-height: 38px;
  }
`;

export const MitigationContainer = styled.div`
  width: 45%;

  > div {
    margin-bottom: ${(props) => props.theme.space['4']};
  }
`;
