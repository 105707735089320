export const sourceDisplayName = (source: string): string | null => {
  switch (source) {
    case 'asana':
      return 'Asana';
    case 'jira':
      return 'JIRA';
    case 'qualio':
      return 'Qualio';
    case 'xrayCloud':
      return 'X-Ray';
    case 'zephyrScale':
      return 'Zephyr Scale';
    case 'testrail':
      return 'TestRail';
    case 'azureDevops':
      return 'Azure Devops';
    default:
      return null;
  }
};
