import { QualityTypeConfigWithIntegrations } from '../context/qualityConfigTypes';
import { BaseQualityType, DesignElementType } from '../types/baseQualityItem';

export const requirementsTypes = new Set([DesignElementType.REQ1, DesignElementType.REQ2, DesignElementType.REQ3]);
export const testCaseTypes = new Set([
  DesignElementType.TEST_CASE_1,
  DesignElementType.TEST_CASE_2,
  DesignElementType.TEST_CASE_3,
]);
const requirementTestCaseMap: Record<string, string> = {
  req1: DesignElementType.TEST_CASE_1,
  req2: DesignElementType.TEST_CASE_2,
  req3: DesignElementType.TEST_CASE_3,
};

export const designControlNativeEnabled = (configs: QualityTypeConfigWithIntegrations[]): Array<BaseQualityType> => {
  return configs.filter((item) => (item.integrations || []).length === 0).map((item) => item.type);
};

export const showConfiguration = (configs: QualityTypeConfigWithIntegrations[], types: BaseQualityType[]): boolean => {
  return designControlNativeEnabled(configs).some((type) => types.includes(type));
};

export const showConfigurationGroup = (
  configs: QualityTypeConfigWithIntegrations[],
  types: BaseQualityType[],
): boolean => {
  if (!configs.some((config) => types.includes(config.type))) {
    return true;
  }

  return showConfiguration(configs, types);
};

export const retrieveRequirements = (
  configs: QualityTypeConfigWithIntegrations[],
): Array<QualityTypeConfigWithIntegrations> => {
  return configs.filter((item) => requirementsTypes.has(item.type));
};

export const getTestCaseTypeOrEmpty = (configs: QualityTypeConfigWithIntegrations[], reqType: string): string => {
  const nativeTestCaseTypes = new Set();
  configs.forEach((c) => {
    nativeTestCaseTypes.add(c.type);
  });

  if (nativeTestCaseTypes.has(requirementTestCaseMap[reqType])) {
    return requirementTestCaseMap[reqType];
  }

  if (nativeTestCaseTypes.has(DesignElementType.TEST_CASE)) {
    return DesignElementType.TEST_CASE;
  }

  return '';
};

export const retrieveNativeRequirements = (
  configs: QualityTypeConfigWithIntegrations[],
): QualityTypeConfigWithIntegrations[] => {
  return designControlNativeEnabled(configs)
    .filter((item) => requirementsTypes.has(item))
    .reduce((requirementConfigs: QualityTypeConfigWithIntegrations[], current) => {
      const found = configs.find((config) => config.type === current);
      if (found) {
        requirementConfigs.push(found);
      }
      return requirementConfigs;
    }, []);
};

const isTestCase = (designElement: BaseQualityType): boolean => {
  return testCaseTypes.has(designElement);
};

export const retrieveNativeTestCases = (
  allDesignElementConfigurations: QualityTypeConfigWithIntegrations[],
): QualityTypeConfigWithIntegrations[] => {
  return designControlNativeEnabled(allDesignElementConfigurations)
    .filter((designElement) => isTestCase(designElement))
    .reduce((testCaseConfigs: QualityTypeConfigWithIntegrations[], currentTestCase) => {
      const testCaseConfigFound = allDesignElementConfigurations.find(
        (designElement) => designElement.type === currentTestCase,
      );
      if (testCaseConfigFound) {
        testCaseConfigs.push(testCaseConfigFound);
      }
      return testCaseConfigs;
    }, []);
};
