export enum NativeTestCasePresentationalLabel {
  TITLE = 'Title',
  DESCRIPTION = 'Description',
  TESTCASE_TYPE = 'Test case type',
  STATUS_LABEL = 'Status Label',
}

export enum NativeTestCasePresentationalControllerName {
  TITLE = 'title',
  DESCRIPTION = 'description',
  TYPE = 'type',
  STATUS_LABEL = 'statusLabel',
  REQ1 = 'req1',
  REQ2 = 'req2',
  REQ3 = 'req3',
}
