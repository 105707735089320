import React from 'react';

import { zodResolver } from '@hookform/resolvers/zod';
import { QAlert, QBox } from '@qualio/ui-components';
import { FormProvider, useForm } from 'react-hook-form';

import { RequirementLevelsForm } from '../../../components';
import { Prefix } from '../../../context/qualityConfigTypes';
import { RequestValidationError } from '../../../types/requestValidationError';
import {
  requirementConfigurationSchemaProvider,
  RequirementConfigurationType,
} from '../../../types/requirementConfiguration';
import { CreateProductContainer } from './CreateProductContainer';

type RequirementProps = {
  prevRef: React.RefObject<any>;
  nextRef: React.RefObject<any>;
  data: RequirementConfigurationType;
  onNextHandler: (item: RequirementConfigurationType) => void;
  onPrevHandler: (item: RequirementConfigurationType) => void;
  usedPrefixes: Array<Prefix>;
  validationErrors: Array<RequestValidationError>;
};

export const Requirement: React.FC<RequirementProps> = ({
  prevRef,
  nextRef,
  data,
  onNextHandler,
  onPrevHandler,
  usedPrefixes,
  validationErrors,
}) => {
  const methods = useForm<RequirementConfigurationType>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: data,
    resolver: zodResolver(requirementConfigurationSchemaProvider(usedPrefixes, [])),
  });

  const {
    formState: { errors },
  } = methods;

  return (
    <FormProvider {...methods}>
      {Object.keys(errors).length ? (
        <QBox mb={'16px'}>
          <QAlert description={'There were some errors'} data-cy="error-alert" status={'error'} />
        </QBox>
      ) : null}
      <CreateProductContainer>
        <RequirementLevelsForm validationErrors={validationErrors} />
      </CreateProductContainer>
      <button style={{ display: 'none' }} ref={prevRef} onClick={methods.handleSubmit(onPrevHandler)} />
      <button style={{ display: 'none' }} ref={nextRef} onClick={methods.handleSubmit(onNextHandler)} />
    </FormProvider>
  );
};
