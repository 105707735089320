import React from 'react';

import { QFlex, QFlexProps } from '@qualio/ui-components';

type DisplayElementHeaderProps = QFlexProps;

export const DisplayElementHeader: React.FC<DisplayElementHeaderProps> = ({ children, ...props }) => {
  return (
    <QFlex data-cy={`display-element-header`} {...props}>
      {children}
    </QFlex>
  );
};

DisplayElementHeader.displayName = DisplayElementHeader.name;
