import React from 'react';

import { QFlex, QFlexProps } from '@qualio/ui-components';

type DisplayElementBodyProps = QFlexProps;

export const DisplayElementBody: React.FC<DisplayElementBodyProps> = ({ children, ...props }) => {
  return (
    <QFlex data-cy={`display-element-body`} {...props}>
      {children}
    </QFlex>
  );
};

DisplayElementBody.displayName = DisplayElementBody.name;
