import React from 'react';

import * as CKE from '@ckeditor/ckeditor5-react';
import { useCurrentUser } from '@qualio/ui-components';

import { getDocumentEditorConfig } from '../../config/DocumentEditorConfig';
import { EditorContainer } from './Editor.styles';
import { EditorTypes } from './EditorTypes';
import { extractInnerCKEditorHTMLData } from './lib';

const Editor: React.FC<EditorTypes> = ({ onChange, value }) => {
  const editValue = extractInnerCKEditorHTMLData(value);

  const { companyId } = useCurrentUser();

  return (
    <EditorContainer>
      <CKE.CKEditor
        editor={CKEditor5.editorClassic.ClassicEditor}
        config={getDocumentEditorConfig(companyId)}
        data={editValue}
        onChange={(_: any, editor: any) => {
          const data = editor.getData();
          onChange(`<div class="ck-content">${data}</div>`);
        }}
      />
    </EditorContainer>
  );
};

export default Editor;
