import React from 'react';

import { QBox } from '@qualio/ui-components';

export const DetailsDrawerFooterPadding: React.FC = () => {
  return (
    <QBox data-cy={'details-drawer-footer-padding'} mb={70}>
      {null}
    </QBox>
  );
};
