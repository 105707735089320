import { globalSearchApi } from '.';
import { SearchResponse } from '../../types/globalSearch';

export const GlobalSearchAPI = {
  async quickSearchResourceItem(companyId: string, resourceSubType: string) {
    const response = await globalSearchApi.get(
      `/v2/quicksearch?companyId=${companyId}&term=status:effective&resources=registry-item&resourceSubType=${resourceSubType}&size=100&page=1`,
    );

    return SearchResponse.parse(response.data);
  },
};
