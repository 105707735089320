import React, { useState } from 'react';

import { Navigate } from 'react-router-dom';

import {
  DeleteDesignElementPolicyModal,
  PolicyToBeDeleted,
  triggerPolicyDeletion,
} from '../../../components/DeleteDesignElementPolicyModal/DeleteDesignElementPolicyModal';
import { Messages, useMessagesContext, withMessages } from '../../../components/Messages';
import { Loading } from '../../../components_new/Loading';
import { useConfigurationView } from '../../../context/configurationView';
import { useCompanyId } from '../../../context/CurrentUserContext';
import { useProduct } from '../../../context/product';
import {
  useArchivePrefixes,
  useDeleteDesignElementConfiguration,
  useQueryDesignElementConfigurations,
  useUpdateDesignElementConfigurations,
} from '../../../hooks';
import { mapBaseQualityTypeToTestCases } from '../../../lib/mapBaseQualityTypeToConfigTypes';
import { allTestCases, DesignElementType } from '../../../types/baseQualityItem';
import { QualityTypeConfigWithType } from '../../../types/qualityTypeConfig';
import { mapTestCaseToDesignElementConfigType } from '../../../types/riskConfiguration';
import { TestCaseConfigurationType } from '../../../types/testCaseConfiguration';
import TestCasesSettingsForm from './Form';

const TestCaseConfigurationView: React.FC = () => {
  const { showTestCases } = useConfigurationView();
  const { id: productId } = useProduct();
  const companyId = useCompanyId();

  const { designElementConfigurations: testCasePolicies, isLoading } =
    useQueryDesignElementConfigurations<QualityTypeConfigWithType>(productId, allTestCases);

  const { archivedPrefixes } = useArchivePrefixes(productId);

  const { updateDesignElementConfigurations, validationErrors, isUpdating } = useUpdateDesignElementConfigurations(
    productId,
    testCasePolicies,
  );
  const { addMessage, clearMessages } = useMessagesContext();
  const { deleteDesignElementConfiguration, isDeleting } = useDeleteDesignElementConfiguration(productId);

  const [policyToBeDeleted, setPolicyToBeDeleted] = useState<PolicyToBeDeleted | undefined>();

  const onDeleteDialogClose = () => {
    setPolicyToBeDeleted(undefined);
  };

  const onDeleteDialogConfirmation = () => {
    if (policyToBeDeleted) {
      setPolicyToBeDeleted(undefined);
      deleteDesignElementConfiguration(policyToBeDeleted?.policyType);
    }
  };

  const handleSubmit = async (data: TestCaseConfigurationType) => {
    const items: QualityTypeConfigWithType[] = [];
    if (data.testCases.testCase1) {
      items.push({
        type: DesignElementType.TEST_CASE_1,
        ...mapTestCaseToDesignElementConfigType(data.testCases.testCase1),
      });
    }

    if (data.testCases.testCase2) {
      items.push({
        type: DesignElementType.TEST_CASE_2,
        ...mapTestCaseToDesignElementConfigType(data.testCases.testCase2),
      });
    }

    if (data.testCases.testCase3) {
      items.push({
        type: DesignElementType.TEST_CASE_3,
        ...mapTestCaseToDesignElementConfigType(data.testCases.testCase3),
      });
    }

    await updateDesignElementConfigurations(items);
  };

  if (isLoading || isDeleting) {
    return <Loading />;
  }

  if (!showTestCases) {
    return <Navigate to={`/product/${productId}/config/risk`} />;
  }

  const initialValues = mapBaseQualityTypeToTestCases(testCasePolicies);

  return (
    <>
      <Messages />
      <TestCasesSettingsForm
        onSubmit={handleSubmit}
        initialValue={initialValues}
        submitIsLoading={isUpdating}
        archivePrefixes={archivedPrefixes}
        validationErrors={validationErrors}
        onDelete={(testCaseType) => {
          const testCaseConfig = initialValues.testCases[testCaseType];
          testCaseConfig &&
            triggerPolicyDeletion(
              companyId,
              productId,
              testCaseType,
              testCaseConfig,
              setPolicyToBeDeleted,
              clearMessages,
              addMessage,
            );
        }}
      />
      <DeleteDesignElementPolicyModal
        onConfirm={onDeleteDialogConfirmation}
        onClose={onDeleteDialogClose}
        policyToBeDeleted={policyToBeDeleted}
      />
    </>
  );
};

export default withMessages(TestCaseConfigurationView);
