import React, { useCallback, useMemo } from 'react';

import { QualioQThemeProvider } from '@qualio/ui-components';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { DrawerAwarenessProvider } from '../../components/CustomDrawer/context/DrawerAwarenessProvider';
import { useProduct } from '../../context/product';
import { SelectedItemParam } from '../../paramTypes';
import { QViewsStylingFix } from '../QViewsStylingFix/QViewsStylingFix';
import { useDrawerProvider } from './context/useDrawerProvider';
import DesignElementDetailsDrawer from './DesignElementDetailsDrawer';

export const DesignElementDrawerContainer: React.VFC = () => {
  const { handleItemSelected, handleGoBack, handleClose, selectedDesignElement, setSelectedDesignElement } =
    useDrawerProvider();
  const { state } = useLocation();
  const navigate = useNavigate();
  const { id: productId } = useProduct();

  const onDrawerClose = useCallback(() => {
    setSelectedDesignElement(undefined);
    handleClose();
  }, [setSelectedDesignElement, handleClose]);

  const onDrawerGoBack = useCallback(() => {
    handleGoBack();
    if (!state?.customState?.id) {
      return;
    }

    setSelectedDesignElement({
      id: state.customState?.id,
      version: state.customState?.version,
    });
  }, [handleGoBack, setSelectedDesignElement, state]);

  const handleSelectedElementOnClick = useCallback(
    (designElementId: string, designElementVersion?: string) => {
      setSelectedDesignElement({ id: designElementId, version: designElementVersion });
      handleItemSelected(designElementId, selectedDesignElement);
    },
    [handleItemSelected, setSelectedDesignElement, selectedDesignElement],
  );

  const onChangeControlClick = useCallback(
    (changeControlId: string) => {
      navigate(`/product/${productId}/change-control/${changeControlId}`);
    },
    [navigate, productId],
  );

  const { selectedItemId } = useParams() as SelectedItemParam;
  const designElement = useMemo(() => {
    if (selectedItemId === selectedDesignElement?.id) {
      return selectedDesignElement;
    }

    return { id: selectedItemId, version: 'v0' };
  }, [selectedItemId, selectedDesignElement]);

  return (
    <QViewsStylingFix>
      <QualioQThemeProvider>
        <DrawerAwarenessProvider>
          <DesignElementDetailsDrawer
            isOpen={true}
            onClose={onDrawerClose}
            goBack={onDrawerGoBack}
            changeControlClick={onChangeControlClick}
            designElementId={designElement.id}
            designElementClick={handleSelectedElementOnClick}
            designElementVersion={designElement.version}
          />
        </DrawerAwarenessProvider>
      </QualioQThemeProvider>
    </QViewsStylingFix>
  );
};
