import React, { useCallback } from 'react';

import { QButton, QEmptyState, QEmptyStateButtonGroup, QEmptyStatePrimaryButton } from '@qualio/ui-components';
import { useNavigate } from 'react-router-dom';

import { UserPermissions } from '../../components';
import hasPermission from '../../components/UserGroupToggle/lib/hasPermission';
import { useUserGroups } from '../../context/CurrentUserContext';

export type NoRiskPolicyEmptyStateProps = {
  product: string;
};

export const NoRiskPolicyEmptyState: React.FC<NoRiskPolicyEmptyStateProps> = ({ product }) => {
  const [userGroups] = useUserGroups();
  const canEditPolicies = hasPermission(userGroups, UserPermissions.EDIT_DESIGN_ELEMENT_POLICIES);
  const navigate = useNavigate();

  const updatePolices = useCallback(() => navigate(`/product/${product}/config/risk`), [navigate, product]);

  return (
    <QEmptyState
      title={'Risk policies not yet defined'}
      subtitle={!canEditPolicies ? 'You do not have permission to configure the risk policy.' : undefined}
    >
      <QEmptyStateButtonGroup>
        <QEmptyStatePrimaryButton>
          {canEditPolicies && (
            <QButton onClick={updatePolices} data-cy="configure-risk-button">
              Configure your risk policy
            </QButton>
          )}
        </QEmptyStatePrimaryButton>
      </QEmptyStateButtonGroup>
    </QEmptyState>
  );
};
