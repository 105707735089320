import React, { useMemo } from 'react';

import { Product, Policy } from '@design-controls/types';
import { QPageLoader } from '@qualio/ui-components';
import { Navigate } from 'react-router';

import { useSelectedProduct } from '../hooks/useSelectedProduct';

export type ProductContext = {
  product: Product;
  policy: Policy[];
};

export const CurrentProductContext = React.createContext<ProductContext | null>(null);

export const CurrentProductProvider: React.FC = ({ children }) => {
  const { isLoading, product, configs } = useSelectedProduct();

  const value = useMemo((): ProductContext | null => {
    if (!product || !configs) {
      return null;
    }

    return { policy: configs, product };
  }, [configs, product]);

  if (!isLoading && !product) {
    return <Navigate to="/" />;
  }

  if (isLoading || !product || !configs) {
    return <QPageLoader />;
  }

  return <CurrentProductContext.Provider value={value}>{children}</CurrentProductContext.Provider>;
};
