import * as z from 'zod';

import { ResultOptions } from '../views/TestCaseResult/TestCaseResult.enums';
import { AttachmentWithId } from './generatePresignedUrl';

const errorMap: z.ZodErrorMap = () => {
  return { message: 'Invalid option' };
};

export const TestResult = z.object({
  result: z.object({
    label: z.string().min(1),
    value: z.union(
      [z.literal(ResultOptions.PASSED), z.literal(ResultOptions.FAILED), z.literal(ResultOptions.BLOCKED)],
      { errorMap },
    ),
  }),
  executedBy: z.string().min(1, { message: 'Required' }).max(255),
  notes: z.string().max(255).optional(),
  attachments: z.array(AttachmentWithId).optional(),
});

export type TestResultType = z.infer<typeof TestResult>;
export type TestResultSubmission = Omit<TestResultType, 'result'> & {
  testCase: string;
  result: ResultOptions;
  codeTitle: string;
};
