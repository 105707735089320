import React, { useCallback, useMemo } from 'react';

import { QBox, QButton, QStack, QText } from '@qualio/ui-components';
import prettyBytes from 'pretty-bytes';
import { useParams } from 'react-router-dom';

import { attachmentsApi } from '../../api/attachmentsApi';
import { useCompanyId } from '../../context/CurrentUserContext';
import { downloadFile } from '../../lib/downloadFile';
import { sortByAlphanumericInsensitive } from '../../lib/sortFunctions';
import { ProductParam } from '../../paramTypes';
import { AttachmentWithIdType } from '../../types/generatePresignedUrl';
import { ClickPropagationBarrier } from '../ClickPropogateBarrier/ClickPropogateBarrier';
import { DisplayElement, DisplayElementBody } from '../DisplayElement';

type AttachmentDisplayElementProps = {
  attachments: Array<AttachmentWithIdType>;
};

export const AttachmentDisplayElement: React.FC<AttachmentDisplayElementProps> = ({ attachments }) => {
  const companyId = useCompanyId();
  const { product } = useParams<ProductParam>() as ProductParam;

  const sortedAttachments = useMemo(
    () => [...attachments].sort((a, b) => sortByAlphanumericInsensitive(a.filename, b.filename)),
    [attachments],
  );

  const handleDownload = useCallback(
    (attachmentId: string) => {
      downloadFile(attachmentsApi.getDownloadUrl(companyId, product, attachmentId));
    },
    [companyId, product],
  );

  return (
    <>
      {sortedAttachments.map((attachment) => (
        <DisplayElement
          key={attachment.id}
          cursor="pointer"
          data-cy={`attachment-element-${attachment.id}`}
          onClick={() => handleDownload(attachment.id)}
        >
          <DisplayElementBody width="100%">
            <QStack direction="column" width="100%">
              <QStack direction="row" justify="space-between" alignItems="center">
                <QBox data-cy={`attachment-display-element`}>
                  <QText>{attachment.filename}</QText>
                  <QText color={'gray.500'} fontSize={'11'}>
                    {prettyBytes(attachment.filesize)}
                  </QText>
                </QBox>
                <ClickPropagationBarrier>
                  <QButton
                    data-cy={`attachment-download-button-${attachment.id}`}
                    rightIcon={'Download'}
                    onClick={() => handleDownload(attachment.id)}
                    variant="link"
                  >
                    &nbsp;
                  </QButton>
                </ClickPropagationBarrier>
              </QStack>
            </QStack>
          </DisplayElementBody>
        </DisplayElement>
      ))}
    </>
  );
};
