import React from 'react';

import { useMessagesContext } from './context';
import { FullMessage } from './FullMessage';
import styles from './Messages.module.less';
import { MessageStyle } from './types';

type Props = {
  style?: MessageStyle;
};

export const Messages: React.FC<Props> = ({ style = MessageStyle.FULL }) => {
  const { messages, removeMessage } = useMessagesContext();

  if (!messages.length) {
    return null;
  }

  const MessageComponent = FullMessage;

  return (
    <div className={styles.messagesContainer}>
      {messages.map((message) => (
        <MessageComponent key={message.id} message={message} onDismiss={removeMessage} />
      ))}
    </div>
  );
};
