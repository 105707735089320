import { itemRelationTypeLabel } from '../../lib/itemRelationTypeLabel';
import { BaseQualityItem } from '../../types/baseQualityItem';
import { BaseQualityItemTrace } from '../../types/qualityItemTrace';
import { QualityTypeConfig, QualityTypeConfigWithType } from '../../types/qualityTypeConfig';
import { RiskTrace } from '../../types/trace';
import { ItemRelationType, Relation } from './types';

function itemToRelation(
  relationType: ItemRelationType,
  { id, code, title, type, subType, itemStatus }: BaseQualityItem,
  config?: QualityTypeConfig,
): Relation {
  return {
    label: relationLabel(relationType, config),
    id,
    code,
    title,
    type,
    subType,
    itemStatus,
    relationLabel: relationType,
  };
}

function isRiskTrace(item: BaseQualityItemTrace | RiskTrace): item is RiskTrace {
  return item.type === 'risk';
}

function configForItem(configs: QualityTypeConfigWithType[], item: BaseQualityItem): QualityTypeConfig | undefined {
  return configs.find((c) => c.type === item.type);
}

function relationLabel(relationType: ItemRelationType, config?: QualityTypeConfig): string {
  if (relationType === ItemRelationType.PARENT || relationType === ItemRelationType.CHILD) {
    return config?.label || itemRelationTypeLabel(relationType);
  }

  return config
    ? `${config.label} (${itemRelationTypeLabel(relationType).toLowerCase()})`
    : itemRelationTypeLabel(relationType);
}

export function getRelations(
  configs: QualityTypeConfigWithType[],
  trace: BaseQualityItemTrace | RiskTrace,
): Relation[] {
  if (isRiskTrace(trace)) {
    return [
      ...(trace.sourceItems || []).map((item) =>
        itemToRelation(ItemRelationType.SOURCED_BY, item, configForItem(configs, item)),
      ),
      ...(trace.mitigationItems || []).map((item) =>
        itemToRelation(ItemRelationType.MITIGATED_BY, item, configForItem(configs, item)),
      ),
    ];
  }

  const relations: Relation[] = [];

  if (trace.parent) {
    relations.push(
      ...trace.parent.map((parent) => itemToRelation(ItemRelationType.PARENT, parent, configForItem(configs, parent))),
    );
  }

  relations.push(
    ...trace.children.map((child) => itemToRelation(ItemRelationType.CHILD, child, configForItem(configs, child))),
  );
  relations.push(
    ...trace.sourcedItems.map((child) =>
      itemToRelation(ItemRelationType.SOURCES, child, configForItem(configs, child)),
    ),
  );
  relations.push(
    ...trace.mitigates.map((child) => itemToRelation(ItemRelationType.MITIGATES, child, configForItem(configs, child))),
  );

  return relations;
}
