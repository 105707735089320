import React, { useCallback } from 'react';

import { QButton } from '@qualio/ui-components';

import DesignElementDisplay from '../DesignElementDisplay/DesignElementDisplay';
import { SearchAndSelectElementType } from './SearchAndSelectElement';

export interface SearchAndSelectElementOptionProps {
  selectedOptions: Array<SearchAndSelectElementType>;
  handleDeleteElement: (id: string) => void;
}

export const SearchAndSelectElementOption: React.FC<SearchAndSelectElementOptionProps> = ({
  selectedOptions,
  handleDeleteElement,
}) => {
  const handleClickEvent = useCallback(
    (e: React.MouseEvent) => {
      handleDeleteElement(e.currentTarget.getAttribute('target-id') as string);
    },
    [handleDeleteElement],
  );

  return (
    <>
      {selectedOptions.map((element: SearchAndSelectElementType) => (
        <DesignElementDisplay key={element.id} element={element}>
          <QButton
            target-id={element.id}
            data-cy={`selected-item-remove-${element.id}`}
            rightIcon={'X'}
            onClick={handleClickEvent}
            variant="link"
          >
            &nbsp;
          </QButton>
        </DesignElementDisplay>
      ))}
    </>
  );
};
