import * as z from 'zod';

export const productType = z.union([
  z.literal('Physical device only'),
  z.literal('Software only'),
  z.literal('Combination of physical device and software'),
]);

export const productConfiguration = z.object({
  name: z.string().min(1, { message: 'Product name is required' }).max(100, { message: 'Product name is too long' }),
  type: productType.optional(),
  tags: z.number().array().optional(),
});

export type ProductConfiguration = z.infer<typeof productConfiguration>;
