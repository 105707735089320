import React from 'react';

import { QDivider } from '@qualio/ui-components';
import { useParams } from 'react-router-dom';

import { Relation } from '../../../components/QualityItemDetails/types';
import { useCompanyId } from '../../../context/CurrentUserContext';
import { useTestLogElement } from '../../../hooks/designElement/useTestLogElement';
import { ProductParam } from '../../../paramTypes';
import { DesignElementType } from '../../../types/baseQualityItem';
import { ChangeHistory } from '../../../types/changeHistory';
import { BaseQualityItemTrace } from '../../../types/qualityItemTrace';
import { TestResult } from '../../TestResult/TestResult';
import { RequirementTestCaseDetailsDrawerBody } from './RequirementTestCaseDetailsDrawerBody';

export const OldTestCaseDetailsDrawerBody: React.FC<{
  designElement: BaseQualityItemTrace;
  relatedDesignElements: Array<Relation>;
  reviewHistory: ChangeHistory;
  configsMap: Record<DesignElementType, string>;
  reviewClick?: (id: string) => void;
  designElementClick: (designElementId: string, designElementVersion?: string) => void;
}> = ({ designElement, relatedDesignElements, reviewHistory, configsMap, reviewClick, designElementClick }) => {
  const result = relatedDesignElements.find((item) => item.type === DesignElementType.TEST_LOG);
  const relatedElements = relatedDesignElements.filter((item) => item.type !== DesignElementType.TEST_LOG);

  const companyId = useCompanyId();
  const { product: productId } = useParams<ProductParam>() as ProductParam;
  const { testResult } = useTestLogElement(companyId, productId, result?.id);

  return (
    <>
      <RequirementTestCaseDetailsDrawerBody
        attachments={[]}
        designElement={designElement}
        relatedDesignElements={relatedElements}
        reviewHistory={reviewHistory}
        configsMap={configsMap}
        reviewClick={reviewClick}
        designElementClick={designElementClick}
      >
        {testResult && (
          <>
            <TestResult testResult={testResult} />
            <QDivider />
          </>
        )}
      </RequirementTestCaseDetailsDrawerBody>
    </>
  );
};
