import React from 'react';

import { MessagesProvider } from './context';
export function withMessages<T extends React.FC<any>>(Component: T): T {
  return ((props: any) => (
    <MessagesProvider>
      <Component {...props} />
    </MessagesProvider>
  )) as T;
}
